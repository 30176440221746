export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAILED = 'ME_FAILED';
export const ME_INIT_USER_OPTIONS = 'ME_INIT_USER_OPTIONS';

export const AUTH_LOADING = 'AUTH_LOADING';

export const TOWN_LIST_LOADING = 'TOWN_LIST_LOADING';
export const TOWN_LIST_SUCCESS = 'TOWN_LIST_SUCCESS';
export const TOWN_LIST_FAILED = 'TOWN_LIST_FAILED';

export const WORKON_APPLICATION_LIST_SUCCESS = 'WORKON_APPLICATION_LIST_SUCCESS';
export const WORKON_APPLICATION_LIST_CLEAN = 'WORKON_APPLICATION_LIST_CLEAN';

export const SET_USER_OPTION = 'SET_USER_OPTION';
export const SAVE_USER_OPTIONS_LOADING = 'SAVE_USER_OPTIONS_LOADING';
export const SAVE_USER_OPTIONS_SUCCESS = 'SAVE_USER_OPTIONS_SUCCESS';
export const SAVE_USER_OPTIONS_FAILED = 'SAVE_USER_OPTIONS_FAILED';
