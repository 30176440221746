<template>
  <vinnuskoli-page>
    <b-row>
      <b-col>
        <h5 class="page-title">Viðvera</h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <b-input-group>
          <b-input-group-text slot="prepend" @click.prevent="changeDate(-1)">
            <i class="fa fa-fw fa-angle-left"></i>
          </b-input-group-text>
          <datepicker
            id="date"
            name="date"
            v-model="date"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            ref="datePickr"
            @input="loadGroups"
          ></datepicker>
          <b-input-group-text slot="append" @click.prevent="changeDate(1)">
            <i class="fa fa-fw fa-angle-right"></i>
          </b-input-group-text>
        </b-input-group>
      </b-col>
      <b-col>
        <b-form-select id="workplace" name="workplace" v-model="options.attendance.group" v-if="groupList.length > 0" @input="validateDate">
          <option :value="null">-- Veldu hóp --</option>
          <option v-for="group in groupList" :key="group.id" :value="group.id">
            {{ group.workplace.name }} - {{ group.name }} {{ group.date_from | moment('DD.MM.YYYY') }}-{{
              group.date_to | moment('DD.MM.YYYY')
            }}
          </option>
        </b-form-select>
        <div v-else>
          <i>Engir hópar eru skráðir á valinn dag, vinsamlegast veljið annan.</i>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4"
      ><!--v-if="workplace">-->
      <b-col>
        <b-alert :show="studentList.length > 0 && validate && validate.batch">
          <i class="fa fa-info-circle"></i>
          Ath! Ekki er hægt að breyta færslum þar sem búið er að læsa þeim vegna uppgjörs.
        </b-alert>
        <table class="table white-table" v-if="studentList.length > 0">
          <tr>
            <th>Nafn</th>
            <th>Kennitala</th>
            <th>Sérþarfir/Ofnæmi</th>
            <th>
              <div class="d-inline-block">Fj. tíma</div>
              <div class="d-inline-block ml-1" v-if="loggedInUserHasAnyPermission('write_attendance')">
                <b-form-input
                  id="hours"
                  name="hours"
                  type="text"
                  v-model="hours"
                  style="width: 55px; padding-top: 0.1rem; padding-bottom: 0.1rem"
                  :disabled="validate.batch"
                  @change="checkHours"
                ></b-form-input>
              </div>
            </th>
            <th v-if="this.projectList.length > 0">
              <div class="d-inline-block">Verkefni</div>
              <div class="d-inline-block ml-1" v-if="loggedInUserHasAnyPermission('write_attendance')">
                <b-form-select id="projectId" name="projectId" v-model="projectId" :disabled="validate.batch" @change="updateAllProjects">
                  <option :value="null">-- Veldu verkefni --</option>
                  <template v-if="projectList">
                    <option v-for="project in projectList" :key="project.id" :value="project.id">{{ project.name }}</option>
                  </template>
                </b-form-select>
              </div>
            </th>
            <th>Samtala unninna tíma á tímabili</th>
            <th>Fjarvist</th>
            <th>Athugasemd</th>
          </tr>
          <attendance-item
            v-for="student in studentList"
            :key="student.id"
            :item="student"
            :date="date"
            :saveInfo="saveInfo"
            :batch="validate.batch"
            :maxHours="validate.max_hours"
            :weekOld="validate.week_old"
            :projectList="projectList"
            :groupId="options.attendance.group"
          >
          </attendance-item>
        </table>
        <div v-else>
          <i v-if="validate && validate.vacation_day">
            Ekki er hægt að skrá viðveru þar sem búið er að skilgreina frídag fyrir valinn dag.
          </i>
          <i v-if="validate && !validate.weekday_valid">
            Ekki er hægt að skrá viðveru þar sem þessi hópur á ekki að vinna á völdum degi.
          </i>
          <i v-if="validate && validate.weekday_valid && !validate.vacation_day && this.options.attendance.group">
            Engir þátttakendur eru skráðir í valinn hóp, vinsamlegast veljið annan.
          </i>
        </div>
      </b-col>
    </b-row>
  </vinnuskoli-page>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import placement from '@/api/placement';
import core from '@/api/core';
import attendance from '@/api/attendance';
import AttendanceItem from '@/components/attendance/AttendanceItem.vue';

export default {
  name: 'Attendance',
  components: {
    Datepicker,
    AttendanceItem,
  },
  data() {
    return {
      date: moment().toDate(),
      hours: '',
      projectId: null,
      lang: is,
      loading: false,
      loadingProjects: false,
      groupList: [],
      projectList: [],
      studentList: [],
      saveInfo: false,
      validate: null,
      maxHours: '',
      options: {},
    };
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission', 'loggedInUserOptions']),
  },
  methods: {
    changeDate(change) {
      this.date = moment(this.date).add(change, 'days').toDate();
      this.loadGroups();
    },
    async validateDate() {
      if (this.options.attendance.group) {
        this.loading = true;
        try {
          const response = await placement.placementGroupDateValid(this.options.attendance.group, this.date);
          await this.setLoggedInUserOption({ option: 'attendance', value: this.options.attendance });
          await this.saveLoggedInUserOptions();
          this.validate = response.data;
          this.studentList = [];
          if (this.validate.weekday_valid && !this.validate.vacation_day) {
            this.loadStudents();
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      } else {
        this.studentList = [];
        await this.setLoggedInUserOption({ option: 'attendance', value: this.options.attendance });
        await this.saveLoggedInUserOptions();
      }
    },
    async loadAttendanceProjects() {
      this.loadingProjects = true;
      this.projectList = [];
      try {
        const response = await attendance.attendanceOverviewProjectList();
        this.projectList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loadingProjects = false;
      }
    },
    async loadGroups() {
      this.loading = true;
      this.groupList = [];
      try {
        const response = await core.dropdownPlacementgroupList([], this.date);
        this.groupList = response.data;
        if (this.options.attendance.group && !this.groupList.find(g => g.id === this.options.attendance.group)) {
          this.options.attendance.group = null;
          this.studentList = [];
          await this.setLoggedInUserOption({ option: 'attendance', value: this.options.attendance });
          await this.saveLoggedInUserOptions();
        } else if (this.options.attendance.group) {
          this.validateDate();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadStudents() {
      this.loading = true;
      this.studentList = [];
      this.hours = '';
      try {
        const response = await attendance.attendanceOverviewList(this.options.attendance.group, this.date);
        this.studentList = response.data.map(x => ({
          id: x.id,
          person: x.person,
          attendance_id: x.attendance ? x.attendance.id : null,
          hours: x.attendance ? x.attendance.hours : '',
          comment: x.attendance ? x.attendance.comment : '',
          attendance_type: x.attendance && x.attendance.attendance_type ? x.attendance.attendance_type : null,
          attendance_project: x.attendance && x.attendance.attendance_project ? x.attendance.attendance_project : null,
          period_hours_total: x.period_hours_total,
          period_max_hours: x.period_max_hours,
          special_needs: x.special_needs,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    updateAllProjects() {
      this.studentList.forEach(s => {
        this.$set(s, 'attendance_project', {});
        this.$set(s.attendance_project, 'id', this.projectId);
        this.saveInfo = true;
        this.$nextTick(() => {
          this.saveInfo = false;
        });
      });
      this.projectId = null;
    },
    updateAllHours() {
      this.studentList.forEach(s => {
        this.$set(s, 'hours', this.hours);
        this.saveInfo = true;
        this.$nextTick(() => {
          this.saveInfo = false;
        });
      });
    },
    async checkHours() {
      try {
        const response = await placement.placementGroupDateValid(this.options.attendance.group, this.date);
        await this.setLoggedInUserOption({ option: 'attendance', value: this.options.attendance });
        await this.saveLoggedInUserOptions();
        this.hours = this.hours !== '' ? this.hours.toString().replace(/,/g, '.') : '0';
        this.validate = response.data;
        if ((this.validate.max_hours == null || this.validate.max_hours >= this.hours) && this.hours >= 0) {
          this.updateAllHours();
          this.hours = '';
        } else {
          this.hours = '';
          this.displayError(`Skráning tíma mistókts. Hámarks fjöldi tíma hópsins á þessum degi er: ${this.validate.max_hours}.`);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
      saveLoggedInUserOptions: 'saveLoggedInUserOptions',
      setLoggedInUserOption: 'setLoggedInUserOption',
    }),
  },
  created() {
    this.options = {};

    this.options.attendance = {
      group: null,
      ...this.loggedInUserOptions.attendance,
    };

    if (this.$route.params.date) {
      this.date = moment(this.$route.params.date).toDate();
    }

    if (this.$route.params.group) {
      this.options.attendance.group = this.$route.params.group;
    }

    this.loadGroups();
    this.loadAttendanceProjects();
  },
};
</script>
