import Vue from 'vue';
import Router from 'vue-router';

import Login from '@/views/Login.vue';
import Workplace from '@/views/Workplace.vue';
import WorkplaceType from '@/views/WorkplaceType.vue';
import District from '@/views/District.vue';
import Users from '@/views/Users.vue';
import SelectTown from '@/views/SelectTown.vue';
import Access from '@/views/Access.vue';
import Period from '@/views/Period.vue';
import PaymentPeriod from '@/views/PaymentPeriod.vue';
import Group from '@/views/Group.vue';
import Groups from '@/views/Groups.vue';
import Student from '@/views/Student.vue';
import Attendance from '@/views/Attendance.vue';
import Absence from '@/views/Absence.vue';
import ApplicationList from '@/views/ApplicationList.vue';
import Application from '@/views/Application.vue';
import CreateApplication from '@/views/CreateApplication.vue';
import ApproveApplication from '@/components/application/ApproveApplication.vue';
import Batch from '@/views/Batch.vue';
import BillingOverview from '@/views/BillingOverview.vue';
import Templates from '@/views/Templates.vue';
import Statistics from '@/views/Statistics.vue';
import Dashboard from '@/views/Dashboard.vue';
import ExceptionList from '@/views/ExemptionList.vue';
import WagePeriod from '@/views/WagePeriod.vue';
import SendMessage from '@/views/SendMessage.vue';
import PeriodVacationDaysList from '@/components/period/PeriodVacationDaysList.vue';
import BatchCorrection from '@/views/BatchCorrection.vue';
import Search from '@/views/Search.vue';
import AttendanceProject from '@/views/AttendanceProject.vue';
import MassEmail from '@/views/MassEmail.vue';
import Payroll from '@/views/Payroll.vue';
import PlacementGroupPersonContacts from '@/views/PlacementGroupPersonContacts.vue';
import PlacementGroupPersons from '@/views/PlacementGroupPersons.vue';
import Help from '@/views/Help.vue';
import ReviewQuestions from '@/views/ReviewQuestions.vue';
import Diary from '@/views/Diary.vue';
import Versions from '@/views/Versions.vue';

import guards from './guards';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    // },
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/velja-sveitarfelag',
      name: 'SelectTown',
      component: SelectTown,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/innskraning',
      name: 'Login',
      component: Login,
    },
    {
      path: '/flokkun-starfsstada',
      name: 'WorkplaceType',
      component: WorkplaceType,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/starfsstadir',
      name: 'Workplace',
      component: Workplace,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/hverfi',
      name: 'District',
      component: District,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/notendur',
      name: 'Users',
      component: Users,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/thatttakendur',
      name: 'PlacementGroupPersons',
      component: PlacementGroupPersons,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/adstod',
      name: 'Help',
      component: Help,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/adstandendur',
      name: 'PlacementGroupPersonContacts',
      component: PlacementGroupPersonContacts,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/adgangur',
      name: 'Access',
      component: Access,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/timabil',
      name: 'Period',
      component: Period,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/launatimabil',
      name: 'PaymentPeriod',
      component: PaymentPeriod,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/launataxti',
      name: 'WagePeriod',
      component: WagePeriod,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/hopar',
      name: 'Groups',
      component: Groups,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/hopar/:id',
      name: 'Group',
      component: Group,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/hopar/:period/:group',
      name: 'GroupsWithParams',
      component: Groups,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/thatttakandi/:id',
      name: 'Student',
      component: Student,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/thatttakandi/:id/:group',
      name: 'StudentWithGroup',
      component: Student,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/vidvera',
      name: 'Attendance',
      component: Attendance,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/vidvera/:date/:group',
      name: 'AttendanceWithParams',
      component: Attendance,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/dagbok',
      name: 'Diary',
      component: Diary,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/dagbok/:date/:group',
      name: 'DiaryWithParams',
      component: Diary,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/fjarvistartegundir',
      name: 'Absence',
      component: Absence,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/umsoknir',
      name: 'ApplicationList',
      component: ApplicationList,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/umsokn/:id',
      name: 'Application',
      component: Application,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/skraumsokn',
      name: 'CreateApplication',
      component: CreateApplication,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/uppgjor',
      name: 'Batch',
      component: Batch,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/uppgjorsyfirlit',
      name: 'BillingOverview',
      component: BillingOverview,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/tilkynningar',
      name: 'Templates',
      component: Templates,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/tolfraedi',
      name: 'Statistics',
      component: Statistics,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/undanthagulisti',
      name: 'ExceptionList',
      component: ExceptionList,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/frammistodumat',
      name: 'ReviewQuestions',
      component: ReviewQuestions,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/afgreida/:id',
      name: 'ApproveApplication',
      component: ApproveApplication,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/skilabod/:group',
      name: 'SendMessage',
      component: SendMessage,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/timabil/:period/fridagar',
      name: 'PeriodVacationDaysList',
      component: PeriodVacationDaysList,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/leidrettingar',
      name: 'BatchCorrection',
      component: BatchCorrection,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/leit',
      name: 'Search',
      component: Search,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/verkefnategundir',
      name: 'AttendanceProject',
      component: AttendanceProject,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/fjoldapostur',
      name: 'MassEmail',
      component: MassEmail,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/launakerfi',
      name: 'Payroll',
      component: Payroll,
      beforeEnter: guards.requireAuthAndTown,
    },
    {
      path: '/utgafur',
      name: 'Versions',
      component: Versions,
      beforeEnter: guards.requireAuth,
    },
  ],
});
