<template>
  <vinnuskoli-page>
    <b-row>
      <b-col>
        <h5 class="page-title">Uppgjörsleiðréttingar</h5>
      </b-col>
      <b-col>
        <b-btn
          variant="primary"
          @click.prevent="correctionCreateModal"
          class="pull-right"
          v-if="loggedInUserHasAnyPermission('write_batch')"
        >
          <i class="fa fa-fw fa-plus-circle"></i>
          Bæta við
        </b-btn>
        <b-btn
          :disabled="!options.correction.period || !correctionList.some(x => x.isSelected)"
          variant="secondary"
          @click.prevent="createExtraBatchModal"
          class="pull-right mr-2"
          v-if="loggedInUserHasAnyPermission('write_batch')"
          v-b-tooltip.hover
          :title="
            !options.correction.period || !correctionList.some(x => x.isSelected)
              ? 'Veldu færslur og launatímabil til þess að stofna auka uppgjör'
              : ''
          "
        >
          <i class="fa fa-fw fa-plus-circle"></i>
          Skrá auka uppgjör
        </b-btn>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="4">
        <strong>Nafn</strong>
        <b-form-input id="name" name="name" v-model="options.correction.name" @change="loadList()"> </b-form-input>
      </b-col>
      <b-col cols="4">
        <strong>Launatímabil</strong>
        <b-form-select id="period" name="period" v-model="options.correction.period" @input="loadList()">
          <option :value="null">-- Veldu tímabil --</option>
          <option v-for="period in paymentPeriodList" :key="period.id" :value="period.id">{{ period.name }}</option>
        </b-form-select>
      </b-col>
      <b-col cols="4">
        <strong>Greitt</strong>
        <b-form-select id="locked" name="locked" v-model="options.correction.locked" @input="loadList()">
          <option :value="null">Bæði greitt og ógreitt</option>
          <option :value="true">Greitt</option>
          <option :value="false">Ógreitt</option>
        </b-form-select>
      </b-col>
    </b-row>
    <b-card no-body border-variant="light" class="mb-2">
      <div v-if="correctionList.length == 0 && !loading">
        <i>Engar leiðréttingar skráðar..</i>
      </div>
      <table class="table mb-0" v-if="correctionList.length > 0">
        <thead>
          <tr class="bg-gray-200 small">
            <td>
              <b-checkbox :checked="allSelected" @change="selectAll" v-if="loggedInUserHasAnyPermission('write_batch')" />
            </td>
            <th>Nafn</th>
            <th>Kennitala</th>
            <th>Launatímabil</th>
            <th>Vinnustaður</th>
            <th>Fj. tíma</th>
            <th>Upphæð</th>
            <th>Athugasemd</th>
            <th>Greitt</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="correction in correctionList" :key="correction.id">
            <td>
              <b-checkbox
                :disabled="correction.locked"
                v-model="correction.isSelected"
                v-if="loggedInUserHasAnyPermission('write_batch')"
              />
            </td>
            <td>{{ correction.person.name }}</td>
            <td>{{ correction.person.ssn }}</td>
            <td>{{ correction.payment_period.name }}</td>
            <td>{{ correction.person.placement_group.workplace.name }} - {{ correction.person.placement_group.name }}</td>
            <td>{{ correction.hours }}</td>
            <td>{{ correction.amount }}</td>
            <td>
              <div v-b-tooltip.hover :title="correction.comment">{{ correction.comment | limitTo(20) }}</div>
            </td>
            <td><span v-if="correction.locked">Já</span><span v-else>Nei</span></td>
            <td class="text-right">
              <b-btn
                :size="'sm'"
                variant="primary"
                @click.prevent="correctionEditModal(correction.id)"
                v-if="loggedInUserHasAnyPermission('write_batch') && !correction.locked"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </b-btn>
              <b-btn
                :size="'sm'"
                variant="danger"
                @click.prevent="deleteCorrection(correction)"
                v-if="loggedInUserHasAnyPermission('write_batch') && !correction.locked"
              >
                <i class="fa fa-fw fa-trash"></i>
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>

      <pagination
        v-if="pagination && pagination.totalPages > 1"
        :pagination-response="pagination"
        @change-limit="changeLimit"
        @change-page="changePage"
      >
      </pagination>
    </b-card>
    <correction-create-modal ref="correctionCreateModal" @successful="loadList()"></correction-create-modal>
    <correction-create-modal ref="correctionEditModal" @successful="loadList()"></correction-create-modal>
    <create-extra-batch-modal ref="createExtraBatchModal" @successful="createExtraBatchComplete"></create-extra-batch-modal>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import core from '@/api/core';
import payment from '@/api/payment';
import correctionCreateModal from '@/components/correction/CorrectionCreateModal.vue';
import CreateExtraBatchModal from '@/components/correction/CreateExtraBatchModal.vue';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'batch-correction',
  components: {
    correctionCreateModal,
    CreateExtraBatchModal,
    Pagination,
  },
  data() {
    return {
      loading: {
        list: false,
        period: false,
      },
      page: null,
      pagination: null,
      correctionList: [],
      paymentPeriodList: [],
      options: {},
    };
  },
  mixins: [paginationMixin],
  computed: {
    allSelected() {
      return this.correctionList.length > 0 ? this.correctionList.filter(x => !x.locked).every(x => x.isSelected) : false;
    },
    ...mapGetters(['loggedInUserOptions', 'loggedInUserHasAnyPermission']),
  },
  methods: {
    async loadList(page = null, limit = null) {
      this.loading.list = true;
      this.correctionList = [];
      try {
        const response = await payment.paymentBatchCorrectionList(page, limit, this.options.correction);
        this.correctionList = response.data.map(c => ({
          ...c,
          isSelected: false,
        }));
        this.pagination = parsePagination(response);
        await this.setLoggedInUserOption({ option: 'correction', value: this.options.correction });
        await this.saveLoggedInUserOptions();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async loadPaymentPeriod() {
      this.loading.period = true;
      this.paymentPeriodList = [];
      try {
        const response = await core.dropdownPaymentperiodList();
        this.paymentPeriodList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.period = false;
      }
    },
    async deleteCorrection(correction) {
      const conf = confirm(`Ertu viss um að þú viljir eyða leiðréttingu fyrir ${correction.person.name}`); //eslint-disable-line
      if (conf) {
        try {
          await payment.paymentBatchCorrectionDelete(correction.id);
          this.displaySuccess('Leiðréttingu hefur verið eytt');
          this.loadList();
        } catch (e) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },

    selectAll() {
      const allSelected = this.correctionList.length > 0 ? this.correctionList.every(x => x.isSelected) : false;

      this.correctionList = this.correctionList.map(d => ({
        ...d,
        isSelected: !d.locked && !allSelected,
      }));
    },

    selectAllWithValue(value) {
      this.correctionList = this.correctionList.map(d => ({
        ...d,
        isSelected: !d.locked && value,
      }));
    },

    /*
     * Modal gluggar
     */
    correctionCreateModal() {
      this.$refs.correctionCreateModal.show();
    },
    createExtraBatchModal() {
      this.$refs.createExtraBatchModal.show(
        this.options.correction.period,
        this.correctionList.filter(x => !x.locked && x.isSelected),
      );
    },
    createExtraBatchComplete(id) {
      this.$router.push({
        name: 'Batch',
        query: {
          id,
        },
      });
    },
    correctionEditModal(id) {
      this.$refs.correctionEditModal.show(id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
      saveLoggedInUserOptions: 'saveLoggedInUserOptions',
      setLoggedInUserOption: 'setLoggedInUserOption',
    }),
  },
  created() {
    this.options = {};

    this.options.correction = {
      period: null,
      name: '',
      locked: null,
      ...this.loggedInUserOptions.correction,
    };

    this.loadList();
    this.loadPaymentPeriod();
    /* this.options = {};

    this.options.batch = {
      selectedBatch: null,
      ...this.loggedInUserOptions.batch,
    }; */
  },
};
</script>

<style></style>
