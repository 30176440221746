<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Undanþágulisti</div>
      </b-col>
      <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_exemption')">
        <b-btn variant="primary" @click.prevent="exemptionCreateModal" class="pull-right">
          <i class="fa fa-fw fa-plus-circle"></i>
          Bæta við
        </b-btn>
      </b-col>
    </b-row>
    <b-card no-body border-variant="light" class="mb-2">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Nafn</th>
            <th>Kennitala</th>
            <th>Fæðingarár <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Raunverulegt fæðingarár"></i></th>
            <th>
              Fæðingarár í kerfi
              <i
                class="fa fa-fw fa-info-circle"
                v-b-tooltip.hover
                title="Fæðingarár sem er notað fyrir einstaklinginn í kerfinu.
                Hann mun geta sótt um starfsstaði miðað við þennan aldur og fá borgað miðað við hann."
              >
              </i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <td colspan="100"><loading /></td>
        </tbody>
        <tbody v-if="exemptionList.length == 0 && !loading">
          <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
        </tbody>
        <tbody v-if="!loading && exemptionList.length > 0">
          <tr v-for="exemption in exemptionList" :key="exemption.id">
            <td>{{ exemption.name }}</td>
            <td>{{ exemption.ssn }}</td>
            <td>{{ getBirthYear(exemption.ssn) }}</td>
            <td>{{ exemption.birth_year_override }}</td>
            <td class="text-right">
              <b-btn
                :size="'sm'"
                variant="primary"
                @click.prevent="exemptionEditModal(exemption.id)"
                v-if="loggedInUserHasAnyPermission('write_exemption')"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </b-btn>
              <b-btn
                :size="'sm'"
                variant="danger"
                @click.prevent="deleteExemption(exemption)"
                v-if="loggedInUserHasAnyPermission('write_exemption')"
              >
                <i class="fa fa-fw fa-trash"></i>
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <pagination
      v-if="pagination && pagination.totalPages > 1"
      :pagination-response="pagination"
      @change-limit="changeLimit"
      @change-page="changePage"
    >
    </pagination>

    <exemption-create-modal ref="exemptionCreateModal" @successful="loadList()"></exemption-create-modal>
    <exemption-create-modal ref="exemptionEditModal" @successful="loadList()"></exemption-create-modal>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import placement from '@/api/placement';
import ExemptionCreateModal from '@/components/exemption/ExemptionCreateModal.vue';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'period',
  components: {
    ExemptionCreateModal,
    Pagination,
  },
  mixins: [paginationMixin],
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission', 'loggedInUserOptions']),
  },
  data() {
    return {
      page: null,
      pagination: null,
      exemptionList: [],
      loading: false,
    };
  },
  methods: {
    async loadList(page = null, limit = null) {
      // eslint-disable-next-line
      this.loading = true;
      this.exemptionList = [];
      try {
        const response = await placement.placementExemptionList(page, limit);
        this.exemptionList = response.data;
        this.pagination = parsePagination(response);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    getBirthYear(ssn) {
      let birthyear = '';
      switch (ssn.substr(9, 1)) {
        case '8':
          birthyear = '18';
          break;
        case '9':
          birthyear = '19';
          break;
        case '0':
          birthyear = '20';
          break;
        case '1':
          birthyear = '21';
          break;
        default:
          birthyear = '20';
      }
      birthyear = `${birthyear}${ssn.substr(4, 2)}`;
      return birthyear;
    },
    async deleteExemption(item) {
      const conf = confirm(`Ertu viss um að þú viljir eyða undanþágu fyrir ${item.name}`); //eslint-disable-line
      if (conf) {
        try {
          await placement.placementExemptionDelete(item.id);
          this.displaySuccess('Undanþágu hefur verið eytt');
          this.loadList();
        } catch (e) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    /*
     * Modal gluggar
     */
    exemptionCreateModal() {
      this.$refs.exemptionCreateModal.show();
    },
    exemptionEditModal(id) {
      this.$refs.exemptionEditModal.show(id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
    // eslint-disable-next-line
  },
};
</script>

<style></style>
