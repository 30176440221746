<template>
  <div>
    <b-modal
      id="previewModal"
      title="Stofna uppgjör fyrir launatímabil"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <div class="alert alert-danger" v-if="errorList.length > 0">
            <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
          </div>
          <b-form-group
            label="Launatímabil:"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Launatímbil til að stofna bunka"
          >
            <b-form-select
              id="payment_period"
              name="payment_period"
              v-model="form.payment_period"
              required
              :state="submitted && errors.has('payment_period') ? false : ''"
              v-validate="'required'"
              data-vv-as="launatímabil"
            >
              <option v-for="period in paymentPeriodList" :key="period.id" :value="period">
                {{ period.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import payment from '@/api/payment';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'preview-batch-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      paymentPeriodList: [],
      form: {
        payment_period: null,
      },
    };
  },
  methods: {
    async loadPaymentPeriod() {
      this.loading = true;
      this.paymentPeriodList = [];
      try {
        const response = await core.dropdownPaymentperiodList();
        this.paymentPeriodList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          const response = await payment.paymentBatchCreate({
            payment_period: this.form.payment_period.id,
          });
          this.displaySuccess(`Uppgjör fyrir launatímabilið „${this.form.payment_period.name}“ hefur verið stofnað.`);
          this.$emit('successful', { id: response.data.id });
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
      }
    },
    show() {
      this.paymentPeriodList = [];
      this.form.payment_period = null;
      this.submitted = false;
      this.loading = false;
      this.loadPaymentPeriod();
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
