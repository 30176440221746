<template>
  <div>
    <b-modal
      id="createExtraBatchModal"
      title="Skrá auka uppgjör"
      size="lg"
      :no-close-on-backdrop="true"
      ok-title="Vista"
      :ok-disabled="submitting"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      ref="createExtraBatchModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <p>Smelltu á Vista til þess að stofna auka uppgjör fyrir {{ this.form.corrections.length }} færslur.</p>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'create-extra-batch-modal',
  data() {
    return {
      submitting: false,
      submitted: false,
      errorList: [],
      form: {
        paymentPeriod: null,
        corrections: [],
      },
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) {
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        // Engar villur í formi.
        this.submitting = true;
        try {
          // this.loading = true;
          const response = await this.$paymentApi.createExtraBatch({
            createExtraBatchRequest: {
              corrections: this.form.corrections.map(x => ({
                id: x.id,
              })),
              paymentPeriod: {
                id: this.form.paymentPeriod,
              },
            },
          });
          this.displaySuccess('Auka leiðrétting hefur verið skráð');
          this.$emit('successful', response.data.id);
          this.$refs.createExtraBatchModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.loading = false;
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.submitting = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
        this.loading = false;
      }
    },
    show(paymentPeriod, corrections) {
      this.form.paymentPeriod = paymentPeriod;
      this.form.corrections = corrections;
      this.submitted = false;
      this.$refs.createExtraBatchModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
