<template>
  <div>
    <b-modal
      id="periodCreateModal"
      :title="title"
      size="lg"
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <div class="alert alert-danger" v-if="errorList.length > 0">
            <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
          </div>
          <b-form-group
            label="Heiti:"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Heiti tímabils"
          >
            <b-form-input
              id="name"
              name="name"
              autofocus
              type="text"
              v-model="form.name"
              required
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="nafn"
            ></b-form-input>
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label="Dags. frá:"
                label-for="dateFrom"
                :state="submitted && (errors.has('dateFrom') || form.datesInValid) ? false : ''"
                :invalid-feedback="errors.first('dateFrom')"
                description="Upphafsdagsetning tímabils"
              >
                <datepicker
                  id="dateFrom"
                  name="dateFrom"
                  placeholder="Veldu dags frá"
                  :class="{ 'is-invalid': submitted && (errors.has('dateFrom') || form.datesInValid) }"
                  v-model="form.dateFrom"
                  :language="lang"
                  :monday-first="true"
                  format="dd.MM.yyyy"
                  :typeable="false"
                  data-vv-as="dags frá"
                  v-validate="'required'"
                  ref="dateFromPickr"
                  @selected="changeDateTo"
                ></datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Dags. til:"
                label-for="dateTo"
                :state="submitted && (errors.has('dateTo') || form.datesInValid) ? false : ''"
                :invalid-feedback="errors.first('dateTo')"
                description="Endadagsetning tímabils"
              >
                <datepicker
                  id="dateTo"
                  name="dateTo"
                  placeholder="Veldu dags til"
                  :class="{ 'is-invalid': submitted && (errors.has('dateTo') || form.datesInValid) }"
                  v-model="form.dateTo"
                  :language="lang"
                  :monday-first="true"
                  format="dd.MM.yyyy"
                  :typeable="false"
                  data-vv-as="dags til"
                  v-validate="'required'"
                  ref="dateToPickr"
                ></datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="submitted && form.datesInValid" class="text-danger" style="margin-top: -1rem; margin-bottom: 0.5rem">
            <b-col> Dags. frá verður að vera á undan Dags.til </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div>
                Hámarksvinnustundir miðað við aldur:
                <i
                  class="fa fa-fw fa-info-circle"
                  v-b-tooltip.hover
                  :title="`Viðmið hámarksfjölda vinnustunda, einungis til upplýsinga í viðverulista og á umsóknarvef.`"
                ></i>
              </div>
              <b-form-group :state="submitted && errors.has('age') ? false : ''" :invalid-feedback="errors.first('age')">
                <div v-for="(item, index) in form.ages" :key="index" class="mb-2">
                  <div class="d-inline-block pr-2">
                    <div style="font-size: 0.75rem">Aldur þátttakenda</div>
                    <b-form-input
                      :id="`item${index}`"
                      :name="`item${index}`"
                      ref="itemFormInput"
                      type="number"
                      v-model="item.age"
                      min="1"
                      style="width: 110px"
                      v-on:keydown.enter="addMaxAge(form)"
                    ></b-form-input>
                  </div>

                  <div class="d-inline-block">
                    <div style="font-size: 0.75rem">Viðmiðunarfjöldi vinnustunda</div>
                    <b-form-input
                      :id="`max_hours${index}`"
                      :name="`max_hours${index}`"
                      ref="max_hoursFormInput"
                      type="number"
                      v-model="item.max_hours"
                      min="1"
                      style="width: 170px"
                      v-on:keydown.enter="addMaxAge(form)"
                    ></b-form-input>
                  </div>
                  <div class="d-inline-block ml-1" v-if="getYear(item.age)">({{ getYear(item.age) }})</div>
                  <div class="d-inline-block">
                    <i class="fa fa-fw fa-trash" style="cursor: pointer" @click.prevent="removeMaxAge(form, index)"></i>
                  </div>
                </div>
                <b-button size="sm" @click="addMaxAge(form)" variant="default">
                  <i class="fa fa-fw fa-plus-circle"></i>
                  Bæta við línu
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
            label="Upplýsingar til umsækjanda:"
            label-for="applyInfo"
            description="Upplýsingar til umsækjanda sem birtast í lok umsóknarferlis."
          >
            <b-form-input id="applyInfo" maxlength="4000" name="applyInfo" autofocus type="text" v-model="form.applyInfo"></b-form-input>
          </b-form-group>

          <b-form-group label="Má sækja um:" label-for="canApply" description="Hvort þátttakendur megi sækja um tímabilið eða ekki">
            <input type="checkbox" id="canApply" name="canApply" v-model="form.openForApplication" />
            <!--b-form-checkbox id="can_apply" name="can_apply"
                      :value="true"
                      :unchecked-value="false"
                              v-model="open_for_application"
                ></b-form-checkbox-->
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group label="Mörg starfstímabil:" label-for="hasParts" description="Hvort mörg starfstímabil séu innan tímabilsins">
                <input type="checkbox" id="hasParts" name="hasParts" v-model="form.hasParts" @change="updatePeriods" />
                <!--b-form-checkbox id="hasParts" name="hasParts"
                                  v-model="form.has_parts"
                                  :value="true"
                                  :unchecked-value="false"
                                  @change="updatePeriods"
                    ></b-form-checkbox-->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form.hasParts">
            <b-col>
              <b-form-group
                label="Fj. sækja um:"
                label-for="numPartsApplication"
                description="Hversu mörg starfstímabil þátttakendur mega sækja um. Eingöngu hægt ef aldurstakmörkun er ekki."
              >
                <b-form-input
                  id="numPartsApplication"
                  name="numPartsApplication"
                  type="text"
                  v-model="form.numPartsApplication"
                  required
                  :state="submitted && errors.has('numPartsApplication') ? false : ''"
                  :disabled="form.ageExclusionParts"
                  v-validate="'required'"
                  data-vv-as="fj sækja um"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Aldurstakmörkun:"
                label-for="ageExclusionParts"
                description="Hvort eigi að takmarka starfstímabil við aldur"
              >
                <input
                  type="checkbox"
                  id="ageExclusionParts"
                  name="ageExclusionParts"
                  v-model="form.ageExclusionParts"
                  @change="form.numPartsApplication = 1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="form.hasParts">
            <div v-for="(period, index) in form.periods" :key="index">
              <div>Starfstímabil númer {{ index + 1 }}</div>
              <b-row class="mt-2" :class="{ 'mb-2': index != form.periods.length - 1 }">
                <b-col>
                  <b-form-group
                    label="Dags. frá:"
                    :label-for="`dateFrom${index}`"
                    :state="submitted && (errors.has(`dateFrom${index}`) || period.invalid || !period.inside) ? false : ''"
                    :invalid-feedback="errors.first(`dateFrom${index}`)"
                    description="Upphafsdagsetning starfstímabils"
                  >
                    <datepicker
                      :id="`dateFrom${index}`"
                      :name="`dateFrom${index}`"
                      placeholder="Veldu dags frá"
                      :class="{ 'is-invalid': submitted && (errors.has(`dateFrom${index}`) || period.invalid || !period.inside) }"
                      v-model="period.dateFrom"
                      :language="lang"
                      :monday-first="true"
                      format="dd.MM.yyyy"
                      :typeable="false"
                      data-vv-as="dags frá"
                      v-validate="'required'"
                      :ref="`dateFromPickr${index}`"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Dags. til:"
                    :label-for="`dateTo${index}`"
                    :state="submitted && (errors.has(`dateTo${index}`) || period.invalid || !period.inside) ? false : ''"
                    :invalid-feedback="errors.first(`dateTo${index}`)"
                    description="Endadagsetning starfstímabils"
                  >
                    <datepicker
                      :id="`dateTo${index}`"
                      :name="`dateTo${index}`"
                      placeholder="Veldu dags til"
                      :class="{ 'is-invalid': submitted && (errors.has(`dateTo${index}`) || period.invalid || !period.inside) }"
                      v-model="period.dateTo"
                      :language="lang"
                      :monday-first="true"
                      format="dd.MM.yyyy"
                      :typeable="false"
                      data-vv-as="dags til"
                      v-validate="'required'"
                      :ref="`dateToPickr${index}`"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="1">
                  <b-button @click="removePeriod(index)" variant="secondary" size="sm" style="margin-top: 30px">
                    <i class="fa fa-fw fa-trash"></i>
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="form.ageExclusionParts">
                <b-col>
                  <b-form-group
                    label="Aldurstakmörkun:"
                    label-for="ageExclusion"
                    :state="submitted && errors.has('ageExclusion') ? false : ''"
                    :invalid-feedback="errors.first('ageExclusion')"
                    description="Sláðu inn fæðingarár"
                  >
                    <div v-for="(age, index) in period.ageExclusion" :key="index" class="mb-2">
                      <div class="d-inline-block">
                        <b-form-input
                          :id="`age${index}`"
                          :name="`age${index}`"
                          ref="ageFormInput"
                          type="number"
                          v-model="age.id"
                          min="1"
                          style="width: 100px"
                          v-on:keydown.enter="addAge(period)"
                          v-on:keydown.tab="addAge(period)"
                        ></b-form-input>
                      </div>
                      <div class="d-inline-block">
                        <i class="fa fa-fw fa-trash" style="cursor: pointer" @click.prevent="removeAge(period, index)"></i>
                      </div>
                    </div>
                    <b-button size="sm" @click="addAge(period)" variant="default">
                      <i class="fa fa-fw fa-plus-circle"></i>
                      Bæta við ári
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-if="submitted && (period.invalid || !period.inside || period.yearInvalid || period.yearEmpty)"
                class="text-danger"
                style="margin-top: -1rem; margin-bottom: 0.5rem"
              >
                <b-col>
                  <div v-if="period.invalid">Dags. frá verður að vera á undan Dags.til</div>
                  <div v-if="!period.inside">Dagsetningarnar verða að vera innan tímabils</div>
                  <div v-if="!period.yearInvalid">Ekki löglegt fæðingarár</div>
                  <div v-if="!period.yearEmpty">Fæðingarár er skilyrði þegar aldurstakmörkun er valin.</div>
                </b-col>
              </b-row>
            </div>
            <b-btn variant="secondary" size="sm" @click.prevent="addPeriod">
              <i class="fa fa-fw fa-plus-circle"></i>
              Bæta við starfstímabili
            </b-btn>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'period-create-modal',
  components: {
    Datepicker,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      periodId: '',
      title: 'Stofna tímabil',
      form: {
        name: '',
        dateFrom: '',
        dateTo: '',
        datesInValid: false,
        hasParts: false,
        applyInfo: '',
        openForApplication: false,
        numPartsApplication: 1,
        ageExclusionParts: false,
        ages: [{ age: '', max_hours: '' }],
        periods: [
          {
            dateFrom: '',
            dateTo: '',
            invalid: false,
            inside: true,
            yearInvalid: false,
            yearEmpty: false,
            ageExclusion: [
              {
                id: undefined,
              },
            ],
          },
        ],
      },
      lang: is,
    };
  },
  mounted() {
    this.$refs.dateFromPickr.$el.querySelector('input').autocomplete = 'off';
    this.$refs.dateToPickr.$el.querySelector('input').autocomplete = 'off';
  },
  methods: {
    addPeriod() {
      let dateFrom = '';
      let dateTo = '';
      if (this.form.periods.length > 0) {
        if (this.form.periods[this.form.periods.length - 1].dateTo !== undefined) {
          dateFrom = moment(this.form.periods[this.form.periods.length - 1].dateTo)
            .add(1, 'days')
            .toDate();
          dateTo = moment(this.form.periods[this.form.periods.length - 1].dateTo)
            .add(2, 'days')
            .toDate();
        }
      } else {
        dateFrom = this.form.dateFrom;
        dateTo = this.form.dateTo;
      }
      this.form.periods.push({
        dateFrom,
        dateTo,
        invalid: false,
        inside: true,
        yearInvalid: false,
        yearEmpty: false,
        ageExclusion: [
          {
            id: undefined,
          },
        ],
      });
    },
    removePeriod(index) {
      this.form.periods.splice(index, 1);
    },
    addAge(period) {
      period.ageExclusion.push({
        id: undefined,
      });
      setTimeout(() => {
        this.$refs.ageFormInput[this.$refs.ageFormInput.length - 1].focus();
      });
    },
    removeAge(period, index) {
      period.ageExclusion.splice(index, 1);
    },
    addMaxAge(form) {
      form.ages.push({
        age: '',
        max_hours: '',
      });
      setTimeout(() => {
        this.$refs.itemFormInput[this.$refs.itemFormInput.length - 1].focus();
      });
    },
    removeMaxAge(form, index) {
      form.ages.splice(index, 1);
    },
    getYear(age) {
      if (age && this.form.dateTo) {
        const born = moment(this.form.dateTo).subtract(age, 'year');
        return born.format('YYYY');
      }
      return '';
    },
    async load() {
      this.loading = true;
      try {
        const response = await this.$coreApi.periodRetrieve({
          uuid: this.periodId,
        });
        this.form.name = response.data.name;
        this.form.dateFrom = response.data.dateFrom;
        this.form.dateTo = response.data.dateTo;
        this.form.hasParts = response.data.hasParts;
        this.form.applyInfo = response.data.applyInfo;
        this.form.openForApplication = response.data.openForApplication;
        this.form.numPartsApplication = response.data.numPartsApplication;
        this.form.ageExclusionParts = response.data.ageExclusionParts;
        this.form.ages =
          response.data.ages.length > 0
            ? response.data.ages.map(x => ({
                id: x.id,
                age: x.age,
                max_hours: Number(x.maxHours),
              }))
            : [{ age: '', max_hours: '' }];

        this.form.periods = this.form.hasParts
          ? response.data.parts.map(x => ({
              dateFrom: moment(x.dateFrom).toDate(),
              dateTo: moment(x.dateTo).toDate(),
              invalid: false,
              inside: true,
              yearInvalid: false,
              yearEmpty: false,
              ageExclusion: x.ageExclusion.split(',').map(y => ({ id: y })),
              id: x.id,
            }))
          : [
              {
                dateFrom: '',
                dateTo: '',
                invalid: false,
                inside: true,
                yearInvalid: false,
                yearEmpty: false,
                ageExclusion: [
                  {
                    id: undefined,
                  },
                ],
              },
            ];
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    validateDates() {
      let valid = true;
      const dateFrom = moment(this.form.dateFrom);
      const dateTo = moment(this.form.dateTo);
      if (dateTo.isBefore(dateFrom)) {
        this.form.datesInValid = true;
        valid = false;
      } else {
        this.form.datesInValid = false;
      }
      if (this.form.hasParts) {
        this.form.periods.forEach(p => {
          // Dags frá á undan dags til
          if (moment(p.dateTo).isBefore(moment(p.dateFrom))) {
            this.$set(p, 'invalid', true);
            valid = false;
          } else {
            this.$set(p, 'invalid', false);
          }
          // Innan tímabils
          if (!moment(p.dateFrom).isBetween(dateFrom, dateTo, null, '[]') || !moment(p.dateTo).isBetween(dateFrom, dateTo, null, '[]')) {
            valid = false;
            this.$set(p, 'inside', false);
          } else {
            this.$set(p, 'inside', true);
          }

          // Validate ár
          if (this.form.ageExclusion) {
            if (p.ageExclusion.filter(x => x.id.length === 0)) {
              this.$set(p, 'yearEmpty', true);
            } else {
              this.$set(p, 'yearEmpty', false);
              let yearsValid = true;
              p.ageExclusion
                .filter(x => x.id.length === 0)
                .forEach(a => {
                  if (!(a > 1900)) {
                    yearsValid = false;
                  }
                });

              this.$set(p, 'yearInvalid', yearsValid);
            }
          }
        });
      }

      return valid;
    },

    hasMaxHours(item) {
      return !item.find(x => x.age === '');
    },

    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response || this.validateDates() === false) {
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
          // Engar villur í formi.
        });

        // Ef það er ekki valið að hafa mörg starfstímabil þá er samt bætt við eitt..
        const defaultPeriodParts = [
          {
            date_from: moment(this.form.dateFrom).format('YYYY-MM-DD'),
            date_to: moment(this.form.dateTo).format('YYYY-MM-DD'),
            age_exclusion: '',
          },
        ];

        try {
          this.loading = true;
          if (this.periodId) {
            await core.periodUpdate(this.periodId, {
              name: this.form.name,
              has_parts: true, // should always be true?
              num_parts_application: this.form.hasParts ? this.form.numPartsApplication : 1,
              apply_info: this.form.applyInfo,
              open_for_application: this.form.openForApplication,
              age_exclusion_parts: this.form.ageExclusionParts,
              ages: this.hasMaxHours(this.form.ages) ? this.form.ages : [],
              parts: this.form.hasParts
                ? this.form.periods.map(x => ({
                    id: x.id ? x.id : null,
                    date_from: moment(x.dateFrom).format('YYYY-MM-DD'),
                    date_to: moment(x.dateTo).format('YYYY-MM-DD'),
                    age_exclusion: this.form.ageExclusionParts
                      ? x.ageExclusion
                          .filter(z => z.id !== undefined)
                          .map(y => y.id)
                          .sort()
                          .join(',')
                      : '',
                  }))
                : defaultPeriodParts,
              date_from: moment(this.form.dateFrom).format('YYYY-MM-DD'),
              date_to: moment(this.form.dateTo).format('YYYY-MM-DD'),
            });
            this.displaySuccess(`Tímabilinu „${this.form.name}“ hefur verið breytt.`);
          } else {
            await core.periodCreate({
              name: this.form.name,
              has_parts: true, // should always be true?
              num_parts_application: this.form.hasParts ? this.form.numPartsApplication : 1,
              apply_info: this.form.applyInfo,
              open_for_application: this.form.openForApplication,
              age_exclusion_parts: this.form.ageExclusionParts,
              ages: this.hasMaxHours(this.form.ages) ? this.form.ages : [],
              parts: this.form.hasParts
                ? this.form.periods.map(x => ({
                    date_from: moment(x.dateFrom).format('YYYY-MM-DD'),
                    date_to: moment(x.dateTo).format('YYYY-MM-DD'),
                    age_exclusion: this.form.ageExclusionParts
                      ? x.ageExclusion
                          .filter(z => z.id !== undefined)
                          .map(y => y.id)
                          .sort()
                          .join(',')
                      : '',
                  }))
                : defaultPeriodParts,
              date_from: moment(this.form.dateFrom).format('YYYY-MM-DD'),
              date_to: moment(this.form.dateTo).format('YYYY-MM-DD'),
            });
            this.displaySuccess(`Tímabilið „${this.form.name}“ hefur verið stofnað.`);
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.loading = false;
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            if (e.response.data.ages) {
              this.form.ages.forEach((i, index) => {
                if (e.response.data.ages[index] && e.response.data.ages[index].length > 0) {
                  i.backendValidation = e.response.data.ages[index]; // eslint-disable-line no-param-reassign, max-len
                }
              });
            }
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
        this.loading = false;
      }
    },
    changeDateTo(val) {
      if (this.form.dateTo === '') {
        this.form.dateTo = val;
      }
    },
    updatePeriods(val) {
      if (val && this.form.periods.length > 0 && this.form.periods[0].dateFrom === undefined && this.form.periods[0].dateTo === undefined) {
        this.$set(this.form.periods[0], 'dateFrom', this.form.dateFrom);
        this.$set(this.form.periods[0], 'dateTo', this.form.dateTo);
      }
    },
    show(periodId) {
      this.form.name = '';
      this.form.dateFrom = '';
      this.form.dateTo = '';
      this.form.hasParts = false;
      this.form.applyInfo = '';
      this.form.openForApplication = false;
      this.form.ageExclusionParts = false;
      this.form.numPartsApplication = 1;
      this.form.ages = [{ age: '', max_hours: '' }];
      this.form.periods = [
        {
          dateFrom: '',
          dateTo: '',
          ageExclusion: [
            {
              id: undefined,
            },
          ],
        },
      ];
      this.submitted = false;
      this.loading = false;
      if (periodId) {
        this.title = 'Breyta tímabili';
        this.periodId = periodId;
        this.load();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
