<template>
  <div>
    <b-card class="card-primary-header mt-3">
      <b-row class="mb-3">
        <b-col cols="2">
          <strong>Tegund</strong>
          <v-select id="status" name="status" v-model="search.type" multiple :options="types" label="name"></v-select>
        </b-col>

        <b-col cols="2">
          <strong
            >Nafn<i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Leitað eftir nafni þátttakanda og nafni tengiliðs"></i
          ></strong>
          <b-form-input id="name" name="name" type="text" v-model="search.name" @keyup.native.enter="loadList()"></b-form-input>
        </b-col>
        <b-col cols="2">
          <strong>Tímabil</strong>
          <b-form-select id="period" name="period" v-model="search.period">
            <option :value="undefined">-- Veldu tímabil --</option>
            <template v-if="!periods.loading && periods.data">
              <option v-for="period in periods.data" :key="period.id" :value="period.id">{{ period.name }}</option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="0" style="padding-top: 20px">
          <b-btn variant="primary" @click.prevent="loadList()"> Leita </b-btn>
        </b-col>
        <b-col cols="1" style="padding-top: 25px">
          <i
            class="fa fa-recycle fa-2x"
            style="color: #367bb5; float: left"
            v-b-tooltip.hover
            :title="`Hreinsa leit`"
            aria-hidden="true"
            @click="clearSearch()"
          ></i>
        </b-col>
      </b-row>
      <div>
        <div v-if="emailList.length === 0 && !loading">
          <i>Engar færslur fundust</i>
        </div>
        <table class="table white-table" v-else>
          <tr>
            <th>Titill</th>
            <th>Þátttakandi / Hópur</th>
            <th>Sent á</th>
            <th>Tegund</th>
            <th>Staða</th>
            <th>Dags</th>
            <th></th>
          </tr>
          <tr v-for="email in emailList" :key="email.id">
            <td>{{ email.subject }}</td>
            <td v-if="email.type == 'MASS_EMAIL'">
              {{ email.person_name }}
              <b-link v-b-tooltip.hover title="Skoða" target="_blank" :to="{ name: 'Student', params: { id: email.person_uuid } }">
                <i class="fa fa-fw fa-search"></i>
              </b-link>
            </td>
            <td v-if="email.type == 'PLACEMENT_GROUP'">
              {{ email.placement_group_name }}
              <b-link v-b-tooltip.hover title="Skoða" target="_blank" :to="{ name: 'Group', params: { id: email.placement_group_uuid } }">
                <i class="fa fa-fw fa-search"></i>
              </b-link>
            </td>
            <td>{{ email.email_to }}</td>
            <td><span v-if="email.is_contact">Aðstandandi</span><span v-else>Þátttakandi</span></td>
            <td>{{ getEmailStatus(email.status) }}</td>
            <td>{{ email.date_created | moment('DD.MM.YYYY HH:mm:ss') }}</td>
            <td>
              <b-btn variant="primary" class="pull-right" @click.prevent="viewEmailModal(email.id)" style="padding: 0px 2px">
                <i class="fa fa-fw fa-search" style="color: #fff"></i>
              </b-btn>
            </td>
          </tr>
        </table>
      </div>
    </b-card>
    <view-email-modal ref="viewEmailModal"></view-email-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import ViewEmailModal from '@/components/email/ViewEmailModal.vue';
import moment from 'moment';

export default {
  name: 'massemaillist',
  components: {
    ViewEmailModal,
  },
  data() {
    return {
      emailList: [],
      loading: false,
      submitted: false,
      search: {
        period: {},
        name: '',
        type: [{ id: 'MASS_EMAIL', name: 'Fjöldapóstur' }],
      },
      periods: {
        data: [],
        loading: false,
        error: false,
      },
      types: [
        { id: 'MASS_EMAIL', name: 'Fjöldapóstur' },
        { id: 'PLACEMENT_GROUP', name: 'Hópapóstur' },
      ],
    };
  },
  methods: {
    getEmailStatus(status) {
      switch (status) {
        case 'NEW':
          return 'Nýskráður';
        case 'DELIVERED':
          return 'Sendur';
        case 'NOT_DELIVERED':
          return 'Ekki sendur';
        default:
          return '';
      }
    },
    clearSearch() {
      this.search = {
        period: {},
        name: '',
        type: [{ id: 'MASS_EMAIL', name: 'Fjöldapóstur' }],
      };
      this.loadList();
    },
    async loadPeriods() {
      this.periods.loading = true;
      this.periods.data = [];
      this.periods.error = false;

      try {
        const periodsResponse = await this.$coreApi.periodDropdownList();

        this.periods.data = periodsResponse.data;

        this.periods.data.forEach(d => {
          if (d.dateFrom <= moment().format('YYYY-MM-DD') && d.dateTo >= moment().format('YYYY-MM-DD')) {
            this.search.period = d.id;
          }
        });

        this.loadList();
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja tímabil. Vinsamlegast reyndu aftur.`);
        this.periods.error = true;
      } finally {
        this.periods.loading = false;
      }
    },
    async loadList() {
      this.emailList = [];
      this.loading = true;
      try {
        const types = this.search.type.map(t => t.id);

        if (types.length === 0) {
          types.push(['MASS_EMAIL', 'PLACEMENT_GROUP']);
        }

        const response = await core.emailMessageList(undefined, undefined, undefined, types, this.search.period, this.search.name);
        this.emailList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Modal gluggar
     */
    viewEmailModal(id) {
      this.$refs.viewEmailModal.show(id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadPeriods();
    // this.loadList();
  },
};
</script>
