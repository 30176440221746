import Vue from 'vue';

import config from '../config';

export default {
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir launatímabil
   */
  paymentPeriodList(noBatch, hasBatch) {
    return Vue.http
      .get(config.PAYMENT_PERIOD_LIST_URL, {
        params: {
          no_batch: noBatch && noBatch === true ? true : null,
          has_batch_lines: hasBatch && hasBatch === true ? true : null,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eitt launatímabil.
   *
   * @param {number} id Einkenni launatímabils til að sækja.
   */
  paymentPeriodDetail(id) {
    return Vue.http
      .get(config.PAYMENT_PERIOD_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá launatímabil.
   *
   * @param {object} data Gögn um nýtt launatímabil
   */
  paymentPeriodCreate(data) {
    return Vue.http
      .post(config.PAYMENT_PERIOD_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra launatímabil.
   *
   * @param {number} id Einkenni launatímabils til að uppfæra.
   * @param {object} data Gögn um breytt launatímabil
   */
  paymentPeriodUpdate(id, data) {
    return Vue.http
      .put(config.PAYMENT_PERIOD_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða launatímabil.
   *
   * @param {number} id Einkenni launatímabils til að eyða.
   */
  paymentPeriodDelete(id) {
    return Vue.http
      .delete(config.PAYMENT_PERIOD_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir launataxta
   */
  wagePeriodList() {
    return Vue.http
      .get(config.WAGE_PERIOD_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn launataxta
   *
   * @param {number} id Einkenni launataxta til að sækja.
   */
  wagePeriodDetail(id) {
    return Vue.http
      .get(config.WAGE_PERIOD_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá launataxta.
   *
   * @param {object} data Gögn um nýjan launataxta
   */
  wagePeriodCreate(data) {
    return Vue.http
      .post(config.WAGE_PERIOD_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra launataxta.
   *
   * @param {number} id Einkenni launataxta til að uppfæra.
   * @param {object} data Gögn um breyttan launataxta
   */
  wagePeriodUpdate(id, data) {
    return Vue.http
      .put(config.WAGE_PERIOD_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða launataxta.
   *
   * @param {number} id Einkenni launataxta til að eyða.
   */
  wagePeriodDelete(id) {
    return Vue.http
      .delete(config.WAGE_PERIOD_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir bunka
   */
  paymentBatchList() {
    return Vue.http
      .get(config.PAYMENT_BATCH_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn bunka
   *
   * @param {number} id Einkenni bunka til að sækja.
   */
  paymentBatchDetail(id) {
    return Vue.http
      .get(config.PAYMENT_BATCH_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá bunka.
   *
   * @param {object} data Gögn um nýjan bunka
   */
  paymentBatchCreate(data) {
    return Vue.http
      .post(config.PAYMENT_BATCH_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra bunka.
   *
   * @param {number} id Einkenni bunka til að uppfæra.
   * @param {object} data Gögn um breyttan bunka
   */
  paymentBatchUpdate(id, data) {
    return Vue.http
      .put(config.PAYMENT_BATCH_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða bunka.
   *
   * @param {number} id Einkenni bunka til að eyða.
   */
  paymentBatchDelete(id) {
    return Vue.http
      .delete(config.WAGE_PERIOD_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að staðfesta
   */
  paymentBatchPopulate(id) {
    return Vue.http
      .post(config.PAYMENT_BATCH_POPULATE_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að forskoða
   */
  paymentBatchPreview(id, sort, sortdesc) {
    return Vue.http
      .get(config.PAYMENT_BATCH_PREVIEW_URL(id), {
        params: {
          sort,
          sortdesc,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja download token fyrir bunka
   */
  paymentBatchExcelToken(id) {
    return Vue.http
      .post(config.PAYMENT_BATCH_EXCEL_TOKEN_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja download token fyrir h3 skjal bunka
   */
  paymentBatchH3Token(id) {
    return Vue.http
      .post(config.PAYMENT_BATCH_H3_TOKEN_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir bunka
   */
  paymentBatchLineList(personId, batchId, paymentPeriodId, isExtra, sort, sortdesc) {
    return Vue.http
      .get(config.PAYMENT_BATCH_LINE_LIST_URL, {
        params: {
          placement_group_person: personId,
          batch: batchId,
          payment_period: paymentPeriodId,
          is_extra: isExtra,
          sort,
          sortdesc,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja uppgjörsyfirlit - niður á þátttakendur
   */
  paymentBatchOverviewStudentList(page, limit, name, year) {
    return Vue.http
      .get(config.PAYMENT_BATCH_OVERVIEW_STUDENT_LIST_URL, {
        params: {
          page,
          limit,
          name,
          year,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja uppgjörsyfirlit - niður á flokkun starfsstaða
   */
  paymentBatchOverviewWorkplaceTypeList(page, limit, name, year) {
    return Vue.http
      .get(config.PAYMENT_BATCH_OVERVIEW_WORKPLACETYPE_LIST_URL, {
        params: {
          page,
          limit,
          name,
          year,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja uppgjörsyfirlit - niður á starfsstaði
   */
  paymentBatchOverviewWorkplaceList(page, limit, name, year) {
    return Vue.http
      .get(config.PAYMENT_BATCH_OVERVIEW_WORKPLACE_LIST_URL, {
        params: {
          page,
          limit,
          name,
          year,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja uppgjörsyfirlit - niður á launatímabil
   */
  paymentBatchOverviewPaymentPeriodList(page, limit, name, year) {
    return Vue.http
      .get(config.PAYMENT_BATCH_OVERVIEW_PAYMENTPERIOD_LIST_URL, {
        params: {
          page,
          limit,
          name,
          year,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja uppgjörsyfirlit - niður á ár
   */
  paymentBatchOverviewYearList(page, limit) {
    return Vue.http
      .get(config.PAYMENT_BATCH_OVERVIEW_YEAR_LIST_URL, {
        params: {
          page,
          limit,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir leiðréttingarfærslur
   */
  paymentBatchCorrectionList(page, limit, search) {
    return Vue.http
      .get(config.PAYMENT_BATCH_CORRECTION_LIST_URL, {
        params: {
          page,
          limit,
          period: search.period ? search.period : null,
          name: search.name ? search.name : null,
          locked: search.locked,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eina leiðréttingarfærslu.
   *
   * @param {number} id Einkenni leiðréttingarfærslu til að sækja.
   */
  paymentBatchCorrectionDetail(id) {
    return Vue.http
      .get(config.PAYMENT_BATCH_CORRECTION_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá leiðréttingarfærslu.
   *
   * @param {object} data Gögn um nýja leiðréttingarfærslu
   */
  paymentBatchCorrectionCreate(data) {
    return Vue.http
      .post(config.PAYMENT_BATCH_CORRECTION_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra leiðréttingarfærslu.
   *
   * @param {number} id Einkenni leiðréttingarfærslu til að uppfæra.
   * @param {object} data Gögn um breytt leiðréttingarfærslu
   */
  paymentBatchCorrectionUpdate(id, data) {
    return Vue.http
      .put(config.PAYMENT_BATCH_CORRECTION_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða leiðréttingarfærslu.
   *
   * @param {number} id Einkenni leiðréttingarfærslu til að eyða.
   */
  paymentBatchCorrectionDelete(id) {
    return Vue.http
      .delete(config.PAYMENT_BATCH_CORRECTION_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja download token fyrir H3
   */
  payrollPaymentEmployeeH3Excel(search) {
    return Vue.http
      .post(
        config.PAYROLL_PAYMENT_EMPLOYEE_H3_EXCEL,
        {},
        {
          params: {
            placement_group: search.placementGroup ? search.placementGroup : null,
            period: search.period ? search.period : null,
            period_part: search.periodPart ? search.periodPart : null,
            workplace: search.workplace ? search.workplace : null,
            workplace_type: search.workplaceType ? search.workplaceType : null,
          },
        },
      )
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja download token fyrir H3
   */
  payrollEmployeeH3Excel() {
    return Vue.http
      .post(config.PAYROLL_EMPLOYEE_H3_EXCEL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
