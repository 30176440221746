// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VeeValidate, { Validator } from 'vee-validate';
import VueMoment from 'vue-moment';
import VueLogger from 'vuejs-logger';
import VueApollo from 'vue-apollo';
import vSelect from 'vue-select';
import sanitizeHtml from 'sanitize-html';
import { sync } from 'vuex-router-sync';
import flatpickr from 'vue-flatpickr-component';
import VueMatchHeights from 'vue-match-heights';
import VueCurrencyFilter from 'vue-currency-filter';
import VueScrollTo from 'vue-scrollto';
import FullCalendar from 'vue-full-calendar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import moment from 'moment';

import 'moment/locale/is';

import {
  faBars,
  faSignOutAlt,
  faTachometerAlt,
  faChartBar,
  faAngleRight,
  faAngleDown,
  faFileAlt,
  faUsers,
  faSearch,
  faEnvelope,
  faPhone,
  faCalendarAlt,
  faClipboard,
  faList,
  faUserPlus,
  faUniversity,
  faCalendarMinus,
  faCalendar,
  faMapMarker,
  faHome,
  faLock,
  faEnvelopeOpen,
  faCalendarCheck,
  faMoneyBill,
  faCalculator,
  faWrench,
  faAngleLeft,
  faLayerGroup,
  faCog,
  faCheck,
  faTimes,
  faIdCard,
  faCaretUp,
  faCaretDown,
  faBalanceScale,
  faCodeBranch,
  faInfoCircle,
  faFile,
} from '@fortawesome/free-solid-svg-icons';

import numeral from 'numeral';

import VinnuskoliPage from './components/VinnuskoliPage.vue';
import Loading from './components/Loading.vue';

import './http';

import App from './App.vue';
import router from './router';
import is from './locale/validation/is';
import store from './store';
import apolloClient from './graphql';

import './style/main.scss';

Vue.config.productionTip = false;

Validator.localize('is', is);

library.add(
  faBars,
  faSignOutAlt,
  faTachometerAlt,
  faChartBar,
  faAngleRight,
  faAngleDown,
  faFileAlt,
  faUsers,
  faSearch,
  faEnvelope,
  faPhone,
  faCalendarAlt,
  faUniversity,
  faUserPlus,
  faList,
  faClipboard,
  faCalendarMinus,
  faCalendar,
  faMapMarker,
  faHome,
  faLock,
  faEnvelopeOpen,
  faCalendarCheck,
  faMoneyBill,
  faCalculator,
  faWrench,
  faAngleLeft,
  faLayerGroup,
  faCog,
  faCheck,
  faTimes,
  faIdCard,
  faCaretUp,
  faCaretDown,
  faBalanceScale,
  faCodeBranch,
  faInfoCircle,
  faFile,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueApollo);
Vue.use(BootstrapVue);
Vue.component('v-select', vSelect);
Vue.use(VeeValidate, {
  locale: 'is',
});
Vue.use(VueMoment, {
  moment,
});
Vue.use(flatpickr);

Vue.use(FullCalendar);

Vue.use(VueScrollTo);

Vue.use(VueLogger as any, {
  // required ['debug', 'info', 'warn', 'error', 'fatal']
  logLevel: 'debug',
  // optional : defaults to false if not specified
  stringifyArguments: false,
  // optional : defaults to false if not specified
  showLogLevel: true,
  // optional : defaults to false if not specified
  showMethodName: false,
  // optional : defaults to '|' if not specified
  separator: '|',
  // optional : defaults to false if not specified
  showConsoleColors: true,
});

Vue.use(VueMatchHeights, {
  disabled: [768], // Optional: default viewports widths to disabled resizing on. Can be overridden per usage
});

Vue.use(VueCurrencyFilter, {
  symbol: 'kr.',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'end',
  symbolSpacing: true,
  avoidEmptyDecimals: '',
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.prototype.sanitizeHtml = (value: string | undefined) => (!value ? '' : sanitizeHtml(value.toString()));

Vue.filter('sanitize', (value: string | undefined) => {
  if (!value) return '';
  return sanitizeHtml(value.toString());
});

Vue.filter('limitTo', (value: string | undefined, length: number) => {
  if (!value) return '';
  if (value && value.length <= length) return value;
  return `${value.substring(0, length)}..`;
});

Vue.filter('formatMaxHours', (value?: any) => {
  return numeral(value).format('0,0.[00]');
});

Vue.component('vinnuskoli-page', VinnuskoliPage);
Vue.component('loading', Loading);

sync(store, router);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
