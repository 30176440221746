<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Uppgjörsyfirlit</div>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-if="tabIndex !== 4">
      <b-col>
        <template v-if="yearOptions">
          <strong> Ár </strong>
          <b-form-select id="year" name="year" v-model="year">
            <option :value="null">
              <span>-- Veldu ár --</span>
            </option>
            <option v-for="(year, index) in yearOptions" :key="index" :value="year">
              {{ year }}
            </option>
          </b-form-select>
        </template>
      </b-col>
      <b-col>
        <strong>Leit</strong>
        <b-form-input
          id="search"
          name="search"
          type="text"
          v-model="search"
          placeholder="Leita eftir nafni þátttakanda"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-tabs v-model="tabIndex">
      <b-tab title="Niður á þátttakanda">
        <participant-overview :active="tabIndex === 0" :year="year" :search="search" class="mt-4">
        </participant-overview>
      </b-tab>
      <b-tab title="Niður á flokk starfsstaðar">
        <workplace-type-overview :active="tabIndex === 1" :year="year" :search="search" class="mt-4">
        </workplace-type-overview>
      </b-tab>
      <b-tab title="Niður á starfsstað">
        <workplace-overview :active="tabIndex === 2" :year="year" :search="search" class="mt-4">
        </workplace-overview>
      </b-tab>
      <b-tab title="Niður á launatímabil">
        <billing-period-overview :active="tabIndex === 3" :year="year" :search="search" class="mt-4">
        </billing-period-overview>
      </b-tab>
      <b-tab title="Niður á ár">
        <year-overview :active="tabIndex === 4" class="mt-4"></year-overview>
      </b-tab>
    </b-tabs>
  </vinnuskoli-page>
</template>

<script>
// import core from '@/api/core';
import moment from 'moment';
import { mapActions } from 'vuex';
import ParticipantOverview from '@/components/billingOverview/ParticipantOverview.vue';
import WorkplaceTypeOverview from '@/components/billingOverview/WorkplaceTypeOverview.vue';
import WorkplaceOverview from '@/components/billingOverview/WorkplaceOverview.vue';
import BillingPeriodOverview from '@/components/billingOverview/BillingPeriodOverview.vue';
import YearOverview from '@/components/billingOverview/YearOverview.vue';

export default {
  name: 'billing-overview',
  components: {
    ParticipantOverview,
    WorkplaceTypeOverview,
    WorkplaceOverview,
    BillingPeriodOverview,
    YearOverview,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      yearOptions: [],
      year: moment().year(),
      search: '',
    };
  },
  methods: {
    getYears() {
      this.yearOptions = [];
      for (let i = 2017; i <= moment().year(); i += 1) {
        this.yearOptions.push(i);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getYears();
  },
};
</script>

<style></style>
