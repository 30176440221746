
import 'reflect-metadata';
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { ApplicationList } from '@/api/openapi/api';

@Component({
  computed: {
    ...mapGetters(['loggedInUserOptions', 'loggedInUserHasAnyPermission']),
  },
})
export default class ApplicationListItem extends Vue {
  @PropSync('selected') syncedSelected!: boolean;
  @Prop() item!: ApplicationList;
  @Prop() applicationFinishOneModal!: (item: ApplicationList) => void;
  @Prop() applicationRejectOneModal!: (item: ApplicationList) => void;
  @Prop() applicationApproveOneModal!: (item: ApplicationList) => void;
  @Prop() applicationEditWorkplaceModal!: (item: ApplicationList) => void;

  get statusName() {
    switch (this.item.status) {
      case 'NEW':
        return 'Nýskráð';
      case 'SEMI_ACCEPTED':
        return 'Afgreidd að hluta';
      case 'ACCEPTED':
        return 'Afgreidd';
      case 'REJECTED':
        return 'Hafnað';
      case 'CANCELLED':
        return 'Afskráð';
      case 'IN_PROGRESS':
        return 'Í vinnslu';
      case 'IN_PROGRESS_APPROVED':
        return 'Í vinnslu samþykkt';
      case 'WITHDRAWN':
        return 'Dregin til baka';
      case 'COMPLETED':
        return 'Ráðning';
      default:
        return '';
    }
  }
}
