<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Launataxti</div>
      </b-col>
      <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_wageperiod')">
        <b-btn variant="primary" @click.prevent="wageperiodCreateModal" class="pull-right">
          <i class="fa fa-fw fa-plus-circle"></i>
          Bæta við
        </b-btn>
      </b-col>
    </b-row>
    <b-card no-body border-variant="light" class="mb-2">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Dags. frá</th>
            <th>Dags. til</th>
            <th>Tímakaup</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <td colspan="100"><loading /></td>
        </tbody>
        <tbody v-if="wageperiodList.length == 0 && !loading">
          <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
        </tbody>
        <tbody v-if="!loading && wageperiodList.length > 0">
          <tr v-for="period in wageperiodList" :key="period.id">
            <td>{{ period.date_from | moment('DD.MM.YYYY') }}</td>
            <td>{{ period.date_to | moment('DD.MM.YYYY') }}</td>
            <td class="p-0">
              <table class="table table-no-border table-hover">
                <tr>
                  <th style="width: 45%">Aldur</th>
                  <th style="width: 45%">Launaliður</th>
                  <th style="width: 10%" class="text-right">Tímakaup</th>
                </tr>
                <tbody>
                  <tr v-for="(age, index) in period.ages" :key="index">
                    <td>{{ age.age }} ára ({{ getYear(period, age.age) }})</td>
                    <td>{{ age.wage_element || period.wage_element }}</td>
                    <td class="text-right">{{ age.wage | currency }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td width="10%">
              <b-btn
                variant="primary"
                @click.prevent="wageperiodEditModal(period.id)"
                class="pull-right"
                v-if="loggedInUserHasAnyPermission('write_wageperiod') && isPeriodOver(period.date_to)"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <wage-period-create-modal ref="wageperiodCreateModal" @successful="loadList"></wage-period-create-modal>
    <wage-period-create-modal ref="wageperiodEditModal" @successful="loadList"></wage-period-create-modal>
  </vinnuskoli-page>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import payment from '@/api/payment';
import WagePeriodCreateModal from '@/components/wageperiod/WagePeriodCreateModal.vue';

export default {
  name: 'wage-period',
  components: {
    WagePeriodCreateModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission', 'loggedInUserOptions']),
  },
  data() {
    return {
      wageperiodList: [],
      loading: false,
    };
  },
  methods: {
    async loadList() {
      this.loading = true;
      this.wageperiodList = [];
      try {
        const response = await payment.wagePeriodList();
        this.wageperiodList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    isPeriodOver(dateTo) {
      return moment(dateTo).isAfter(moment());
    },
    getYear(wage, age) {
      if (age) {
        let year = moment();
        if (wage && wage.date_to) {
          year = wage.date_to;
        }
        const born = moment(year).subtract(age, 'year');
        return born.format('YYYY');
      }
      return '';
    },
    /*
     * Modal gluggar
     */
    wageperiodCreateModal() {
      this.$refs.wageperiodCreateModal.show();
    },
    wageperiodEditModal(id) {
      this.$refs.wageperiodEditModal.show(id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss">
.table.table-no-border {
  tr {
    td,
    th {
      border-color: transparent;
    }
  }
}
</style>
