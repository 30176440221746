import Vue from 'vue';
import qs from 'qs';
import moment from 'moment';

import config from '../config';

export default {
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir sveitarfélög.
   */
  townList() {
    return Vue.http
      .get(config.TOWN_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir hverfi.
   */
  districtList() {
    return Vue.http
      .get(config.DISTRICT_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eitt hverfi.
   *
   * @param {number} id Einkenni sveitarfélags til að sækja.
   */
  districtDetail(id) {
    return Vue.http
      .get(config.DISTRICT_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá hverfi.
   *
   * @param {object} data Gögn um nýtt hverfi.
   */
  districtCreate(data) {
    return Vue.http
      .post(config.DISTRICT_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra hverfi.
   *
   * @param {number} id Einkenni hverfi til að uppfæra.
   * @param {object} data Gögn um breytt hverfi.
   */
  districtUpdate(id, data) {
    return Vue.http
      .put(config.DISTRICT_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða hverfi.
   *
   * @param {number} id Einkenni hverfi til að eyða.
   */
  districtDelete(id) {
    return Vue.http
      .delete(config.DISTRICT_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá tímabil.
   *
   * @param {object} data Gögn um nýtt tímabil
   */
  periodCreate(data) {
    return Vue.http
      .post(config.PERIOD_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra tímabil.
   *
   * @param {number} id Einkenni tímabils til að uppfæra.
   * @param {object} data Gögn um breytt tímabil
   */
  periodUpdate(id, data) {
    return Vue.http
      .put(config.PERIOD_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða tímabili.
   *
   * @param {number} id Einkenni tímabils til að eyða.
   */
  periodDelete(id) {
    return Vue.http
      .delete(config.PERIOD_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja download token fyrir lista af reikningsnúmerum
   */
  periodPersonAccountsExcelToken(id) {
    return Vue.http
      .post(config.PAYMENT_PERSON_ACCOUNTS_EXCEL_TOKEN_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tilkynningar.
   */
  templateList() {
    return Vue.http
      .get(config.TEMPLATE_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eina tilkynningu.
   *
   * @param {number} id Einkenni tilkynningar til að sækja.
   */
  templateDetail(id) {
    return Vue.http
      .get(config.TEMPLATE_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá tilkynningu.
   *
   * @param {object} data Gögn um nýja tilkynningu
   */
  templateCreate(data) {
    return Vue.http
      .post(config.TEMPLATE_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra tilkynningu.
   *
   * @param {number} id Einkenni tilkynningu til að uppfæra.
   * @param {object} data Gögn um breytta tilkynningu
   */
  templateUpdate(id, data) {
    return Vue.http
      .put(config.TEMPLATE_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir frídaga tímabils
   */
  periodVacationDaysList(period) {
    return Vue.http
      .get(config.PERIOD_VACATION_DAYS_LIST_URL, {
        params: {
          period,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá fridag tímabil.
   *
   * @param {object} data Gögn um nýjan frídag
   */
  periodVacationDaysCreate(data) {
    return Vue.http
      .post(config.PERIOD_VACATION_DAYS_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða frídegi tímabili.
   *
   * @param {number} id Einkenni frídags tímabils til að eyða.
   */
  periodVacationDaysDelete(id) {
    return Vue.http
      .delete(config.PERIOD_VACATION_DAYS_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tímabil fyrir dropdownlista
   */
  dropdownPeriodList() {
    return Vue.http
      .get(config.DROPDOWN_PERIOD_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir flokkun starfsstaða fyrir dropdownlista
   */
  dropdownWorkplacetypeList() {
    return Vue.http
      .get(config.DROPDOWN_WORKPLACETYPE_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir starfsstaði fyrir dropdownlista
   */
  dropdownWorkplaceList(type) {
    return Vue.http
      .get(config.DROPDOWN_WORKPLACE_LIST_URL, {
        params: {
          type,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  dropdownWorkplaceEditUserList(type) {
    return Vue.http
      .get(config.DROPDOWN_WORKPLACE_EDIT_USER_LIST_URL, {
        params: {
          type,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir hverfi fyrir dropdownlista
   */
  dropdownWorkplaceDistrictList() {
    return Vue.http
      .get(config.DROPDOWN_WORKPLACEDISTRICT_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir hópa fyrir dropdownlista
   */
  dropdownPlacementgroupList(search, date) {
    return Vue.http
      .get(config.DROPDOWN_PLACEMENTGROUP_LIST_URL, {
        params: {
          workplace: search.workplace ? search.workplace : null,
          period: search.period ? search.period.id : null,
          period_part: search.period_part ? search.period_part : null,
          workplace_type: search.type ? search.type : null,
          workplace_district: search.district ? search.district : null,
          date: date ? moment(date).format('YYYY-MM-DD') : null,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir hópa fyrir dropdownlista í samþykkja umsókn
   */
  dropdownApplicationPlacementgroupList(search, date) {
    return Vue.http
      .get(config.DROPDOWN_APPLICATION_PLACEMENTGROUP_LIST_URL, {
        params: {
          workplace: search.workplace ? search.workplace : null,
          workplaces: search.workplaces ? search.workplaces : null,
          period: search.period ? search.period : null,
          period_part: search.period_part ? search.period_part : null,
          workplace_type: search.type ? search.type : null,
          workplace_district: search.district ? search.district : null,
          birth_year: search.birth_year ? search.birth_year : null,
          date: date ? moment(date).format('YYYY-MM-DD') : null,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir aðgang fyrir dropdownlista
   */
  dropdownAccessList() {
    return Vue.http
      .get(config.DROPDOWN_ACCESS_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir notendur fyrir dropdownlista
   */
  dropdownInstructorList() {
    return Vue.http
      .get(config.DROPDOWN_INSTRUCTOR_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir launatímabil fyrir dropdownlista
   */
  dropdownPaymentperiodList() {
    return Vue.http
      .get(config.DROPDOWN_PAYMENTPERIOD_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tölvupósta.
   */
  emailMessageList(application, group, person, type, period, name) {
    return Vue.http
      .get(config.EMAIL_MESSAGE_LIST_URL, {
        params: {
          application,
          placement_group: group,
          placement_group_person: person,
          type,
          period,
          name,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
          });
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn tölvupóst
   *
   * @param {number} id Einkenni tölvupósts til að sækja.
   */
  emailMessageDetail(id) {
    return Vue.http
      .get(config.EMAIL_MESSAGE_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir hópa
   */
  emailGroupList(dateFrom, dateTo) {
    return Vue.http
      .get(config.EMAIL_MESSAGE_GROUP_LIST_URL, {
        params: {
          dateFrom: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : null,
          dateTo: dateTo ? moment(dateTo).format('YYYY-MM-DD') : null,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir mögulega móttakendur.
   */
  emailRecipientsList(groups, year) {
    return Vue.http
      .get(config.EMAIL_MESSAGE_RECIPIENTS_LIST_URL, {
        params: {
          groups,
          year,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að senda póst
   *
   * @param {object} data Gögn um nýjan póst
   */
  emailMassSendMail(data) {
    return Vue.http
      .post(config.EMAIL_MESSAGE_SEND_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja breytingasögu
   */
  historyList(page, limit, application, person) {
    return Vue.http
      .get(config.HISTORY_LIST_URL, {
        params: {
          page,
          limit,
          application,
          placement_group_person: person,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja leit
   */
  searchList(page, limit, search) {
    return Vue.http
      .get(config.SEARCH_LIST_URL, {
        params: {
          page,
          limit,
          search_type: search.type ? search.type : 'Person',
          name: search.name ? search.name : null,
          ssn: search.ssn ? search.ssn : null,
          email: search.email ? search.email : null,
          tel: search.tel ? search.tel : null,
          period: search.period ? search.period : null,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
