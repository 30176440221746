
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { PlacementGroupPersonList, PlacementApiPlacementGroupPersonListRequest } from '@/api/openapi';
import Pagination from '@/components/Pagination.vue';
import { parsePagination } from '@/utils';

@Component({
  components: { Pagination },
})
export default class GroupInstructors extends Vue {
  @Prop() readonly id!: string;

  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  @Getter loggedInUserHasAnyPermission: any;

  data: Array<PlacementGroupPersonList> | null = null;
  loading: boolean = true;
  pagination: any | null = null;

  async loadList(page?: number, limit?: number) {
    const variables: PlacementApiPlacementGroupPersonListRequest = {
      page: page || undefined,
      limit: limit || 100,
      placementGroup: this.id,
    };

    this.loading = true;
    this.data = [];

    try {
      if (this.loggedInUserHasAnyPermission('read_person')) {
        const response = await this.$placementApi.placementGroupPersonList(variables);

        this.data = response.data;

        this.pagination = parsePagination(response);
      }
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading = false;
    }
  }

  changeLimit(limit: number) {
    this.loadList(undefined, limit);
  }

  changePage(page: number) {
    this.loadList(page, this.pagination.currentLimit);
  }

  async created() {
    this.loadList();
  }
}
