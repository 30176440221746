<template>
  <div>
    <b-modal
      id="groupCopyFromModal"
      :title="title"
      size="lg"
      :no-close-on-backdrop="true"
      ok-title="Skrá"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      ref="copyFromGroupModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <h5>Ertu viss um að þú viljir stofna aftur eftirfarandi skráningu?</h5>
          <table class="table" v-if="groupPerson && groupPerson.placement_group">
            <tr>
              <td>Tímabil</td>
              <td>{{ groupPerson.placement_group.period.name }}</td>
            </tr>
            <tr v-if="groupPerson.placement_group.period_part">
              <td>Starfstímabil</td>
              <td>
                {{ groupPerson.placement_group.period_part.date_from | moment('DD.MM.YYYY') }} -
                {{ groupPerson.placement_group.period_part.date_to | moment('DD.MM.YYYY') }}
              </td>
            </tr>
            <tr>
              <td>Vinnustaður</td>
              <td>{{ groupPerson.placement_group.workplace.name }}</td>
            </tr>
            <tr>
              <td>Hópur</td>
              <td>{{ groupPerson.placement_group.name }}</td>
            </tr>
          </table>

          <b-form-group
            label="Breyting tekur gildi frá og með*"
            label-for="date"
            :state="submitted && errors.has('date') ? false : ''"
            :invalid-feedback="errors.first('date')"
          >
            <datepicker
              id="date"
              name="date"
              placeholder="Veldu dagsetningu"
              :class="{ 'is-invalid': submitted && errors.has('date') }"
              v-model="form.date"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              data-vv-as="dags"
              :v-validate="'required'"
              ref="dateFromPickr"
            />
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { is } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import placement from '@/api/placement';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'copy-from-group-modal',
  components: {
    Datepicker,
  },
  data() {
    return {
      submitted: false,
      lang: is,
      errorList: [],
      groupPerson: {},
      title: 'Skrá þátttakanda aftur í hóp',
      form: {
        date: null,
      },
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) {
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        // Engar villur í formi.
        try {
          // this.loading = true;
          await placement.placementGroupPersonCopyGroup(this.groupPerson.id, { date: moment(this.form.date).format('YYYY-MM-DD') });
          this.displaySuccess('Þátttakandi hefur verið skráður aftur í hóp');
          this.$emit('successful', {});
          this.$refs.copyFromGroupModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.loading = false;
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
        this.loading = false;
      }
    },
    show(groupPerson) {
      this.groupPerson = groupPerson;
      this.form.date = null;
      this.submitted = false;
      this.$refs.copyFromGroupModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
