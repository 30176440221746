import { ApolloClient } from 'apollo-client';  // eslint-disable-line
import { createHttpLink } from 'apollo-link-http';  // eslint-disable-line
import { InMemoryCache } from 'apollo-cache-inmemory';  // eslint-disable-line

import I18nLink from './i18n-link';

const i18nLink = new I18nLink();

const httpLink = createHttpLink({
  uri: '/graphql',
  credentials: 'same-origin',
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  connectToDevTools: true,
  link: i18nLink.concat(httpLink),
  cache,
});

export default apolloClient;
