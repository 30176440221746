<template>
  <div>
    <b-modal
      id="rejectApplicationModal"
      title="Hafna umsóknum"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Hafna"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading || cantRejectAll"
      ref="createModal"
    >
      <div>
        <h5>Ertu viss um að þú viljir hafna eftirfarandi umsókn/um?</h5>
        <table class="table white-table">
          <tr>
            <th>Nafn</th>
            <th>Fæðingarár</th>
            <th>Tímabil</th>
            <th>Vinnustaður</th>
            <th></th>
          </tr>
          <tr
            v-for="application in applications"
            :key="application.id"
            :class="{
              'text-danger':
                application.status !== 'NEW' && application.status !== 'IN_PROGRESS' && application.status !== 'IN_PROGRESS_APPROVED',
            }"
          >
            <td>{{ application.name }}</td>
            <td>{{ application.birthYear.birthYear }}</td>
            <td>{{ application.period.name }}</td>
            <td>
              <div v-for="workplace in application.workplaces" :key="workplace.id">
                {{ workplace.workplace.name }}
              </div>
            </td>
            <td>
              <i
                v-if="application.status !== 'NEW' && application.status !== 'IN_PROGRESS' && application.status !== 'IN_PROGRESS_APPROVED'"
                class="fa fa-exclamation-triangle"
                v-b-tooltip.hover
                title="Ekki er hægt að eyða umsókn sem er búin að samþykkja að einhverju leiti"
              ></i>
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import appl from '@/api/application';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'reject-application-modal',
  data() {
    return {
      loading: false,
      applications: [],
    };
  },
  computed: {
    cantRejectAll() {
      return this.applications.some(a => a.status !== 'NEW' && a.status !== 'IN_PROGRESS' && a.status !== 'IN_PROGRESS_APPROVED');
    },
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.errorList = [];
        const promises = [];

        this.applications.forEach(a => {
          promises.push(appl.applicationReject(a.id, {}));
        });
        await Promise.all(promises);
        this.$emit('successful', {});
        this.$refs.createModal.hide();
      } catch (e) {
        // Aðgerð mistókst.
        this.saving = true;
        this.$log.debug(e);
        if (e.response && e.response.status === 400) {
          djangoErrorsToVee(e.response.data, this.errors, this.errorList);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    show(applications) {
      this.applications = applications;
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
