<template>
  <div>
    <b-modal
      id="workplaceCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="submitting || $apollo.queries.workplace.loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Flokkun"
            label-for="types"
            :state="submitted && errors.has('types') ? false : null"
            :invalid-feedback="errors.first('types')"
            description="Veldu flokkun ef á við"
          >
            <v-select id="types" name="types" v-model="form.types" multiple :options="typeOptions" label="name"></v-select>
          </b-form-group>
          <b-form-group
            label="Heiti:"
            label-for="name"
            :state="submitted && errors.has('name') ? false : null"
            :invalid-feedback="errors.first('name')"
            description="Heiti starfsstaðar"
          >
            <b-form-input
              id="name"
              name="name"
              autofocus
              type="text"
              v-model="form.name"
              required
              :state="submitted && errors.has('name') ? false : null"
              v-validate="'required'"
              data-vv-as="heiti"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Hverfi:"
            label-for="district"
            :state="submitted && errors.has('district') ? false : null"
            :invalid-feedback="errors.first('district')"
            description="Veldu hverfi ef á við"
          >
            <b-form-select id="district" name="district" v-model="form.district">
              <option :value="null">-- Veldu hverfi --</option>
              <template v-if="!$apollo.queries.workplaceDistricts.loading && workplaceDistricts && workplaceDistricts.edges">
                <option v-for="district in workplaceDistricts.edges.map(w => w.node)" :key="district.id" :value="district.id">
                  {{ district.name }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group label-for="enabled" description="Er starfsstaður virkur?">
            <b-form-checkbox id="enabled" name="enabled" autofocus v-model="form.enabled" data-vv-as="virkur"> Virkur </b-form-checkbox>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies
import { mapActions } from 'vuex';
import { serverValidationToForm } from '@/utils';

export default {
  name: 'workplace-create-modal',
  data() {
    return {
      submitting: false,
      submitted: false,
      title: 'Stofna starfsstað',
      id: null,
      errorList: [],
      typeOptions: [],
      form: {
        name: '',
        district: null,
        types: [],
        enabled: true,
      },
    };
  },
  apollo: {
    workplaceTypes: {
      query: gql`
        query WorkplaceTypes {
          workplaceTypes(isDropdown: true, first: 100) {
            totalCount
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      result({ data: { workplaceTypes } = {} }) {
        if (workplaceTypes) {
          this.typeOptions = workplaceTypes.edges.map(w => ({
            id: w.node.id,
            name: w.node.name,
          }));
        }
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
    workplaceDistricts: {
      query: gql`
        query WorkplaceDistricts {
          workplaceDistricts(isDropdown: true, first: 100) {
            totalCount
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
    workplace: {
      query: gql`
        query Workplace($id: ID!) {
          workplace(id: $id) {
            id
            name
            enabled
            allTypes {
              id
              type {
                id
                name
              }
            }
            district {
              id
            }
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
      result({ data: { workplace } }) {
        this.form.name = workplace.name;
        this.form.types = workplace.allTypes ? workplace.allTypes.map(t => ({ id: t.type.id, name: t.type.name })) : [];
        this.form.district = workplace.district ? workplace.district.id : null;
        this.form.enabled = workplace.enabled;
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        const mutation = this.id
          ? gql`
              mutation ($input: UpdateWorkplaceInput!) {
                updateWorkplace(input: $input) {
                  errors {
                    field
                    messages
                  }
                }
              }
            `
          : gql`
              mutation ($input: CreateWorkplaceInput!) {
                createWorkplace(input: $input) {
                  workplace {
                    id
                  }
                  errors {
                    field
                    messages
                  }
                }
              }
            `;

        const mutationName = this.id ? 'updateWorkplace' : 'createWorkplace';

        const variables = {
          input: {
            name: this.form.name,
            enabled: this.form.enabled,
            district: this.form.district,
            types: this.form.types.map(t => t.id),
          },
        };

        if (this.id) {
          variables.input.id = this.id;
        }

        // Engar villur í formi.
        try {
          this.submitting = true;

          const {
            data: {
              [mutationName]: { errors },
            },
          } = await this.$apollo.mutate({
            mutation,
            variables,
          });

          if (errors) {
            serverValidationToForm({
              errors,
              setFieldError: (field, error) => {
                this.errors.add({
                  field,
                  msg: error,
                  rule: 'server',
                });
              },
              errorFieldMap: { details: 'name' },
            });
          } else {
            if (this.id) {
              this.displaySuccess(`Starfsstaðnum „${this.form.name}“ hefur verið breytt.`);
            } else {
              this.displaySuccess(`Starfsstaðurinn „${this.form.name}“ hefur verið stofnaður.`);
            }
            this.submitted = false;
            this.$emit('successful', { id: this.id });
            this.$refs.createModal.hide();
            this.id = null;
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.submitting = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(id) {
      // Hreinsa formið
      this.submitted = false;
      this.errorList = [];
      this.form.name = '';
      this.form.types = [];
      this.form.district = null;
      this.id = null;
      this.title = 'Skrá starfsstað';
      if (id) {
        this.title = 'Breyta starfsstað';
        this.id = id;
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
