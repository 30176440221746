<template>
  <vinnuskoli-page>
    <div>
      <b-row class="mb-3">
        <b-col>
          <div class="page-title mb-0">Umsóknir</div>
        </b-col>
        <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_application')">
          <b-btn variant="primary" :to="{ name: 'CreateApplication' }" class="pull-right">
            <i class="fa fa-fw fa-plus-circle"></i> Skrá umsókn
          </b-btn>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col cols="4">
          <strong>Tímabil</strong>
          <b-form-select id="period" name="period" v-model="options.application.period" @change="onPeriodChange" @input="loadList()">
            <option :value="null">-- Veldu tímabil --</option>
            <template v-if="!periods.loading && periods.data">
              <option v-for="period in periods.data" :key="period.id" :value="period.id">{{ period.name }}</option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="4">
          <template v-if="!periodParts.loading && periodParts.data.length > 0">
            <strong> Starfstímabil </strong>
            <b-form-select id="periodPart" name="periodPart" v-model="options.application.periodPart" @input="loadList()">
              <option :value="null">
                <span>-- Veldu starfstímabil --</span>
              </option>
              <option v-for="part in periodParts.data" :key="part.id" :value="part.id">
                {{ part.dateFrom | moment('DD.MM.YYYY') }} - {{ part.dateTo | moment('DD.MM.YYYY') }}
              </option>
            </b-form-select>
          </template>
        </b-col>
        <b-col cols="4">
          <strong>Staða</strong>
          <v-select
            id="status"
            name="status"
            v-model="options.application.status"
            multiple
            :options="statuses"
            @input="loadList()"
            label="name"
            style="background-color: white"
          ></v-select>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <strong>Flokkun starfsstaða</strong>
          <b-form-select id="type" name="type" v-model="options.application.type" @input="loadList()" @change="onWorkplaceChange">
            <option :value="null">-- Veldu flokk --</option>
            <template v-if="!workplaceTypes.loading && workplaceTypes.data">
              <option v-for="type in workplaceTypes.data" :key="type.id" :value="type.id">{{ type.name }}</option>
            </template>
          </b-form-select>
        </b-col>
        <b-col>
          <strong>Starfsstaður</strong>
          <b-form-select id="workplace" name="workplace" v-model="options.application.workplace" @input="loadList()">
            <option :value="null">-- Veldu starfsstað --</option>
            <template v-if="!workplaces.loading && workplaces.data">
              <option v-for="workplace in workplaces.data" :key="workplace.id" :value="workplace.id">
                {{ workplace.name }}
              </option>
            </template>
          </b-form-select>
        </b-col>
        <b-col>
          <strong>Hverfi</strong>
          <b-form-select id="district" name="district" v-model="options.application.district" @input="loadList()">
            <option :value="null">-- Veldu hverfi --</option>
            <template v-if="!workplaceDistricts.loading && workplaceDistricts.data">
              <option v-for="district in workplaceDistricts.data" :key="district.id" :value="district.id">
                {{ district.name }}
              </option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="4">
          <strong>Fæðingarár umsækjenda</strong>
          <v-select
            id="birthYear"
            name="birthYear"
            v-model="options.application.birthYear"
            multiple
            :options="birthYears"
            @input="loadList()"
            label="name"
            style="background-color: white"
          ></v-select>
        </b-col>
      </b-row>
      <b-row :class="{ 'mb-3': !loggedInUserHasAnyPermission('write_application') }">
        <b-col>
          <i
            class="fa fa-recycle fa-2x"
            style="color: #367bb5; float: right"
            v-b-tooltip.hover
            :title="`Hreinsa síur`"
            aria-hidden="true"
            @click="clearFilters()"
          ></i>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <div class="page-title mb-0">Leit</div>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="3">
          <strong>Nafn</strong>
          <b-form-input id="name" name="name" type="text" @keyup.native.enter="loadList()" v-model="search.name"> </b-form-input>
        </b-col>
        <b-col cols="2">
          <strong>Kennitala</strong>
          <b-form-input id="ssn" name="ssn" type="text" @keyup.native.enter="loadList()" v-model="search.ssn"> </b-form-input>
        </b-col>
        <b-col cols="0" style="padding-top: 20px">
          <b-btn variant="primary" @click.prevent="loadList()"> Leita </b-btn>
        </b-col>
        <b-col cols="1" style="padding-top: 25px">
          <i
            class="fa fa-recycle fa-2x"
            style="color: #367bb5; float: left"
            v-b-tooltip.hover
            :title="`Hreinsa leit`"
            aria-hidden="true"
            @click="clearSearch()"
          ></i>
        </b-col>
      </b-row>
      <b-row class="mb-3 mt-2" v-if="loggedInUserHasAnyPermission('write_application')">
        <b-col>
          <b-button-group>
            <b-btn
              variant="success"
              :disabled="!someSelected"
              @click.prevent="applicationApproveManyModal"
              v-if="loggedInUserHasAnyPermission('write_approve_application')"
            >
              <i class="fa fa-fw fa-check"></i> Samþykkja valdar
            </b-btn>
            <b-btn
              variant="danger"
              :disabled="!someSelected"
              @click.prevent="applicationRejectManyModal()"
              v-if="loggedInUserHasAnyPermission('write_approve_application')"
            >
              <i class="fa fa-fw fa-remove"></i>Hafna völdum
            </b-btn>
          </b-button-group>
          <b-dropdown variant="info" class="mx-1" right text="Aðrar stöður" :disabled="!someSelected">
            <b-dropdown-item @click.prevent="applicationNewModal()">Nýskráð</b-dropdown-item>
            <b-dropdown-item @click.prevent="applicationInProgressManyModal()">Í vinnslu</b-dropdown-item>
            <b-dropdown-item @click.prevent="applicationInProgressApprovedManyModal()">Í vinnslu samþykkt</b-dropdown-item>
            <b-dropdown-item
              @click.prevent="applicationWithdrawManyModal()"
              v-if="loggedInUserHasAnyPermission('write_approve_application')"
              >Draga til baka</b-dropdown-item
            >
            <b-dropdown-item @click.prevent="applicationCancelManyModal()" v-if="loggedInUserHasAnyPermission('write_approve_application')"
              >Afskrá</b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="applicationCompleteManyModal()"
              v-if="loggedInUserHasAnyPermission('write_approve_application')"
              >Ráðning</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
        <b-col>
          <b-btn
            class="pull-right"
            style="background-color: #008000"
            @click.prevent="excel()"
            v-b-tooltip.hover
            title="Sækja yfirlit í Excel"
          >
            <i class="fa fa-fw fa-file-excel-o"></i>
          </b-btn>
        </b-col>
      </b-row>

      <b-card no-body border-variant="light" class="mb-2">
        <div class="m-2" v-if="applications.loading"><loading /></div>
        <div class="m-2" v-if="!applications.loading && applications.data && applications.data.length === 0">
          <i>Engar umsóknir fundust.</i>
        </div>
        <div class="overflow-auto" v-if="!applications.loading && applications.data && applications.data.length > 0">
          <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage">
          </pagination>
          <table class="table mb-0">
            <thead>
              <tr class="bg-gray-200 small">
                <th>
                  <b-checkbox v-if="loggedInUserHasAnyPermission('write_application')" @change="setAllSelected" />
                </th>
                <th @click.prevent="changeSort('name')" style="cursor: pointer">Nafn</th>
                <th @click.prevent="changeSort('ssn')" style="cursor: pointer">Kennitala</th>
                <th @click.prevent="changeSort('ssnYear')" style="cursor: pointer">F.ár</th>
                <th @click.prevent="changeSort('email')" style="cursor: pointer">Netfang</th>
                <th @click.prevent="changeSort('postalCode')" style="cursor: pointer">Pnr</th>
                <th @click.prevent="changeSort('status')" style="cursor: pointer">Staða</th>
                <th @click.prevent="changeSort('periodName')" style="cursor: pointer">Tímabil</th>
                <th @click.prevent="changeSort('periodDate')" style="cursor: pointer">Starfstímabil</th>
                <th>Starfsstaður</th>
                <th @click.prevent="changeSort('dateCreated')" style="cursor: pointer">Dags.</th>
                <th></th>
              </tr>
            </thead>
            <application-list-item
              v-for="(application, idx) in applications.data"
              :key="application.id"
              :item="application"
              :selected.sync="selected[idx]"
              :applicationFinishOneModal="applicationFinishOneModal"
              :applicationRejectOneModal="applicationRejectOneModal"
              :applicationApproveOneModal="applicationApproveOneModal"
              :applicationEditWorkplaceModal="applicationEditWorkplaceModal"
            />
          </table>
          <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage">
          </pagination>
        </div>
      </b-card>
    </div>
    <!--approve-application-modal ref="approveApplicationModal" @successful="loadList"></approve-application-modal-->
    <reject-application-modal ref="rejectApplicationModal" @successful="() => loadList()"></reject-application-modal>
    <finish-application-modal ref="finishApplicationModal" @successful="() => loadList()"></finish-application-modal>
    <application-in-progress-modal ref="applicationInProgressModal" @successful="() => loadList()"></application-in-progress-modal>
    <application-in-progress-approved-modal
      ref="applicationInProgressApprovedModal"
      @successful="() => loadList()"
    ></application-in-progress-approved-modal>
    <application-withdraw-modal ref="withdrawApplicationModal" @successful="() => loadList()"></application-withdraw-modal>
    <application-cancel-modal ref="cancelApplicationModal" @successful="() => loadList()"></application-cancel-modal>
    <application-complete-modal ref="completeApplicationModal" @successful="() => loadList()"></application-complete-modal>
    <application-new-modal ref="applicationNewModal" @successful="() => loadList()"></application-new-modal>
    <application-edit-workplace-modal ref="applicationEditWorkplaceModal" @successful="() => loadList()" />
  </vinnuskoli-page>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import RejectApplicationModal from '@/components/application/RejectApplication.vue';
import FinishApplicationModal from '@/components/application/FinishApplication.vue';
import ApplicationInProgressModal from '@/components/application/ApplicationInProgressModal.vue';
import ApplicationWithdrawModal from '@/components/application/ApplicationWithdrawModal.vue';
import ApplicationCancelModal from '@/components/application/ApplicationCancelModal.vue';
import ApplicationCompleteModal from '@/components/application/ApplicationCompleteModal.vue';
import ApplicationInProgressApprovedModal from '@/components/application/ApplicationInProgressApprovedModal.vue';
import ApplicationNewModal from '@/components/application/ApplicationNewModal.vue';
import ApplicationListItem from '@/components/application/ApplicationListItem.vue';
import ApplicationEditWorkplaceModal from '@/components/application/ApplicationEditWorkplaceModal.vue';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';
import { ApplicationApiAxiosParamCreator } from '@/api/openapi/api';

export default Vue.extend({
  name: 'ApplicationList',
  components: {
    RejectApplicationModal,
    FinishApplicationModal,
    ApplicationInProgressModal,
    ApplicationWithdrawModal,
    ApplicationCancelModal,
    ApplicationCompleteModal,
    ApplicationInProgressApprovedModal,
    ApplicationNewModal,
    ApplicationListItem,
    ApplicationEditWorkplaceModal,
    Pagination,
  },
  mixins: [paginationMixin],
  computed: {
    statuses() {
      return this.statusList;
    },
    birthYears() {
      return this.applicationBirthYearLookups.data.map(birthYear => ({
        id: birthYear,
        name: birthYear,
      }));
    },
    someSelected() {
      return Object.values(this.selected).filter(a => a === true).length > 0;
    },
    ...mapGetters(['loggedInUserOptions', 'loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      page: null,
      pagination: null,
      statusList: [
        { id: 'NEW', name: 'Nýskráð' },
        { id: 'IN_PROGRESS', name: 'Í vinnslu' },
        { id: 'IN_PROGRESS_APPROVED', name: 'Í vinnslu samþykkt' },
        { id: 'SEMI_ACCEPTED', name: 'Afgreidd að hluta' },
        { id: 'ACCEPTED', name: 'Afgreidd' },
        { id: 'REJECTED', name: 'Hafnað' },
        { id: 'CANCELLED', name: 'Afskráð' },
        { id: 'WITHDRAWN', name: 'Dregin til baka' },
        { id: 'COMPLETED', name: 'Ráðning' },
      ],
      selected: {},
      options: {},

      search: {
        name: '',
        ssn: '',
      },

      periods: {
        data: [],
        loading: false,
        error: false,
      },
      periodParts: {
        data: [],
        loading: false,
        error: false,
      },
      workplaceTypes: {
        data: [],
        loading: false,
        error: false,
      },
      workplaceDistricts: {
        data: [],
        loading: false,
        error: false,
      },
      workplaces: {
        data: [],
        loading: false,
        error: false,
      },
      applicationBirthYearLookups: {
        data: [],
        loading: false,
        error: false,
      },
      applications: {
        data: [],
        loading: false,
        error: false,
      },
      sort: 'name',
      sortdesc: false,
    };
  },
  methods: {
    clearFilters() {
      this.$set(this.options, 'application', {
        period: null,
        periodPart: null,
        district: null,
        status: [],
        birthYear: [],
        type: null,
        workplace: null,
      });
      this.loadList();
    },
    clearSearch() {
      this.search = {
        name: '',
        ssn: '',
      };
      this.loadList();
    },
    setAllSelected(value) {
      if (this.applications.data) {
        this.$set(
          this,
          'selected',
          this.applications.data
            .map((a, i) => [
              i,
              value,
              // a.status === 'NEW' || a.status === 'SEMI_ACCEPTED' || a.status === 'IN_PROGRESS' || a.status === 'IN_PROGRESS_APPROVED'
              //   ? value
              //   : null,
            ])
            .reduce((acc, curr) => ({ ...acc, [curr[0]]: curr[1] }), {}),
        );
      } else {
        this.$set(this, 'selected', {});
      }
    },
    // Modal gluggar
    applicationEditWorkplaceModal(item) {
      this.$refs.applicationEditWorkplaceModal.show(item);
    },
    applicationApproveOneModal(id) {
      // const items = [];
      // items.push(appl.id);
      // this.$refs.approveApplicationModal.show(items);
      this.$router.push({ name: 'ApproveApplication', params: { id } });
    },
    applicationApproveManyModal() {
      const items = this.applications.data.filter((a, i) => this.selected[i] === true).map(a => a.id);
      // this.$refs.approveApplicationModal.show(items);
      this.$router.push({ name: 'ApproveApplication', params: { id: items.join() } });
    },
    applicationRejectOneModal(appl) {
      const items = [];
      items.push(appl);
      this.$refs.rejectApplicationModal.show(items);
    },
    applicationRejectManyModal() {
      const items = this.applications.data.filter((a, i) => this.selected[i] === true);
      this.$refs.rejectApplicationModal.show(items);
    },
    applicationInProgressManyModal() {
      const items = this.applications.data.filter((a, i) => this.selected[i] === true);
      this.$refs.applicationInProgressModal.show(items);
    },
    applicationInProgressApprovedManyModal() {
      const items = this.applications.data.filter((a, i) => this.selected[i] === true);
      this.$refs.applicationInProgressApprovedModal.show(items);
    },
    applicationFinishOneModal(appl) {
      const items = [];
      items.push(appl);
      this.$refs.finishApplicationModal.show(items);
    },
    applicationNewModal() {
      const items = this.applications.data.filter((a, i) => this.selected[i] === true);
      this.$refs.applicationNewModal.show(items);
    },
    applicationFinishManyModal() {
      const items = this.applications.data.filter((a, i) => this.selected[i] === true);
      this.$refs.finishApplicationModal.show(items);
    },
    applicationWithdrawManyModal() {
      const items = this.applications.data.filter((a, i) => this.selected[i] === true);
      this.$refs.withdrawApplicationModal.show(items);
    },
    applicationCancelManyModal() {
      const items = this.applications.data.filter((a, i) => this.selected[i] === true);
      this.$refs.cancelApplicationModal.show(items);
    },
    applicationCompleteManyModal() {
      const items = this.applications.data.filter((a, i) => this.selected[i] === true);
      this.$refs.completeApplicationModal.show(items);
    },
    async loadPeriods() {
      this.periods.loading = true;
      this.periods.data = [];
      this.periods.error = false;

      try {
        const periodsResponse = await this.$coreApi.periodDropdownList();

        this.periods.data = periodsResponse.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja tímabil. Vinsamlegast reyndu aftur.`);
        this.periods.error = true;
      } finally {
        this.periods.loading = false;
        this.loadPeriodParts();
      }
    },
    async loadPeriodParts() {
      this.periodParts.loading = true;
      this.periodParts.data = [];
      this.periodParts.error = false;

      try {
        if (this.options.application.period) {
          const response = await this.$coreApi.periodDropdownPeriodPartDropdownList({
            period: this.options.application.period,
          });

          this.periodParts.data = response.data;

          if (!this.periodParts.data.map(p => p.id).includes(this.options.application.periodPart)) {
            this.options.application.periodPart = null;
          }
        }
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja starfstímabil. Vinsamlegast reyndu aftur.`);
        this.periodParts.error = true;
      } finally {
        this.periodParts.loading = false;
      }
    },
    async loadWorkplaceTypes() {
      this.workplaceTypes.loading = true;
      this.workplaceTypes.data = [];
      this.workplaceTypes.error = false;

      try {
        const response = await this.$coreApi.workplaceTypeDropdownList();

        this.workplaceTypes.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp að sækja flokkun starfsstaða. Vinsamlegast reyndu aftur.`);
        this.workplaceTypes.error = true;
      } finally {
        this.workplaceTypes.loading = false;
      }
    },
    async loadWorkplaceDistricts() {
      this.workplaceDistricts.loading = true;
      this.workplaceDistricts.data = [];
      this.workplaceDistricts.error = false;

      try {
        const response = await this.$coreApi.workplaceDistrictDropdownList();

        this.workplaceDistricts.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp að sækja hverfi. Vinsamlegast reyndu aftur.`);
        this.workplaceDistricts.error = true;
      } finally {
        this.workplaceDistricts.loading = false;
      }
    },
    async loadWorkplaces() {
      this.workplaces.loading = true;
      this.workplaces.data = [];
      this.workplaces.error = false;

      try {
        const response = await this.$coreApi.workplaceDropdownList({
          type: this.options.application.type || undefined,
        });

        this.workplaces.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja starfsstaði. Vinsamlegast reyndu aftur.`);
        this.workplaces.error = true;
      } finally {
        this.workplaces.loading = false;
      }
    },
    async loadApplicationBirthYearLookups() {
      this.applicationBirthYearLookups.loading = true;
      this.applicationBirthYearLookups.data = [];
      this.applicationBirthYearLookups.error = false;

      try {
        const response = await this.$applicationApi.applicationBirthYearLookups();

        this.applicationBirthYearLookups.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja fæðingarár. Vinsamlegast reyndu aftur.`);
        this.applicationBirthYearLookups.error = true;
      } finally {
        this.applicationBirthYearLookups.loading = false;
      }
    },
    async loadList(page = undefined, limit = undefined) {
      await this.setLoggedInUserOption({ option: 'application', value: this.options.application });
      await this.saveLoggedInUserOptions();

      this.applications.loading = true;
      this.applications.data = [];
      this.applications.error = false;

      try {
        const variables = {
          page: page || undefined,
          limit: limit || undefined,
          status: undefined,
        };

        if (this.options.application.birthYear && this.options.application.birthYear.length > 0) {
          variables.birthYear = this.options.application.birthYear.map(s => s.id);
        }
        if (this.options.application.type) variables.workplaceType = this.options.application.type;
        if (this.options.application.workplace) variables.workplace = this.options.application.workplace;
        if (this.options.application.district) variables.workplaceDistrict = this.options.application.district;
        if (this.options.application.period) variables.period = this.options.application.period;
        if (this.options.application.periodPart) variables.periodPart = this.options.application.periodPart;
        if (this.sort && this.sortdesc) variables.orderBy = `-${this.sort}`;
        if (this.sort && !this.sortdesc) variables.orderBy = this.sort;
        if (this.search.name) variables.name = this.search.name;
        if (this.search.ssn) variables.ssn = this.search.ssn;

        if (this.options.application.status && this.options.application.status.length > 0) {
          variables.status = this.options.application.status.map(s => s.id);
        }
        const response = await this.$applicationApi.applicationList(variables);

        this.applications.data = response.data;

        this.pagination = parsePagination(response);

        this.setAllSelected(false);
      } catch (e) {
        this.displayError(`Óvænt villa kom upp að að sækja lista. Vinsamlegast reyndu aftur.`);
        this.applications.error = true;
      } finally {
        this.applications.loading = false;
      }
    },
    onWorkplaceChange() {
      this.loadWorkplaces();
    },
    onPeriodChange() {
      this.loadPeriodParts();
    },
    async excel() {
      const requestParameters = {
        birthYear: this.options.application.birthYear ? this.options.application.birthYear.map(s => s.id) : undefined,
        name: this.search.name || undefined,
        period: this.options.application.period || undefined,
        periodPart: this.options.application.periodPart || undefined,
        ssn: this.search.ssn || undefined,
        email: this.options.email || undefined,
        status:
          this.options.application.status && this.options.application.status.length > 0
            ? this.options.application.status.map(s => s.id)
            : undefined,
        workplace: this.options.application.workplace || undefined,
        district: this.options.application.district || undefined,
        type: this.options.application.type || undefined,
      };

      if (this.sort && this.sortdesc) requestParameters.orderBy = `-${this.sort}`;
      if (this.sort && !this.sortdesc) requestParameters.orderBy = this.sort;

      const bla = await ApplicationApiAxiosParamCreator().applicationExcel(
        requestParameters.birthYear,
        requestParameters.email,
        requestParameters.endDate,
        requestParameters.ids,
        requestParameters.name,
        requestParameters.orderBy,
        requestParameters.period,
        requestParameters.periodPart,
        requestParameters.ssn,
        requestParameters.startDate,
        requestParameters.status,
        requestParameters.workplace,
        requestParameters.workplaceDistrict,
        requestParameters.workplaceType,
      );

      window.location.href = bla.url;
    },
    changeSort(column) {
      if (this.sort === column) {
        this.sortdesc = !this.sortdesc;
      } else {
        this.sort = column;
        this.sortdesc = false;
      }
      this.loadList();
    },
    ...mapActions(['saveLoggedInUserOptions', 'setLoggedInUserOption', 'displayError', 'displaySuccess']),
  },
  async created() {
    this.options = {};

    this.$set(this.options, 'application', {
      period: null,
      periodPart: null,
      district: null,
      status: [
        { id: 'NEW', name: 'Nýskráð' },
        { id: 'SEMI_ACCEPTED', name: 'Afgreidd að hluta' },
        { id: 'IN_PROGRESS', name: 'Í vinnslu' },
        { id: 'IN_PROGRESS_APPROVED', name: 'Í vinnslu samþykkt' },
      ],
      birthYear: [],
      type: null,
      workplace: null,
      ...this.loggedInUserOptions.application,
    });

    this.loadList();
    this.loadPeriods();
    this.loadWorkplaceTypes();
    this.loadWorkplaceDistricts();
    this.loadWorkplaces(this.options.application.type);
    this.loadApplicationBirthYearLookups();
  },
  // beforeRouteUpdate(to, from, next) {
  //   const page = parseInt(to.query.page, 10) || 1;
  //   const limit = parseInt(to.query.limit, 10) || DEFAULT_LIMIT;
  //   this.page = page;
  //   this.limit = limit;
  //   next();
  // },
});
</script>
