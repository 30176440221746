/* eslint-disable class-methods-use-this */

import { ApolloLink } from 'apollo-link'; // eslint-disable-line
import Cookies from 'js-cookie';

export default class I18nLink extends ApolloLink {
  request(operation, forward) {
    operation.setContext(({ headers }) => ({
      headers: {
        'Accept-Language': `is`,
        ...headers,
        'X-CSRFTOKEN': Cookies.get('csrftoken'),
      },
    }));

    return forward(operation);
  }
}

/* eslint-enable */
