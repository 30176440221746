<template>
  <b-row>
    <b-col>
      <b-form-select
        size="sm"
        v-model="availableSelected"
        :options="available"
        :multiple="true"
        :select-size="6"
        @dblclick.native="moveRight"
      >
      </b-form-select>
    </b-col>
    <b-col cols="1" class="text-center pt-3">
      <b-btn class="mb-1" :disabled="availableSelected.length === 0" @click="moveRight">
        <i class="fa fa-angle-right" aria-hidden="true"></i> </b-btn
      ><br />
      <b-btn class="mb-1" :disabled="selectedSelected.length === 0" @click="moveLeft">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
      </b-btn>
    </b-col>
    <b-col>
      <b-form-select
        size="sm"
        v-model="selectedSelected"
        :options="selected"
        :multiple="true"
        :select-size="6"
        @dblclick.native="moveLeft"
      ></b-form-select>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'shuttle',
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      availableSelected: [],
      selectedSelected: [],
    };
  },
  computed: {
    available() {
      return this.list
        .filter(g => !this.value.includes(g.id))
        .map(g => ({
          value: g.id,
          text: g.name,
        }));
    },
    selected() {
      return this.list
        .filter(g => this.value.includes(g.id))
        .map(g => ({
          value: g.id,
          text: g.name,
        }));
    },
  },
  methods: {
    moveRight() {
      this.value.push(...this.availableSelected);
      this.availableSelected = [];
      this.selectedSelected = [];
    },
    moveLeft() {
      for (let i = this.value.length - 1; i >= 0; i--) {  // eslint-disable-line
        // eslint-disable-line no-plusplus
        if (this.selectedSelected.includes(this.value[i])) {
          this.value.splice(i, 1);
        }
      }

      this.availableSelected = [];
      this.selectedSelected = [];
    },
  },
};
</script>

<style></style>
