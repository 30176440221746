<template>
  <vinnuskoli-page>
    <b-row>
      <b-col>
        <div class="page-title">Samþykkja umsóknir</div>
      </b-col>
      <b-col>
        <b-btn variant="secondary" :to="{ name: 'ApplicationList' }" class="pull-right"> <i class="fa fa-fw fa-reply"></i> Til baka </b-btn>
      </b-col>
    </b-row>
    <div>
      <div v-if="$apollo.queries.approveApplications.loading || loadingGroups"><i class="fa fa-spin fa-spinner"></i></div>
      <div v-for="periodGroup in periodGrouping" :key="periodGroup.id" v-else>
        <hr />
        <h4 class="mt-4 mb-3">
          {{ periodGroup.name }}
          <small class="text-muted">
            {{ periodGroup.dateFrom | moment('DD.MM.YYYY') }} - {{ periodGroup.dateTo | moment('DD.MM.YYYY') }}
          </small>
        </h4>
        <template v-if="periodGroup.applicationGrouping.length > 0">
          <hr />
          <approve-application-item :periodGroup="periodGroup" :ref="`periodGroupItem`" />
        </template>

        <div v-for="periodGroupingPart in periodGroup.periodGroupingParts" :key="`${periodGroup.id}${periodGroupingPart.id}`">
          <hr />
          <h5 class="mt-3 mb-3">
            {{ periodGroupingPart.dateFrom | moment('DD.MM.YYYY') }} - {{ periodGroupingPart.dateTo | moment('DD.MM.YYYY') }}
          </h5>
          <hr />

          <approve-application-item
            :periodGroup="periodGroupingPart"
            v-if="periodGroupingPart.applicationGrouping.length > 0"
            :ref="`periodGroupItem`"
          />
        </div>
      </div>
    </div>
    <b-row class="mt-3">
      <b-col>
        <b-button variant="secondary" :to="{ name: 'ApplicationList' }" :disabled="saving">
          <span v-if="saved"><i class="fa fa-fw fa-reply"></i> Til baka á yfirlit</span
          ><span v-else><i class="fa fa-remove"></i> Hætta við</span>
        </b-button>
        <b-button v-if="!saved" variant="success" @click.prevent="validateBeforeSubmit" :disabled="saving">
          <i class="fa fa-check"></i>
          Staðfesta
        </b-button>
      </b-col>
    </b-row>
  </vinnuskoli-page>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies

import { mapActions } from 'vuex';
import core from '@/api/core';

import ApproveApplicationItem from './ApproveApplicationItem.vue';

export default {
  name: 'approve-application-modal',
  data() {
    return {
      loadingGroups: false,
      saving: false,
      saved: false,
      periodGrouping: null,
    };
  },
  components: {
    ApproveApplicationItem,
  },
  apollo: {
    approveApplications: {
      query: gql`
        query ApproveApplications($databaseIds: [UUID]) {
          approveApplications: applications(
            first: 100
            databaseIds: $databaseIds
            status: ["NEW", "SEMI_ACCEPTED", "IN_PROGRESS", "IN_PROGRESS_APPROVED"]
          ) {
            edges {
              node {
                id
                databaseId
                name
                status
                birthYear {
                  birthYear
                  birthYearReal
                  birthYearOverridden
                }
                allWorkplaces {
                  id
                  workplace {
                    id
                    databaseId
                    name
                    # type {
                    #   id
                    #   databaseId
                    #   name
                    # }
                  }
                }
                period {
                  id
                  databaseId
                  name
                  dateFrom
                  dateTo
                }
                allPeriodParts {
                  id
                  databaseId
                  status
                  periodPart {
                    id
                    databaseId
                    dateFrom
                    dateTo
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          databaseIds: this.$route.params.id.split(','),
        };
      },
      async result({ data: { approveApplications } = {} }) {
        if (approveApplications && approveApplications.edges) {
          this.loadingGroups = true;
          const promises = [];
          // Setja hópa á applications. Þurfum að gera það sync hérna svo það rati allt inn
          // í næstu ítranir.
          /* eslint-disable no-param-reassign */
          approveApplications.edges
            .map(e => e.node)
            .forEach((a, aIndex) => {
              if (a.allPeriodParts && a.allPeriodParts.length > 0) {
                a.allPeriodParts.forEach((p, pIndex) => {
                  if (p.status === 'NEW') {
                    promises.push(
                      this.getGroups(a, p).then(availableGroups => {
                        approveApplications.edges[aIndex].node.allPeriodParts[pIndex].availableGroups = availableGroups;
                      }),
                    );
                  }
                });
              } else if (a.status === 'NEW' || a.status === 'IN_PROGRESS' || a.status === 'IN_PROGRESS_APPROVED') {
                promises.push(
                  this.getGroups(a, null).then(availableGroups => {
                    approveApplications.edges[aIndex].node.availableGroups = availableGroups;
                  }),
                );
              }
            });
          /* eslint-enable */

          await Promise.all(promises);

          // Safna saman öllum unique tímabilum í eitt array.
          const periodGrouping = approveApplications.edges
            .map(e => e.node.period)
            .filter((v, i, a) => a.findIndex(v2 => v.id === v2.id) === i)
            .map(p => ({
              applicationGrouping: [],
              periodGroupingParts: [],
              ...p,
            }));

          // Safna saman öllum unique starfstímabilum inn í tímabilin.
          periodGrouping.forEach(pg => {
            pg.periodGroupingParts.push(
              ...approveApplications.edges
                .map(e => e.node)
                .filter(a => a.period.id === pg.id)
                .flatMap(a => a.allPeriodParts)
                .map(p => p.periodPart)
                .filter((v, i, a) => a.findIndex(v2 => v.id === v2.id) === i)
                .map(p => ({
                  applicationGrouping: [],
                  ...p,
                })),
            );
          });

          // Safna saman öllum umsóknum inn í tímabil og starfstímabil.
          periodGrouping.forEach(pg => {
            // Tímabil
            approveApplications.edges
              .map(e => e.node)
              .filter(a => !a.allPeriodParts || a.allPeriodParts.length === 0)
              .filter(a => a.period.id === pg.id)
              .forEach(a => pg.applicationGrouping.push(a));

            // Starfstímabil
            pg.periodGroupingParts.forEach(pg2 => {
              approveApplications.edges
                .map(e => e.node)
                .filter(a => a.allPeriodParts && a.allPeriodParts.length > 0)
                .forEach(a => {
                  a.allPeriodParts
                    .filter(ap => ap.periodPart.id === pg2.id)
                    .forEach(ap =>
                      pg2.applicationGrouping.push({
                        ...a,
                        periodGroupingPart: ap,
                      }),
                    );
                });
            });
          });

          this.periodGrouping = periodGrouping;
          this.loadingGroups = false;
        }
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  methods: {
    async getGroups(application, periodPart) {
      let response;
      if (periodPart) {
        response = await core.dropdownApplicationPlacementgroupList({
          period: application.period.databaseId,
          period_part: periodPart.periodPart.databaseId,
          workplaces: application.allWorkplaces.map(x => x.workplace.databaseId).join(),
          type: null, // application.allWorkplaces.length > 0 ? application.allWorkplaces[0].workplace.type.databaseId : null,
          birth_year: application.birthYear.birthYear,
        });
      } else {
        response = await core.dropdownApplicationPlacementgroupList({
          period: application.period.databaseId,
          workplaces: application.allWorkplaces.map(x => x.workplace.databaseId).join(),
          type: null, // application.allWorkplaces.length > 0 ? application.allWorkplaces[0].workplace.type.databaseId : null,
          birth_year: application.birthYear.birthYear,
        });
      }

      return response.data;
    },
    async validateBeforeSubmit() {
      try {
        this.saving = true;

        const promises = [];

        this.$refs.periodGroupItem.forEach(r => promises.push(r.doApprove()));

        await Promise.all(promises);

        this.saved = true;

        this.displaySuccess('Umsóknir samþykktar');
        // this.$router.push({ name: 'ApplicationList' });
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
