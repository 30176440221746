<template>
  <div>
    <b-modal
      id="applicationInProgressModal"
      title="Merkja umsóknir í vinnslu"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Merkja í vinnslu"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading || !allNew"
      ref="createModal"
    >
      <div>
        <h5>Veldu starfsstaði til þess að merkja umsóknir á</h5>
        <table class="table white-table">
          <tr>
            <th>Nafn</th>
            <th>Fæðingarár</th>
            <th>Tímabil</th>
            <th>Starfsstaður</th>
            <th></th>
          </tr>
          <tr
            v-for="application in applications"
            :key="application.id"
            :class="{ 'text-danger': application.status !== 'NEW' && application.status !== 'IN_PROGRESS' }"
          >
            <td>{{ application.name }}</td>
            <td>{{ application.birthYear.birthYear }}</td>
            <td>{{ application.period.name }}</td>
            <td>
              <!-- <div v-for="workplace in application.workplaces" :key="workplace.id">
                {{ workplace.workplace.name }}
              </div> -->
              <b-form-select id="workplace" name="workplace" v-model="applicationWorkplaces[application.id]">
                <option :value="null">-- Veldu starfsstað --</option>
                <template v-if="!workplaces.loading && workplaces.data">
                  <option
                    v-for="workplace in workplaces.data.filter(w => application.workplaces.some(aw => aw.workplace.id === w.id))"
                    :key="workplace.id"
                    :value="workplace.id"
                  >
                    {{ workplace.name }}
                  </option>
                </template>
              </b-form-select>
            </td>
            <td>
              <i
                v-if="application.status !== 'NEW' && application.status !== 'IN_PROGRESS'"
                class="fa fa-exclamation-triangle"
                v-b-tooltip.hover
                title="Ekki er hægt að merkja umsókn í vinnslu"
              ></i>
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'application-in-progress-modal',
  data() {
    return {
      loading: false,
      applications: [],
      workplaces: {
        data: [],
        loading: false,
        error: false,
      },
      applicationWorkplaces: {},
    };
  },
  computed: {
    allNew() {
      return this.applications.every(a => a.status === 'NEW' || a.status === 'IN_PROGRESS');
    },
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.errorList = [];
        const promises = [];

        this.applications.forEach(a => {
          promises.push(
            this.$applicationApi.applicationChangeStatus({
              uuid: a.id,
              applicationChangeStatusRequest: {
                status: 'IN_PROGRESS',
                workplace: this.applicationWorkplaces[a.id],
              },
            }),
          );
        });
        await Promise.all(promises);
        this.$emit('successful', {});
        this.$refs.createModal.hide();
      } catch (e) {
        // Aðgerð mistókst.
        this.saving = true;
        this.$log.debug(e);
        if (e.response && e.response.status === 400) {
          djangoErrorsToVee(e.response.data, this.errors, this.errorList);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    show(applications) {
      this.loadWorkplaces();
      this.applicationWorkplaces = applications.reduce((result, item) => {
        // eslint-disable-next-line no-param-reassign
        result[item.id] = null;
        return result;
      }, {});
      this.applications = applications;
      this.$refs.createModal.show();
    },
    async loadWorkplaces() {
      this.workplaces.loading = true;
      this.workplaces.data = [];
      this.workplaces.error = false;

      try {
        const response = await this.$coreApi.workplaceDropdownList();

        this.workplaces.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.workplaces.error = true;
      } finally {
        this.workplaces.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
