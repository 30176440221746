
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
// import { serverValidationToForm } from '@/utils';
import { BModal } from 'bootstrap-vue';
import { HelpCategory } from '@/api/openapi';

@Component
export default class HelpDocumentModal extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;

  category: {
    data: HelpCategory | null;
    loading: boolean;
    error: boolean;
  } = {
    data: null,
    loading: false,
    error: false,
  };

  submitting = false;
  submitted = false;
  failed = false;

  errorList = [];

  form: {
    document: File | null;
    name: string;
  } = {
    document: null,
    name: '',
  };

  $refs!: {
    modal: BModal;
  };

  async loadCategory(id: string) {
    this.category.loading = true;
    this.category.data = null;
    this.category.error = false;

    try {
      const response = await this.$coreApi.helpCategoryRetrieve({ uuid: id });

      this.category.data = response.data;
    } catch (e) {
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      this.category.error = true;
    } finally {
      this.category.loading = false;
    }
  }

  async show(categoryId: string) {
    // Hreinsa formið
    this.submitted = false;
    this.errorList = [];
    this.form.document = null;
    this.form.name = '';

    this.loadCategory(categoryId);

    this.$refs.modal.show();
  }

  async validateBeforeSubmit() {
    try {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll().then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
      });
      // Engar villur í formi.
      try {
        this.submitting = true;

        await this.$coreApi.helpDocumentUpload({
          category: this.category.data?.id || '',
          document: this.form.document,
          name: this.form.name,
        });
        this.displaySuccess(`Skrá hefur verið stofnuð.`);

        this.submitted = false;
        this.$emit('successful', {});
        this.$refs.modal.hide();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    } catch (e) {
      // Villur í formi.
      this.$log.debug(e);
    }
  }
}
