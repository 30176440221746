<template>
  <div>
    <b-card no-body border-variant="light" class="mb-2 mt-3">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Launamánuður</th>
            <th class="text-right">Upphæð</th>
          </tr>
        </thead>
        <tr v-for="period in periodList" :key="period.id">
          <td>{{ period.name }}</td>
          <td align="right">{{ period.wages | currency }}</td>
        </tr>
        <tr v-if="periodList.length === 0">
          <td colspan="100%" class="text-center"><i>Engar niðurstöður fundust</i></td>
        </tr>
      </table>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import payment from '@/api/payment';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'billing-period-overview',
  props: ['active', 'year', 'search'],
  mixins: [paginationMixin],
  data() {
    return {
      page: null,
      pagination: null,
      loading: false,
      periodList: [],
    };
  },
  methods: {
    async loadList(page = null, limit = null) {
      this.loading = true;
      this.periodList = [];
      try {
        const response = await payment.paymentBatchOverviewPaymentPeriodList(page, limit, this.search, this.year);
        this.periodList = response.data;
        this.pagination = parsePagination(response);
        // await this.setLoggedInUserOption({ option: 'application', value: this.options.application });
        // await this.saveLoggedInUserOptions();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    active(val) {
      if (val) {
        this.loadList();
      }
    },
    year(val) {
      if (val) {
        this.loadList();
      }
    },
    search(val) {
      if (val) {
        this.loadList();
      }
    }
  },
  created() {
    if (this.active) {
      this.loadList();
    }
  },
};
</script>
