<template>
  <div>
    <b-modal
      id="populateModal"
      title="Staðfesta forskoðun"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Staðfesta"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate v-if="batch.payment_period">
          <div class="alert alert-danger" v-if="errorList.length > 0">
            <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
          </div>
          <div style="font-size: 20px"><strong>Ertu viss um að þú viljir staðfesta forskoðun fyrir eftirfarandi uppgjör</strong></div>
          <br />
          <div>Þessa aðgerð er <strong>EKKI</strong> hægt að afturkalla.</div>
          <div>Þegar forskoðun er staðfest læsast allar viðverufærslur tímabilsins og ekki lengur hægt að breyta þeim</div>
          <br />
          <div>
            Launatímabil:
            {{ batch.payment_period.name }}
            ({{ batch.payment_period.date_from | moment('DD.MM.YYYY') }} - {{ batch.payment_period.date_to | moment('DD.MM.YYYY') }})
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import payment from '@/api/payment';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'populate-batch-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      id: null,
      batch: {},
    };
  },
  methods: {
    async load() {
      this.loading = true;
      this.batch = {};
      try {
        const response = await payment.paymentBatchDetail(this.id);
        this.batch = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        // Engar villur í formi.
        try {
          this.loading = true;
          await payment.paymentBatchPopulate(this.id);
          this.displaySuccess(`Uppgjör hefur verið staðfest fyrir launatímabilið „${this.batch.payment_period.name}“.`);
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
      }
    },
    show(id) {
      this.batch = {};
      this.submitted = false;
      this.loading = false;
      this.id = id;
      this.load();
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
