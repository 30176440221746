<template>
  <div>
    <b-modal
      id="accessEditModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <div v-for="group in groupList" :key="group.id">
            <b-form-group :label="`${group.name}:`" :label-for="`group${group.id}`">
              <b-form-select :id="`group${group.id}`" :name="`group${group.id}`" v-model="group.permission">
                <option value="none">Enginn</option>
                <option value="read">Les</option>
                <option value="write">Les+Skrif</option>
              </b-form-select>
            </b-form-group>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import auth from '@/api/auth';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'access-edit-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      title: 'Breyta',
      accessId: '',
      accessName: '',
      groupList: [],
    };
  },
  methods: {
    async load() {
      this.loading = true;
      this.groupList = [];
      try {
        const response = await auth.authGroupList();
        this.groupList = response.data.map(x => ({
          id: x.id,
          name: x.name,
          permission:
            x.permissions.find(p => p.name === this.accessId) !== undefined
              ? x.permissions.find(p => p.name === this.accessId).permission
              : 'none',
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          const promises = [];

          this.groupList.forEach(g => {
            promises.push(
              auth.authGroupUpdate(g.id, {
                name: g.name,
                permissions: [
                  {
                    name: this.accessId,
                    permission: g.permission,
                  },
                ],
              }),
            );
          });
          await Promise.all(promises);
          this.displaySuccess(`Aðgang starfstegunda fyrir „${this.accessName}“ hefur verið breytt.`);

          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(accessId, accessName) {
      this.accessId = accessId;
      this.accessName = accessName;
      this.title = `Breyta ${accessName}`;
      this.submitted = false;
      this.loading = false;
      this.load();
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
