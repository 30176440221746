<template>
  <li class="nav-item" :class="{ active }">
    <b-link :to="to" class="nav-link">
      <font-awesome-icon v-if="icon" class="nav-link-icon" fixedWidth :icon="icon" /> <span>{{ name }}</span>
    </b-link>
  </li>
</template>

<script>
export default {
  name: 'side-nav-link',
  props: {
    name: String,
    startsWith: Boolean,
    to: [Object, String],
    icon: String,
    active: Boolean,
  },
};
</script>
