<template>
  <div>
    <b-modal
      id="cancelApplicationModal"
      title="Breyta stöðu umsóknar"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Breyta stöðu"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <div>Ertu viss um að þú viljir afskrá umsóknina?</div>
        <table class="table">
          <tr>
            <th>Ástæða afskráningar</th>
            <td><textarea v-model="comment" style="width: 100%"></textarea></td>
          </tr>
          <tr>
            <th>
              Afskrá þátttakanda úr öllum <br />
              hópum sem hann er skráður í ?
            </th>
            <td>
              <b-form-checkbox id="deleteGroups" name="deleteGroups" type="text" v-model="deleteGroups"></b-form-checkbox>
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import appl from '@/api/application';

export default {
  name: 'cancel-application-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      failed: false,
      deleteGroups: true,
      comment: '',
      id: null,
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        const item = {
          comment: this.comment,
          deleteGroups: this.deleteGroups,
        };
        try {
          await appl.applicationCancel(this.id, item);
          this.displaySuccess('Umsókn hefur verið afskráð.');
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(id) {
      this.id = id;
      this.comment = '';
      this.deleteGroups = true;
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
