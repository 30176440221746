<template>
  <b-card class="card-primary-header">
    <div slot="header">
      <div>
        Viðvera
        <a @click.prevent="registerAttendanceModal()">
          <i class="fa fa-fw fa-plus" style="font-size: 20px; float: right; padding-top: 5px"></i>
        </a>
      </div>
    </div>
    <div class="equal-height-3">
      <div v-if="!loggedInUserHasAnyPermission('read_attendance')">
        <i>Þú hefur ekki aðgang að því að skoða viðveru</i>
      </div>
      <div v-else>
        <table class="table" v-if="data.length > 0">
          <tr>
            <i style="padding-right: 20px"
              >Samtala unninna tíma á tímabili: <b>{{ periodTotalHours }}</b></i
            >
            <i v-if="periodMaxHours"
              >Hámarks vinnustundir á tímabili: <b>{{ Number(periodMaxHours) }}</b></i
            >
          </tr>
          <tr>
            <th>Dags</th>
            <th>Fj. tíma</th>
            <th>Athugasemd</th>
            <th></th>
          </tr>
          <tr v-for="attendance in data" :key="attendance.id">
            <td>{{ attendance.date | moment('DD.MM.YYYY') }}</td>
            <td v-if="!attendance.attendance_type">
              {{ attendance.hours }}
              <i v-if="attendance.attendance_project"> - {{ attendance.attendance_project.name }}</i>
            </td>
            <td v-else><i>Fjarvist: </i>{{ attendance.attendance_type.name }}</td>
            <td>{{ attendance.comment }}</td>
            <td>
              <i
                v-if="attendance.created_by_registration"
                class="fa fa-info-circle"
                style="color: #008ad0"
                v-b-tooltip.hover
                title="Skráð af aðstandanda á umsóknarvef"
              ></i>
              <!-- <font-awesome-icon fixedWidth :icon="attendance.created_by_registration ? 'info_circle' : ''" /> -->
            </td>
          </tr>
        </table>

        <div v-else><i>Engin viðvera skráð</i></div>
      </div>
    </div>
    <div class="px-4">
      <pagination
        v-if="pagination && data && data.length > 0"
        :pagination-response="pagination"
        @change-limit="changeLimit"
        @change-page="changePage"
      >
      </pagination>
    </div>
    <register-attendance-modal ref="registerAttendanceModal" @successful="loadList()"></register-attendance-modal>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import attendance from '@/api/attendance';
import Pagination from '@/components/Pagination.vue';
import { parsePagination } from '@/utils';
import RegisterAttendanceModal from '@/components/student/RegisterAttendanceModal.vue';

export default {
  name: 'student-attendance',
  props: ['reload', 'item', 'periodMaxHours', 'periodTotalHours'],
  components: {
    Pagination,
    RegisterAttendanceModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      pagination: null,
      loading: false,
      data: [],
    };
  },
  methods: {
    async loadList(page, limit) {
      this.data = [];
      if (this.loggedInUserHasAnyPermission('read_attendance') && this.item) {
        this.loading = true;
        try {
          const response = await attendance.attendanceList(page || undefined, limit || 5, this.item.id);
          this.data = response.data;
          this.pagination = parsePagination(response);
          this.$nextTick(() => {
            document.dispatchEvent(new CustomEvent('matchheight', {}));
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    changeLimit(limit) {
      this.loadList(undefined, limit);
    },
    changePage(page) {
      this.loadList(page, this.pagination.currentLimit);
    },
    /*
     * Modal gluggar
     */
    registerAttendanceModal() {
      this.$refs.registerAttendanceModal.show(this.item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    reload(val) {
      if (val) {
        this.loadList();
      }
    },
  },
  created() {
    this.loadList();
  },
};
</script>
