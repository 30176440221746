<template>
  <div>
    <b-row class="mb-3 mt-3">
      <b-col cols="4">
        <strong>Tímabil</strong>
        <b-form-select id="period" name="period" v-model="search.period" @change="onSearchChanged">
          <option :value="null" v-if="!autoPeriod">-- Veldu tímabil --</option>
          <template v-if="!$apollo.queries.periods.loading && periods && periods.edges">
            <option v-for="period in periods.edges.map(w => w.node)" :key="period.id" :value="period.id">
              {{ period.name }}
            </option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="4">
        <template v-if="!$apollo.queries.periodParts.loading && periodParts && periodParts.edges && periodParts.edges.length > 0">
          <strong> Starfstímabil </strong>
          <b-form-select id="periodPart" name="periodPart" v-model="search.periodPart" @change="onSearchChanged">
            <option :value="null">
              <span>-- Veldu starfstímabil --</span>
            </option>
            <option v-for="part in periodParts.edges.map(p => p.node)" :key="part.id" :value="part.id">
              {{ part.dateFrom | moment('DD.MM.YYYY') }} - {{ part.dateTo | moment('DD.MM.YYYY') }}
            </option>
          </b-form-select>
        </template>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="4">
        <strong>Flokkun starfsstaða</strong>
        <b-form-select id="type" name="type" v-model="search.workplaceType" @change="onSearchChanged">
          <option :value="null">-- Veldu flokk --</option>
          <template v-if="!$apollo.queries.workplaceTypes.loading && workplaceTypes && workplaceTypes.edges">
            <option v-for="type in workplaceTypes.edges.map(w => w.node)" :key="type.id" :value="type.id">
              {{ type.name }}
            </option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="4">
        <strong>Starfsstaður</strong>
        <b-form-select id="workplace" name="workplace" v-model="search.workplace" @change="onSearchChanged">
          <option :value="null">-- Veldu starfsstað --</option>
          <template v-if="!$apollo.queries.workplaces.loading && workplaces && workplaces.edges">
            <option v-for="workplace in workplaces.edges.map(w => w.node)" :key="workplace.id" :value="workplace.id">
              {{ workplace.name }}
            </option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="4">
        <strong>Hópur</strong>
        <b-form-select id="placementGroup" name="placementGroup" v-model="search.placementGroup" @change="onSearchChanged">
          <option :value="null">-- Veldu hóp --</option>
          <template v-if="!$apollo.queries.placementGroups.loading && placementGroups && placementGroups.edges">
            <option v-for="placementGroup in placementGroups.edges.map(w => w.node)" :key="placementGroup.id" :value="placementGroup.id">
              {{ placementGroup.name }}
              {{ placementGroup.dateFrom | moment('DD.MM.YYYY') }} -
              {{ placementGroup.dateTo | moment('DD.MM.YYYY') }}
            </option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="4" v-if="kjarni">
        <strong>Til í launakerfi</strong>
        <b-form-select id="exists" name="exists" v-model="search.exists" @change="onSearchChanged">
          <option :value="null">Allir</option>
          <option value="false">Ekki til í launakerfi</option>
          <option value="true">Til í launakerfi</option>
          <option value="sent-yes">Sendir í launakerfi</option>
          <option value="sent-no">Ekki sendir í launakerfi</option>
        </b-form-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies
import { mapActions } from 'vuex';

export default {
  name: 'payroll-payment-employees-h3-filters',
  props: {
    value: Object,
    databaseId: Boolean,
    autoPeriod: Boolean,
    kjarni: Boolean,
  },
  data() {
    return {
      search: {
        placementGroup: null,
        period: null,
        periodPart: null,
        workplace: null,
        workplaceType: null,
        exists: null,
      },
    };
  },
  apollo: {
    periods: {
      query: gql`
        query Periods {
          periods(isDropdown: true, first: 100) {
            totalCount
            edges {
              node {
                id
                databaseId
                name
                hasParts
                dateFrom
                dateTo
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      result({ data: { periods } = {} }) {
        if (this.autoPeriod && periods.edges.length > 0 && !this.search.period) {
          this.search.period = periods.edges[0].node.id;
          this.onSearchChanged();
        }
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
    placementGroups: {
      query: gql`
        query PlacementGroupsDropdown($period: ID, $periodPart: ID) {
          placementGroups(isDropdown: true, first: 100, period: $period, periodPart: $periodPart) {
            totalCount
            edges {
              node {
                id
                databaseId
                name
                dateFrom
                dateTo
              }
            }
          }
        }
      `,
      variables() {
        const variables = {};

        if (this.search.period) variables.period = this.search.period;
        if (this.search.periodPart) variables.periodPart = this.search.periodPart;

        return variables;
      },
      result({ data: { placementGroups } = {} }) {
        if (this.search.placementGroup) {
          if (placementGroups && placementGroups.edges && !placementGroups.edges.map(p => p.node.id).includes(this.search.placementGroup)) {
            this.search.placementGroup = null;
          }
        }
      },
      fetchPolicy: 'cache-and-network',
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
    periodParts: {
      query: gql`
        query PeriodParts($period: ID!, $hasId: Boolean!) {
          periodParts(isDropdown: true, first: 100, period: $period) @include(if: $hasId) {
            totalCount
            edges {
              node {
                id
                databaseId
                dateFrom
                dateTo
              }
            }
          }
        }
      `,
      variables() {
        return {
          period: this.search.period || '',
          hasId: !!this.search.period, // Virtist vera eina leiðin til að láta listann uppfrast þegar period er tómt.
        };
      },
      result({ data: { periodParts } = {} }) {
        if (!this.search.period) {
          this.search.periodPart = null;
        } else if (this.search.periodPart) {
          if (periodParts && periodParts.edges && !periodParts.edges.map(p => p.node.id).includes(this.search.periodPart)) {
            this.search.periodPart = null;
          }
        }
      },
      fetchPolicy: 'cache-and-network',
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
    workplaceTypes: {
      query: gql`
        query WorkplaceTypes {
          workplaceTypes(isDropdown: true, first: 100) {
            totalCount
            edges {
              node {
                id
                databaseId
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
    workplaces: {
      query: gql`
        query Workplaces($type: ID) {
          workplaces(isDropdown: true, first: 100, type: $type) {
            totalCount
            edges {
              node {
                id
                databaseId
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      variables() {
        const variables = {};

        if (this.search.workplaceType) variables.type = this.search.workplaceType;

        return variables;
      },
      result({ data: { workplaces } = {} }) {
        if (this.search.workplace) {
          if (workplaces && workplaces.edges && !workplaces.edges.map(p => p.node.id).includes(this.search.workplace)) {
            this.search.workplace = null;
          }
        }
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  methods: {
    onSearchChanged() {
      let f;
      if (this.databaseId) {
        f = (value, items) => (value ? items.edges.find(w => w.node.id === value).node.databaseId : null);
      } else {
        f = value => value;
      }

      this.$emit('input', {
        placementGroup: f(this.search.placementGroup, this.placementGroups),
        period: f(this.search.period, this.periods),
        periodPart: f(this.search.periodPart, this.periodParts),
        workplace: f(this.search.workplace, this.workplaces),
        workplaceType: f(this.search.workplaceType, this.workplaceTypes),
        exists: this.search.exists,
      });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
