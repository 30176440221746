import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Vuex, { GetterTree } from 'vuex';
import { ADD_TOAST_MESSAGE, createModule } from 'vuex-toast';
import { MyAccount } from '@/api/openapi';
import core from '@/api/core';
import auth from '@/api/auth';
import * as types from './mutation-types';

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production';

// export type MyAccountState = {
//   options?: any;
//   town?: any;
//   // eslint-disable-next-line camelcase
//   is_superuser: boolean;
//   permissions: Array<string>;
// }; & MyAccount;

export type State = {
  authLoading: boolean;
  loggedInUser: MyAccount | null;
  townListLoading: boolean;
  townList: any;
  applicationWorkonList: any;
  saveUserOptionsLoading: any;
};

const state: State = {
  authLoading: true,
  loggedInUser: null,
  townListLoading: false,
  townList: null,
  applicationWorkonList: null,
  saveUserOptionsLoading: false,
};

export type Getters = {
  loggedInUserHasAnyPermission(s: State): (...permission: string[]) => boolean;
  loggedInUserOptions(s: State): any;
};

const getters: GetterTree<State, State> & Getters = {
  /**
   * Athuga hvort innskráður notandi hafi réttindi.
   *
   * @param {string} permission Réttindi á sniðmátinu app.object.permission (auth.ferilbokuser.add)
   */
  loggedInUserHasAnyPermission:
    s =>
    (...permission: string[]) => {
      const { loggedInUser } = s;

      if (!loggedInUser) {
        return false;
      }

      if (loggedInUser.isSuperuser === true) {
        return true;
      }

      if (loggedInUser.permissions === null) {
        return false;
      }

      return loggedInUser.permissions.filter(p => permission.includes(p)).length > 0;
    },

  loggedInUserOptions: s => {
    const { loggedInUser } = s;

    if (!loggedInUser) {
      return null;
    }

    return loggedInUser.options;
  },
};

const actions: any = {
  /**
   * Birta jákvæð toast skilaboð.
   *
   * @param {*} param0 Vuex virkni.
   * @param {string} message Skilaboð til að birta.
   */
  displaySuccess({ dispatch }: any, message: any) {
    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: message,
        type: 'success',
        dismissAfter: 10000,
      },
      {
        root: true,
      },
    );
  },
  /**
   * Birta neikvæð toast skilaboð.
   *
   * @param {*} param0 Vuex virkni.
   * @param {string} message Skilaboð til að birta.
   */
  displayError({ dispatch }: any, message: any) {
    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: message,
        type: 'danger',
        dismissAfter: 15000,
      },
      {
        root: true,
      },
    );
  },
  /**
   * Sækja innskráðan notanda og vista í store.
   *
   * @param {*} param0 Vuex virkni.
   */
  getLoggedInUser({ commit }: any) {
    return new Promise((resolve, reject) => {
      commit(types.AUTH_LOADING, true);

      Vue.$authApi
        .myAccount()
        .then(response => {
          resolve(response.data);
          commit(types.ME_SUCCESS, response.data);
          commit(types.ME_INIT_USER_OPTIONS);
          commit(types.AUTH_LOADING, false);
        })
        .catch(error => {
          reject(error);
          commit(types.ME_FAILED);
          commit(types.AUTH_LOADING, false);
        });
    });
  },
  /**
   * Sækja yfirlit yfir sveitarfélög til að geta birt í haus.
   *
   * @param {*} param0 Vuex virkni.
   */
  getTownList({ commit }: any) {
    return new Promise((resolve, reject) => {
      commit(types.TOWN_LIST_LOADING, true);
      core
        .townList()
        .then((response: AxiosResponse) => {
          resolve(response.data);
          commit(types.TOWN_LIST_SUCCESS, response.data);
          commit(types.TOWN_LIST_LOADING, false);
        })
        .catch((error: any) => {
          reject(error);
          commit(types.TOWN_LIST_FAILED);
          commit(types.TOWN_LIST_LOADING, false);
        });
    });
  },
  /**
   * Stilla lista af umsóknum til að vinna með í næstu skjámynd.
   *
   * @param {*} param0 Vuex virkni.
   * @param {*} param1 Listi af umsóknum.
   */
  setApplicationWorkonList({ commit }: any, applicationList: any) {
    commit(types.WORKON_APPLICATION_LIST_SUCCESS, applicationList);
  },
  /**
   * Hreinsa lista af umsóknum til að vinna með í næstu skjámynd.
   *
   * @param {*} param0 Vuex virkni.
   */
  cleanApplicationWorkonList({ commit }: any) {
    commit(types.WORKON_APPLICATION_LIST_CLEAN);
  },
  /**
   * Uppfæra stillingu fyrir núverandi notanda og vista.
   */
  setLoggedInUserOption({ commit }: any, { option, value }: any) {
    commit(types.SET_USER_OPTION, { option, value });
  },
  /**
   * Vista stillingar fyrir núverandi notanda á núverandi sveitafélagi.
   */
  saveLoggedInUserOptions({ state: st }: any) {
    return new Promise((resolve, reject) => {
      // commit(types.SAVE_USER_OPTIONS_LOADING, true);
      auth
        .saveUserOptions(st.loggedInUser.options)
        .then((response: AxiosResponse) => {
          resolve(response.data);
          // commit(types.SAVE_USER_OPTIONS_LOADING, false);
        })
        .catch((error: any) => {
          reject(error);
          // commit(types.SAVE_USER_OPTIONS_LOADING, false);
        });
    });
  },
};

const mutations: any = {
  [types.ME_SUCCESS](s: State, data: any) {
    Vue.set(s, 'loggedInUser', data);
  },
  [types.ME_FAILED](s: State) {
    Vue.set(s, 'loggedInUser', null);
  },
  [types.AUTH_LOADING](s: State, authLoading: any) {
    Vue.set(s, 'authLoading', authLoading);
  },
  [types.TOWN_LIST_LOADING](s: State, townListLoading: any) {
    Vue.set(s, 'townListLoading', townListLoading);
  },
  [types.TOWN_LIST_SUCCESS](s: State, data: any) {
    Vue.set(s, 'townList', data);
  },
  [types.TOWN_LIST_FAILED](s: State) {
    Vue.set(s, 'townList', null);
  },
  [types.WORKON_APPLICATION_LIST_SUCCESS](s: State, applicationList: any) {
    Vue.set(s, 'applicationWorkonList', applicationList);
  },
  [types.WORKON_APPLICATION_LIST_CLEAN](s: State) {
    Vue.set(s, 'applicationWorkonList', null);
  },
  [types.SAVE_USER_OPTIONS_LOADING](s: State, loading: any) {
    Vue.set(s, 'saveUserOptionsLoading', loading);
  },
  [types.SET_USER_OPTION](s: State, { option, value }: any) {
    if (s.loggedInUser && s.loggedInUser.options) {
      Vue.set(s.loggedInUser.options, option, value);
    }
  },
  [types.ME_INIT_USER_OPTIONS](s: State) {
    let options = null;

    if (s.loggedInUser && s.loggedInUser.town && !s.loggedInUser.options) {
      options = {};
    } else if (s.loggedInUser) {
      options = JSON.parse(JSON.stringify(s.loggedInUser.options));
    }

    if (s.loggedInUser) {
      Vue.set(s.loggedInUser, 'options', options);
    }
  },
};

export default new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    toast: createModule({
      dismissInterval: 8000,
    }),
  },
  strict: false,
});
