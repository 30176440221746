var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vinnuskoli-page',[_c('div',{staticClass:"page-title"},[_vm._v("Skoða umsókn")]),_c('h2',{staticClass:"font-weight-normal"},[_vm._v(_vm._s(_vm.data && _vm.data.name))]),(_vm.data)?_c('div',{staticClass:"py-2"},[_c('b-button-toolbar',{attrs:{"aria-label":"Aðgerðir á umsókn"}},[_c('b-button-group',{staticClass:"mx-1"},[(
            _vm.availableStatuses[_vm.data.status].includes('ACCEPTED') &&
            _vm.loggedInUserHasAnyPermission('write_application') &&
            _vm.loggedInUserHasAnyPermission('write_approve_application')
          )?_c('b-btn',{attrs:{"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.applicationApproveModal()}}},[_c('i',{staticClass:"fa fa-fw fa-check",staticStyle:{"color":"#fff"}}),_vm._v(" Samþykkja umsókn ")]):_vm._e(),(
            _vm.data.status === 'SEMI_ACCEPTED' &&
            _vm.loggedInUserHasAnyPermission('write_application') &&
            _vm.loggedInUserHasAnyPermission('write_approve_application')
          )?_c('b-btn',{attrs:{"variant":"dark"},on:{"click":function($event){$event.preventDefault();return _vm.applicationFinishModal()}}},[_c('i',{staticClass:"fa fa-fw fa-envelope"}),_vm._v(" Klára umsókn ")]):_vm._e(),(
            _vm.availableStatuses[_vm.data.status].includes('REJECTED') &&
            _vm.loggedInUserHasAnyPermission('write_application') &&
            _vm.loggedInUserHasAnyPermission('write_approve_application')
          )?_c('b-btn',{attrs:{"variant":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.applicationRejectModal()}}},[_c('i',{staticClass:"fa fa-fw fa-remove"}),_vm._v(" Hafna umsókn ")]):_vm._e()],1),(
          _vm.loggedInUserHasAnyPermission('write_application') &&
          (_vm.availableStatuses[_vm.data.status].includes('NEW') ||
            _vm.availableStatuses[_vm.data.status].includes('IN_PROGRESS') ||
            _vm.availableStatuses[_vm.data.status].includes('IN_PROGRESS_APPROVED') ||
            _vm.availableStatuses[_vm.data.status].includes('WITHDRAWN') ||
            _vm.availableStatuses[_vm.data.status].includes('CANCELLED') ||
            _vm.availableStatuses[_vm.data.status].includes('COMPLETED'))
        )?_c('b-dropdown',{staticClass:"mx-1",attrs:{"variant":"info","right":"","text":"Aðrar stöður"}},[(_vm.availableStatuses[_vm.data.status].includes('NEW') && _vm.loggedInUserHasAnyPermission('write_application'))?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.applicationNewModal()}}},[_vm._v("Nýskráð")]):_vm._e(),(_vm.availableStatuses[_vm.data.status].includes('IN_PROGRESS') && _vm.loggedInUserHasAnyPermission('write_application'))?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.applicationInProgressModal()}}},[_vm._v("Í vinnslu")]):_vm._e(),(_vm.availableStatuses[_vm.data.status].includes('IN_PROGRESS_APPROVED') && _vm.loggedInUserHasAnyPermission('write_application'))?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.applicationInProgressApproveModal()}}},[_vm._v("Í vinnslu samþykkt")]):_vm._e(),(
            _vm.availableStatuses[_vm.data.status].includes('WITHDRAWN') &&
            _vm.loggedInUserHasAnyPermission('write_application') &&
            _vm.loggedInUserHasAnyPermission('write_approve_application')
          )?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.applicationWithdrawModal()}}},[_vm._v("Draga til baka")]):_vm._e(),(
            _vm.availableStatuses[_vm.data.status].includes('CANCELLED') &&
            _vm.loggedInUserHasAnyPermission('write_application') &&
            _vm.loggedInUserHasAnyPermission('write_approve_application')
          )?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.applicationCancelModal()}}},[_vm._v("Afskrá")]):_vm._e(),(
            _vm.availableStatuses[_vm.data.status].includes('COMPLETED') &&
            _vm.loggedInUserHasAnyPermission('write_application') &&
            _vm.loggedInUserHasAnyPermission('write_approve_application')
          )?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.applicationCompleteModal()}}},[_vm._v("Ráðning")]):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(_vm.application2 && _vm.data)?_c('b-card',{staticClass:"card-primary-header"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',[_vm._v("Umsókn")])]),_c('application-applicant',{attrs:{"item":_vm.application2,"item2":_vm.applicationNationalRegistry,"id":_vm.id},on:{"reload":_vm.doReload}}),_c('application-registration',{attrs:{"item":_vm.application2,"item2":_vm.data,"id":_vm.id},on:{"reload":_vm.doReload}}),_c('application-contacts',{attrs:{"item":_vm.data},on:{"reload":_vm.doReload}}),_c('b-row',{staticClass:"mt-4"},[_c('b-col')],1)],1):_vm._e(),_c('application-email',{attrs:{"id":_vm.id,"reload":_vm.doReload}}),_c('application-history',{attrs:{"id":_vm.id,"reload":_vm.doReload}}),_c('reject-application-modal',{ref:"rejectApplicationModal",on:{"successful":_vm.doReload}}),_c('finish-application-modal',{ref:"finishApplicationModal",on:{"successful":_vm.doReload}}),_c('application-in-progress-modal',{ref:"applicationInProgressModal",on:{"successful":_vm.doReload}}),_c('application-in-progress-approved-modal',{ref:"applicationInProgressApprovedModal",on:{"successful":_vm.doReload}}),_c('application-withdraw-modal',{ref:"withdrawApplicationModal",on:{"successful":_vm.doReload}}),_c('application-complete-modal',{ref:"completeApplicationModal",on:{"successful":_vm.doReload}}),_c('application-cancel-modal',{ref:"cancelApplicationModal",on:{"successful":_vm.doReload}}),_c('application-new-modal',{ref:"applicationNewModal",on:{"successful":_vm.doReload}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }