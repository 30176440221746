<template>
  <div>
    <b-row>
      <b-col class="pt-2"> Sýni niðurstöður {{ showingFrom }} - {{ showingTo }} af {{ totalCount }} </b-col>

      <b-col>
        <b-pagination
          class="pull-right"
          v-model="tmpPage"
          :per-page="limit"
          :total-rows="totalCount"
          @change="setCurrentPage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="text-right">
          Sýna
          <select v-model="tmpLimit" @change="onChangeLimit($event)">
            <option :value="1">1</option>
            <option :value="5">5</option>
            <option :value="10">10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
          </select>
          leitarniðurstöður á blaðsíðu
        </div>
      </b-col></b-row
    >
  </div>
</template>

<script>
import { parseAfter } from '@/utils';

export default {
  name: 'Pagination2',
  props: {
    dataLength: Number,
    totalCount: Number,
    currentPage: Number,
    fetchMore: Function,
    setCurrentPage: Function,
    small: Boolean,
    limit: Number,
    setLimit: Function,
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalCount / this.limit);
    },
    showingFrom() {
      return this.limit * (this.currentPage - 1) + 1;
    },
    showingTo() {
      return this.limit * (this.currentPage - 1) + this.dataLength;
    },
  },
  data() {
    return {
      tmpPage: this.currentPage,
      tmpLimit: this.limit,
    };
  },
  methods: {
    onChangeLimit(e) {
      this.setLimit(parseInt(e.target.value, 10));
      this.setCurrentPage(1);
    },
    onLoadPage(page) {
      const after = parseAfter(page, this.limit);

      if (this.fetchMore) {
        this.fetchMore({
          variables: {
            after,
            first: this.limit,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.setCurrentPage(page);
            return fetchMoreResult;
          },
        });
      } else {
        this.setCurrentPage(page);
      }
    },
  },
};
</script>
