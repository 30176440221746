const API_URL = '/api/';

const PING_URL = `${API_URL}auth/ping/`;
const STATUS_URL = `/status/status.json`;
const SELECT_TOWN_URL = `${API_URL}auth/select-town/`;
const USER_GROUP_LIST_URL = `${API_URL}auth/usergroup/`;
const USER_GROUP_DETAIL_URL = id => `${API_URL}auth/usergroup/${encodeURIComponent(id)}/`;
const USER_LIST_URL = `${API_URL}auth/user/`;
const USER_DETAIL_URL = id => `${API_URL}auth/user/${encodeURIComponent(id)}/`;
const USER_CHECK_URL = `${API_URL}auth/usercheck/`;
const NAME_CHECK_URL = `${API_URL}auth/name-check/`;
const USER_OPTIONS_URL = `${API_URL}auth/save-options/`;

const TOWN_LIST_URL = `${API_URL}core/town/`;
const TOWN_DETAIL_URL = id => `${API_URL}core/town/${encodeURIComponent(id)}/`;
const WORKPLACE_TYPE_LIST_URL = `${API_URL}core/workplacetype/`;
const WORKPLACE_TYPE_DETAIL_URL = id => `${API_URL}core/workplacetype/${encodeURIComponent(id)}/`;
const WORKPLACE_LIST_URL = `${API_URL}core/workplace/`;
const WORKPLACE_DETAIL_URL = id => `${API_URL}core/workplace/${encodeURIComponent(id)}/`;
const DISTRICT_LIST_URL = `${API_URL}core/workplacedistrict/`;
const DISTRICT_DETAIL_URL = id => `${API_URL}core/workplacedistrict/${encodeURIComponent(id)}/`;
const PERIOD_LIST_URL = `${API_URL}core/period/`;
const PERIOD_DETAIL_URL = id => `${API_URL}core/period/${encodeURIComponent(id)}/`;
const PAYMENT_PERSON_ACCOUNTS_EXCEL_TOKEN_URL = id => `${API_URL}core/period/${encodeURIComponent(id)}/excel/token/`;
const TEMPLATE_LIST_URL = `${API_URL}core/template/`;
const TEMPLATE_DETAIL_URL = id => `${API_URL}core/template/${encodeURIComponent(id)}/`;
const PERIOD_VACATION_DAYS_LIST_URL = `${API_URL}core/periodvacationdays/`;
const PERIOD_VACATION_DAYS_DETAIL_URL = id => `${API_URL}core/periodvacationdays/${encodeURIComponent(id)}/`;
const DROPDOWN_PERIOD_LIST_URL = `${API_URL}core/dropdown/period/`;
const DROPDOWN_WORKPLACETYPE_LIST_URL = `${API_URL}core/dropdown/workplacetype/`;
const DROPDOWN_WORKPLACE_LIST_URL = `${API_URL}core/dropdown/workplace/`;
const DROPDOWN_WORKPLACE_EDIT_USER_LIST_URL = `${API_URL}core/dropdown/user-edit-workplace/`;
const DROPDOWN_WORKPLACEDISTRICT_LIST_URL = `${API_URL}core/dropdown/workplacedistrict/`;
const DROPDOWN_PLACEMENTGROUP_LIST_URL = `${API_URL}core/dropdown/placementgroup/`;
const DROPDOWN_APPLICATION_PLACEMENTGROUP_LIST_URL = `${API_URL}core/dropdown/application-placementgroup/`;
const DROPDOWN_ACCESS_LIST_URL = `${API_URL}core/dropdown/access/`;
const DROPDOWN_PAYMENTPERIOD_LIST_URL = `${API_URL}core/dropdown/paymentperiod/`;
const DROPDOWN_INSTRUCTOR_LIST_URL = `${API_URL}core/dropdown/instructor/`;
const EMAIL_MESSAGE_LIST_URL = `${API_URL}core/emailmessage/`;
const EMAIL_MESSAGE_DETAIL_URL = id => `${API_URL}core/emailmessage/${encodeURIComponent(id)}/`;
const EMAIL_MESSAGE_GROUP_LIST_URL = `${API_URL}core/emailmessage/groups/`;
const EMAIL_MESSAGE_RECIPIENTS_LIST_URL = `${API_URL}core/emailmessage/recipients/`;
const EMAIL_MESSAGE_SEND_URL = `${API_URL}core/emailmessage/sendmail/`;
const HISTORY_LIST_URL = `${API_URL}core/history/`;
const SEARCH_LIST_URL = `${API_URL}core/search/`;

const PLACEMENT_GROUP_LIST_URL = `${API_URL}placement/placementgroup/`;
const PLACEMENT_GROUP_DETAIL_URL = id => `${API_URL}placement/placementgroup/${encodeURIComponent(id)}/`;
const PLACEMENT_EXEMPTION_LIST_URL = `${API_URL}placement/exemption/`;
const PLACEMENT_EXEMPTION_CHECK_URL = `${API_URL}placement/exemption/check-exemption/`;
const PLACEMENT_EXEMPTION_DETAIL_URL = id => `${API_URL}placement/exemption/${encodeURIComponent(id)}/`;
const PLACEMENT_GROUP_DATE_LIST_URL = `${API_URL}placement/placementgroup/groupsbydate/`;
const PLACEMENT_GROUP_PERSON_LIST_URL = `${API_URL}placement/person/`;
const PLACEMENT_GROUP_PERSON_URL = id => `${API_URL}placement/person/${encodeURIComponent(id)}/`;
const PLACEMENT_GROUP_MAIL_URL = id => `${API_URL}placement/placementgroup/${encodeURIComponent(id)}/sendmail/`;
const PLACEMENT_GROUP_PERSON_DETAIL_LIST_URL = id => `${API_URL}placement/placementgroup/${encodeURIComponent(id)}/persons/`;
const PLACEMENT_GROUP_DATE_VALID_URL = id => `${API_URL}placement/placementgroup/${encodeURIComponent(id)}/datevalid/`;
const PLACEMENT_GROUP_PERSON_DETAIL_URL = id => `${API_URL}placement/placementgroupperson/${encodeURIComponent(id)}/`;
const PLACEMENT_GROUP_PERSON_CHANGE_GROUP_URL = id => `${API_URL}placement/placementgroupperson/${encodeURIComponent(id)}/changegroup/`;
const PLACEMENT_GROUP_PERSON_COPY_GROUP_URL = id => `${API_URL}placement/placementgroupperson/${encodeURIComponent(id)}/copygroup/`;
const PLACEMENT_GROUP_PERSON_CONTACT_LIST_URL = `${API_URL}placement/placementgrouppersoncontact/`;
const PLACEMENT_GROUP_PERSON_CONTACT_DETAIL_URL = id => `${API_URL}placement/placementgrouppersoncontact/${encodeURIComponent(id)}/`;
const INSTRUCTOR_PLACEMENT_GROUP_LIST_URL = `${API_URL}placement/instructorgroups/`;

const ATTENDANCE_TYPE_LIST_URL = `${API_URL}attendance/attendancetype/`;
const ATTENDANCE_TYPE_DETAIL_URL = id => `${API_URL}attendance/attendancetype/${encodeURIComponent(id)}/`;
const ATTENDANCE_OVERVIEW_URL = `${API_URL}attendance/attendanceoverview/`;
const ATTENDANCE_OVERVIEW_TYPE_URL = `${API_URL}attendance/attendance/attendancetype/`;
const ATTENDANCE_OVERVIEW_PROJECT_URL = `${API_URL}attendance/attendance/attendanceproject/`;
const ATTENDANCE_LIST_URL = `${API_URL}attendance/attendance/`;
const ATTENDANCE_DETAIL_URL = id => `${API_URL}attendance/attendance/${encodeURIComponent(id)}/`;
const ATTENDANCE_PROJECT_LIST_URL = `${API_URL}attendance/attendanceproject/`;
const ATTENDANCE_PROJECT_DETAIL_URL = id => `${API_URL}attendance/attendanceproject/${encodeURIComponent(id)}/`;

const APPLICATION_LIST_URL = `${API_URL}application/application/`;
const APPLICATION_DETAIL_URL = id => `${API_URL}application/application/${encodeURIComponent(id)}/`;
const APPLICATION_ACCEPT_URL = id => `${API_URL}application/application/${encodeURIComponent(id)}/accept/`;
const APPLICATION_REJECT_URL = id => `${API_URL}application/application/${encodeURIComponent(id)}/reject/`;
const APPLICATION_FINISH_URL = id => `${API_URL}application/application/${encodeURIComponent(id)}/finish/`;
const APPLICATION_CHANGE_STATUS_URL = id => `${API_URL}application/application/${encodeURIComponent(id)}/changestatus/`;
const APPLICATION_CANCEL_URL = id => `${API_URL}application/application/${encodeURIComponent(id)}/cancel/`;
const APPLICATION_CONTACT_LIST_URL = `${API_URL}application/applicationcontact/`;
const APPLICATION_CONTACT_DETAIL_URL = id => `${API_URL}application/applicationcontact/${encodeURIComponent(id)}/`;
const APPLICATION_UPDATE_PERIOD_URL = id => `${API_URL}application/application/${encodeURIComponent(id)}/updateperiod/`;
const APPLICATION_UPDATE_PERIOD_PART_URL = id => `${API_URL}application/application/${encodeURIComponent(id)}/updateperiodpart/`;
const APPLICATION_PERIOD_LIST_URL = `${API_URL}application/application/period/`;
const APPLICATION_WORKPLACE_TYPE_LIST_URL = `${API_URL}application/application/workplacetype/`;
const APPLICATION_WORKPLACE_LIST_URL = `${API_URL}application/application/workplace/`;

const PAYMENT_PERIOD_LIST_URL = `${API_URL}payment/paymentperiod/`;
const PAYMENT_PERIOD_DETAIL_URL = id => `${API_URL}payment/paymentperiod/${encodeURIComponent(id)}/`;
const WAGE_PERIOD_LIST_URL = `${API_URL}payment/wageperiod/`;
const WAGE_PERIOD_DETAIL_URL = id => `${API_URL}payment/wageperiod/${encodeURIComponent(id)}/`;
const PAYMENT_BATCH_LIST_URL = `${API_URL}payment/batch/`;
const PAYMENT_BATCH_DETAIL_URL = id => `${API_URL}payment/batch/${encodeURIComponent(id)}/`;
const PAYMENT_BATCH_EXCEL_TOKEN_URL = id => `${API_URL}payment/batch/${encodeURIComponent(id)}/excel/token/`;
const PAYMENT_BATCH_H3_TOKEN_URL = id => `${API_URL}payment/batch/${encodeURIComponent(id)}/h3/token/`;
const PAYMENT_BATCH_POPULATE_URL = id => `${API_URL}payment/batch/${encodeURIComponent(id)}/populate/`;
const PAYMENT_BATCH_PREVIEW_URL = id => `${API_URL}payment/batch/${encodeURIComponent(id)}/preview/`;
const PAYMENT_BATCH_LINE_LIST_URL = `${API_URL}payment/batchline/`;
const PAYMENT_BATCH_CORRECTION_LIST_URL = `${API_URL}payment/batchcorrection/`;
const PAYMENT_BATCH_CORRECTION_DETAIL_URL = id => `${API_URL}payment/batchcorrection/${encodeURIComponent(id)}/`;

const PAYMENT_BATCH_OVERVIEW_STUDENT_LIST_URL = `${API_URL}payment/batchoverview/students/`;
const PAYMENT_BATCH_OVERVIEW_WORKPLACETYPE_LIST_URL = `${API_URL}payment/batchoverview/workplacetype/`;
const PAYMENT_BATCH_OVERVIEW_WORKPLACE_LIST_URL = `${API_URL}payment/batchoverview/workplace/`;
const PAYMENT_BATCH_OVERVIEW_PAYMENTPERIOD_LIST_URL = `${API_URL}payment/batchoverview/paymentperiod/`;
const PAYMENT_BATCH_OVERVIEW_YEAR_LIST_URL = `${API_URL}payment/batchoverview/year/`;

const PAYROLL_PAYMENT_EMPLOYEE_H3_EXCEL = `${API_URL}payment/payrollpaymentemployeeh3excel/`;
const PAYROLL_EMPLOYEE_H3_EXCEL = `${API_URL}payment/payrollemployeeh3excel/`;

export default {
  PING_URL,
  SELECT_TOWN_URL,
  STATUS_URL,
  TOWN_LIST_URL,
  TOWN_DETAIL_URL,
  WORKPLACE_TYPE_LIST_URL,
  WORKPLACE_TYPE_DETAIL_URL,
  WORKPLACE_LIST_URL,
  WORKPLACE_DETAIL_URL,
  DISTRICT_LIST_URL,
  DISTRICT_DETAIL_URL,
  USER_GROUP_LIST_URL,
  USER_GROUP_DETAIL_URL,
  USER_LIST_URL,
  USER_DETAIL_URL,
  USER_CHECK_URL,
  PERIOD_LIST_URL,
  PERIOD_DETAIL_URL,
  PAYMENT_PERSON_ACCOUNTS_EXCEL_TOKEN_URL,
  PLACEMENT_GROUP_LIST_URL,
  PLACEMENT_GROUP_DETAIL_URL,
  ATTENDANCE_TYPE_LIST_URL,
  ATTENDANCE_TYPE_DETAIL_URL,
  PLACEMENT_EXEMPTION_LIST_URL,
  PLACEMENT_EXEMPTION_DETAIL_URL,
  APPLICATION_LIST_URL,
  APPLICATION_DETAIL_URL,
  NAME_CHECK_URL,
  PLACEMENT_GROUP_DATE_LIST_URL,
  PLACEMENT_GROUP_PERSON_LIST_URL,
  APPLICATION_ACCEPT_URL,
  APPLICATION_FINISH_URL,
  APPLICATION_CHANGE_STATUS_URL,
  APPLICATION_CANCEL_URL,
  PLACEMENT_GROUP_PERSON_URL,
  APPLICATION_REJECT_URL,
  ATTENDANCE_OVERVIEW_URL,
  ATTENDANCE_LIST_URL,
  ATTENDANCE_DETAIL_URL,
  TEMPLATE_LIST_URL,
  TEMPLATE_DETAIL_URL,
  PAYMENT_PERIOD_LIST_URL,
  PAYMENT_PERIOD_DETAIL_URL,
  WAGE_PERIOD_LIST_URL,
  WAGE_PERIOD_DETAIL_URL,
  PLACEMENT_GROUP_MAIL_URL,
  PLACEMENT_GROUP_PERSON_DETAIL_LIST_URL,
  PERIOD_VACATION_DAYS_LIST_URL,
  PERIOD_VACATION_DAYS_DETAIL_URL,
  PLACEMENT_GROUP_DATE_VALID_URL,
  PLACEMENT_GROUP_PERSON_DETAIL_URL,
  PLACEMENT_GROUP_PERSON_CHANGE_GROUP_URL,
  PLACEMENT_GROUP_PERSON_COPY_GROUP_URL,
  PLACEMENT_EXEMPTION_CHECK_URL,
  USER_OPTIONS_URL,
  APPLICATION_CONTACT_LIST_URL,
  APPLICATION_CONTACT_DETAIL_URL,
  PLACEMENT_GROUP_PERSON_CONTACT_LIST_URL,
  PLACEMENT_GROUP_PERSON_CONTACT_DETAIL_URL,
  PAYMENT_BATCH_LIST_URL,
  PAYMENT_BATCH_DETAIL_URL,
  PAYMENT_BATCH_POPULATE_URL,
  PAYMENT_BATCH_PREVIEW_URL,
  DROPDOWN_PERIOD_LIST_URL,
  DROPDOWN_WORKPLACETYPE_LIST_URL,
  DROPDOWN_WORKPLACE_LIST_URL,
  DROPDOWN_WORKPLACEDISTRICT_LIST_URL,
  DROPDOWN_PLACEMENTGROUP_LIST_URL,
  DROPDOWN_APPLICATION_PLACEMENTGROUP_LIST_URL,
  DROPDOWN_WORKPLACE_EDIT_USER_LIST_URL,
  DROPDOWN_ACCESS_LIST_URL,
  DROPDOWN_PAYMENTPERIOD_LIST_URL,
  DROPDOWN_INSTRUCTOR_LIST_URL,
  PAYMENT_BATCH_EXCEL_TOKEN_URL,
  PAYMENT_BATCH_H3_TOKEN_URL,
  PAYMENT_BATCH_LINE_LIST_URL,
  PAYMENT_BATCH_OVERVIEW_STUDENT_LIST_URL,
  PAYMENT_BATCH_OVERVIEW_WORKPLACETYPE_LIST_URL,
  PAYMENT_BATCH_OVERVIEW_WORKPLACE_LIST_URL,
  PAYMENT_BATCH_OVERVIEW_PAYMENTPERIOD_LIST_URL,
  PAYMENT_BATCH_OVERVIEW_YEAR_LIST_URL,
  HISTORY_LIST_URL,
  PAYMENT_BATCH_CORRECTION_LIST_URL,
  PAYMENT_BATCH_CORRECTION_DETAIL_URL,
  EMAIL_MESSAGE_LIST_URL,
  EMAIL_MESSAGE_DETAIL_URL,
  EMAIL_MESSAGE_GROUP_LIST_URL,
  EMAIL_MESSAGE_RECIPIENTS_LIST_URL,
  EMAIL_MESSAGE_SEND_URL,
  INSTRUCTOR_PLACEMENT_GROUP_LIST_URL,
  SEARCH_LIST_URL,
  APPLICATION_UPDATE_PERIOD_PART_URL,
  APPLICATION_UPDATE_PERIOD_URL,
  APPLICATION_PERIOD_LIST_URL,
  APPLICATION_WORKPLACE_TYPE_LIST_URL,
  APPLICATION_WORKPLACE_LIST_URL,
  ATTENDANCE_PROJECT_LIST_URL,
  ATTENDANCE_PROJECT_DETAIL_URL,
  ATTENDANCE_OVERVIEW_TYPE_URL,
  ATTENDANCE_OVERVIEW_PROJECT_URL,
  PAYROLL_PAYMENT_EMPLOYEE_H3_EXCEL,
  PAYROLL_EMPLOYEE_H3_EXCEL,
};
