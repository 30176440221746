<template>
  <div>
    <b-card no-body border-variant="light" class="mb-2 mt-3">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Starfsstaður</th>
            <th class="text-right">Upphæð</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="workplace in workplaceList" :key="workplace.id">
            <td>{{ workplace.name }}</td>
            <td align="right">{{ workplace.wages | currency }}</td>
          </tr>
          <tr v-if="workplaceList.length === 0">
            <td colspan="100%" class="text-center"><i>Engar niðurstöður fundust</i></td>
          </tr>
          <!--tr v-if="workplace.collapsed">
              <td colspan="4" class="border border-secondary">
                <workplace-item :item="workplace"></workplace-item>
              </td>
            </tr-->
        </tbody>
      </table>
    </b-card>
    <pagination
      v-if="pagination && pagination.totalPages > 1"
      :pagination-response="pagination"
      @change-limit="changeLimit"
      @change-page="changePage"
    >
    </pagination>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import payment from '@/api/payment';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'workplace-overview',
  props: ['active', 'year', 'search'],
  components: {
    Pagination,
  },
  mixins: [paginationMixin],
  data() {
    return {
      page: null,
      pagination: null,
      loading: false,
      workplaceList: [],
    };
  },
  methods: {
    async loadList(page = null, limit = null) {
      this.loading = true;
      this.workplaceList = [];
      try {
        const response = await payment.paymentBatchOverviewWorkplaceList(page, limit, this.search, this.year);
        this.workplaceList = response.data;
        this.pagination = parsePagination(response);
        // await this.setLoggedInUserOption({ option: 'application', value: this.options.application });
        // await this.saveLoggedInUserOptions();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    collapseRecords(workplace) {
      this.$set(workplace, 'collapsed', workplace.collapsed ? !workplace.collapsed : true);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    active(val) {
      if (val) {
        this.loadList();
      }
    },
    year(val) {
      if (val) {
        this.loadList();
      }
    },
    search(val) {
      if (val) {
        this.loadList();
      }
    }
  },
  created() {
    if (this.active) {
      this.loadList();
    }
  },
};
</script>
