<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Tímabil</div>
      </b-col>
      <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_period')">
        <b-btn variant="primary" @click.prevent="periodCreateModal" class="pull-right">
          <i class="fa fa-fw fa-plus-circle"></i>
          Bæta við
        </b-btn>
      </b-col>
    </b-row>
    <b-card no-body border-variant="light" class="mb-2">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Heiti</th>
            <th>Dags. frá</th>
            <th>Dags. til</th>
            <th>Starfstímabil</th>
            <th>Frídagar</th>
            <th>Má sækja um</th>
            <th><div v-b-tooltip.hover title="Fjöldi starfstímabila sem má sækja um">Fj. sækja um</div></th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <td colspan="100"><loading /></td>
        </tbody>
        <tbody v-if="periodList.length == 0 && !loading">
          <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
        </tbody>
        <tbody v-if="!loading && periodList.length > 0">
          <tr v-for="(period, periodIndex) in periodList" :key="period.id">
            <td>{{ period.name }}</td>
            <td>{{ period.dateFrom | moment('DD.MM.YYYY') }}</td>
            <td>{{ period.dateTo | moment('DD.MM.YYYY') }}</td>
            <td>
              <div v-if="!period.hasParts">{{ period.dateFrom | moment('DD.MM.YYYY') }} - {{ period.dateTo | moment('DD.MM.YYYY') }}</div>
              <div v-else>
                <div
                  class="d-block"
                  v-for="part in period.parts.filter((part, idx) => period.openForApplication || idx < 2)"
                  :key="part.id"
                >
                  <div>{{ part.dateFrom | moment('DD.MM.YYYY') }} - {{ part.dateTo | moment('DD.MM.YYYY') }}</div>
                  <div class="small" style="display: block" v-if="period.ageExclusionParts">Aldurstakmörkun: {{ part.ageExclusion }}</div>
                </div>
                <div v-if="!period.openForApplication && period.parts.length > 2" class="text-muted small">
                  <span :id="`parts-tooltip-target-${periodIndex}`">+ {{ period.parts.length - 2 }} fleiri</span>
                  <b-tooltip :target="`parts-tooltip-target-${periodIndex}`" triggers="hover" placement="bottom">
                    <div v-for="part in period.parts.filter((part, idx) => idx >= 2)" :key="part.id">
                      <div>{{ part.dateFrom | moment('DD.MM.YYYY') }} - {{ part.dateTo | moment('DD.MM.YYYY') }}</div>
                      <div class="small" style="display: block" v-if="period.ageExclusionParts">
                        Aldurstakmörkun: {{ part.ageExclusion }}
                      </div>
                    </div>
                  </b-tooltip>
                </div>
              </div>
            </td>
            <td>
              {{ period.vacationCount }}
              <b-link :to="{ name: 'PeriodVacationDaysList', params: { period: period.id } }">
                <i class="fa fa-fw fa-pencil" v-if="loggedInUserHasAnyPermission('write_period')"></i>
                <i class="fa fa-fw fa-search" v-else></i>
              </b-link>
            </td>
            <td><span v-if="period.openForApplication">Já</span><span v-else>Nei</span></td>
            <td>
              <div v-if="period.ageExclusionParts">Aldurstakmörkun</div>
              <span v-else>
                <div v-if="!period.hasParts">1</div>
                <div v-else>
                  {{ period.numPartsApplication }}
                </div>
              </span>
            </td>
            <td class="text-right">
              <b-btn
                :size="'sm'"
                style="background-color: #008000"
                @click.prevent="getExcel(period.id)"
                v-if="loggedInUserHasAnyPermission('write_period')"
                v-b-tooltip.hover
                title="Sækja reikningsnúmer þátttakenda í excel"
              >
                <i class="fa fa-fw fa-file-excel-o"></i>
              </b-btn>
              <b-btn
                :size="'sm'"
                variant="primary"
                @click.prevent="periodEditModal(period.id)"
                v-if="loggedInUserHasAnyPermission('write_period')"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </b-btn>
              <b-btn
                :size="'sm'"
                variant="danger"
                @click.prevent="periodDeleteModal(period.id)"
                v-if="loggedInUserHasAnyPermission('write_period')"
              >
                <i class="fa fa-fw fa-trash"></i>
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <period-create-modal ref="periodCreateModal" @successful="loadList"></period-create-modal>
    <period-create-modal ref="periodEditModal" @successful="loadList"></period-create-modal>
    <period-delete-modal ref="periodDeleteModal" @successful="loadList"></period-delete-modal>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import core from '@/api/core';
import PeriodCreateModal from '@/components/period/PeriodCreateModal.vue';
import PeriodDeleteModal from '@/components/period/PeriodDeleteModal.vue';

export default {
  name: 'period',
  components: {
    PeriodCreateModal,
    PeriodDeleteModal,
  },
  data() {
    return {
      periodList: [],
      loading: false,
      updateId: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  methods: {
    async loadList() {
      this.loading = true;
      this.periodList = [];
      try {
        const response = await this.$coreApi.periodList();
        this.periodList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async getExcel(periodId) {
      const w = window.open('', '_blank');

      const response = await core.periodPersonAccountsExcelToken(periodId);
      w.location.href = response.data.url;
    },
    /*
     * Modal gluggar
     */
    periodCreateModal() {
      this.$refs.periodCreateModal.show();
    },
    periodEditModal(id) {
      this.$refs.periodEditModal.show(id);
    },
    periodDeleteModal(id) {
      this.$refs.periodDeleteModal.show(id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style></style>
