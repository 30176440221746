<template>
  <vinnuskoli-page>
    <div>
      <b-row class="mb-3">
        <b-col>
          <div class="page-title mb-0">Starfsstaðir</div>
        </b-col>
        <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_workplace')">
          <b-btn variant="primary" @click.prevent="() => createOrUpdate()" class="pull-right"
            ><i class="fa fa-fw fa-plus-circle"></i> Bæta við starfsstað</b-btn
          >
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="2">
          <strong>Nafn</strong>
          <b-form-input id="name" name="name" type="text" v-model="filter.name" @input="loadList()"></b-form-input>
        </b-col>

        <!-- <b-col cols="2">
          <strong>Flokkun</strong>
          <v-select
            id="workplaceType"
            name="workplaceType"
            v-model="filter.workplaceType"
            multiple
            :options="workplaceTypes.data"
            @input="loadList()"
            label="name"
            style="background-color: white"
          ></v-select>
        </b-col> -->
        <b-col cols="2">
          <strong>Flokkun</strong>
          <b-form-select id="workplaceType" name="workplaceType" v-model="filter.workplaceType" @input="loadList()">
            <option :value="undefined">-- Veldu flokkun --</option>
            <template v-if="!workplaceTypes.loading && workplaceTypes.data">
              <option v-for="workplaceType in workplaceTypes.data" :key="workplaceType.id" :value="workplaceType.id">
                {{ workplaceType.name }}
              </option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <strong>Hverfi</strong>
          <b-form-select id="district" name="district" v-model="filter.district" @input="loadList()">
            <option :value="undefined">-- Veldu hverfi --</option>
            <template v-if="!districts.loading && districts.data">
              <option v-for="district in districts.data" :key="district.id" :value="district.id">
                {{ district.name }}
              </option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row class="mt-4">
            <b-form-checkbox class="mr-4 ml-3" v-model="isActive" @change="() => loadList()"> Virkir </b-form-checkbox>
            <b-form-checkbox class="mr-4" v-model="isNotActive" @change="() => loadList()"> Óvirkir </b-form-checkbox>
            <i
              class="fa fa-recycle fa-2x"
              style="color: #367bb5"
              v-b-tooltip.hover
              :title="`Hreinsa síur`"
              aria-hidden="true"
              @click="clearFilters()"
            ></i>
          </b-row>
        </b-col>
      </b-row>

      <b-card no-body border-variant="light" class="mb-2">
        <table class="table mb-0">
          <thead>
            <tr class="bg-gray-200 small">
              <th @click.prevent="changeSort('name')" style="cursor: pointer">
                Heiti (<span v-if="pagination">{{ pagination.totalCount }}</span
                >)
              </th>
              <th>Flokkun</th>
              <th @click.prevent="changeSort('districtName')" style="cursor: pointer">Hverfi</th>
              <th @click.prevent="changeSort('enabled')" style="cursor: pointer">Virkur</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="loading">
            <td colspan="100"><loading /></td>
          </tbody>
          <tbody v-if="!loading && data && data === 0">
            <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
          </tbody>
          <tbody v-if="!loading && data && data.length > 0">
            <tr v-for="workplace in data" :key="workplace.id">
              <td class="align-middle">{{ workplace.name }}</td>
              <td class="align-middle">
                <div v-for="type in workplace.types" :key="type.id">
                  {{ type.type.name }}
                </div>
              </td>
              <td class="align-middle">{{ workplace.district && workplace.district.name }}</td>
              <td class="align-middle">
                <font-awesome-icon class="text-muted" fixedWidth :icon="workplace.enabled ? 'check' : 'times'" />
              </td>
              <!-- <td></td> -->
              <td class="text-right">
                <b-dropdown variant="link" toggle-class="text-muted" v-if="loggedInUserHasAnyPermission('write_workplace')">
                  <template v-slot:button-content><font-awesome-icon class="text-muted" fixedWidth icon="cog" /></template>
                  <b-dropdown-item href="#" @click.prevent="() => createOrUpdate(workplace.id)">Breyta</b-dropdown-item>
                  <b-dropdown-item href="#" @click.prevent="() => deleteObj(workplace.id, workplace.name)">Eyða</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>

      <pagination
        v-if="pagination && data && data.length > 0"
        :pagination-response="pagination"
        @change-limit="changeLimit"
        @change-page="changePage"
      >
      </pagination>
    </div>
    <workplace-create-modal ref="workplaceCreateModal" @successful="() => loadList()" />
  </vinnuskoli-page>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies
import { mapGetters, mapActions } from 'vuex';

import { parsePagination, b64EncodeUnicode } from '@/utils';
import Pagination from '@/components/Pagination.vue';
import workplaceCreateModal from '@/components/workplace/WorkplaceCreateModal.vue';

const DEFAULT_LIMIT = 25;

export default {
  name: 'workplace',
  components: { Pagination, workplaceCreateModal },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      data: [],
      loading: false,
      error: false,
      pagination: null,
      page: parseInt(this.$route.query.page, 10) || 1,
      limit: parseInt(this.$route.query.limit, 10) || DEFAULT_LIMIT,
      orderBy: 'name',
      filter: {
        name: '',
        district: '',
        workplaceType: '',
      },
      isActive: true,
      isNotActive: false,
      districts: {
        data: [],
        loading: false,
        error: false,
      },
      workplaceTypes: {
        data: [],
        loading: false,
        error: false,
      },
    };
  },
  methods: {
    async loadList(page, limit) {
      this.loading = true;
      this.data = [];
      this.error = false;

      let isActive;

      if (this.isActive && !this.isNotActive) {
        isActive = true;
      } else if (!this.isActive && this.isNotActive) {
        isActive = false;
      }

      try {
        const response = await this.$coreApi.workplaceList({
          page: page || undefined,
          limit: limit || 10,
          orderBy: this.orderBy,
          name: this.filter.name,
          enabled: isActive,
          district: this.filter.district,
          type: this.filter.workplaceType,
        });

        this.data = response.data;
        this.pagination = parsePagination(response);
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    async loadDistricts() {
      this.districts.loading = true;
      this.districts.data = [];
      this.districts.error = false;

      try {
        const response = await this.$coreApi.workplaceDistrictDropdownList();

        this.districts.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja hverfi. Vinsamlegast reyndu aftur.`);
        this.districts.error = true;
      } finally {
        this.districts.loading = false;
      }
    },

    async loadTypes() {
      this.workplaceTypes.loading = true;
      this.workplaceTypes.data = [];
      this.workplaceTypes.error = false;

      try {
        const response = await this.$coreApi.workplaceTypeDropdownList();

        this.workplaceTypes.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja flokka. Vinsamlegast reyndu aftur.`);
        this.workplaceTypes.error = true;
      } finally {
        this.workplaceTypes.loading = false;
      }
    },

    changeSort(clickedSortValue) {
      if (this.orderBy === clickedSortValue) {
        this.orderBy = `-${clickedSortValue}`;
      } else {
        this.orderBy = `${clickedSortValue}`;
      }

      this.loadList();
    },
    changeLimit(limit) {
      this.loadList(undefined, limit);
    },
    changePage(page) {
      this.loadList(page, this.pagination.currentLimit);
    },

    clearFilters() {
      this.$set(this, 'filter', {
        name: undefined,
      });
      this.isActive = true;
      this.isNotActive = false;
      this.loadList();
    },

    createOrUpdate(id = null) {
      this.$refs.workplaceCreateModal.show(id ? b64EncodeUnicode(`WorkplaceType:${id}`) : null);
    },
    async deleteObj(id, name) {
      try {
        const conf = confirm(`Ert þú viss um að þú viljir eyða „${name}“?`); // eslint-disable-line no-restricted-globals, no-alert

        if (conf) {
          await this.$apollo.mutate({
            mutation: gql`
              mutation DeleteWorkplace($input: DeleteWorkplaceInput!) {
                deleteWorkplace(input: $input) {
                  errors {
                    field
                    messages
                  }
                }
              }
            `,
            variables: {
              input: {
                id: b64EncodeUnicode(`WorkplaceType:${id}`),
              },
            },
          });

          this.loadList();
          this.displaySuccess(`„${name}“ hefur verið eytt.`);
        }
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions(['displayError', 'displaySuccess']),
  },
  created() {
    this.loadList();
    this.loadDistricts();
    this.loadTypes();
  },
};
</script>
