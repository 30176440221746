<template>
  <div class="my-2">
    <b-row>
      <b-col class="pl-4 pt-3">Sýni niðurstöður {{ showingFrom }} - {{ showingTo }} af {{ totalCount }}</b-col>
      <b-col>
        <b-pagination
          class="pr-2"
          align="right"
          :total-rows="totalCount"
          :value="currentPage"
          :per-page="currentLimit"
          @change="changePage"
        >
        </b-pagination>
      </b-col>
    </b-row>
    <div class="text-right small pr-2">
      Sýna
      <select :value="currentLimit" @change="changeLimit">
        <option value="1">1</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      leitarniðurstöður á blaðsíðu
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: ['paginationResponse'],
  computed: {
    showingFrom() {
      return this.paginationResponse.showingFrom;
    },
    showingTo() {
      return this.paginationResponse.showingTo;
    },
    currentPage() {
      return this.paginationResponse.currentPage;
    },
    totalCount() {
      return this.paginationResponse.totalCount;
    },
    currentLimit() {
      return this.paginationResponse.currentLimit;
    },
  },
  methods: {
    changePage(page) {
      this.$emit('change-page', page);
    },
    changeLimit(v) {
      this.$emit('change-limit', v.target.value);
    },
  },
};
</script>
