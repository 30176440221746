<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" @click="() => setSideNavToggled(!sideNavToggled)">
      <font-awesome-icon class="nav-link-icon" icon="bars" fixed-width />
    </button>

    <div v-if="title" class="my-2 ml-md-3" style="font-size: 1.25rem">
      {{ title }}
    </div>

    <ul class="navbar-nav ml-auto">
      <b-nav-item-dropdown
        right
        class="no-arrow mx-1"
        menu-class="shadow"
        v-if="towns.data && (towns.data.length > 1 || !loggedInUser.town)"
      >
        <template v-slot:button-content>
          <span class="nav-link cursor-pointer" v-if="loggedInUser && loggedInUser.town">{{ loggedInUser.town.name }}</span>
          <span v-else>Veldu sveitarfélag</span>
        </template>

        <b-dropdown-item
          v-for="t in towns.data.filter(t => !loggedInUser.town || loggedInUser.town.id !== t.id)"
          href="#"
          @click.prevent="selectTown(t.id)"
          :key="t.id"
        >
          <span>{{ t.name }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <div class="topbar-divider d-none d-sm-block"></div>

      <b-nav-item-dropdown class="no-arrow">
        <template v-slot:button-content>
          <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ loggedInUser && loggedInUser.name }}</span>
          <b-badge
            pill
            variant="primary"
            class="font-weight-normal"
            style="padding: 0.5em; border-radius: 50%; font-size: 0.8rem; line-height: inherit; min-width: 2.6em"
          >
            {{ currentUserInitials }}
          </b-badge>
        </template>
        <b-dropdown-item v-if="loggedInUser.isSuperuser" href="/advania-admin/">
          <font-awesome-icon icon="sign-out-alt" fixed-width size="sm" class="mr-2 text-gray-400" />
          Ofurnotandi
        </b-dropdown-item>
        <b-dropdown-item v-if="loggedInUser.isOnBehalf" href="/advania-hijack/release/">
          <font-awesome-icon icon="sign-out-alt" fixed-width size="sm" class="mr-2 text-gray-400" />
          Hætta sem {{ currentUserInitials }}
        </b-dropdown-item>
        <b-dropdown-item @click="logout">
          <font-awesome-icon icon="sign-out-alt" fixed-width size="sm" class="mr-2 text-gray-400" />
          Skrá út
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </ul>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

import auth from '@/api/auth';
import { getInitials } from '@/utils';

export default {
  name: 'top-bar',
  props: {
    sideNavToggled: Boolean,
    setSideNavToggled: Function,
    title: String,
  },
  data() {
    return {
      towns: {
        data: [],
        loading: false,
        error: false,
      },
    };
  },
  computed: {
    currentUserInitials() {
      return this.loggedInUser && this.loggedInUser.name && getInitials(this.loggedInUser.name);
    },
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async selectTown(id) {
      try {
        await auth.selectTown(id);
        window.location.href = '/';
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadTowns() {
      this.towns.loading = true;
      this.towns.data = [];
      this.towns.error = false;

      try {
        const townsResponse = await this.$coreApi.townList();
        this.towns.data = townsResponse.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.towns.error = true;
      } finally {
        this.towns.loading = false;
      }
    },
    async logout() {
      await this.$authApi.logout();
      window.location.href = '/innskraning?logout=true';
    },
  },
  async created() {
    this.loadTowns();
  },
};
</script>
