<template>
  <vinnuskoli-page>
    <b-row>
      <b-col>
        <h5 class="page-title">Þátttakandaspjald</h5>
      </b-col>
    </b-row>
    <b-row
      class="mb-3"
      v-match-heights="{
        el: ['.equal-height-1'], // Array of selectors to fix
      }"
    >
      <b-col>
        <b-card class="card-primary-header">
          <div slot="header">
            <div>Upplýsingar</div>
          </div>
          <div class="equal-height-1">
            <table class="table white-table">
              <tr>
                <td>
                  <strong>Birtingarnafn
                  <i class="fa fa-fw fa-info-circle"
                    v-b-tooltip.hover title=
                    "Auk birtingarnafns er hér hægt að skrá persónufornafn þátttakanda
                    (hann, hún, hán, það, þau, hín, héð og hé). Dæmi: 'Guðrún Jónsdóttir (hún)'">
                  </i>
                  </strong>
                </td>
                <td>
                  <inline-edit
                    class="mb-0"
                    field="display_name"
                    :doEdit="edit.display_name"
                    @editField="editDisplayNameField"
                    v-if="true && loggedInUserHasAnyPermission('write_person')"
                    @saveField="changeDisplayName()">
                    <span slot="content">
                      <p>{{ info.display_name ? info.display_name : 'Ekki skráð' }}</p>
                    </span>
                    <span slot="input">
                      <b-form-textarea
                        id="display_name"
                        name="display_name"
                        type="text"
                        v-model="form.display_name"
                        :state="submitted && errors.has('display_name') ? false : ''"
                        data-vv-as="birtingarnafn"
                        v-on:keyup.enter="changeDisplayName()"
                        ref="display_nameInput"
                      ></b-form-textarea>
                    </span>
                  </inline-edit>
                  <span v-else>
                    {{ info.display_name }}
                  </span>
                  <i style="font-size:11px´;displa:inline-block">Þjóðskrárnafn fyrir launakeyrslu: {{info.legal_name}}</i>
                    <button type="button" style="display:inline-block" class="btn btn-sm btn-default" v-if="info.name_locked">
                      <i class="fa fa-recycle"
                      style="color: #367bb5; float: right"
                      v-b-tooltip.hover
                      title="Breyta birtingarnafni aftur í þjóðskrárnafn"
                      aria-hidden="true"
                      @click="resetName()"></i>
                    </button>
                </td>
              </tr>
              <tr>
                <td><strong>Kennitala</strong></td>
                <td>{{ info.ssn }}</td>
              </tr>
              <tr v-if="personNationalRegistry">
                <td>
                  <strong>Lögheimili <small class="text-muted">(Í dag)</small></strong>
                </td>
                <td>
                  {{ personNationalRegistry.domicile }}, {{ personNationalRegistry.postcode }} {{ personNationalRegistry.postcodeName }}
                </td>
              </tr>
              <tr>
                <td><strong>Hópur</strong></td>
                <td>
                  <b-form-select id="group" name="group" v-model="info.group" @input="loadGroupData">
                    <option v-for="group in info.placement_groups" :key="group.id" :value="group">
                      <template v-if="group.is_moved">(Færð/ur) </template>
                      <template v-if="group.is_removed">(Eydd/ur) </template>
                      {{ group.placement_group.period.name }} - {{ group.placement_group.workplace.name }} -
                      {{ group.placement_group.name }}
                    </option>
                  </b-form-select>
                </td>
              </tr>
              <tr v-if="info.group && info.group.date_from">
                <td><strong>Hópaskráning tók gildi</strong></td>
                <td>{{ info.group.date_from | moment('DD.MM.YYYY') }}</td>
              </tr>
              <tr v-if="info.group && info.group.date_to">
                <td><strong>Hópaskráning gildir til</strong></td>
                <td>{{ info.group.date_to | moment('DD.MM.YYYY') }}</td>
              </tr>
              <tr v-if="info.group">
                <td><strong>Dagsetningar hóps</strong></td>
                <td>
                  <span v-if="info.group.placement_group.period_part">
                    {{ info.group.placement_group.period_part.date_from | moment('DD.MM.YYYY') }} -
                    {{ info.group.placement_group.period_part.date_to | moment('DD.MM.YYYY') }}
                  </span>
                  <span v-else>
                    {{ info.group.placement_group.period.date_from | moment('DD.MM.YYYY') }} -
                    {{ info.group.placement_group.period.date_to | moment('DD.MM.YYYY') }}
                  </span>
                </td>
              </tr>
              <tr v-if="info.group">
                <td><strong>Leiðbeinandi</strong></td>
                <td>
                  <div v-if="info.group.placement_group.instructors.length > 0">
                    <span v-for="(instructor, index) in info.group.placement_group.instructors" :key="instructor.id">
                      <span v-if="index !== 0">, </span>
                      {{ instructor.user.name }}
                    </span>
                  </div>
                  <div v-else>
                    <i>Enginn leiðbeinandi skráður</i>
                  </div>
                </td>
              </tr>
            </table>

            <div v-if="info.group && !info.group.is_moved && !info.group.is_removed">
              <div v-if="loggedInUserHasAnyPermission('write_placementgroup')" style="display: inline-block">
                <b-btn @click="changeGroupModal" variant="warning">
                  <i class="fa fa-exclamation-triangle"></i>
                  Færa þátttakanda á milli hópa
                </b-btn>
              </div>
              <div v-if="loggedInUserHasAnyPermission('write_placementgroup')" style="display: inline-block; float: right">
                <b-btn @click="deleteFromGroupModal" variant="danger">
                  <i class="fa fa-exclamation-triangle"></i>
                  Eyða þátttakanda úr hóp
                </b-btn>
              </div>
            </div>
            <div v-if="info.group && info.group.is_removed">
              <div v-if="loggedInUserHasAnyPermission('write_placementgroup')" style="display: inline-block">
                <b-btn @click="copyFromGroupModal" variant="warning">
                  <i class="fa fa-exclamation-triangle"></i>
                  Skrá aftur í hóp
                </b-btn>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="card-primary-header">
          <div slot="header">
            <div>Skráning</div>
          </div>
          <div class="equal-height-1">
            <div class="alert alert-danger" v-if="errorList.length > 0">
              <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
            </div>
            <table class="table white-table">
              <tr>
                <td><strong>Netfang</strong></td>
                <td>
                  <inline-edit
                    class="mb-0"
                    field="email"
                    :doEdit="edit.email"
                    @editField="editEmailField"
                    v-if="true"
                    @saveField="validateBeforeSubmit('email')"
                  >
                    <span slot="content"
                      ><i>{{ info.email ? info.email : 'Ekki skráð' }}</i></span
                    >
                    <span slot="input">
                      <b-form-textarea
                        id="email"
                        name="email"
                        type="text"
                        v-model="form.email"
                        required
                        placeholder="Sláðu inn netfang"
                        :state="submitted && errors.has('email') ? false : ''"
                        v-validate="'required'"
                        data-vv-as="netfang"
                        v-on:keyup.enter="validateBeforeSubmit('email')"
                        ref="emailInput"
                      ></b-form-textarea>
                    </span>
                  </inline-edit>
                  <span v-else>
                    {{ info.email }}
                  </span>
                </td>
              </tr>
              <tr>
                <td><strong>Farsími</strong></td>
                <td>
                  <inline-edit
                    class="mb-0"
                    field="tel"
                    :doEdit="edit.tel"
                    @editField="editTelField"
                    v-if="true"
                    @saveField="validateBeforeSubmit('tel')"
                  >
                    <span slot="content"
                      ><i>{{ info.tel ? info.tel : 'Ekki skráð' }}</i></span
                    >
                    <span slot="input">
                      <b-form-textarea
                        id="tel"
                        name="tel"
                        type="text"
                        v-model="form.tel"
                        required
                        placeholder="Sláðu inn farsíma"
                        :state="submitted && errors.has('tel') ? false : ''"
                        v-validate="'required'"
                        data-vv-as="farsími"
                        v-on:keyup.enter="validateBeforeSubmit('tel')"
                        ref="telInput"
                      ></b-form-textarea>
                    </span>
                  </inline-edit>
                  <span v-else>
                    {{ info.tel }}
                  </span>
                </td>
              </tr>
              <tr>
                <td><strong>Reikningsnúmer</strong></td>
                <td>
                  <inline-edit
                    class="mb-0"
                    field="account"
                    :doEdit="edit.account"
                    @editField="editAccountField"
                    v-if="true"
                    @saveField="validateBeforeSubmit('account')"
                  >
                    <span slot="content"
                      ><i>{{ info.account ? info.account : 'Ekki skráð' }}</i></span
                    >
                    <span slot="input">
                      <masked-input
                        type="text"
                        id="account"
                        name="account"
                        class="form-control"
                        v-model="form.account"
                        :mask="[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
                        :guide="true"
                        :showMask="false"
                        :class="{ 'is-invalid': submitted && errors.has('account') }"
                        v-validate="'required'"
                        data-vv-as="bankareikning"
                        v-on:keyup.enter="validateBeforeSubmit('account')"
                        ref="accountInput"
                      ></masked-input>
                    </span>
                  </inline-edit>
                  <span v-else>
                    {{ info.account }}
                  </span>
                </td>
              </tr>
              <tr>
                <td><strong>Sérþarfir</strong></td>
                <td>
                  <inline-edit
                    class="mb-0"
                    field="special_needs"
                    :doEdit="edit.special_needs"
                    @editField="editSpecialNeedsField"
                    v-if="true"
                    @saveField="validateBeforeSubmit('special_needs')"
                  >
                    <span slot="content"
                      ><i>{{ info.special_needs ? info.special_needs : 'Ekki skráð' }}</i></span
                    >
                    <span slot="input">
                      <b-form-textarea
                        id="special_needs"
                        name="special_needs"
                        type="text"
                        v-model="form.special_needs"
                        placeholder="Sláðu inn sérþarfir"
                        :state="submitted && errors.has('special_needs') ? false : ''"
                        data-vv-as="sérþarfir"
                        v-on:keyup.enter="validateBeforeSubmit('special_needs')"
                        ref="special_needsInput"
                      ></b-form-textarea>
                    </span>
                  </inline-edit>
                  <span v-else>
                    {{ info.special_needs }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      class="mb-3"
      v-match-heights="{
        el: ['.equal-height-2'], // Array of selectors to fix
      }"
    >
      <b-col>
        <b-card class="card-primary-header">
          <div slot="header">
            <div>Mat á frammistöðu</div>
          </div>
          <div class="equal-height-2">
            <div v-if="info.reviewQuestions && info.reviewQuestions.length > 0" class="mb-4">
              <div class="mb-2"><strong>Frammistöðukvarðar:</strong></div>
              <div v-for="reviewQuestion in info.reviewQuestions" :key="reviewQuestion.id" class="mb-3">
                <div class="mb-1">{{ reviewQuestion.reviewQuestion.question }}</div>
                <star-rating :show-rating="false" :star-size="20" v-model="reviewQuestion.rating" :read-only="true" />
              </div>
            </div>
            <span v-if="info.review" style="white-space: pre-wrap">{{ info.review }}</span>
            <span v-else><i>Mat ekki skráð.</i></span>
            <div v-if="loggedInUserHasAnyPermission('write_person')">
              <div :class="{ 'pt-3': info.review }">
                <b-link @click.prevent="reviewModal" v-if="info.group && !info.group.is_moved && !info.group.is_removed">
                  <i v-if="!info.review">Smelltu hérna til þess að skrá</i>
                  <i v-else>Breyta mati</i>
                </b-link>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="card-primary-header">
          <div slot="header">
            <div>Aðstandendur</div>
          </div>
          <div class="equal-height-2">
            <div v-if="info.contacts.length === 0" class="mb-4"><i>Engir aðstandendur skráðir</i></div>
            <div v-for="contact in info.contacts" :key="contact.id" class="mb-4 text-center">
              <div>
                <b-link
                  v-if="loggedInUserHasAnyPermission('write_person') && info.group && !info.group.is_moved && !info.group.is_removed"
                  @click.prevent="updateContactModal(contact.id)"
                >
                  <i class="fa fa-fw fa-pencil"></i>
                </b-link>
                {{ contact.name }} kt.{{ contact.ssn }}
                <b-link
                  v-if="
                    info.contacts.length > 1 &&
                    loggedInUserHasAnyPermission('write_person') &&
                    !info.group.is_moved &&
                    !info.group.is_removed
                  "
                  @click.prevent="deleteContact(contact)"
                >
                  <i class="fa fa-fw fa-trash"></i>
                </b-link>
              </div>
              <div>Netfang: {{ contact.email }}</div>
              <div>Farsími: {{ contact.tel }}</div>
            </div>
            <b-btn
              variant="primary"
              size="sm"
              @click.prevent="createContact()"
              v-if="info.group && loggedInUserHasAnyPermission('write_person') && !info.group.is_moved && !info.group.is_removed"
            >
              <i class="fa fa-fw fa-plus-circle" style="color: #fff"></i>
              Bæta við aðstandanda
            </b-btn>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-match-heights="{
        el: ['.equal-height-3'], // Array of selectors to fix
      }"
    >
      <b-col>
        <student-attendance :item="info.group" :periodMaxHours="info.period_max_hours"
         :periodTotalHours="info.period_hours_total" :reload="reload"></student-attendance>
      </b-col>
      <b-col>
        <b-card class="card-primary-header">
          <div slot="header">
            <div>Uppgjör</div>
          </div>
          <div class="equal-height-3">
            <div v-if="!loggedInUserHasAnyPermission('read_batch')">
              <i>Þú hefur ekki aðgang að því að skoða uppgjör</i>
            </div>
            <div v-else>
              <div v-if="paymentList.length === 0"><i>Ekki komið uppgjör fyrir valinn hóp</i></div>
              <table class="table" v-else>
                <tr>
                  <th class="text-center">Fj. daga/dagar launatímabils</th>
                  <th class="text-center">Fj. klukkutíma/Hámark</th>
                  <th class="text-right">Laun</th>
                </tr>
                <tr v-for="payment in paymentList" :key="payment.id">
                  <td class="text-center">{{ payment.days }}/{{ payment.days_in_period }}</td>
                  <td class="text-center">
                    {{ payment.hours }}/<span v-if="payment.max_hours_in_period">{{ payment.max_hours_in_period | formatMaxHours }}</span>
                  </td>
                  <td class="text-right">{{ payment.wages | currency }}</td>
                </tr>
              </table>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <student-email :item="info.group" :reload="reload"></student-email>
    <student-history :item="info.group" :reload="reload"></student-history>
    <review-modal ref="reviewModal" @successful="loadPersonDetails"></review-modal>
    <change-group-modal ref="changeGroupModal" @successful="changeGroupSuccess"></change-group-modal>
    <delete-from-group-modal ref="deleteFromGroupModal" @successful="changeGroupSuccess"></delete-from-group-modal>
    <copy-from-group-modal ref="copyFromGroupModal" @successful="changeGroupSuccess"></copy-from-group-modal>
    <contact-modal ref="contactModal" @successful="reloadContacts"></contact-modal>
  </vinnuskoli-page>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies

import { mapActions, mapGetters } from 'vuex';
import MaskedInput from 'vue-text-mask';
import StarRating from 'vue-star-rating';
import payment from '@/api/payment';
import placement from '@/api/placement';
import { djangoErrorsToVee, b64EncodeUnicode } from '@/utils';
import ReviewModal from '@/components/student/ReviewModal.vue';
import ChangeGroupModal from '@/components/student/ChangeGroupModal.vue';
import DeleteFromGroupModal from '@/components/student/DeleteFromGroupModal.vue';
import CopyFromGroupModal from '@/components/student/CopyFromGroupModal.vue';
import InlineEdit from '@/components/InlineEdit.vue';
import ContactModal from '@/components/contact/ContactModal.vue';
import StudentHistory from '@/components/student/StudentHistory.vue';
import StudentEmail from '@/components/student/StudentEmail.vue';
import StudentAttendance from '@/components/student/StudentAttendance.vue';

export default {
  name: 'student',
  components: {
    ReviewModal,
    InlineEdit,
    MaskedInput,
    ContactModal,
    StudentHistory,
    StudentEmail,
    StudentAttendance,
    ChangeGroupModal,
    DeleteFromGroupModal,
    CopyFromGroupModal,
    StarRating,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      page: null,
      id: null,
      group: null,
      reload: false,
      loading: {
        info: false,
        details: false,
      },
      info: {
        name_locked: '',
        legal_name: '',
        display_name: '',
        ssn: '',
        special_needs: '',
        tel: '',
        email: '',
        account: '',
        review: '',
        reviewQuestions: [],
        placement_groups: [],
        group: null,
        contacts: [],
        period_max_hours: null,
        period_hours_total: null,
      },
      groupList: [],
      paymentList: [],
      submitted: false,
      errorList: [],
      form: {
        display_name: null,
        email: null,
        tel: null,
        special_needs: null,
        account: null,
      },
      edit: {
        display_name: false,
        email: false,
        tel: false,
        special_needs: false,
        account: false,
      },
    };
  },
  apollo: {
    personNationalRegistry: {
      query: gql`
        query PersonNationalRegistry($id: ID!) {
          personNationalRegistry(id: $id) {
            ssn
            name
            domicile
            postcode
            postcodeName
          }
        }
      `,
      variables() {
        return {
          id: b64EncodeUnicode(`PersonNationalRegistryType:${this.id}`),
        };
      },
      skip() {
        return !this.id;
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  methods: {
    async loadInfo() {
      this.loading.info = true;
      try {
        const response = await placement.placementGroupPerson(this.id);
        this.info.display_name = response.data.name;
        this.info.ssn = response.data.ssn;
        this.info.placement_groups = response.data.placement_groups;
        this.info.birth_year = response.data.birth_year;
        if (this.info.placement_groups.length > 0) {
          if (this.group) {
            this.info.group = this.info.placement_groups.find(x => x.placement_group.id.replace(/-/g, '') === this.group.replace(/-/g, ''));
            if (!this.info.group) {
              [this.info.group] = this.info.placement_groups;
            }
          } else {
            [this.info.group] = this.info.placement_groups;
          }
          this.$nextTick(() => {
            document.dispatchEvent(new CustomEvent('matchheight', {}));
          });
        }
      } catch (e) {
        if (e.response?.status === 404) {
          this.$router.push({ name: 'Groups' });
        } else {
          this.$log.error(e);
          this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.loading.info = false;
      }
    },
    async loadPersonDetails() {
      this.loading.details = true;
      try {
        const response = await this.$placementApi.placementGroupPersonRetrieve({
          uuid: this.info.group.id,
        });
        this.info.name = response.data.person.name;
        this.info.legal_name = response.data.person.legalName !== "" ? response.data.person.legalName : response.data.person.name;
        this.info.name_locked = response.data.person.nameLocked;
        this.info.special_needs = response.data.specialNeeds;
        this.info.tel = response.data.tel;
        this.info.email = response.data.email;
        this.info.account = response.data.account;
        this.info.review = response.data.review;
        this.info.reviewQuestions = response.data.reviewQuestions;
        this.info.contacts = response.data.contacts;
        this.info.period_max_hours = response.data.periodMaxHours;
        this.info.period_hours_total = response.data.periodHoursTotal;
        this.$nextTick(() => {
          document.dispatchEvent(new CustomEvent('matchheight', {}));
        });
        this.reload = true;
        this.$nextTick(() => {
          this.reload = false;
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.details = false;
      }
    },
    async loadPayment() {
      this.paymentList = [];
      if (this.loggedInUserHasAnyPermission('read_batch')) {
        this.loading.payment = true;
        try {
          const response = await payment.paymentBatchLineList(this.info.group.id);
          this.paymentList = response.data;
          this.$nextTick(() => {
            document.dispatchEvent(new CustomEvent('matchheight', {}));
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.payment = false;
        }
      }
    },
    changeGroupSuccess(data) {
      if (data.id) {
        this.group = data.id;
      } else {
        this.group = null;
      }
      this.loadInfo();
    },
    loadGroupData() {
      this.loadPersonDetails();
      this.loadPayment();
    },
    async reloadContacts() {
      this.loading.contacts = true;
      try {
        const response = await placement.placementGroupPersonContactList(this.info.group.id);
        this.$set(this.info, 'contacts', response.data);
        document.dispatchEvent(new CustomEvent('matchheight', {}));
        this.reload = true;
        this.$nextTick(() => {
          this.reload = false;
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.contacts = false;
      }
    },
    async deleteContact(contact) {
      const conf = confirm(`Ertu viss um að þú viljir eyða eftirfarandi tengilið - ${contact.name} kt.${contact.ssn}`); //eslint-disable-line
      if (conf) {
        try {
          await placement.placementGroupPersonContactDelete(contact.id);
          this.reloadContacts();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.contacts = false;
        }
      }
    },
    async changeDisplayName() {
      this.loading.info = true;
      try {
        await this.$placementApi.personUpdateName({
          uuid: this.id,
          personUpdateNameRequest: {
            name: this.form.display_name,
            nameLocked: true
          },
        });
        this.displaySuccess('Birtingarnafni hefur verið breytt');
          this.$set(this.info, 'display_name', this.form.display_name);
          this.loadPersonDetails();
          this.editDisplayNameField(false);
      } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
    async resetName() {
      this.loading.info = true;
      try {
        await this.$placementApi.personUpdateName({
          uuid: this.id,
          personUpdateNameRequest: {
            name: this.info.legal_name,
            nameLocked: false
          },
        });
        this.displaySuccess('Birtingarnafni hefur verið breytt');
          this.$set(this.info, 'display_name', this.info.legal_name);
          this.loadPersonDetails();
      } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
    editField(field, edit) {
      if (edit) {
        this.edit[field] = true;
        this.form[field] = this.info[field];
        setTimeout(() => {
          this.$refs[`${field}Input`].focus();
        }, 0);
      } else {
        this.edit[field] = false;
        this.form[field] = null;
        this.errorList = [];
      }
      this.$nextTick(() => {
        document.dispatchEvent(new CustomEvent('matchheight', {}));
      });
    },
    editEmailField({ doEdit }) {
      this.editField('email', doEdit);
    },
    editTelField({ doEdit }) {
      this.editField('tel', doEdit);
    },
    editAccountField({ doEdit }) {
      this.editField('account', doEdit);
    },
    editSpecialNeedsField({ doEdit }) {
      this.editField('special_needs', doEdit);
    },
    editDisplayNameField({ doEdit }) {
      this.editField('display_name', doEdit);
    },
    async validateBeforeSubmit(type) {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        // Engar villur í formi.
        try {
          const data = {};
          let display = '';
          switch (type) {
            case 'email':
              data.email = this.form.email;
              display = '"Netfang" uppfært!';
              break;
            case 'tel':
              data.tel = this.form.tel;
              display = '"Farsími" uppfærður!';
              break;
            case 'account':
              data.account = this.form.account;
              display = '"Bankareikningur" uppfærður!';
              break;
            case 'special_needs':
              data.special_needs = this.form.special_needs;
              display = '"Sérþarfir" uppfærðar!';
              break;
            default:
              break;
          }
          await placement.placementGroupPersonPatch(this.info.group.id, data);
          this.displaySuccess(display);
          this.$set(this.info, type, this.form[type]);
          this.reload = true;
          this.$nextTick(() => {
            this.reload = false;
          });
          this.editEmailField(false);
          this.editTelField(false);
          this.editAccountField(false);
          this.editSpecialNeedsField(false);
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
          this.$nextTick(() => {
            document.dispatchEvent(new CustomEvent('matchheight', {}));
          });
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },

    /*
     * Modal gluggar
     */
    reviewModal() {
      this.$refs.reviewModal.show(this.info.group.id);
    },
    createContact() {
      this.$refs.contactModal.show('placement', this.info.group.id);
    },
    updateContactModal(id) {
      this.$refs.contactModal.show('placement', this.info.group.id, id);
    },
    changeGroupModal() {
      this.$refs.changeGroupModal.show(this.info.group, this.info.birth_year);
    },
    deleteFromGroupModal() {
      this.$refs.deleteFromGroupModal.show(this.info.group);
    },
    copyFromGroupModal() {
      this.$refs.copyFromGroupModal.show(this.info.group);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.id = this.$route.params.id;
    if (this.$route.params.group) {
      this.group = this.$route.params.group;
    }
    this.loadInfo();
  },
};
</script>

<style lang="scss">
@import '../style/variables';
.card-primary-header {
  .card-header {
    background-color: $primary;
    color: #fff;
    font-size: 18px;
  }
}
</style>
