<template>
  <vinnuskoli-page>
    <b-row>
      <b-col>
        <h5 class="page-title">Fjöldapóstar</h5>
      </b-col>
    </b-row>
    <b-tabs v-model="tabIndex">
      <b-tab title="Senda póst">
        <mass-email-send :active="tabIndex === 0" class="mt-4"></mass-email-send>
      </b-tab>
      <b-tab title="Yfirlit">
        <mass-email-list :active="tabIndex === 1" class="mt-4"></mass-email-list>
      </b-tab>
    </b-tabs>
  </vinnuskoli-page>
</template>

<script>
import { mapActions } from 'vuex';
import MassEmailSend from '@/components/massemail/MassEmailSend.vue';
import MassEmailList from '@/components/massemail/MassEmailList.vue';

export default {
  name: 'massemail',
  components: {
    MassEmailSend,
    MassEmailList,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
    };
  },
  methods: {
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
