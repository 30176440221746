
import { Component, Vue } from 'vue-property-decorator';
import { Action, State, Getter } from 'vuex-class';
import { CoreApiAxiosParamCreator, HelpCategory } from '@/api/openapi';

import HelpCategoryModal from '@/components/help/HelpCategoryModal.vue';
import HelpDocumentModal from '@/components/help/HelpDocumentModal.vue';

@Component({
  components: { HelpCategoryModal, HelpDocumentModal },
})
export default class Help extends Vue {
  @Action displaySuccess!: (s: string) => void;
  @Action displayError!: (s: string) => void;
  @State loggedInUser: any;
  @Getter loggedInUserHasAnyPermission: any;

  data: Array<HelpCategory> | null = null;
  loading: boolean = true;
  tabIndex = 0;

  $refs!: {
    modal: HelpCategoryModal;
    documentModal: HelpCategoryModal;
  };

  async loadList() {
    this.loading = true;
    this.data = [];

    try {
      const response = await this.$coreApi.helpCategoryList();
      const link = (await CoreApiAxiosParamCreator().helpDocumentDownload('uuid')).url;

      this.data = response.data.map(c => ({
        ...c,
        documents: c.documents.map(d => ({
          ...d,
          link: link.replaceAll('uuid', d.id),
        })),
      }));
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp við að sækja lista. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading = false;
    }
  }

  async created() {
    this.loadList();
  }

  showModal(id?: string) {
    this.$refs.modal.show(id);
  }

  showDocumentModal(categoryId: string) {
    this.$refs.documentModal.show(categoryId);
  }

  async deleteCategory(id: string, name: string) {
    try {
      const conf = confirm(`Ert þú viss um að þú viljir eyða „${name}“?`); // eslint-disable-line no-restricted-globals, no-alert

      if (conf) {
        await this.$coreApi.helpCategoryDestroy({
          uuid: id,
        });

        this.loadList();
        this.displaySuccess(`„${name}“ hefur verið eytt.`);
      }
    } catch (e) {
      this.$log.debug(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    }
  }

  async deleteDocument(id: string, name: string) {
    try {
      const conf = confirm(`Ert þú viss um að þú viljir eyða „${name}“?`); // eslint-disable-line no-restricted-globals, no-alert

      if (conf) {
        await this.$coreApi.helpDocumentDestroy({
          uuid: id,
        });

        this.loadList();
        this.displaySuccess(`„${name}“ hefur verið eytt.`);
      }
    } catch (e) {
      this.$log.debug(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    }
  }
}
