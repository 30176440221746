<template>
  <div>
    <b-modal
      id="districtCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <div class="alert alert-danger" v-if="errorList.length > 0">
          <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
        </div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Heiti*"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Heiti hóps"
          >
            <b-form-input
              id="name"
              name="name"
              autofocus
              type="text"
              v-model="form.name"
              required
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="nafn"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Starfsstaður*"
            label-for="workplace"
            :state="submitted && errors.has('workplace') ? false : ''"
            :invalid-feedback="errors.first('workplace')"
            description="Starfsstaður sem hópurinn tilheyrir"
          >
            <b-form-select
              id="workplace"
              name="workplace"
              v-model="form.workplace"
              :state="submitted && errors.has('workplace') ? false : ''"
              data-vv-as="vinnustað"
              v-validate="'required'"
              :disabled="groupId != null"
            >
              <option :value="null">-- Veldu starfsstað --</option>
              <template v-if="!workplaces.loading && workplaces.data">
                <option v-for="workplace in workplaces.data" :key="workplace.id" :value="workplace.id">
                  {{ workplace.name }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Tímabil*"
            label-for="period"
            :state="submitted && errors.has('period') ? false : ''"
            :invalid-feedback="errors.first('period')"
            description="Tímabil sem hópurinn tilheyrir"
          >
            <b-form-select
              id="period"
              name="period"
              v-model="form.period"
              :state="submitted && errors.has('period') ? false : ''"
              data-vv-as="tímabil"
              v-validate="'required'"
              :disabled="groupId != null"
              @change="periodChanged"
            >
              <option :value="null">-- Veldu tímabil --</option>
              <template v-if="!periods.loading && periods.data">
                <option v-for="period in periods.data" :key="period.id" :value="period.id">
                  {{ period.name }} ({{ period.dateFrom | moment('DD.MM.YYYY') }} - {{ period.dateTo | moment('DD.MM.YYYY') }})
                </option>
              </template>
            </b-form-select>
          </b-form-group>
          <template v-if="form.hasParts && !periodParts.loading && periodParts.data">
            <b-form-group
              label="Starfstímabil*"
              label-for="part"
              :state="submitted && errors.has('part') ? false : ''"
              :invalid-feedback="errors.first('part')"
              description="Starfstímabil sem hópurinn tilheyrir."
            >
              <b-form-select
                id="part"
                name="part"
                v-model="form.part"
                :state="submitted && errors.has('part') ? false : ''"
                data-vv-as="starfstímabil"
                v-validate="'required'"
                label="title"
                @change="partChanged"
              >
                <option :value="null">-- Veldu starfstímabil --</option>
                <template v-if="!periodParts.loading && periodParts.data">
                  <option v-for="part in periodParts.data" :key="part.id" :value="part.id">
                    {{ part.dateFrom | moment('DD.MM.YYYY') }} - {{ part.dateTo | moment('DD.MM.YYYY') }}
                  </option>
                </template>
              </b-form-select>
            </b-form-group>
          </template>

          <b-form-row>
            <b-form-group
              class="col-6 mb-0"
              label="Hópatímabil frá*"
              label-for="date_from"
              :state="submitted && errors.has('date_from') ? false : ''"
              :invalid-feedback="errors.first('date_from')"
            >
              <datepicker
                id="date_from"
                name="date_from"
                :placeholder="!this.form.period ? 'Veldu fyrst tímabil' : 'Veldu dags frá'"
                :class="{ 'is-invalid': submitted && errors.has('date_from') }"
                v-model="form.date_from"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                data-vv-as="dags frá"
                v-validate="'required'"
                ref="dateFromPickr"
                :disabled-dates="disabledDatesFrom"
                :disabled="!this.form.period"
              />
            </b-form-group>
            <b-form-group
              class="col-6 mb-0"
              label="Hópatímabil til*"
              label-for="date_to"
              :state="submitted && errors.has('date_to') ? false : ''"
              :invalid-feedback="errors.first('date_to')"
            >
              <datepicker
                id="date_to"
                name="date_to"
                :placeholder="!this.form.period ? 'Veldu fyrst tímabil' : 'Veldu dags til'"
                :class="{ 'is-invalid': submitted && errors.has('date_to') }"
                v-model="form.date_to"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                data-vv-as="dags til"
                v-validate="'required'"
                ref="dateToPickr"
                :disabled-dates="disabledDatesTo"
                :disabled="!this.form.period"
              />
            </b-form-group>
          </b-form-row>
          <div class="mb-2 small form-text text-muted">Tímabilið sem hópurinn gildir á</div>

          <b-form-group
            label="Takmörkun á fæðingarári"
            label-for="inputBirthYear"
            description="Takmörkun á fæðingarár þátttakenda í hópi. Ef óútfyllt er engin takmörkun."
          >
            <div class="mb-2">
              <div v-if="this.form.birthYears.length === 0" class="mb-1 small"><i>Engin takmörkun skráð.</i></div>
              <div v-for="birthYear in this.form.birthYears" :key="birthYear" class="mb-1">
                {{ birthYear }}
                <a href role="button" class="text-muted" @click.prevent="() => removeBirthYear(birthYear)"><i class="fa fa-remove"></i></a>
              </div>
            </div>
            <b-form-row>
              <b-input-group class="col-6 col-md-4 col-lg-3">
                <b-form-input
                  id="inputBirthYear"
                  name="inputBirthYear"
                  v-model="form.inputBirthYear"
                  type="number"
                  min="0"
                  v-on:keyup.enter="() => validInputBirthYear && addInputBirthYear()"
                />
                <b-input-group-append>
                  <b-button size="sm" variant="primary" :disabled="!validInputBirthYear" @click="addInputBirthYear">Skrá</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-row>
          </b-form-group>

          <b-form-group
            label="Hámark tíma á dag"
            label-for="max_hours"
            :state="submitted && errors.has('max_hours') ? false : ''"
            :invalid-feedback="errors.first('max_hours')"
            description="Hámarksfjöldi tíma sem má skrá að þátttakandi hafi unnið á hverjum degi. Ef óútfyllt er ekkert hámark."
          >
            <b-form-input
              id="max_hours"
              name="max_hours"
              v-model="form.max_hours"
              type="number"
              min="0"
              :state="submitted && errors.has('max_hours') ? false : ''"
              data-vv-as="hámark"
              @input="maxHoursMagic"
            >
            </b-form-input>
          </b-form-group>

          <b-form-row>
            <b-form-group
              class="col-6 mb-0"
              label="Vinnutími frá"
              label-for="time_from"
              :state="submitted && errors.has('time_from') ? false : ''"
              :invalid-feedback="errors.first('time_from')"
            >
              <b-form-timepicker
                id="time_from"
                name="time_from"
                v-model="form.time_from"
                :state="submitted && errors.has('time_from') ? false : ''"
                data-vv-as="tími frá"
                :hour12="false"
                locale="fr-FR-u-hc-h23"
                reset-button
                v-bind="labels['is']"
                @input="timeFromMagic"
              >
              </b-form-timepicker>
            </b-form-group>
            <!-- <b-col cols="1" class="text-center">-</b-col> -->
            <b-form-group
              class="col-6 mb-0"
              label="Vinnutími til"
              label-for="time_to"
              :state="submitted && errors.has('time_to') ? false : ''"
              :invalid-feedback="errors.first('time_to')"
            >
              <b-form-timepicker
                id="time_to"
                name="time_to"
                v-model="form.time_to"
                :state="submitted && errors.has('time_to') ? false : ''"
                data-vv-as="tími til"
                locale="fr-FR-u-hc-h23"
                :hour12="false"
                reset-button
                v-bind="labels['is']"
                @input="timeToMagic"
              >
              </b-form-timepicker>
            </b-form-group>
          </b-form-row>
          <div class="mb-2 small form-text text-muted">Vinnutími hóps alla daga. Ef óútfyllt er enginn vinnutími skilgreindur.</div>

          <b-form-group label="Starfsdagar" description="Vikudagar sem hópurinn vinnur.">
            <div>
              <div class="d-inline-block v-align-top">
                <b-form-checkbox id="monday" name="monday" type="text" v-model="form.monday"></b-form-checkbox>
              </div>
              <div class="d-inline-block v-align-top">Mánudagur</div>
            </div>
            <div>
              <div class="d-inline-block v-align-top">
                <b-form-checkbox id="tuesday" name="tuesday" type="text" v-model="form.tuesday"></b-form-checkbox>
              </div>
              <div class="d-inline-block v-align-top">Þriðjudagur</div>
            </div>
            <div>
              <div class="d-inline-block v-align-top">
                <b-form-checkbox id="wednesday" name="wednesday" type="text" v-model="form.wednesday"></b-form-checkbox>
              </div>
              <div class="d-inline-block v-align-top">Miðvikudagur</div>
            </div>
            <div>
              <div class="d-inline-block v-align-top">
                <b-form-checkbox id="thursday" name="thursday" type="text" v-model="form.thursday"></b-form-checkbox>
              </div>
              <div class="d-inline-block v-align-top">Fimmtudagur</div>
            </div>
            <div>
              <div class="d-inline-block v-align-top">
                <b-form-checkbox id="friday" name="friday" type="text" v-model="form.friday"></b-form-checkbox>
              </div>
              <div class="d-inline-block v-align-top">Föstudagur</div>
            </div>
            <div>
              <div class="d-inline-block v-align-top">
                <b-form-checkbox id="saturday" name="saturday" type="text" v-model="form.saturday"></b-form-checkbox>
              </div>
              <div class="d-inline-block v-align-top">Laugardagur</div>
            </div>
            <div>
              <div class="d-inline-block v-align-top">
                <b-form-checkbox id="sunday" name="sunday" type="text" v-model="form.sunday"></b-form-checkbox>
              </div>
              <div class="d-inline-block v-align-top">Sunnudagur</div>
            </div>
          </b-form-group>
          <b-form-group
            label="Leiðbeinandi"
            label-for="instructors"
            :state="submitted && errors.has('instructors') ? false : ''"
            :invalid-feedback="errors.first('instructors')"
            description="Leiðbeinandi/leiðbeinendur hópsins.
                Ath eingöngu þeir sem eru í aðgangshópnum Leiðbeinendur og hafa aðgang að völdum vinnustað birtast í listanum"
          >
            <v-select
              id="instructors"
              name="instructors"
              v-model="form.instructors"
              :state="submitted && errors.has('instructors') ? false : ''"
              data-vv-as="leiðbeinendur"
              multiple
              :options="instructors"
              reset-button
              label="name"
            ></v-select>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { is } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import { mapActions } from 'vuex';
import core from '@/api/core';
import placement from '@/api/placement';
import { djangoErrorsToVee } from '@/utils';

const DEFAULT_FORM = {
  name: '',
  period: null,
  hasParts: false,
  workplace: null,
  part: null,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: false,
  sunday: false,
  max_hours: '',
  date_from: null,
  date_to: null,
  time_from: '',
  time_to: '',
  instructors: [],
  birthYears: [],
  inputBirthYear: null,
};

export default {
  name: 'group-create-modal',
  components: {
    Datepicker,
  },
  data() {
    return {
      shownOnce: false,
      loading: false,
      submitted: false,
      errorList: [],
      selected: '',
      groupId: null,
      instructorList: [],
      title: 'Stofna hóp',
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
      lang: is,
      labels: {
        is: {
          labelHours: 'Klukkutímar',
          labelMinutes: 'Mínútur',
          labelSeconds: 'Sekúndur',
          labelIncrement: 'Hækka',
          labelDecrement: 'Lækka',
          labelSelected: 'Valinn tími',
          labelNoTimeSelected: 'Enginn tími valinn',
          labelCloseButton: 'Loka',
          labelResetButton: 'Hreinsa',
        },
      },
      data: {
        loading: false,
        error: false,
      },
      workplaces: {
        data: [],
        loading: false,
        error: false,
      },
      periods: {
        data: [],
        loading: false,
        error: false,
      },
      periodParts: {
        data: [],
        loading: false,
        error: false,
      },
    };
  },
  computed: {
    instructors() {
      return this.instructorList.filter(i => !this.form.instructors.find(x => x.id === i.id));
    },
    validInputBirthYear() {
      return (
        this.form.inputBirthYear &&
        this.form.inputBirthYear >= 1000 &&
        this.form.inputBirthYear <= 9999 &&
        !this.form.birthYears.includes(this.form.inputBirthYear)
      );
    },
    disabledDatesFrom() {
      if (this.form.period && (!this.form.hasParts || !this.form.part)) {
        const period = this?.periods.data?.find(p => p.id === this.form.period);
        if (period) {
          return {
            to: moment(period.dateFrom).toDate(),
            from: moment.min([moment(period.dateTo), moment(this.form.date_to)]).toDate(),
          };
        }
      } else if (this.form.part) {
        const part = this?.periodParts.data?.find(p => p.id === this.form.part);
        if (part) {
          return {
            to: moment(part.dateFrom).toDate(),
            from: moment.min([moment(part.dateTo), moment(this.form.date_to)]).toDate(),
          };
        }
      }
      return {};
    },
    disabledDatesTo() {
      if (this.form.period && (!this.form.hasParts || !this.form.part)) {
        const period = this?.periods.data?.find(p => p.id === this.form.period);
        if (period) {
          return {
            to: moment.max([moment(period.dateFrom), moment(this.form.date_from)]).toDate(),
            from: moment(period.dateTo).toDate(),
          };
        }
      } else if (this.form.part) {
        const part = this?.periodParts.data?.find(p => p.id === this.form.part);
        if (part) {
          return {
            to: moment.max([moment(part.dateFrom), moment(this.form.date_from)]).toDate(),
            from: moment(part.dateTo).toDate(),
          };
        }
      }
      return {};
    },
  },
  methods: {
    periodChanged(e) {
      const newValue = this.periods.data.find(p => p.id === e);
      this.$set(this.form, 'part', null);
      if (e) {
        this.$set(this.form, 'hasParts', newValue.hasParts);
      } else {
        this.$set(this.form, 'hasParts', false);
      }

      this.$set(this.form, 'date_from', newValue ? newValue.dateFrom : null);
      this.$set(this.form, 'date_to', newValue ? newValue.dateTo : null);

      this.loadPeriodParts();
    },
    partChanged(e) {
      const newValue = this.periodParts.data.find(p => p.id === e);

      this.$set(this.form, 'date_from', newValue ? newValue.dateFrom : null);
      this.$set(this.form, 'date_to', newValue ? newValue.dateTo : null);
    },
    timeFromMagic() {
      if (this.form.time_from && this.form.max_hours) {
        this.$set(this.form, 'time_to', moment(this.form.time_from, 'HH:mm:ss').add(this.form.max_hours, 'hours').format('HH:mm:ss'));
      }
    },
    timeToMagic() {
      if (this.form.time_to && this.form.max_hours) {
        this.$set(this.form, 'time_from', moment(this.form.time_to, 'HH:mm:ss').subtract(this.form.max_hours, 'hours').format('HH:mm:ss'));
      }
    },
    maxHoursMagic() {
      if (this.form.time_from) {
        this.timeFromMagic();
      } else if (this.form.time_to) {
        this.timeToMagic();
      }
    },
    addInputBirthYear() {
      this.form.birthYears.push(this.form.inputBirthYear);
      this.form.inputBirthYear = null;
    },
    removeBirthYear(birthYear) {
      const index = this.form.birthYears.indexOf(birthYear);
      if (index !== -1) this.form.birthYears.splice(index, 1);
    },
    async loadData(groupId) {
      this.data.loading = true;
      this.data.error = false;
      try {
        const response = await this.$placementApi.placementGroupRetrieve({
          uuid: groupId,
        });

        const placementGroup = response.data;

        this.form.name = placementGroup.name;
        this.form.max_hours = placementGroup.maxHours;
        this.form.time_from = placementGroup.timeFrom;
        this.form.time_to = placementGroup.timeTo;
        this.form.date_from = placementGroup.dateFrom;
        this.form.date_to = placementGroup.dateTo;
        this.form.monday = placementGroup.monday;
        this.form.tuesday = placementGroup.tuesday;
        this.form.wednesday = placementGroup.wednesday;
        this.form.thursday = placementGroup.thursday;
        this.form.friday = placementGroup.friday;
        this.form.saturday = placementGroup.saturday;
        this.form.sunday = placementGroup.sunday;
        this.form.workplace = placementGroup.workplace.id;
        this.form.period = placementGroup.period.id;
        this.form.hasParts = placementGroup.period.hasParts;
        this.form.part = placementGroup.periodPart ? placementGroup.periodPart.id : null;

        this.loadBirthYearData(groupId);
        this.loadInstructorData(groupId);
        this.loadWorkplaces();
        this.loadPeriods();
      } catch (e) {
        this.$log.error(e);
        this.data.error = true;
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.data.loading = false;
      }
    },
    async loadBirthYearData(groupId) {
      try {
        const response = await this.$placementApi.placementGroupBirthYearList({
          placementGroup: groupId,
        });

        this.form.birthYears = response.data.map(b => b.birthYear);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadInstructorData(groupId) {
      try {
        const response = await this.$placementApi.placementGroupInstructorList({
          placementGroup: groupId,
        });

        this.loadInstructors(response.data);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadInstructors(instructors) {
      this.instructorList = [];
      try {
        const response = await core.dropdownInstructorList();
        this.instructorList = response.data;
        if (instructors && instructors.length > 0) {
          this.$set(
            this.form,
            'instructors',
            this.instructorList.filter(i => instructors.map(x => x.userId).includes(i.id)),
          );
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          const item = {
            name: this.form.name,
            workplace: this.form.workplace,
            period_part: this.form.part ? this.form.part : null,
            period: this.form.period,
            instructors: this.form.instructors.map(x => x.id),
            max_hours: this.form.max_hours ? this.form.max_hours : null,
            date_from: moment(this.form.date_from).format('YYYY-MM-DD'),
            date_to: moment(this.form.date_to).format('YYYY-MM-DD'),
            time_from: this.form.time_from ? this.form.time_from : null,
            time_to: this.form.time_to ? this.form.time_to : null,
            monday: this.form.monday,
            tuesday: this.form.tuesday,
            wednesday: this.form.wednesday,
            thursday: this.form.thursday,
            friday: this.form.friday,
            saturday: this.form.saturday,
            sunday: this.form.sunday,
            birth_years: this.form.birthYears,
          };
          if (this.groupId) {
            await placement.placementGroupUpdate(this.groupId, item);
            this.displaySuccess(`Hópnum „${this.form.name}“ hefur verið breytt.`);
          } else {
            await placement.placementGroupCreate(item);

            this.displaySuccess(`Hópurinn „${this.form.name}“ hefur verið stofnaður.`);
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
      }
    },
    show(groupId, copy = false) {
      this.shownOnce = true;
      this.$set(this, 'form', JSON.parse(JSON.stringify(DEFAULT_FORM)));
      this.submitted = false;
      this.loading = false;
      this.groupId = null;
      this.title = 'Stofna hóp';
      if (groupId) {
        if (!copy) {
          this.title = 'Breyta hóp';
          this.groupId = groupId;
        }
        this.loadData(groupId);
      } else {
        this.loadInstructors();
        this.loadWorkplaces();
        this.loadPeriods();
      }
      this.$refs.createModal.show();
    },

    async loadWorkplaces() {
      this.workplaces.loading = true;
      this.workplaces.data = [];
      this.workplaces.error = false;

      try {
        const response = await this.$coreApi.workplaceDropdownList();

        this.workplaces.data = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.workplaces.error = true;
      } finally {
        this.workplaces.loading = false;
      }
    },
    async loadPeriods() {
      this.periods.loading = true;
      this.periods.data = [];
      this.periods.error = false;

      try {
        const periodsResponse = await this.$coreApi.periodDropdownList();

        this.periods.data = periodsResponse.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.periods.error = true;
      } finally {
        this.periods.loading = false;
        this.loadPeriodParts();
      }
    },
    async loadPeriodParts() {
      this.periodParts.loading = true;
      this.periodParts.data = [];
      this.periodParts.error = false;

      try {
        if (this.form.period) {
          const response = await this.$coreApi.periodDropdownPeriodPartDropdownList({
            period: this.form.period,
          });

          this.periodParts.data = response.data;

          if (!this.periodParts.data.map(p => p.id).includes(this.form.part)) {
            this.form.part = null;
          }
        } else {
          this.periodParts.data = [];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.periodParts.error = true;
      } finally {
        this.periodParts.loading = false;
      }
    },

    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style>
.d-inline-block {
  display: inline-block;
}
.v-align-top {
  vertical-align: top;
}
</style>
