<template>
  <vinnuskoli-page>
    <div class="page-title">Skoða umsókn</div>
    <h2 class="font-weight-normal">{{ data && data.name }}</h2>

    <div class="py-2" v-if="data">
      <b-button-toolbar aria-label="Aðgerðir á umsókn">
        <b-button-group class="mx-1">
          <b-btn
            variant="success"
            v-if="
              availableStatuses[data.status].includes('ACCEPTED') &&
              loggedInUserHasAnyPermission('write_application') &&
              loggedInUserHasAnyPermission('write_approve_application')
            "
            @click.prevent="applicationApproveModal()"
          >
            <i class="fa fa-fw fa-check" style="color: #fff"></i>
            Samþykkja umsókn
          </b-btn>
          <b-btn
            variant="dark"
            v-if="
              data.status === 'SEMI_ACCEPTED' &&
              loggedInUserHasAnyPermission('write_application') &&
              loggedInUserHasAnyPermission('write_approve_application')
            "
            @click.prevent="applicationFinishModal()"
          >
            <i class="fa fa-fw fa-envelope"></i>
            Klára umsókn
          </b-btn>
          <b-btn
            variant="warning"
            v-if="
              availableStatuses[data.status].includes('REJECTED') &&
              loggedInUserHasAnyPermission('write_application') &&
              loggedInUserHasAnyPermission('write_approve_application')
            "
            @click.prevent="applicationRejectModal()"
          >
            <i class="fa fa-fw fa-remove"></i>
            Hafna umsókn
          </b-btn>
          <!-- <b-btn
            variant="danger"
            v-if="availableStatuses[data.status].includes('REJECTED') && loggedInUserHasAnyPermission('write_application')"
            @click.prevent="applicationDelete()"
          >
            <i class="fa fa-fw fa-exclamation-triangle" style="color: #fff"></i>
            Eyða umsókn
          </b-btn> -->
        </b-button-group>
        <b-dropdown
          variant="info"
          class="mx-1"
          right
          text="Aðrar stöður"
          v-if="
            loggedInUserHasAnyPermission('write_application') &&
            (availableStatuses[data.status].includes('NEW') ||
              availableStatuses[data.status].includes('IN_PROGRESS') ||
              availableStatuses[data.status].includes('IN_PROGRESS_APPROVED') ||
              availableStatuses[data.status].includes('WITHDRAWN') ||
              availableStatuses[data.status].includes('CANCELLED') ||
              availableStatuses[data.status].includes('COMPLETED'))
          "
        >
          <b-dropdown-item
            v-if="availableStatuses[data.status].includes('NEW') && loggedInUserHasAnyPermission('write_application')"
            @click.prevent="applicationNewModal()"
            >Nýskráð</b-dropdown-item
          >
          <b-dropdown-item
            v-if="availableStatuses[data.status].includes('IN_PROGRESS') && loggedInUserHasAnyPermission('write_application')"
            @click.prevent="applicationInProgressModal()"
            >Í vinnslu</b-dropdown-item
          >
          <b-dropdown-item
            v-if="availableStatuses[data.status].includes('IN_PROGRESS_APPROVED') && loggedInUserHasAnyPermission('write_application')"
            @click.prevent="applicationInProgressApproveModal()"
            >Í vinnslu samþykkt</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              availableStatuses[data.status].includes('WITHDRAWN') &&
              loggedInUserHasAnyPermission('write_application') &&
              loggedInUserHasAnyPermission('write_approve_application')
            "
            @click.prevent="applicationWithdrawModal()"
            >Draga til baka</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              availableStatuses[data.status].includes('CANCELLED') &&
              loggedInUserHasAnyPermission('write_application') &&
              loggedInUserHasAnyPermission('write_approve_application')
            "
            @click.prevent="applicationCancelModal()"
            >Afskrá</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              availableStatuses[data.status].includes('COMPLETED') &&
              loggedInUserHasAnyPermission('write_application') &&
              loggedInUserHasAnyPermission('write_approve_application')
            "
            @click.prevent="applicationCompleteModal()"
            >Ráðning</b-dropdown-item
          >
        </b-dropdown>
        <!-- <b-button-group class="mx-1">
        <b-button>Save</b-button>
        <b-button>Cancel</b-button>
      </b-button-group> -->
      </b-button-toolbar>
    </div>

    <b-card class="card-primary-header" v-if="application2 && data">
      <div slot="header">
        <div>Umsókn</div>
      </div>
      <application-applicant :item="application2" :item2="applicationNationalRegistry" :id="id" @reload="doReload" />
      <application-registration :item="application2" :item2="data" :id="id" @reload="doReload" />
      <application-contacts :item="data" @reload="doReload" />
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-card>
    <application-email :id="id" :reload="doReload" />
    <application-history :id="id" :reload="doReload" />

    <!--approve-application-modal ref="approveApplicationModal" @successful="loadList"></approve-application-modal-->
    <reject-application-modal ref="rejectApplicationModal" @successful="doReload"></reject-application-modal>
    <finish-application-modal ref="finishApplicationModal" @successful="doReload"></finish-application-modal>
    <application-in-progress-modal ref="applicationInProgressModal" @successful="doReload"></application-in-progress-modal>
    <application-in-progress-approved-modal
      ref="applicationInProgressApprovedModal"
      @successful="doReload"
    ></application-in-progress-approved-modal>
    <application-withdraw-modal ref="withdrawApplicationModal" @successful="doReload"></application-withdraw-modal>
    <application-complete-modal ref="completeApplicationModal" @successful="doReload"></application-complete-modal>
    <application-cancel-modal ref="cancelApplicationModal" @successful="doReload"></application-cancel-modal>
    <application-new-modal ref="applicationNewModal" @successful="doReload"></application-new-modal>
  </vinnuskoli-page>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies

import { mapGetters, mapActions } from 'vuex';
import appl from '@/api/application';
import RejectApplicationModal from '@/components/application/RejectApplication.vue';
import FinishApplicationModal from '@/components/application/FinishApplication.vue';
import ApplicationInProgressModal from '@/components/application/ApplicationInProgressModal.vue';
import ApplicationWithdrawModal from '@/components/application/ApplicationWithdrawModal.vue';
import ApplicationCancelModal from '@/components/application/ApplicationCancelModal.vue';
import ApplicationCompleteModal from '@/components/application/ApplicationCompleteModal.vue';
import ApplicationNewModal from '@/components/application/ApplicationNewModal.vue';
import ApplicationInProgressApprovedModal from '@/components/application/ApplicationInProgressApprovedModal.vue';
import ApplicationApplicant from '@/components/application/ApplicationApplicant.vue';
import ApplicationRegistration from '@/components/application/ApplicationRegistration.vue';
import ApplicationContacts from '@/components/application/ApplicationContacts.vue';
import ApplicationHistory from '@/components/application/ApplicationHistory.vue';
import ApplicationEmail from '@/components/application/ApplicationEmail.vue';
import { b64EncodeUnicode } from '@/utils';

export default {
  name: 'Application',
  components: {
    RejectApplicationModal,
    FinishApplicationModal,
    ApplicationApplicant,
    ApplicationRegistration,
    ApplicationContacts,
    ApplicationHistory,
    ApplicationEmail,
    ApplicationInProgressModal,
    ApplicationNewModal,
    ApplicationWithdrawModal,
    ApplicationCancelModal,
    ApplicationCompleteModal,
    ApplicationInProgressApprovedModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      id: null,
      loading: false,
      error: false,
      data: null,
      availableStatuses: {
        NEW: ['IN_PROGRESS', 'IN_PROGRESS_APPROVED', 'SEMI_ACCEPTED', 'ACCEPTED', 'REJECTED', 'CANCELLED', 'WITHDRAWN'],
        IN_PROGRESS: ['NEW', 'IN_PROGRESS', 'IN_PROGRESS_APPROVED', 'SEMI_ACCEPTED', 'ACCEPTED', 'REJECTED', 'CANCELLED', 'WITHDRAWN'],
        IN_PROGRESS_APPROVED: ['NEW', 'SEMI_ACCEPTED', 'ACCEPTED', 'REJECTED', 'CANCELLED', 'WITHDRAWN'],
        SEMI_ACCEPTED: ['ACCEPTED'],
        ACCEPTED: ['COMPLETED'],
        REJECTED: [],
        CANCELLED: [],
        WITHDRAWN: [],
        COMPLETED: [],
      },
    };
  },
  apollo: {
    application2: {
      query: gql`
        query Application($id: ID!) {
          application2: application(id: $id) {
            id
            databaseId
            ssn
            name
            account
            tel
            email
            specialNeeds
            other
            otherWorkplace
            resolutionComment
            birthYear {
              birthYear
              birthYearReal
              birthYearOverridden
            }
            period {
              id
              databaseId
              name
              dateFrom
              dateTo
            }
            allPeriodParts {
              id
              databaseId
              status
              periodPart {
                id
                databaseId
                dateFrom
                dateTo
              }
            }
            allPlacementGroupPersons {
              id
              databaseId
              placementGroup {
                id
                databaseId
                name
                workplace {
                  id
                  databaseId
                  name
                  allTypes {
                    id
                    databaseId
                    type {
                      id
                      databaseId
                      name
                    }
                  }
                }
                period {
                  id
                  databaseId
                }
                periodPart {
                  id
                  databaseId
                }
              }
            }
            status
            personDatabaseId
            workplaceInProgress {
              id
              databaseId
              name
            }
            dateCreated
            dateUpdated
          }
        }
      `,
      variables() {
        return {
          id: b64EncodeUnicode(`ApplicationType:${this.id}`),
        };
      },
      skip() {
        return !this.id;
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
    applicationNationalRegistry: {
      query: gql`
        query ApplicationNationalRegistry($id: ID!) {
          applicationNationalRegistry(id: $id) {
            ssn
            name
            familyNumber
            domicile
            postcode
            postcodeName
          }
        }
      `,
      variables() {
        return {
          id: b64EncodeUnicode(`ApplicationNationalRegistryType:${this.id}`),
        };
      },
      skip() {
        return !this.id;
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  methods: {
    getStatusName(status) {
      switch (status) {
        case 'NEW':
          return 'Nýskráð';
        case 'SEMI_ACCEPTED':
          return 'Afgreidd að hluta';
        case 'ACCEPTED':
          return 'Afgreidd';
        case 'REJECTED':
          return 'Hafnað';
        case 'CANCELLED':
          return 'Afskráð';
        case 'IN_PROGRESS':
          return 'Í vinnslu';
        case 'IN_PROGRESS_APPROVED':
          return 'Í vinnslu samþykkt';
        case 'WITHDRAWN':
          return 'Dregin til baka';
        case 'COMPLETED':
          return 'Ráðning';
        default:
          return '';
      }
    },
    async doReload() {
      this.$apollo.queries.application2.refetch();
      this.loadApplication();
    },
    // Modal gluggar
    applicationApproveModal() {
      this.$router.push({ name: 'ApproveApplication', params: { id: this.id } });
    },
    applicationRejectModal() {
      const items = [];
      items.push(this.data);
      this.$refs.rejectApplicationModal.show(items);
    },
    applicationFinishModal() {
      const items = [];
      items.push(this.data);
      this.$refs.finishApplicationModal.show(items);
    },
    async applicationDelete() {
      const conf = confirm('Ertu viss alveg viss um að þú viljir eyða umsókninni'); //eslint-disable-line
      if (conf) {
        await appl.applicationDelete(this.id);
        this.displaySuccess('Umsóknir eytt');
        this.$router.push({ name: 'ApplicationList' });
      }
    },
    applicationInProgressModal() {
      const items = [];
      items.push(this.data);
      this.$refs.applicationInProgressModal.show(items);
    },
    applicationInProgressApproveModal() {
      const items = [];
      items.push(this.data);
      this.$refs.applicationInProgressApprovedModal.show(items);
    },
    applicationWithdrawModal() {
      const items = [];
      items.push(this.data);
      this.$refs.withdrawApplicationModal.show(items);
    },
    applicationCancelModal() {
      const items = [];
      items.push(this.data);
      this.$refs.cancelApplicationModal.show(items);
    },
    applicationCompleteModal() {
      const items = [];
      items.push(this.data);
      this.$refs.completeApplicationModal.show(items);
    },
    applicationNewModal() {
      const items = [];
      items.push(this.data);
      this.$refs.applicationNewModal.show(items);
    },
    async loadApplication() {
      this.loading = true;
      this.data = null;
      this.error = false;

      try {
        const response = await this.$applicationApi.applicationRetrieve({
          uuid: this.id,
        });

        this.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.id = this.$route.params.id;
    this.loadApplication();
  },
};
</script>

<style lang="scss">
@import '../style/variables';
.card-primary-header {
  .card-header {
    background-color: $primary;
    color: #fff;
    font-size: 18px;
  }
}
h5.header {
  font-weight: 100;
}
</style>
