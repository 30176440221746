<template>
  <div>
    <b-modal
      id="contactModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <div class="alert alert-danger" v-if="errorList.length > 0">
            <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
          </div>
          <b-form-group
            label="Nafn:"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Nafn aðstandanda"
          >
            <b-form-input
              id="name"
              name="name"
              type="text"
              v-model="form.name"
              required
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="nafn"
              :disabled="contactId !== null"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Kennitala:"
            label-for="ssn"
            :state="submitted && errors.has('ssn') ? false : ''"
            :invalid-feedback="errors.first('ssn')"
            description="Kennitala aðstandanda"
          >
            <b-form-input
              id="ssn"
              name="ssn"
              type="text"
              v-model="form.ssn"
              required
              :state="submitted && errors.has('ssn') ? false : ''"
              v-validate="'required'"
              data-vv-as="kennitala"
              :disabled="contactId !== null"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Netfang:"
            label-for="email"
            :state="submitted && errors.has('email') ? false : ''"
            :invalid-feedback="errors.first('email')"
            description="Netfang aðstandanda"
          >
            <b-form-input
              id="email"
              name="email"
              type="text"
              v-model="form.email"
              required
              :state="submitted && errors.has('email') ? false : ''"
              v-validate="'required'"
              data-vv-as="netfang"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Farsími:"
            label-for="tel"
            :state="submitted && errors.has('tel') ? false : ''"
            :invalid-feedback="errors.first('tel')"
            description="Farsími aðstandanda"
          >
            <b-form-input
              id="tel"
              name="tel"
              type="text"
              v-model="form.tel"
              required
              :state="submitted && errors.has('tel') ? false : ''"
              v-validate="'required'"
              data-vv-as="farsími"
            ></b-form-input>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import appl from '@/api/application';
import placement from '@/api/placement';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'contact-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      districtId: '',
      title: 'Stofna aðstandanda',
      type: '',
      id: null,
      contactId: null,
      form: {
        name: '',
        ssn: '',
        tel: '',
        email: '',
      },
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        let response = null;
        if (this.type === 'application') {
          response = await appl.applicationContactDetail(this.contactId);
        } else {
          response = await placement.placementGroupPersonContactDetail(this.contactId);
        }
        this.form.name = response.data.name;
        this.form.ssn = response.data.ssn;
        this.form.tel = response.data.tel;
        this.form.email = response.data.email;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          if (this.contactId) {
            if (this.type === 'application') {
              this.form.application = this.id;
              await appl.applicationContactUpdate(this.contactId, this.form);
              this.displaySuccess(`Tengiliðnum „${this.form.name}“ hefur verið breytt.`);
            } else {
              this.form.placement_group_person = this.id;
              await placement.placementGroupPersonContactUpdate(this.contactId, this.form);
              this.displaySuccess(`Tengiliðnum „${this.form.name}“ hefur verið breytt.`);
            }
          } else if (!this.contactId) {
            if (this.type === 'application') {
              this.form.application = this.id;
              await appl.applicationContactCreate(this.form);
              this.displaySuccess(`Tengiliðnum „${this.form.name}“ hefur verið stofnaður.`);
            } else {
              this.form.placement_group_person = this.id;
              await placement.placementGroupPersonContactCreate(this.form);
              this.displaySuccess(`Tengiliðnum „${this.form.name}“ hefur verið stofnaður.`);
            }
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
      }
    },
    show(type, id, contactId) {
      this.type = type;
      this.id = id;
      this.contactId = null;
      this.form.ssn = '';
      this.form.name = '';
      this.form.tel = '';
      this.form.email = '';
      this.submitted = false;
      this.loading = false;
      if (contactId) {
        this.title = 'Breyta aðstandanda';
        this.contactId = contactId;
        this.load();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
