<template>
  <div>
    <b-modal
      id="registerCommentModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <h5 style="font-weight: 100" class="mb-3" v-if="this.student">
            <strong>{{ this.student.name }}</strong>
          </h5>
          <b-form-group
            label="Athugasemd:"
            label-for="comment"
            :state="submitted && errors.has('comment') ? false : ''"
            :invalid-feedback="errors.first('comment')"
          >
            <b-form-textarea
              id="comment"
              name="comment"
              autofocus
              type="text"
              v-model="form.comment"
              required
              :state="submitted && errors.has('comment') ? false : ''"
              v-validate="'required'"
              data-vv-as="athugasemd"
            ></b-form-textarea>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import core from '@/api/core';
import { mapActions } from 'vuex';
// import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'register-comment-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      periodId: '',
      student: null,
      title: 'Skrá athugasemd',
      form: {
        comment: '',
      },
    };
  },
  methods: {
    validateBeforeSubmit() {
      this.$emit('successful', {
        comment: this.form.comment,
      });
      this.$refs.createModal.hide();
    },
    show(student) {
      this.form.comment = student.comment ? student.comment : '';
      this.student = student;
      this.submitted = false;
      this.loading = false;
      if (student.comment) {
        this.title = 'Breyta athugasemd';
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
