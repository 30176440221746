<template>
  <div>
    <b-modal
      id="reviewModal"
      :title="title"
      size="lg"
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <div class="alert alert-danger" v-if="errorList.length > 0">
            <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
          </div>

          <div v-if="!reviewQuestions.loading && reviewQuestions.data" class="mb-4">
            <div class="mb-2"><strong>Frammistöðukvarðar:</strong></div>
            <div v-for="reviewQuestion in reviewQuestions.data" :key="reviewQuestion.id" class="mb-3">
              <div class="mb-1">{{ reviewQuestion.question }}</div>
              <star-rating :show-rating="false" :star-size="20" v-model="form.reviewQuestions[reviewQuestion.id]" :clearable="true" />
            </div>
          </div>

          <b-form-group
            label="Mat:"
            label-for="review"
            :state="submitted && errors.has('review') ? false : ''"
            :invalid-feedback="errors.first('review')"
            description="Mat á frammistöðu"
          >
            <b-form-textarea
              id="review"
              name="review"
              autofocus
              type="text"
              v-model="form.review"
              :state="submitted && errors.has('review') ? false : ''"
              data-vv-as="mat"
            ></b-form-textarea>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import StarRating from 'vue-star-rating';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'review-modal',
  components: {
    StarRating,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      groupId: '',
      groupPersonId: '',
      title: 'Skrá mat',
      form: {
        review: '',
        reviewQuestions: {},
      },
      reviewQuestions: {
        loading: true,
        error: false,
        data: null,
      },
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) {
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          await this.$placementApi.placementGroupPersonReview({
            uuid: this.groupPersonId,
            placementGroupPersonReviewRequest: {
              review: this.form.review,
              reviewQuestions: Object.entries(this.form.reviewQuestions)
                .filter(([, v]) => !!v)
                .map(([k, v]) => ({
                  rating: v,
                  reviewQuestion: {
                    id: k,
                  },
                })),
            },
          });
          this.displaySuccess('Mat á frammistöðu skráð');
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.loading = false;
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
        this.loading = false;
      }
    },
    async loadReviewQuestions(periodId) {
      this.reviewQuestions.loading = false;
      this.reviewQuestions.data = null;
      this.reviewQuestions.error = false;

      try {
        const response = await this.$placementApi.reviewQuestionDropdownList({
          period: periodId,
        });

        this.reviewQuestions.data = response.data;

        response.data.forEach(r => {
          this.$set(this.form.reviewQuestions, r.id, 0);
        });
      } catch (e) {
        this.reviewQuestions.error = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.reviewQuestions.loading = false;
      }
    },
    async show(personId) {
      const placementGroupPersonRetrieve = (
        await this.$placementApi.placementGroupPersonRetrieve({
          uuid: personId,
        })
      ).data;

      this.form.reviewQuestions = {};

      await this.loadReviewQuestions(placementGroupPersonRetrieve.placementGroup.period.id);

      placementGroupPersonRetrieve.reviewQuestions.forEach(rq => {
        if (rq.reviewQuestion.id in this.form.reviewQuestions) {
          this.$set(this.form.reviewQuestions, rq.reviewQuestion.id, rq.rating);
        }
      });

      this.groupId = placementGroupPersonRetrieve.placementGroup.id;
      this.groupPersonId = placementGroupPersonRetrieve.id;
      this.form.review = placementGroupPersonRetrieve.review || '';
      this.submitted = false;
      this.loading = false;
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
