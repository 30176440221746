
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import { CoreApiAxiosParamCreator, CoreApiStatisticsSummaryExcelRequest, PeriodDropdown, StatisticsSummary } from '@/api/openapi';

import BarChart from '@/components/BarChart.vue';

const defaultColors = [
  '#f87979',
  '#3366CC',
  '#DC3912',
  '#FF9900',
  '#109618',
  '#990099',
  '#3B3EAC',
  '#0099C6',
  '#DD4477',
  '#66AA00',
  '#B82E2E',
  '#316395',
  '#994499',
  '#22AA99',
  '#AAAA11',
  '#6633CC',
  '#E67300',
  '#8B0707',
  '#329262',
  '#5574A6',
  '#651067',
];

@Component({
  components: { BarChart, Datepicker },
})
export default class ReviewQuestions extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;

  lang = is;

  form: {
    period: string | undefined;
    date: Date;
  } = {
    period: undefined,
    date: moment().toDate(),
  };

  summary: {
    loading: boolean;
    error: boolean;
    data: StatisticsSummary | null;
  } = {
    loading: true,
    error: false,
    data: null,
  };

  periods: {
    loading: boolean;
    error: boolean;
    data: Array<PeriodDropdown> | null;
  } = {
    loading: true,
    error: false,
    data: null,
  };

  barChartOptions(xTitle: string, title: string, legend: boolean = false) {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          grace: '1%',
          ticks: {
            stepSize: 1,
          },
        },
        x: {
          title: {
            display: true,
            text: xTitle,
          },
        },
      },
      plugins: {
        legend,
        title: {
          display: true,
          text: title,
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          // color: defaultColors[0],
        },
      },
    };
  }

  typicalChart(key: keyof StatisticsSummary, xTitle: string, title: string, yLabel: string) {
    if (this.summary.data) {
      const labels = Object.keys(this.summary.data[key]);
      const data = Object.values(this.summary.data[key]);
      const total = data.reduce((sum, item) => sum + item, 0);

      return {
        data: {
          labels,
          datasets: [
            {
              label: yLabel,
              backgroundColor: '#f87979',
              data,
            },
          ],
        },
        options: this.barChartOptions(xTitle, `${title} (${total})`),
      };
    }

    return null;
  }

  get applicationBirthYearChart() {
    return this.typicalChart('applicationBirthYears', 'Fæðingarár', 'Fjöldi umsókna eftir fæðingarári', 'Fjöldi umsókna');
  }

  get applicationStatusChart() {
    return this.typicalChart('applicationStatuses', 'Staða umsóknar', 'Fjöldi umsókna eftir stöðu umsóknar', 'Fjöldi umsókna');
  }

  get personBirthYearChart() {
    return this.typicalChart('personBirthYears', 'Fæðingarár', 'Fjöldi þátttakenda eftir fæðingarári', 'Fjöldi þátttakenda');
  }

  get personDistrictChart() {
    return this.typicalChart('personDistricts', 'Hverfi', 'Fjöldi þátttakenda eftir hverfi', 'Fjöldi þátttakenda');
  }

  get personWorkplaceChart() {
    return this.typicalChart('personWorkplaces', 'Starfsstaður', 'Fjöldi þátttakenda eftir starfsstað', 'Fjöldi þátttakenda');
  }

  get attendanceBirthYearChart() {
    return this.typicalChart('attendanceBirthYears', 'Fæðingarár', 'Summa viðverutíma eftir fæðingarári', 'Summa viðverutíma');
  }

  get attendanceDistrictChart() {
    return this.typicalChart('attendanceDistricts', 'Hverfi', 'Summa viðverutíma eftir hverfi', 'Summa viðverutíma');
  }

  get attendanceWorkplaceChart() {
    return this.typicalChart('attendanceWorkplaces', 'Starfsstaður', 'Summa viðverutíma eftir starfsstað', 'Summa viðverutíma');
  }

  typicalAttendanceTypeChart(key: keyof StatisticsSummary, xTitle: string, title: string, yLabel: string) {
    if (this.summary.data) {
      const labels = Object.keys(this.summary.data[key]);
      const values = Object.values(this.summary.data[key]);
      const datasets = [];

      if (values.length > 0) {
        datasets.push(
          ...Object.keys(values[0]).map((k, idx) => ({
            label: k,
            backgroundColor: defaultColors[idx],
            data: labels.map(() => 0),
          })),
        );
      } else {
        datasets.push({
          label: yLabel,
          backgroundColor: '#f87979',
          data: labels.map(() => 0),
        });
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i !== datasets.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j !== datasets[i].data.length; j++) {
          datasets[i].data[j] += values[j][datasets[i].label];
        }
      }

      const total = datasets.map(x => x.data.reduce((sum, item) => sum + item, 0)).reduce((sum, item) => sum + item, 0);

      return {
        data: {
          labels,
          datasets,
        },
        options: this.barChartOptions(xTitle, `${title} (${total})`, true),
      };
    }

    return null;
  }

  get attendanceTypeBirthYearChart() {
    return this.typicalAttendanceTypeChart('attendanceTypeBirthYears', 'Fæðingarár', 'Fjöldi fjarvistartegundardaga eftir fæðingarári', '');
  }

  get attendanceTypeDistrictChart() {
    return this.typicalAttendanceTypeChart('attendanceTypeDistricts', 'Hverfi', 'Fjöldi fjarvistartegundardaga eftir hverfi', '');
  }

  get attendanceTypeWorkplaceChart() {
    return this.typicalAttendanceTypeChart(
      'attendanceTypeWorkplaces',
      'Starfsstaður',
      'Fjöldi fjarvistartegundardaga eftir starfsstað',
      '',
    );
  }

  async loadPeriods() {
    this.periods.loading = true;
    this.periods.data = [];
    this.periods.error = false;

    try {
      const response = await this.$coreApi.periodDropdownList();

      this.periods.data = response.data;

      if (response.data.length > 0) {
        this.form.period = response.data[0].id;
      }
    } catch (e) {
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      this.periods.error = true;
    } finally {
      this.periods.loading = false;
    }
  }

  async loadSummary() {
    this.summary.data = null;

    if (this.form.period && this.form.date) {
      this.summary.loading = true;
      this.summary.error = false;

      try {
        const response = await this.$coreApi.statisticsSummary({
          date: moment(this.form.date).format('YYYY-MM-DD'),
          period: this.form.period,
        });

        this.summary.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.summary.error = true;
      } finally {
        this.summary.loading = false;
      }
    }
  }

  async excel() {
    if (this.form.period && this.form.date) {
      const requestParameters: CoreApiStatisticsSummaryExcelRequest = {
        date: moment(this.form.date).format('YYYY-MM-DD'),
        period: this.form.period,
      };

      const excelData = await CoreApiAxiosParamCreator().statisticsSummaryExcel(requestParameters.date, requestParameters.period);

      window.location.href = excelData.url;
    }
  }

  async created() {
    this.loadPeriods();
  }
}
