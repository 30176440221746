<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Leit</div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2">
        <strong>Tegund</strong>
        <b-form-select id="type" name="type" type="type" v-model="options.search.type" @input="clearResults()">
          <option value="Person">Þátttakendur</option>
          <option value="Application">Umsóknir</option>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2">
        <strong>Tímabil</strong>
        <b-form-select id="period" name="period" v-model="options.search.period">
          <option :value="undefined">-- Veldu tímabil --</option>
          <template v-if="!periods.loading && periods.data">
            <option v-for="period in periods.data" :key="period.id" :value="period.id">
              {{ period.name }}
            </option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <strong>Nafn</strong>
        <b-form-input id="name" name="name" type="text" @keyup.native.enter="loadList()" v-model="options.search.name"> </b-form-input>
      </b-col>
      <b-col cols="2">
        <strong>Kennitala</strong>
        <b-form-input id="ssn" name="ssn" type="text" @keyup.native.enter="loadList()" v-model="options.search.ssn"> </b-form-input>
      </b-col>
      <b-col cols="2">
        <strong>Netfang</strong>
        <b-form-input id="email" name="email" type="text" @keyup.native.enter="loadList()" v-model="options.search.email"> </b-form-input>
      </b-col>
      <b-col cols="2">
        <strong>Farsími</strong>
        <b-form-input id="tel" name="tel" type="text" @keyup.native.enter="loadList()" v-model="options.search.tel"> </b-form-input>
      </b-col>
      <b-col cols="0" style="margin-top: 20px">
        <b-btn variant="primary" @click.prevent="loadList()"> Leita </b-btn>
      </b-col>
      <b-col cols="1" style="margin-top: 25px">
        <i
          class="fa fa-recycle fa-2x"
          style="color: #367bb5; float: left"
          v-b-tooltip.hover
          :title="`Hreinsa síur`"
          aria-hidden="true"
          @click="clearFilters()"
        ></i
      ></b-col>
    </b-row>
    <b-card no-body border-variant="light" class="mb-2">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Tegund</th>
            <th>Nafn</th>
            <th>Kennitala</th>
            <th>Netfang</th>
            <th>Sími</th>
            <th>
              <span v-if="options.search.type === 'Application'">Umsækjandi</span>
              <span v-else>Þátttakandi</span>
            </th>
            <th>Tímabil</th>
            <th v-if="options.search.type === 'Application'">Staða</th>
            <th v-else>Vinnustaður</th>
          </tr>
        </thead>
        <tbody v-if="firstTime">
          <tr>
            <td colspan="8" class="text-center">
              <i>Vinsamlegast veldu leitarskilyrði og smelltu á leita</i>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!firstTime">
          <tr v-if="searchList.length === 0 && !loading">
            <td colspan="8" class="text-center">
              <i>Engar leitarniðurstöður fundust</i>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-for="(search, index) in searchList" :key="index">
            <td>{{ getType(search.result_type) }}</td>
            <td>
              {{ search.search_name }}
              <b-link
                v-b-tooltip.hover
                title="Skoða"
                v-if="search.result_type === 'application' && loggedInUserHasAnyPermission('read_application')"
                target="_blank"
                :to="{ name: 'Application', params: { id: search.application_id } }"
              >
                <i class="fa fa-fw fa-search"></i>
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="Skoða"
                v-if="search.result_type === 'placement_group_person' && loggedInUserHasAnyPermission('read_person')"
                target="_blank"
                :to="{ name: 'StudentWithGroup', params: { id: search.person_id, group: search.group_id } }"
              >
                <i class="fa fa-fw fa-search"></i>
              </b-link>
            </td>
            <td>{{ search.search_ssn }}</td>
            <td>{{ search.search_email }}</td>
            <td>{{ search.search_tel }}</td>
            <td>
              {{ search.application_name }}
              {{ search.person_name }}
              <b-link
                v-b-tooltip.hover
                title="Skoða"
                v-if="search.application_name && loggedInUserHasAnyPermission('read_application')"
                target="_blank"
                :to="{ name: 'Application', params: { id: search.application_id } }"
              >
                <i class="fa fa-fw fa-search"></i>
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="Skoða"
                v-if="search.person_name && loggedInUserHasAnyPermission('read_person')"
                target="_blank"
                :to="{ name: 'StudentWithGroup', params: { id: search.person_id, group: search.group_id } }"
              >
                <i class="fa fa-fw fa-search"></i>
              </b-link>
            </td>
            <td>
              {{ search.period_name }}
              <div v-if="search.period_part_date_from" style="display: block; font-style: italic; font-size: 10px">
                {{ search.period_part_date_from | moment('DD.MM.YYYY') }} - {{ search.period_part_date_to | moment('DD.MM.YYYY') }}
              </div>
            </td>
            <td v-if="options.search.type === 'Application'">{{ getStatusName(search.application_status) }}</td>
            <td v-else>{{ search.workplace_name }}</td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <pagination
      v-if="pagination && pagination.totalPages > 1"
      :pagination-response="pagination"
      @change-limit="changeLimit"
      @change-page="changePage"
    >
    </pagination>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import core from '@/api/core';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'search',
  components: {
    Pagination,
  },
  mixins: [paginationMixin],
  computed: {
    ...mapGetters(['loggedInUserOptions', 'loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      searchList: [],
      loading: false,
      page: null,
      pagination: null,
      firstTime: true,
      periods: {
        data: [],
        loading: false,
        error: false,
      },
    };
  },
  methods: {
    getType(type) {
      switch (type) {
        case 'application':
          return 'Umsókn';
        case 'application_contact':
          return 'Aðstandandi';
        case 'placement_group_person':
          return 'Þátttakandi';
        case 'placement_group_person_contact':
          return 'Aðstandandi';
        default:
          return '';
      }
    },
    clearFilters() {
      this.options.search = {
        name: '',
        ssn: '',
        email: '',
        tel: '',
        type: 'Person',
        period: '',
      };
      this.loadList();
    },
    getStatusName(status) {
      switch (status) {
        case 'NEW':
          return 'Nýskráð';
        case 'SEMI_ACCEPTED':
          return 'Afgreidd að hluta';
        case 'ACCEPTED':
          return 'Afgreidd';
        case 'REJECTED':
          return 'Hafnað';
        case 'CANCELLED':
          return 'Afskráð';
        case 'IN_PROGRESS':
          return 'Í vinnslu';
        case 'IN_PROGRESS_APPROVED':
          return 'Í vinnslu samþykkt';
        case 'WITHDRAWN':
          return 'Dregin til baka';
        case 'COMPLETED':
          return 'Ráðning';
        default:
          return '';
      }
    },
    clearResults() {
      this.searchList = [];
      this.firstTime = true;
      this.pagination = null;
    },
    async loadList(page = null, limit = null) {
      this.loading = true;
      this.searchList = [];
      try {
        const response = await core.searchList(page, limit, this.options.search);
        this.searchList = response.data;
        this.pagination = parsePagination(response);
        this.firstTime = false;
        await this.setLoggedInUserOption({ option: 'search', value: this.options.search });
        await this.saveLoggedInUserOptions();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadPeriods() {
      this.periods.loading = true;
      this.periods.data = [];
      this.periods.error = false;

      try {
        const response = await this.$coreApi.periodDropdownList({});

        this.periods.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja tímabil. Vinsamlegast reyndu aftur.`);
        this.periods.error = true;
      } finally {
        this.periods.loading = false;
      }
    },
    ...mapActions(['saveLoggedInUserOptions', 'setLoggedInUserOption', 'displayError', 'displaySuccess']),
  },
  created() {
    this.loadPeriods();
    this.options = {};

    this.options.search = {
      name: '',
      ssn: '',
      email: '',
      tel: '',
      type: 'Person',
      period: '',
      ...this.loggedInUserOptions.search,
    };

    if (!this.options.search.type || this.options.search.type === '') {
      this.options.search.type = 'Person';
    }

    if (
      this.options.search.name !== '' ||
      this.options.search.ssn !== '' ||
      this.options.search.email !== '' ||
      this.options.search.tel !== '' ||
      this.options.search.period !== ''
    ) {
      this.loadList();
    }
  },
};
</script>

<style></style>
