
import { PayrollEmployeeH3List, PaymentApiPayrollemployeeh3ListRequest } from '@/api/openapi';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PayrollEmployeesH3Filters from './PayrollEmployeesH3Filters.vue';

// @ts-ignore
@Component({
  components: { PayrollEmployeesH3Filters },
})
export default class PayrollEmployeesH3New extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  @Getter loggedInUserHasAnyPermission: any;

  data: Array<PayrollEmployeeH3List & { isChecked: boolean; isSubmitting: boolean; isCreated: boolean; isError: boolean }> | null = null;
  loading = true;
  submitting = false;

  search: PaymentApiPayrollemployeeh3ListRequest = {
    placementGroup: undefined,
    period: undefined,
    periodPart: undefined,
    workplace: undefined,
    workplaceType: undefined,
    exists: undefined,
  };

  @Watch('search', { deep: true })
  onSearchChanged() {
    this.loadList();
  }

  get allSelected() {
    return (this.data || []).every(a => a.isChecked);
  }

  get someSelected() {
    return (this.data || []).some(a => a.isChecked);
  }

  async loadList() {
    this.loading = true;

    try {
      const response = await this.$paymentApi.payrollemployeeh3List({
        period: this.search.period ? this.search.period : undefined,
        periodPart: this.search.periodPart ? this.search.periodPart : undefined,
        placementGroup: this.search.placementGroup ? this.search.placementGroup : undefined,
        workplace: this.search.workplace ? this.search.workplace : undefined,
        workplaceType: this.search.workplaceType ? this.search.workplaceType : undefined,
        exists: this.search.exists ? this.search.exists : undefined,
      });

      this.data = response.data.map(d => ({ isChecked: false, isSubmitting: false, isCreated: false, isError: false, ...d }));

    } catch (e) {
      this.data = [];
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading = false;
    }
  }

  async createInH3(emp: PayrollEmployeeH3List) {
    if (this.search.period) {
      this.submitting = true;

      try {
        await this.$paymentApi.createInH3({
          period: this.search.period,
          person: emp.id,
        });

        this.displaySuccess(`Starfsmaðurinn „${emp.name}“ hefur verið stofnaður.`);

        this.loadList();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    }
  }

  async rehireInH3(emp: PayrollEmployeeH3List) {
    if (this.search.period) {
      this.submitting = true;

      try {
        await this.$paymentApi.rehireInH3({
          period: this.search.period,
          person: emp.id,
        });

        this.displaySuccess(`Starfsmaðurinn „${emp.name}“ hefur verið endurráðin/n.`);

        this.loadList();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    }
  }

  async runSelected() {
    if (this.data && this.search.period) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i !== this.data.length; i++) {
        const d = this.data[i];
        if (d.isChecked && !d.isCreated && !d.isError) {
          this.data[i].isSubmitting = true;
          try {
            if (d.reusableInH3) {
              // eslint-disable-next-line no-await-in-loop
              await this.$paymentApi.rehireInH3({
                period: this.search.period,
                person: d.id,
              });
            } else {
              // eslint-disable-next-line no-await-in-loop
              await this.$paymentApi.createInH3({
                period: this.search.period,
                person: d.id,
              });
            }
            this.data[i].isCreated = true;
          } catch {
            this.data[i].isError = true;
          } finally {
            this.data[i].isChecked = false;
            this.data[i].isSubmitting = false;
          }
        }
      }
    }
  }

  selectAll() {
    if (this.data) {
      this.$set(
        this,
        'data',
        this.data.map(a => ({
          ...a,
          isChecked: !this.allSelected,
        })),
      );

      // this.event.data.attendees[index].isCreated = value;
    }
  }

  rowChecked(index: number, value: boolean) {
    if (this.data) {
      this.data[index].isChecked = value;
    }
  }
}
