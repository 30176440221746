
import { Action } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';

import { PaymentApiAxiosParamCreator, PayrollEmployeeH3 } from '@/api/openapi';
import PayrollEmployeesH3Filters from './PayrollEmployeesH3Filters.vue';

type Search = {
  placementGroup: string | null;
  period: string | null;
  periodPart: string | null;
  workplace: string | null;
  workplaceType: string | null;
};

// @ts-ignore
@Component({
  components: {
    PayrollEmployeesH3Filters,
  },
})
export default class PayrollEmployeesH3 extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;

  search: Search = {
    placementGroup: null,
    period: null,
    periodPart: null,
    workplace: null,
    workplaceType: null,
  };

  loading = true;
  error = false;
  data: Array<PayrollEmployeeH3> | null = null;

  async load() {
    this.loading = true;
    this.data = null;
    this.error = false;
    try {
      const response = await this.$paymentApi.payrollemployeeh3excelList({
        period: this.search.period ? this.search.period : undefined,
        periodPart: this.search.periodPart ? this.search.periodPart : undefined,
        placementGroup: this.search.placementGroup ? this.search.placementGroup : undefined,
        workplace: this.search.workplace ? this.search.workplace : undefined,
        workplaceType: this.search.workplaceType ? this.search.workplaceType : undefined,
      });

      this.data = response.data;
    } catch (e) {
      this.error = true;
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading = false;
    }
  }

  @Watch('search', { deep: true })
  onSearchChanged() {
    this.load();
  }

  async excel() {
    const xlsx = await PaymentApiAxiosParamCreator()
      .payrollemployeeh3Excel(
        this.search.period ? this.search.period : undefined,
        this.search.periodPart ? this.search.periodPart : undefined,
        this.search.placementGroup ? this.search.placementGroup : undefined,
        this.search.workplace ? this.search.workplace : undefined,
        this.search.workplaceType ? this.search.workplaceType : undefined,
      )
      .catch(e => {
        this.$log.error(e);
      });
    if (!xlsx) {
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      return;
    }
    window.location.href = xlsx.url;
  }

  created() {
    this.load();
  }
}
