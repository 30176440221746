<template>
  <vinnuskoli-page>
    <div>
      <b-row class="mb-3">
        <b-col>
          <div class="page-title mb-0">Flokkun starfsstaða</div>
        </b-col>
        <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_workplacetype')">
          <b-btn variant="primary" @click.prevent="() => createOrUpdate()" class="pull-right"
            ><i class="fa fa-fw fa-plus-circle"></i> Skrá flokk</b-btn
          >
        </b-col>
      </b-row>

      <b-card no-body border-variant="light" class="mb-2">
        <table class="table mb-0">
          <thead>
            <tr class="bg-gray-200 small">
              <th>Nafn</th>
              <th>Virkur</th>
              <th v-if="loggedInUser.town.filterWorkplacesApplication">Aldurstakmörkun</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="$apollo.queries.workplaceTypes.loading">
            <td colspan="100"><loading /></td>
          </tbody>
          <tbody v-if="!$apollo.queries.workplaceTypes.loading && workplaceTypes && workplaceTypes.edges.length === 0">
            <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
          </tbody>
          <tbody v-if="!$apollo.queries.workplaceTypes.loading && workplaceTypes && workplaceTypes.edges.length > 0">
            <tr v-for="workplaceType in workplaceTypes.edges.map(w => w.node)" :key="workplaceType.id">
              <td class="align-middle">{{ workplaceType.name }}</td>
              <td class="align-middle">
                <font-awesome-icon class="text-muted" fixedWidth :icon="workplaceType.enabled ? 'check' : 'times'" />
              </td>
              <td class="align-middle" v-if="loggedInUser.town.filterWorkplacesApplication">
                <span v-if="workplaceType.ageExclusions">
                  {{ workplaceType.ageExclusions.split(',').join(', ') }}
                </span>
                <span v-else> - </span>
              </td>
              <td class="text-right">
                <b-btn
                  :size="'sm'"
                  variant="primary"
                  @click.prevent="createOrUpdate(workplaceType.id)"
                  v-if="loggedInUserHasAnyPermission('write_workplacetype')"
                >
                  <i class="fa fa-fw fa-pencil"></i>
                </b-btn>
                <b-btn :size="'sm'" variant="danger" @click.prevent="deleteObj(workplaceType.id, workplaceType.name)">
                  <i class="fa fa-fw fa-trash"></i>
                </b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
      <template v-if="!$apollo.queries.workplaceTypes.loading">
        <pagination-2
          :dataLength="workplaceTypes.edges.length"
          :totalCount="workplaceTypes.totalCount"
          :currentPage="page"
          :setCurrentPage="setPage"
          :small="true"
          :limit="limit"
          :setLimit="setLimit"
        />
      </template>
    </div>
    <workplace-type-create-modal ref="workplaceTypeCreateModal" @successful="() => $apollo.queries.workplaceTypes.refetch()" />
  </vinnuskoli-page>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies
import { mapGetters, mapActions, mapState } from 'vuex';

import { parseAfter } from '@/utils';
import Pagination2 from '@/components/Pagination2.vue';
import workplaceTypeCreateModal from '@/components/workplace/WorkplaceTypeCreateModal.vue';

const DEFAULT_LIMIT = 25;

export default {
  name: 'workplace-type',
  components: { Pagination2, workplaceTypeCreateModal },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  apollo: {
    workplaceTypes: {
      query: gql`
        query WorkplaceTypes($first: Int, $after: String) {
          workplaceTypes(first: $first, after: $after) {
            totalCount
            edges {
              node {
                id
                databaseId
                ageExclusions
                name
                enabled
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          first: this.limit,
          after: parseAfter(this.page, this.limit),
        };
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  data() {
    return {
      page: parseInt(this.$route.query.page, 10) || 1,
      limit: parseInt(this.$route.query.limit, 10) || DEFAULT_LIMIT,
    };
  },
  methods: {
    setPage(page) {
      this.setSearch(page, this.limit);
    },
    setLimit(limit) {
      this.setSearch(1, limit);
    },
    setSearch(page, limit) {
      const query = {};
      if (page && page > 1) {
        query.page = page;
      }
      if (limit && limit !== DEFAULT_LIMIT) {
        query.limit = limit;
      }
      this.$router.push({ name: 'WorkplaceType', query });
    },
    createOrUpdate(id = null) {
      this.$refs.workplaceTypeCreateModal.show(id);
    },
    async deleteObj(id, name) {
      try {
        const conf = confirm(`Ert þú viss um að þú viljir eyða „${name}“?`); // eslint-disable-line no-restricted-globals, no-alert

        if (conf) {
          await this.$apollo.mutate({
            mutation: gql`
              mutation DeleteWorkplaceType($input: DeleteWorkplaceTypeInput!) {
                deleteWorkplaceType(input: $input) {
                  errors {
                    field
                    messages
                  }
                }
              }
            `,
            variables: {
              input: {
                id,
              },
            },
          });

          this.$apollo.queries.workplaceTypes.refetch();
          this.displaySuccess(`„${name}“ hefur verið eytt.`);
        }
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions(['displayError', 'displaySuccess']),
  },
  beforeRouteUpdate(to, from, next) {
    const page = parseInt(to.query.page, 10) || 1;
    const limit = parseInt(to.query.limit, 10) || DEFAULT_LIMIT;
    this.page = page;
    this.limit = limit;
    next();
  },
};
</script>
