<template>
  <vinnuskoli-page>
    <b-row>
      <b-col>
        <h5 class="page-title">Hópar</h5>
      </b-col>
      <b-col>
        <b-btn
          variant="primary"
          @click.prevent="groupCreateModal()"
          class="pull-right"
          v-if="loggedInUserHasAnyPermission('write_placementgroup')"
        >
          <i class="fa fa-fw fa-plus-circle"></i>
          Bæta við
        </b-btn>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="3">
        <strong>Tímabil</strong>
        <b-form-select id="period" name="period" v-model="options.groups.period" @change="onPeriodChange" @input="loadList()">
          <option :value="null">-- Veldu tímabil --</option>
          <template v-if="!periods.loading && periods.data">
            <option v-for="period in periods.data" :key="period.id" :value="period.id">{{ period.name }}</option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="3">
        <template v-if="!periodParts.loading && periodParts.data.length > 0">
          <strong> Starfstímabil </strong>
          <b-form-select id="periodPart" name="periodPart" v-model="options.groups.periodPart" @input="loadList()">
            <option :value="null">
              <span>-- Veldu starfstímabil --</span>
            </option>
            <option v-for="part in periodParts.data" :key="part.id" :value="part.id">
              {{ part.dateFrom | moment('DD.MM.YYYY') }} - {{ part.dateTo | moment('DD.MM.YYYY') }}
            </option>
          </b-form-select>
        </template>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="3">
        <strong>Hverfi</strong>
        <b-form-select id="district" name="district" v-model="options.groups.district" @input="loadList()">
          <option :value="null">-- Veldu hverfi --</option>
          <template v-if="!workplaceDistricts.loading && workplaceDistricts.data">
            <option v-for="district in workplaceDistricts.data" :key="district.id" :value="district.id">
              {{ district.name }}
            </option>
          </template>
        </b-form-select>
      </b-col>

      <b-col cols="3">
        <strong>Flokkun starfsstaða</strong>
        <b-form-select id="type" name="type" v-model="options.groups.type" @input="loadList()" @change="onWorkplaceChange">
          <option :value="null">-- Veldu flokk --</option>
          <template v-if="!workplaceTypes.loading && workplaceTypes.data">
            <option v-for="type in workplaceTypes.data" :key="type.id" :value="type.id">{{ type.name }}</option>
          </template>
        </b-form-select>
      </b-col>

      <b-col cols="3">
        <strong>Starfsstaður</strong>
        <b-form-select id="workplace" name="workplace" v-model="options.groups.workplace" @input="loadList()">
          <option :value="null">-- Veldu starfsstað --</option>
          <template v-if="!workplaces.loading && workplaces.data">
            <option v-for="workplace in workplaces.data" :key="workplace.id" :value="workplace.id">
              {{ workplace.name }}
            </option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <i
          class="fa fa-recycle fa-2x"
          style="color: #367bb5; float: right"
          v-b-tooltip.hover
          :title="`Hreinsa síur`"
          aria-hidden="true"
          @click="clearFilters()"
        ></i>
      </b-col>
    </b-row>

    <b-card no-body border-variant="light" class="mb-2">
      <div class="m-2" v-if="$apollo.queries.placementGroups.loading"><loading /></div>
      <div class="m-2" v-if="!$apollo.queries.placementGroups.loading && placementGroups && placementGroups.edges.length === 0">
        <i>Engir hópar fundust.</i>
      </div>
      <div v-if="!$apollo.queries.placementGroups.loading && placementGroups && placementGroups.edges.length > 0">
        <table class="table mb-0">
          <thead>
            <tr class="bg-gray-200 small">
              <th>Starfsstaður</th>
              <th>Heiti</th>
              <th>Tímabil</th>
              <th>Hópatímabil</th>
              <th>Fj. leiðb.</th>
              <th>Fj. þáttt.</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="placementGroup in placementGroups.edges.map(a => a.node)" :key="placementGroup.id">
              <td class="align-middle">{{ placementGroup.workplace.name }}</td>
              <td class="align-middle">{{ placementGroup.name }}</td>
              <td class="align-middle">
                {{ placementGroup.period.name }}
                <div class="small" v-if="placementGroup.periodPart">
                  <span class="text-muted">Starfstímabil: </span>
                  {{ placementGroup.periodPart.dateFrom | moment('DD.MM.YYYY') }} -
                  {{ placementGroup.periodPart.dateTo | moment('DD.MM.YYYY') }}
                </div>
              </td>
              <td class="align-middle">
                {{ placementGroup.dateFrom | moment('DD.MM.YYYY') }} - {{ placementGroup.dateTo | moment('DD.MM.YYYY') }}
              </td>
              <td class="align-middle">
                {{ placementGroup.allInstructors.length }}
                <i
                  v-if="placementGroup.allInstructors.length === 0"
                  class="fa fa-exclamation-triangle text-warning"
                  v-b-tooltip.hover
                  title="Ath! Enginn leiðbeinandi er skráður á hópinn"
                >
                </i>
              </td>
              <td class="align-middle">{{ placementGroup.allPersons.length }}</td>
              <td class="align-middle">
                <b-link
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="Skoða"
                  :to="{ name: 'Group', params: { id: placementGroup.databaseId } }"
                  style="padding: 0px 2px"
                >
                  <i class="fa fa-fw fa-search" style="color: #fff"></i>
                </b-link>
                <b-btn
                  variant="dark"
                  v-b-tooltip.hover
                  title="Afrita"
                  @click.prevent="groupCreateModal(placementGroup.databaseId)"
                  style="padding: 0px 2px"
                  class="ml-1"
                >
                  <i class="fa fa-fw fa-copy" style="color: #fff"></i>
                </b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

    <b-row>
      <b-col>
        <div v-if="!$apollo.queries.placementGroups.loading && placementGroups && placementGroups.edges.length === 0">
          <i>Engir hópar fundust.</i>
        </div>
        <template v-if="!$apollo.queries.placementGroups.loading && placementGroups && placementGroups.edges.length > 0"> </template>
        <template v-if="!$apollo.queries.placementGroups.loading && placementGroups && placementGroups.edges.length > 0">
          <pagination-2
            :dataLength="placementGroups.edges.length"
            :totalCount="placementGroups.totalCount"
            :currentPage="page"
            :setCurrentPage="setPage"
            :small="true"
            :limit="limit"
            :setLimit="setLimit"
          />
        </template>
      </b-col>
    </b-row>
    <group-create-modal ref="groupCreateModal" @successful="() => $apollo.queries.placementGroups.refetch()"></group-create-modal>
  </vinnuskoli-page>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies

import { mapActions, mapGetters } from 'vuex';
import GroupCreateModal from '@/components/groups/GroupCreateModal.vue';
import Pagination2 from '@/components/Pagination2.vue';
import { parseAfter, b64EncodeUnicode } from '@/utils';

const DEFAULT_LIMIT = 25;

export default {
  name: 'groups',
  components: {
    GroupCreateModal,
    Pagination2,
  },
  computed: {
    ...mapGetters(['loggedInUserOptions', 'loggedInUserHasAnyPermission']),
  },
  apollo: {
    placementGroups: {
      query: gql`
        query PlacementGroups(
          $first: Int
          $after: String
          $workplaceType: ID
          $workplace: ID
          $workplaceDistrict: ID
          $period: ID
          $periodPart: ID
        ) {
          placementGroups(
            first: $first
            after: $after
            workplaceType: $workplaceType
            workplace: $workplace
            workplaceDistrict: $workplaceDistrict
            period: $period
            periodPart: $periodPart
          ) {
            totalCount
            edges {
              node {
                id
                databaseId
                name

                dateFrom
                dateTo

                # Oracle 12.1 er asnarlegt og leyfir ekki gott count hérna. Laga þegar uppfært.
                # personCount
                # instructorCount

                allInstructors {
                  id
                }

                allPersons {
                  id
                }

                period {
                  id
                  name
                  dateFrom
                  dateTo
                }
                periodPart {
                  id
                  dateFrom
                  dateTo
                }
                workplace {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      variables() {
        const variables = {
          first: this.limit,
          after: parseAfter(this.page, this.limit),
        };

        if (this.options.groups.type) variables.workplaceType = b64EncodeUnicode(`WorkplaceTypeType:${this.options.groups.type}`);
        if (this.options.groups.workplace) variables.workplace = b64EncodeUnicode(`WorkplaceType:${this.options.groups.workplace}`);
        if (this.options.groups.district)
          variables.workplaceDistrict = b64EncodeUnicode(`WorkplaceDistrictType:${this.options.groups.district}`);
        if (this.options.groups.period) variables.period = b64EncodeUnicode(`PeriodType:${this.options.groups.period}`);
        if (this.options.groups.periodPart) variables.periodPart = b64EncodeUnicode(`PeriodPartType:${this.options.groups.periodPart}`);

        return variables;
      },
      skip() {
        return !('groups' in this.options);
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  data() {
    return {
      page: parseInt(this.$route.query.page, 10) || 1,
      limit: parseInt(this.$route.query.limit, 10) || DEFAULT_LIMIT,
      options: {},
      periods: {
        data: [],
        loading: false,
        error: false,
      },
      periodParts: {
        data: [],
        loading: false,
        error: false,
      },
      workplaceTypes: {
        data: [],
        loading: false,
        error: false,
      },
      workplaceDistricts: {
        data: [],
        loading: false,
        error: false,
      },
      workplaces: {
        data: [],
        loading: false,
        error: false,
      },
    };
  },
  methods: {
    setPage(page) {
      this.setSearch(page, this.limit);
    },
    setLimit(limit) {
      this.setSearch(1, limit);
    },
    setSearch(page, limit) {
      this.page = page;
      this.limit = limit;

      // const query = {};
      // if (page && page > 1) {
      //   query.page = page;
      // }
      // if (limit && limit !== DEFAULT_LIMIT) {
      //   query.limit = limit;
      // }
      // this.$router.push({ name: 'Group', query });
    },
    async loadList() {
      await this.setLoggedInUserOption({ option: 'groups', value: this.options.groups });
      await this.saveLoggedInUserOptions();
      this.setSearch(1, this.limit);
    },
    async loadPeriods() {
      this.periods.loading = true;
      this.periods.data = [];
      this.periods.error = false;

      try {
        const periodsResponse = await this.$coreApi.periodDropdownList();

        this.periods.data = periodsResponse.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.periods.error = true;
      } finally {
        this.periods.loading = false;
        this.loadPeriodParts();
      }
    },
    async loadPeriodParts() {
      this.periodParts.loading = true;
      this.periodParts.data = [];
      this.periodParts.error = false;

      try {
        if (this.options.groups.period) {
          const response = await this.$coreApi.periodDropdownPeriodPartDropdownList({
            period: this.options.groups.period,
          });

          this.periodParts.data = response.data;

          if (!this.periodParts.data.map(p => p.id).includes(this.options.groups.periodPart)) {
            this.options.groups.periodPart = null;
          }
        } else {
          this.$set(this.options.groups, 'periodPart', null);
        }
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.periodParts.error = true;
      } finally {
        this.periodParts.loading = false;
      }
    },
    async loadWorkplaceTypes() {
      this.workplaceTypes.loading = true;
      this.workplaceTypes.data = [];
      this.workplaceTypes.error = false;

      try {
        const response = await this.$coreApi.workplaceTypeDropdownList();

        this.workplaceTypes.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.workplaceTypes.error = true;
      } finally {
        this.workplaceTypes.loading = false;
      }
    },
    async loadWorkplaceDistricts() {
      this.workplaceDistricts.loading = true;
      this.workplaceDistricts.data = [];
      this.workplaceDistricts.error = false;

      try {
        const response = await this.$coreApi.workplaceDistrictDropdownList();

        this.workplaceDistricts.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.workplaceDistricts.error = true;
      } finally {
        this.workplaceDistricts.loading = false;
      }
    },
    async loadWorkplaces() {
      this.workplaces.loading = true;
      this.workplaces.data = [];
      this.workplaces.error = false;

      try {
        const response = await this.$coreApi.workplaceDropdownList({
          type: this.options.groups.type || undefined,
        });

        this.workplaces.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.workplaces.error = true;
      } finally {
        this.workplaces.loading = false;
      }
    },
    clearFilters() {
      this.$set(this.options, 'groups', {
        period: null,
        periodPart: null,
        type: null,
        workplace: null,
        district: null,
        opened: [],
      });
      this.loadList();
    },
    notCollapsed(id) {
      return this.options.groups.opened.indexOf(id) > -1;
    },
    onPeriodChange() {
      this.loadPeriodParts();
    },
    onWorkplaceChange() {
      this.loadWorkplaces();
    },
    /*
     * Modal gluggar
     */
    groupCreateModal(groupId) {
      this.$refs.groupCreateModal.show(groupId, true);
    },
    async updateOpen(val) {
      if (val.value && this.options.groups.opened.indexOf(val.id) === -1) {
        this.$set(this.options.groups, 'opened', [...this.options.groups.opened, val.id]);
      } else if (!val.value && this.options.groups.opened.indexOf(val.id) > -1) {
        this.options.groups.opened = this.options.groups.opened.filter(x => x !== val.id);
      }
      await this.setLoggedInUserOption({ option: 'groups', value: this.options.groups });
      await this.saveLoggedInUserOptions();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
      saveLoggedInUserOptions: 'saveLoggedInUserOptions',
      setLoggedInUserOption: 'setLoggedInUserOption',
    }),
  },
  async created() {
    this.options = {};

    this.$set(this.options, 'groups', {
      period: null,
      periodPart: null,
      type: null,
      workplace: null,
      district: null,
      opened: [],
      ...this.loggedInUserOptions.groups,
    });

    if (this.$route.params.group) {
      this.$set(this.options.groups, 'opened', [this.$route.params.group]);
    }

    if (this.$route.params.period) {
      this.$set(this.options.groups, 'period', this.$route.params.period);
    }

    this.loadList();
    this.loadPeriods();
    this.loadWorkplaceTypes();
    this.loadWorkplaceDistricts();
    this.loadWorkplaces();
  },
  // beforeRouteUpdate(to, from, next) {
  //   const page = parseInt(to.query.page, 10) || 1;
  //   const limit = parseInt(to.query.limit, 10) || DEFAULT_LIMIT;
  //   this.page = page;
  //   this.limit = limit;
  //   next();
  // },
};
</script>
