<template>
  <div>
    <b-modal
      id="wageperiodCreateModal"
      :title="title"
      size="lg"
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <div class="alert alert-danger" v-if="errorList.length > 0">
            <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
          </div>
          <b-row>
            <b-col>
              <b-form-group
                label="Dags. frá:"
                label-for="date_from"
                :state="submitted && (errors.has('date_from') || form.datesInValid) ? false : ''"
                :invalid-feedback="errors.first('date_from')"
                description="Upphafsdagsetning"
              >
                <datepicker
                  id="date_from"
                  name="date_from"
                  placeholder="Veldu dags frá"
                  :class="{ 'is-invalid': submitted && (errors.has('date_from') || form.datesInValid) }"
                  v-model="form.date_from"
                  :language="lang"
                  :monday-first="true"
                  format="dd.MM.yyyy"
                  :typeable="false"
                  data-vv-as="dags frá"
                  v-validate="'required'"
                  ref="dateFromPickr"
                  @selected="changeDateTo"
                ></datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Dags. til:"
                label-for="date_to"
                :state="submitted && (errors.has('date_to') || form.datesInValid) ? false : ''"
                :invalid-feedback="errors.first('date_to')"
                description="Lokadagsetning"
              >
                <datepicker
                  id="date_to"
                  name="date_to"
                  placeholder="Veldu dags til"
                  :class="{ 'is-invalid': submitted && (errors.has('date_to') || form.datesInValid) }"
                  v-model="form.date_to"
                  :language="lang"
                  :monday-first="true"
                  format="dd.MM.yyyy"
                  :typeable="false"
                  data-vv-as="dags til"
                  v-validate="'required'"
                  ref="dateToPickr"
                ></datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Launaliður:"
                label-for="wage_element"
                :state="submitted && errors.has('wage_element') ? false : ''"
                :invalid-feedback="errors.first('wage_element')"
              >
                <b-form-input id="wage_element" name="wage_element" type="text" v-model="form.wage_element" maxLength="50"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <table class="table table-no-border">
                <tr>
                  <th>Aldur</th>
                  <th>Tímakaup</th>
                  <th>Launaliður (ef annar)</th>
                </tr>
                <tr v-for="(item, index) in form.ages" :key="index">
                  <td width="22%">
                    <div class="d-inline-block">
                      <b-form-input
                        :id="`age${index}`"
                        :name="`age${index}`"
                        type="number"
                        v-model="item.age"
                        min="1"
                        :class="{ 'is-invalid': submitted && item.backendValidation }"
                        style="width: 60px"
                        v-on:keydown.enter="addAge"
                      ></b-form-input>
                    </div>
                    <div class="d-inline-block ml-1" v-if="getYear(item.age)">({{ getYear(item.age) }})</div>
                    <div v-if="item.backendValidation" class="text-danger">
                      {{ item.backendValidation }}
                    </div>
                  </td>
                  <td width="22%">
                    <div class="d-inline-block">
                      <b-form-input
                        :id="`wageAge${index}`"
                        :name="`wageAge${index}`"
                        type="number"
                        v-model="item.wage"
                        min="0"
                        :class="{ 'is-invalid': submitted && item.backendValidation }"
                        style="width: 100px"
                        v-on:keydown.enter="addAge"
                      ></b-form-input>
                    </div>
                    <div class="d-inline-block ml-1">kr.</div>
                  </td>
                  <td width="22%">
                    <div class="d-inline-block">
                      <b-form-input
                        :id="`wage_element${index}`"
                        :name="`wage_element${index}`"
                        type="text"
                        v-model="item.wage_element"
                        :class="{ 'is-invalid': submitted && item.backendValidation }"
                        style="width: 150px"
                        v-on:keydown.enter="addAge"
                      ></b-form-input>
                    </div>
                  </td>
                  <td width="10%">
                    <b-button size="sm" variant="danger" class="pull-right" @click="removeAge(index)">
                      <i class="fa fa-fw fa-trash"></i>
                    </b-button>
                  </td>
                </tr>
              </table>

              <b-button size="sm" @click="addAge">
                <i class="fa fa-fw fa-plus-circle"></i>
                Bæta við
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import { djangoErrorsToVee } from '@/utils';
import payment from '@/api/payment';

export default {
  name: 'wageperiod-create-modal',
  components: {
    Datepicker,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      periodId: '',
      title: 'Stofna launataxta',
      form: {
        date_from: '',
        date_to: '',
        datesInValid: false,
        wage_element: '',
        ages: [{ age: '', wage: '', wage_element: '', backendValidation: null }],
      },
      lang: is,
    };
  },
  mounted() {
    this.$refs.dateFromPickr.$el.querySelector('input').autocomplete = 'off';
    this.$refs.dateToPickr.$el.querySelector('input').autocomplete = 'off';
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const response = await payment.wagePeriodDetail(this.periodId);
        this.form.date_from = moment(response.data.date_from).toDate();
        this.form.date_to = moment(response.data.date_to).toDate();
        this.form.ages = response.data.ages;
        this.form.wage_element = response.data.wage_element;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    validateDates() {
      let valid = true;
      const dateFrom = moment(this.form.date_from);
      const dateTo = moment(this.form.date_to);
      if (dateTo.isBefore(dateFrom)) {
        this.form.datesInValid = true;
        valid = false;
      } else {
        this.form.datesInValid = false;
      }
      return valid;
    },
    changeDateTo(val) {
      if (this.form.date_to === '') {
        this.form.date_to = val;
      }
    },
    addAge() {
      this.form.ages.push({
        age: '',
        wage: '',
        wage_element: '',
        backendValidation: null,
      });
    },
    removeAge(index) {
      this.form.ages.splice(index, 1);
    },
    getYear(age) {
      if (age && this.form.date_to) {
        const born = moment(this.form.date_to).subtract(age, 'year');
        return born.format('YYYY');
      }
      return '';
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response || this.validateDates() === false) {
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });

        // Engar villur í formi.
        try {
          this.loading = true;

          if (this.periodId) {
            await payment.wagePeriodUpdate(this.periodId, {
              date_from: moment(this.form.date_from).format('YYYY-MM-DD'),
              date_to: moment(this.form.date_to).format('YYYY-MM-DD'),
              wage_element: this.form.wage_element,
              ages: this.form.ages,
            });
            this.displaySuccess('Launataxta hefur verið breytt.');
          } else {
            await payment.wagePeriodCreate({
              date_from: moment(this.form.date_from).format('YYYY-MM-DD'),
              date_to: moment(this.form.date_to).format('YYYY-MM-DD'),
              wage_element: this.form.wage_element,
              ages: this.form.ages,
            });

            this.displaySuccess('Launataxti hefur verið stofnaður');
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);

          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            if (e.response.data.ages) {
              this.form.ages.forEach((i, index) => {
                if (e.response.data.ages[index] && e.response.data.ages[index].length > 0) {
                  i.backendValidation = e.response.data.ages[index]; // eslint-disable-line no-param-reassign, max-len
                }
              });
            }
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
      }
    },
    show(periodId) {
      this.form.date_from = '';
      this.form.date_to = '';
      this.form.wage_element = '';
      this.form.ages = [{ age: '', wage: '', wage_element: '' }];
      this.form.datesInValid = false;
      this.submitted = false;
      this.loading = false;
      this.periodId = null;
      this.title = 'Skrá launataxta';
      if (periodId) {
        this.title = 'Breyta launataxta';
        this.periodId = periodId;
        this.load();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss">
.table.table-no-border {
  tr {
    td,
    th {
      border-color: transparent;
    }
  }
}
</style>
