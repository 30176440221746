/* tslint:disable */
/* eslint-disable */
/**
 * Vinnuskóli vefur
 * Vinnuskóli vefþjónustur fyrir vef
 *
 * The version of the OpenAPI document: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplicationBirthYear
 */
export interface ApplicationBirthYear {
    /**
     * 
     * @type {number}
     * @memberof ApplicationBirthYear
     */
    'birthYear': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBirthYear
     */
    'birthYearReal': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBirthYear
     */
    'birthYearOverridden': boolean;
}
/**
 * Færa umsókn um stöðu
 * @export
 * @interface ApplicationChangeStatusRequest
 */
export interface ApplicationChangeStatusRequest {
    /**
     * 
     * @type {ApplicationStatusEnum}
     * @memberof ApplicationChangeStatusRequest
     */
    'status': ApplicationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationChangeStatusRequest
     */
    'workplace'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationContact
 */
export interface ApplicationContact {
    /**
     * 
     * @type {string}
     * @memberof ApplicationContact
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContact
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContact
     */
    'tel': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContact
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ApplicationDetail
 */
export interface ApplicationDetail {
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'tel': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'specialNeeds': string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'other': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'otherWorkplace': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'resolutionComment': string | null;
    /**
     * 
     * @type {ApplicationBirthYear}
     * @memberof ApplicationDetail
     */
    'birthYear': ApplicationBirthYear;
    /**
     * 
     * @type {ApplicationStatusEnum}
     * @memberof ApplicationDetail
     */
    'status': ApplicationStatusEnum;
    /**
     * 
     * @type {ApplicationPeriod}
     * @memberof ApplicationDetail
     */
    'period': ApplicationPeriod;
    /**
     * 
     * @type {Array<ApplicationPeriodPart>}
     * @memberof ApplicationDetail
     */
    'periodParts': Array<ApplicationPeriodPart>;
    /**
     * 
     * @type {Array<ApplicationWorkplace>}
     * @memberof ApplicationDetail
     */
    'workplaces': Array<ApplicationWorkplace>;
    /**
     * 
     * @type {ApplicationWorkplaceItem}
     * @memberof ApplicationDetail
     */
    'workplaceInProgress': ApplicationWorkplaceItem;
    /**
     * 
     * @type {Array<ApplicationContact>}
     * @memberof ApplicationDetail
     */
    'contacts': Array<ApplicationContact>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDetail
     */
    'dateCreated': string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationDetail
     */
    'workplacesCount': number;
}
/**
 * 
 * @export
 * @interface ApplicationList
 */
export interface ApplicationList {
    /**
     * 
     * @type {string}
     * @memberof ApplicationList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationList
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationList
     */
    'name': string;
    /**
     * 
     * @type {ApplicationBirthYear}
     * @memberof ApplicationList
     */
    'birthYear': ApplicationBirthYear;
    /**
     * 
     * @type {string}
     * @memberof ApplicationList
     */
    'email': string;
    /**
     * 
     * @type {ApplicationStatusEnum}
     * @memberof ApplicationList
     */
    'status': ApplicationStatusEnum;
    /**
     * 
     * @type {ApplicationPeriod}
     * @memberof ApplicationList
     */
    'period': ApplicationPeriod;
    /**
     * 
     * @type {Array<ApplicationPeriodPart>}
     * @memberof ApplicationList
     */
    'periodParts': Array<ApplicationPeriodPart>;
    /**
     * 
     * @type {Array<ApplicationWorkplace>}
     * @memberof ApplicationList
     */
    'workplaces': Array<ApplicationWorkplace>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationList
     */
    'dateCreated': string;
    /**
     * 
     * @type {ApplicationWorkplaceItem}
     * @memberof ApplicationList
     */
    'workplaceInProgress': ApplicationWorkplaceItem;
    /**
     * 
     * @type {number}
     * @memberof ApplicationList
     */
    'workplacesCount': number;
}
/**
 * 
 * @export
 * @interface ApplicationPeriod
 */
export interface ApplicationPeriod {
    /**
     * 
     * @type {string}
     * @memberof ApplicationPeriod
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPeriod
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPeriod
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPeriod
     */
    'dateTo': string;
}
/**
 * 
 * @export
 * @interface ApplicationPeriodPart
 */
export interface ApplicationPeriodPart {
    /**
     * 
     * @type {string}
     * @memberof ApplicationPeriodPart
     */
    'id': string;
    /**
     * 
     * @type {ApplicationPeriodPartItem}
     * @memberof ApplicationPeriodPart
     */
    'periodPart': ApplicationPeriodPartItem;
    /**
     * 
     * @type {ApplicationPeriodPartStatusEnum}
     * @memberof ApplicationPeriodPart
     */
    'status'?: ApplicationPeriodPartStatusEnum;
}
/**
 * 
 * @export
 * @interface ApplicationPeriodPartItem
 */
export interface ApplicationPeriodPartItem {
    /**
     * 
     * @type {string}
     * @memberof ApplicationPeriodPartItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPeriodPartItem
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPeriodPartItem
     */
    'dateTo': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationPeriodPartStatusEnum = {
    New: 'NEW',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;

export type ApplicationPeriodPartStatusEnum = typeof ApplicationPeriodPartStatusEnum[keyof typeof ApplicationPeriodPartStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationStatusEnum = {
    New: 'NEW',
    InProgress: 'IN_PROGRESS',
    InProgressApproved: 'IN_PROGRESS_APPROVED',
    SemiAccepted: 'SEMI_ACCEPTED',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Cancelled: 'CANCELLED',
    Withdrawn: 'WITHDRAWN',
    Completed: 'COMPLETED'
} as const;

export type ApplicationStatusEnum = typeof ApplicationStatusEnum[keyof typeof ApplicationStatusEnum];


/**
 * 
 * @export
 * @interface ApplicationWorkplace
 */
export interface ApplicationWorkplace {
    /**
     * 
     * @type {string}
     * @memberof ApplicationWorkplace
     */
    'id': string;
    /**
     * 
     * @type {ApplicationWorkplaceItem}
     * @memberof ApplicationWorkplace
     */
    'workplace': ApplicationWorkplaceItem;
    /**
     * 
     * @type {number}
     * @memberof ApplicationWorkplace
     */
    'priority'?: number;
}
/**
 * 
 * @export
 * @interface ApplicationWorkplaceItem
 */
export interface ApplicationWorkplaceItem {
    /**
     * 
     * @type {string}
     * @memberof ApplicationWorkplaceItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationWorkplaceItem
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AttendanceSummary
 */
export interface AttendanceSummary {
    /**
     * 
     * @type {number}
     * @memberof AttendanceSummary
     */
    'hours': number;
    /**
     * 
     * @type {string}
     * @memberof AttendanceSummary
     */
    'dateMax': string | null;
    /**
     * 
     * @type {string}
     * @memberof AttendanceSummary
     */
    'dateMin': string | null;
}
/**
 * Leiðrétting
 * @export
 * @interface BatchCorrectionRelated
 */
export interface BatchCorrectionRelated {
    /**
     * 
     * @type {string}
     * @memberof BatchCorrectionRelated
     */
    'id': string;
}
/**
 * Leiðrétting
 * @export
 * @interface BatchCorrectionRelatedRequest
 */
export interface BatchCorrectionRelatedRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchCorrectionRelatedRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CreateExtraBatch
 */
export interface CreateExtraBatch {
    /**
     * 
     * @type {string}
     * @memberof CreateExtraBatch
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CreateExtraBatchRequest
 */
export interface CreateExtraBatchRequest {
    /**
     * 
     * @type {PaymentPeriodRelatedRequest}
     * @memberof CreateExtraBatchRequest
     */
    'paymentPeriod': PaymentPeriodRelatedRequest;
    /**
     * 
     * @type {Array<BatchCorrectionRelatedRequest>}
     * @memberof CreateExtraBatchRequest
     */
    'corrections': Array<BatchCorrectionRelatedRequest>;
}
/**
 * Hjálp
 * @export
 * @interface HelpCategory
 */
export interface HelpCategory {
    /**
     * 
     * @type {string}
     * @memberof HelpCategory
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HelpCategory
     */
    'name': string;
    /**
     * 
     * @type {Array<HelpDocumentRelated>}
     * @memberof HelpCategory
     */
    'documents': Array<HelpDocumentRelated>;
    /**
     * 
     * @type {boolean}
     * @memberof HelpCategory
     */
    'isTown': boolean;
}
/**
 * Hjálp
 * @export
 * @interface HelpCategoryRequest
 */
export interface HelpCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof HelpCategoryRequest
     */
    'name': string;
}
/**
 * Hjálp
 * @export
 * @interface HelpDocumentRelated
 */
export interface HelpDocumentRelated {
    /**
     * 
     * @type {string}
     * @memberof HelpDocumentRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HelpDocumentRelated
     */
    'name': string;
}
/**
 * Hjálp
 * @export
 * @interface HelpDocumentUpload
 */
export interface HelpDocumentUpload {
    /**
     * 
     * @type {string}
     * @memberof HelpDocumentUpload
     */
    'id': string;
}
/**
 * Innskráður notandandi
 * @export
 * @interface MyAccount
 */
export interface MyAccount {
    /**
     * 
     * @type {string}
     * @memberof MyAccount
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MyAccount
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof MyAccount
     */
    'name': string;
    /**
     * Til marks um að notandinn hafi öll réttindi án þess að taka þau sérstaklega fram.
     * @type {boolean}
     * @memberof MyAccount
     */
    'isSuperuser': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MyAccount
     */
    'permissions': Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof MyAccount
     */
    'options': { [key: string]: any; } | null;
    /**
     * 
     * @type {MyAccountTown}
     * @memberof MyAccount
     */
    'town': MyAccountTown | null;
    /**
     * 
     * @type {boolean}
     * @memberof MyAccount
     */
    'isOnBehalf': boolean;
}
/**
 * 
 * @export
 * @interface MyAccountTown
 */
export interface MyAccountTown {
    /**
     * 
     * @type {string}
     * @memberof MyAccountTown
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MyAccountTown
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof MyAccountTown
     */
    'numWorkplacesApplication': number;
    /**
     * 
     * @type {boolean}
     * @memberof MyAccountTown
     */
    'prioritizeWorkplacesApplication': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyAccountTown
     */
    'filterWorkplacesApplication': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyAccountTown
     */
    'hasKjarni': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyAccountTown
     */
    'hasH3': boolean;
}
/**
 * Hjálp
 * @export
 * @interface PatchedHelpCategoryRequest
 */
export interface PatchedHelpCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedHelpCategoryRequest
     */
    'name'?: string;
}
/**
 * Kvarði á frammistöðumati
 * @export
 * @interface PatchedReviewQuestionDetailUpdateRequest
 */
export interface PatchedReviewQuestionDetailUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedReviewQuestionDetailUpdateRequest
     */
    'question'?: string;
}
/**
 * Notandi á sveitarfélagi
 * @export
 * @interface PatchedUserDetailRequest
 */
export interface PatchedUserDetailRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUserDetailRequest
     */
    'isActive'?: boolean;
}
/**
 * Launatímabil
 * @export
 * @interface PaymentPeriodRelated
 */
export interface PaymentPeriodRelated {
    /**
     * 
     * @type {string}
     * @memberof PaymentPeriodRelated
     */
    'id': string;
}
/**
 * Launatímabil
 * @export
 * @interface PaymentPeriodRelatedRequest
 */
export interface PaymentPeriodRelatedRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentPeriodRelatedRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface PayrollEmployeeH3
 */
export interface PayrollEmployeeH3 {
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'employeecode': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'mainjob': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'jobnamecode': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'jobcategorycode': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'departmentcode': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'projectcode': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'workpct': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'fulljobhrs': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'unionconcode': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'conlevelname': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'constepname': string;
    /**
     * 
     * @type {number}
     * @memberof PayrollEmployeeH3
     */
    'hrsprholihr': number | null;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'holidaypct1': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'holidaypct2': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'calcholiday': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'workfirststarted': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'hiretypecode': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'workended': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'workstatus': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3
     */
    'status': string;
}
/**
 * Launamaður í H3
 * @export
 * @interface PayrollEmployeeH3List
 */
export interface PayrollEmployeeH3List {
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3List
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3List
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3List
     */
    'ssn': string;
    /**
     * 
     * @type {boolean}
     * @memberof PayrollEmployeeH3List
     */
    'existsInH3': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PayrollEmployeeH3List
     */
    'reusableInH3': boolean;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3List
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3List
     */
    'tel': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3List
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3List
     */
    'minDateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeH3List
     */
    'dateSentToH3': string | null;
}
/**
 * Launamaður í Kjarna
 * @export
 * @interface PayrollEmployeeKjarni
 */
export interface PayrollEmployeeKjarni {
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'ssn': string;
    /**
     * 
     * @type {boolean}
     * @memberof PayrollEmployeeKjarni
     */
    'existsInKjarni': boolean;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'tel': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'contactName': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'contactTel': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'minDateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof PayrollEmployeeKjarni
     */
    'dateSentToKjarni': string | null;
}
/**
 * 
 * @export
 * @interface PeriodAgeDetail
 */
export interface PeriodAgeDetail {
    /**
     * 
     * @type {string}
     * @memberof PeriodAgeDetail
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PeriodAgeDetail
     */
    'age': number;
    /**
     * 
     * @type {string}
     * @memberof PeriodAgeDetail
     */
    'maxHours': string | null;
}
/**
 * Tímabil
 * @export
 * @interface PeriodDetail
 */
export interface PeriodDetail {
    /**
     * 
     * @type {string}
     * @memberof PeriodDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDetail
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDetail
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDetail
     */
    'dateTo': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDetail
     */
    'applyInfo': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodDetail
     */
    'openForApplication': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodDetail
     */
    'hasParts': boolean;
    /**
     * 
     * @type {number}
     * @memberof PeriodDetail
     */
    'numPartsApplication': number;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodDetail
     */
    'ageExclusionParts': boolean;
    /**
     * 
     * @type {Array<PeriodPartDetail>}
     * @memberof PeriodDetail
     */
    'parts': Array<PeriodPartDetail>;
    /**
     * 
     * @type {Array<PeriodAgeDetail>}
     * @memberof PeriodDetail
     */
    'ages': Array<PeriodAgeDetail>;
    /**
     * 
     * @type {number}
     * @memberof PeriodDetail
     */
    'groupCount': number;
    /**
     * 
     * @type {string}
     * @memberof PeriodDetail
     */
    'dateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDetail
     */
    'dateUpdated': string;
}
/**
 * Tímabil
 * @export
 * @interface PeriodDropdown
 */
export interface PeriodDropdown {
    /**
     * 
     * @type {string}
     * @memberof PeriodDropdown
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDropdown
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDropdown
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDropdown
     */
    'dateTo': string;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodDropdown
     */
    'hasParts': boolean;
}
/**
 * Tímabil
 * @export
 * @interface PeriodList
 */
export interface PeriodList {
    /**
     * 
     * @type {string}
     * @memberof PeriodList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodList
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodList
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodList
     */
    'dateTo': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodList
     */
    'applyInfo': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodList
     */
    'openForApplication': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodList
     */
    'hasParts': boolean;
    /**
     * 
     * @type {number}
     * @memberof PeriodList
     */
    'numPartsApplication': number;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodList
     */
    'ageExclusionParts': boolean;
    /**
     * 
     * @type {Array<PeriodPartDetail>}
     * @memberof PeriodList
     */
    'parts': Array<PeriodPartDetail>;
    /**
     * 
     * @type {number}
     * @memberof PeriodList
     */
    'vacationCount': number;
    /**
     * 
     * @type {string}
     * @memberof PeriodList
     */
    'dateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodList
     */
    'dateUpdated': string;
}
/**
 * Partar af tímabili
 * @export
 * @interface PeriodPartDetail
 */
export interface PeriodPartDetail {
    /**
     * 
     * @type {string}
     * @memberof PeriodPartDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodPartDetail
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodPartDetail
     */
    'dateTo': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodPartDetail
     */
    'ageExclusion': string | null;
}
/**
 * Partar af tímabili
 * @export
 * @interface PeriodPartDropdown
 */
export interface PeriodPartDropdown {
    /**
     * 
     * @type {string}
     * @memberof PeriodPartDropdown
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodPartDropdown
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodPartDropdown
     */
    'dateTo': string;
}
/**
 * Partar af tímabili
 * @export
 * @interface PeriodPartList
 */
export interface PeriodPartList {
    /**
     * 
     * @type {string}
     * @memberof PeriodPartList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodPartList
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodPartList
     */
    'dateTo': string;
}
/**
 * Partar af tímabili
 * @export
 * @interface PeriodPartRelated
 */
export interface PeriodPartRelated {
    /**
     * 
     * @type {string}
     * @memberof PeriodPartRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodPartRelated
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodPartRelated
     */
    'dateTo': string;
}
/**
 * Tímabil
 * @export
 * @interface PeriodRelated
 */
export interface PeriodRelated {
    /**
     * 
     * @type {string}
     * @memberof PeriodRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodRelated
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodRelated
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodRelated
     */
    'dateTo': string;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodRelated
     */
    'hasParts': boolean;
}
/**
 * Tímabil
 * @export
 * @interface PeriodRelatedRequest
 */
export interface PeriodRelatedRequest {
    /**
     * 
     * @type {string}
     * @memberof PeriodRelatedRequest
     */
    'id': string;
}
/**
 * Persóna
 * @export
 * @interface PersonRelated
 */
export interface PersonRelated {
    /**
     * 
     * @type {string}
     * @memberof PersonRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRelated
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRelated
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof PersonRelated
     */
    'nameLocked': boolean;
    /**
     * 
     * @type {string}
     * @memberof PersonRelated
     */
    'legalName': string;
}
/**
 * 
 * @export
 * @interface PersonUpdateNameRequest
 */
export interface PersonUpdateNameRequest {
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateNameRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof PersonUpdateNameRequest
     */
    'nameLocked': boolean;
}
/**
 * Takmörkun á fæðingarári á hóp
 * @export
 * @interface PlacementGroupBirthYearList
 */
export interface PlacementGroupBirthYearList {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupBirthYearList
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PlacementGroupBirthYearList
     */
    'birthYear': number;
}
/**
 * Hópur.
 * @export
 * @interface PlacementGroupDetail
 */
export interface PlacementGroupDetail {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDetail
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDetail
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDetail
     */
    'dateTo': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDetail
     */
    'maxHours': string | null;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDetail
     */
    'timeFrom': string | null;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDetail
     */
    'timeTo': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupDetail
     */
    'monday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupDetail
     */
    'tuesday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupDetail
     */
    'wednesday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupDetail
     */
    'thursday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupDetail
     */
    'friday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupDetail
     */
    'saturday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupDetail
     */
    'sunday': boolean;
    /**
     * 
     * @type {WorkplaceRelated}
     * @memberof PlacementGroupDetail
     */
    'workplace': WorkplaceRelated;
    /**
     * 
     * @type {PeriodRelated}
     * @memberof PlacementGroupDetail
     */
    'period': PeriodRelated;
    /**
     * 
     * @type {PeriodPartRelated}
     * @memberof PlacementGroupDetail
     */
    'periodPart': PeriodPartRelated | null;
}
/**
 * Dagbók
 * @export
 * @interface PlacementGroupDiaryDetail
 */
export interface PlacementGroupDiaryDetail {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDiaryDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDiaryDetail
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDiaryDetail
     */
    'text'?: string;
}
/**
 * Dagbók
 * @export
 * @interface PlacementGroupDiaryDetailRequest
 */
export interface PlacementGroupDiaryDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDiaryDetailRequest
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDiaryDetailRequest
     */
    'text'?: string;
}
/**
 * Dagbók
 * @export
 * @interface PlacementGroupDiaryList
 */
export interface PlacementGroupDiaryList {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDiaryList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDiaryList
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupDiaryList
     */
    'text': string;
}
/**
 * Leiðbeinandi á hóp
 * @export
 * @interface PlacementGroupInstructorList
 */
export interface PlacementGroupInstructorList {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupInstructorList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupInstructorList
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupInstructorList
     */
    'userId': string;
}
/**
 * Hópur.
 * @export
 * @interface PlacementGroupList
 */
export interface PlacementGroupList {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupList
     */
    'id': string;
}
/**
 * Aðstandendur
 * @export
 * @interface PlacementGroupPersonContact
 */
export interface PlacementGroupPersonContact {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContact
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContact
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContact
     */
    'tel': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContact
     */
    'email': string;
    /**
     * 
     * @type {PlacementGroupPersonRelated}
     * @memberof PlacementGroupPersonContact
     */
    'placementGroupPerson': PlacementGroupPersonRelated;
}
/**
 * Aðstandendur
 * @export
 * @interface PlacementGroupPersonContactRelated
 */
export interface PlacementGroupPersonContactRelated {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContactRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContactRelated
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContactRelated
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContactRelated
     */
    'tel': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonContactRelated
     */
    'email': string;
}
/**
 * Nemandi í hóp
 * @export
 * @interface PlacementGroupPersonDetail
 */
export interface PlacementGroupPersonDetail {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'tel': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'specialNeeds': string | null;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'review': string | null;
    /**
     * 
     * @type {Array<PlacementGroupPersonReviewQuestionRelated>}
     * @memberof PlacementGroupPersonDetail
     */
    'reviewQuestions': Array<PlacementGroupPersonReviewQuestionRelated>;
    /**
     * 
     * @type {PersonRelated}
     * @memberof PlacementGroupPersonDetail
     */
    'person': PersonRelated;
    /**
     * 
     * @type {PlacementGroupRelated}
     * @memberof PlacementGroupPersonDetail
     */
    'placementGroup': PlacementGroupRelated;
    /**
     * 
     * @type {Array<PlacementGroupPersonContactRelated>}
     * @memberof PlacementGroupPersonDetail
     */
    'contacts': Array<PlacementGroupPersonContactRelated>;
    /**
     * 
     * @type {number}
     * @memberof PlacementGroupPersonDetail
     */
    'birthYear': number;
    /**
     * 
     * @type {number}
     * @memberof PlacementGroupPersonDetail
     */
    'birthYearReal': number;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupPersonDetail
     */
    'birthYearOverridden': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'dateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'dateUpdated': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupPersonDetail
     */
    'isMoved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupPersonDetail
     */
    'isRemoved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'dateFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'dateTo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlacementGroupPersonDetail
     */
    'placementGroupHoursTotal': number | null;
    /**
     * 
     * @type {number}
     * @memberof PlacementGroupPersonDetail
     */
    'periodHoursTotal': number | null;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonDetail
     */
    'periodMaxHours': string | null;
}
/**
 * Nemandi í hóp
 * @export
 * @interface PlacementGroupPersonList
 */
export interface PlacementGroupPersonList {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonList
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonList
     */
    'tel': string;
    /**
     * 
     * @type {PersonRelated}
     * @memberof PlacementGroupPersonList
     */
    'person': PersonRelated;
    /**
     * 
     * @type {PlacementGroupRelated}
     * @memberof PlacementGroupPersonList
     */
    'placementGroup': PlacementGroupRelated;
    /**
     * 
     * @type {number}
     * @memberof PlacementGroupPersonList
     */
    'birthYear': number;
    /**
     * 
     * @type {number}
     * @memberof PlacementGroupPersonList
     */
    'birthYearReal': number;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupPersonList
     */
    'birthYearOverridden': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupPersonList
     */
    'isMoved': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacementGroupPersonList
     */
    'isRemoved': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonList
     */
    'dateFrom': string | null;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonList
     */
    'dateTo': string | null;
}
/**
 * Þátttakandi
 * @export
 * @interface PlacementGroupPersonRelated
 */
export interface PlacementGroupPersonRelated {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonRelated
     */
    'id': string;
    /**
     * 
     * @type {PersonRelated}
     * @memberof PlacementGroupPersonRelated
     */
    'person': PersonRelated;
    /**
     * 
     * @type {PlacementGroupRelated}
     * @memberof PlacementGroupPersonRelated
     */
    'placementGroup': PlacementGroupRelated;
}
/**
 * 
 * @export
 * @interface PlacementGroupPersonReviewQuestionRelated
 */
export interface PlacementGroupPersonReviewQuestionRelated {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonReviewQuestionRelated
     */
    'id': string;
    /**
     * 
     * @type {ReviewQuestionRelated}
     * @memberof PlacementGroupPersonReviewQuestionRelated
     */
    'reviewQuestion': ReviewQuestionRelated;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonReviewQuestionRelated
     */
    'rating': string;
}
/**
 * 
 * @export
 * @interface PlacementGroupPersonReviewQuestionRelatedRequest
 */
export interface PlacementGroupPersonReviewQuestionRelatedRequest {
    /**
     * 
     * @type {ReviewQuestionRelatedRequest}
     * @memberof PlacementGroupPersonReviewQuestionRelatedRequest
     */
    'reviewQuestion': ReviewQuestionRelatedRequest;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonReviewQuestionRelatedRequest
     */
    'rating': string;
}
/**
 * 
 * @export
 * @interface PlacementGroupPersonReviewRequest
 */
export interface PlacementGroupPersonReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupPersonReviewRequest
     */
    'review'?: string | null;
    /**
     * 
     * @type {Array<PlacementGroupPersonReviewQuestionRelatedRequest>}
     * @memberof PlacementGroupPersonReviewRequest
     */
    'reviewQuestions': Array<PlacementGroupPersonReviewQuestionRelatedRequest>;
}
/**
 * Hópur
 * @export
 * @interface PlacementGroupRelated
 */
export interface PlacementGroupRelated {
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupRelated
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupRelated
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PlacementGroupRelated
     */
    'dateTo': string;
    /**
     * 
     * @type {WorkplaceRelated}
     * @memberof PlacementGroupRelated
     */
    'workplace': WorkplaceRelated;
    /**
     * 
     * @type {PeriodRelated}
     * @memberof PlacementGroupRelated
     */
    'period': PeriodRelated;
    /**
     * 
     * @type {PeriodPartRelated}
     * @memberof PlacementGroupRelated
     */
    'periodPart': PeriodPartRelated;
}
/**
 * Kvarði á frammistöðumati
 * @export
 * @interface ReviewQuestionDetail
 */
export interface ReviewQuestionDetail {
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionDetail
     */
    'question': string;
    /**
     * 
     * @type {PeriodRelated}
     * @memberof ReviewQuestionDetail
     */
    'period': PeriodRelated;
}
/**
 * Kvarði á frammistöðumati
 * @export
 * @interface ReviewQuestionDetailRequest
 */
export interface ReviewQuestionDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionDetailRequest
     */
    'question': string;
    /**
     * 
     * @type {PeriodRelatedRequest}
     * @memberof ReviewQuestionDetailRequest
     */
    'period': PeriodRelatedRequest;
}
/**
 * Kvarði á frammistöðumati
 * @export
 * @interface ReviewQuestionDetailUpdate
 */
export interface ReviewQuestionDetailUpdate {
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionDetailUpdate
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionDetailUpdate
     */
    'question': string;
    /**
     * 
     * @type {PeriodRelated}
     * @memberof ReviewQuestionDetailUpdate
     */
    'period': PeriodRelated;
}
/**
 * Kvarði á frammistöðumati
 * @export
 * @interface ReviewQuestionDetailUpdateRequest
 */
export interface ReviewQuestionDetailUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionDetailUpdateRequest
     */
    'question': string;
}
/**
 * Kvarði á frammistöðumati - Fellilisti
 * @export
 * @interface ReviewQuestionDropdown
 */
export interface ReviewQuestionDropdown {
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionDropdown
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionDropdown
     */
    'question': string;
    /**
     * 
     * @type {PeriodRelated}
     * @memberof ReviewQuestionDropdown
     */
    'period': PeriodRelated;
}
/**
 * Kvarði á frammistöðumati
 * @export
 * @interface ReviewQuestionList
 */
export interface ReviewQuestionList {
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionList
     */
    'question': string;
    /**
     * 
     * @type {PeriodRelated}
     * @memberof ReviewQuestionList
     */
    'period': PeriodRelated;
}
/**
 * Kvarði á frammistöðumati
 * @export
 * @interface ReviewQuestionRelated
 */
export interface ReviewQuestionRelated {
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionRelated
     */
    'question': string;
}
/**
 * Kvarði á frammistöðumati
 * @export
 * @interface ReviewQuestionRelatedRequest
 */
export interface ReviewQuestionRelatedRequest {
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionRelatedRequest
     */
    'id': string;
}
/**
 * Default serializer sem yfirskrifar update og create virkni í DRF.
 * @export
 * @interface StatisticsSummary
 */
export interface StatisticsSummary {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatisticsSummary
     */
    'applicationBirthYears': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatisticsSummary
     */
    'applicationStatuses': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatisticsSummary
     */
    'personBirthYears': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatisticsSummary
     */
    'personWorkplaces': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatisticsSummary
     */
    'personDistricts': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatisticsSummary
     */
    'attendanceBirthYears': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatisticsSummary
     */
    'attendanceWorkplaces': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatisticsSummary
     */
    'attendanceDistricts': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof StatisticsSummary
     */
    'attendanceTypeBirthYears': { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof StatisticsSummary
     */
    'attendanceTypeWorkplaces': { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof StatisticsSummary
     */
    'attendanceTypeDistricts': { [key: string]: { [key: string]: number; }; };
}
/**
 * 
 * @export
 * @interface Town
 */
export interface Town {
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    'number': string | null;
}
/**
 * Notandi á sveitarfélagi
 * @export
 * @interface UserDetail
 */
export interface UserDetail {
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetail
     */
    'isActive': boolean;
}
/**
 * Notandi á sveitarfélagi
 * @export
 * @interface UserDetailRequest
 */
export interface UserDetailRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailRequest
     */
    'isActive': boolean;
}
/**
 * Default serializer sem yfirskrifar update og create virkni í DRF.
 * @export
 * @interface UserGroupRelated
 */
export interface UserGroupRelated {
    /**
     * 
     * @type {string}
     * @memberof UserGroupRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupRelated
     */
    'name': string;
}
/**
 * Notandi á sveitarfélagi
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {string}
     * @memberof UserList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserList
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof UserList
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserList
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserList
     */
    'tel': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    'isActive': boolean;
    /**
     * 
     * @type {Array<UserWorkplaceRelated>}
     * @memberof UserList
     */
    'workplaces': Array<UserWorkplaceRelated>;
    /**
     * 
     * @type {Array<UserGroupRelated>}
     * @memberof UserList
     */
    'groups': Array<UserGroupRelated>;
    /**
     * 
     * @type {string}
     * @memberof UserList
     */
    'dateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof UserList
     */
    'dateUpdated': string;
}
/**
 * 
 * @export
 * @interface UserWorkplaceRelated
 */
export interface UserWorkplaceRelated {
    /**
     * 
     * @type {WorkplaceRelated}
     * @memberof UserWorkplaceRelated
     */
    'workplace': WorkplaceRelated;
}
/**
 * Útgáfulýsingar
 * @export
 * @interface Version
 */
export interface Version {
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'description': string;
}
/**
 * Starfsstaður
 * @export
 * @interface WorkplaceDetail
 */
export interface WorkplaceDetail {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDetail
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkplaceDetail
     */
    'enabled': boolean;
    /**
     * 
     * @type {WorkplaceDistrictRelated}
     * @memberof WorkplaceDetail
     */
    'district': WorkplaceDistrictRelated | null;
    /**
     * 
     * @type {Array<WorkplaceWorkplaceTypeRelated>}
     * @memberof WorkplaceDetail
     */
    'types': Array<WorkplaceWorkplaceTypeRelated>;
}
/**
 * Hverfi
 * @export
 * @interface WorkplaceDistrictDetail
 */
export interface WorkplaceDistrictDetail {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDistrictDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDistrictDetail
     */
    'name': string;
}
/**
 * Hverfi
 * @export
 * @interface WorkplaceDistrictDropdown
 */
export interface WorkplaceDistrictDropdown {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDistrictDropdown
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDistrictDropdown
     */
    'name': string;
}
/**
 * Hverfi
 * @export
 * @interface WorkplaceDistrictList
 */
export interface WorkplaceDistrictList {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDistrictList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDistrictList
     */
    'name': string;
}
/**
 * Hverfi
 * @export
 * @interface WorkplaceDistrictRelated
 */
export interface WorkplaceDistrictRelated {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDistrictRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDistrictRelated
     */
    'name': string;
}
/**
 * Starfsstaður
 * @export
 * @interface WorkplaceDropdown
 */
export interface WorkplaceDropdown {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDropdown
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDropdown
     */
    'name': string;
}
/**
 * Starfsstaður
 * @export
 * @interface WorkplaceList
 */
export interface WorkplaceList {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceList
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkplaceList
     */
    'enabled': boolean;
    /**
     * 
     * @type {WorkplaceDistrictRelated}
     * @memberof WorkplaceList
     */
    'district': WorkplaceDistrictRelated | null;
    /**
     * 
     * @type {Array<WorkplaceWorkplaceTypeRelated>}
     * @memberof WorkplaceList
     */
    'types': Array<WorkplaceWorkplaceTypeRelated>;
}
/**
 * Starfsstaður
 * @export
 * @interface WorkplaceRelated
 */
export interface WorkplaceRelated {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceRelated
     */
    'name': string;
}
/**
 * Flokkun starfsstaða
 * @export
 * @interface WorkplaceTypeDetail
 */
export interface WorkplaceTypeDetail {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceTypeDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceTypeDetail
     */
    'name': string;
}
/**
 * Flokkun starfsstaða
 * @export
 * @interface WorkplaceTypeDropdown
 */
export interface WorkplaceTypeDropdown {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceTypeDropdown
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceTypeDropdown
     */
    'name': string;
}
/**
 * Flokkun starfsstaða
 * @export
 * @interface WorkplaceTypeList
 */
export interface WorkplaceTypeList {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceTypeList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceTypeList
     */
    'name': string;
}
/**
 * Flokkun
 * @export
 * @interface WorkplaceTypeRelated
 */
export interface WorkplaceTypeRelated {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceTypeRelated
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceTypeRelated
     */
    'name': string;
}
/**
 * Flokkun
 * @export
 * @interface WorkplaceWorkplaceTypeRelated
 */
export interface WorkplaceWorkplaceTypeRelated {
    /**
     * 
     * @type {string}
     * @memberof WorkplaceWorkplaceTypeRelated
     */
    'id': string;
    /**
     * 
     * @type {WorkplaceTypeRelated}
     * @memberof WorkplaceWorkplaceTypeRelated
     */
    'type': WorkplaceTypeRelated;
}

/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sækja yfirlit yfir fæðingarár umsókna á sveitaféælagi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationBirthYearLookups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/application/application/birth-year-lookups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Færa umsókn um stöðu
         * @param {string} uuid 
         * @param {ApplicationChangeStatusRequest} applicationChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationChangeStatus: async (uuid: string, applicationChangeStatusRequest: ApplicationChangeStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('applicationChangeStatus', 'uuid', uuid)
            // verify required parameter 'applicationChangeStatusRequest' is not null or undefined
            assertParamExists('applicationChangeStatus', 'applicationChangeStatusRequest', applicationChangeStatusRequest)
            const localVarPath = `/web-v3/application/application/{uuid}/change-status/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationChangeStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja allar umsóknir í Excel
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [email] email
         * @param {string} [endDate] endDate
         * @param {Array<string>} [ids] ids
         * @param {string} [name] name
         * @param {'ssn' | '-ssn' | 'name' | '-name' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [ssn] ssn
         * @param {string} [startDate] startDate
         * @param {Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>} [status] status
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationExcel: async (birthYear?: Array<string>, email?: string, endDate?: string, ids?: Array<string>, name?: string, orderBy?: 'ssn' | '-ssn' | 'name' | '-name' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated', period?: string, periodPart?: string, ssn?: string, startDate?: string, status?: Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/application/application/excel/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (birthYear) {
                localVarQueryParameter['birthYear'] = birthYear;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (periodPart !== undefined) {
                localVarQueryParameter['periodPart'] = periodPart;
            }

            if (ssn !== undefined) {
                localVarQueryParameter['ssn'] = ssn;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }

            if (workplaceDistrict !== undefined) {
                localVarQueryParameter['workplaceDistrict'] = workplaceDistrict;
            }

            if (workplaceType !== undefined) {
                localVarQueryParameter['workplaceType'] = workplaceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sýsla með umsóknir.
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [email] email
         * @param {string} [postalCode] postalCode
         * @param {string} [endDate] endDate
         * @param {Array<string>} [ids] ids
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'ssn' | '-ssn' | 'name' | '-name' | 'email' | '-email' | 'postalCode' | '-postalCode' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [ssn] ssn
         * @param {string} [startDate] startDate
         * @param {Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>} [status] status
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationList: async (birthYear?: Array<string>, email?: string, postalCode?: string, endDate?: string, ids?: Array<string>, limit?: number, name?: string, orderBy?: 'ssn' | '-ssn' | 'name' | '-name' | 'email' | '-email' | 'postalCode' | '-postalCode' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated', page?: number, period?: string, periodPart?: string, ssn?: string, startDate?: string, status?: Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/application/application/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (birthYear) {
                localVarQueryParameter['birthYear'] = birthYear;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (periodPart !== undefined) {
                localVarQueryParameter['periodPart'] = periodPart;
            }

            if (ssn !== undefined) {
                localVarQueryParameter['ssn'] = ssn;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }

            if (workplaceDistrict !== undefined) {
                localVarQueryParameter['workplaceDistrict'] = workplaceDistrict;
            }

            if (workplaceType !== undefined) {
                localVarQueryParameter['workplaceType'] = workplaceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sýsla með umsóknir.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationRetrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('applicationRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/application/application/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * Sækja yfirlit yfir fæðingarár umsókna á sveitaféælagi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationBirthYearLookups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationBirthYearLookups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Færa umsókn um stöðu
         * @param {string} uuid 
         * @param {ApplicationChangeStatusRequest} applicationChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationChangeStatus(uuid: string, applicationChangeStatusRequest: ApplicationChangeStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationChangeStatus(uuid, applicationChangeStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja allar umsóknir í Excel
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [email] email
         * @param {string} [endDate] endDate
         * @param {Array<string>} [ids] ids
         * @param {string} [name] name
         * @param {'ssn' | '-ssn' | 'name' | '-name' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [ssn] ssn
         * @param {string} [startDate] startDate
         * @param {Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>} [status] status
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationExcel(birthYear?: Array<string>, email?: string, endDate?: string, ids?: Array<string>, name?: string, orderBy?: 'ssn' | '-ssn' | 'name' | '-name' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated', period?: string, periodPart?: string, ssn?: string, startDate?: string, status?: Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationExcel(birthYear, email, endDate, ids, name, orderBy, period, periodPart, ssn, startDate, status, workplace, workplaceDistrict, workplaceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sýsla með umsóknir.
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [email] email
         * @param {string} [postalCode] postalCode
         * @param {string} [endDate] endDate
         * @param {Array<string>} [ids] ids
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'ssn' | '-ssn' | 'name' | '-name' | 'email' | '-email' | 'postalCode' | '-postalCode' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [ssn] ssn
         * @param {string} [startDate] startDate
         * @param {Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>} [status] status
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationList(birthYear?: Array<string>, email?: string, postalCode?: string, endDate?: string, ids?: Array<string>, limit?: number, name?: string, orderBy?: 'ssn' | '-ssn' | 'name' | '-name' | 'email' | '-email' | 'postalCode' | '-postalCode' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated', page?: number, period?: string, periodPart?: string, ssn?: string, startDate?: string, status?: Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationList(birthYear, email, postalCode, endDate, ids, limit, name, orderBy, page, period, periodPart, ssn, startDate, status, workplace, workplaceDistrict, workplaceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sýsla með umsóknir.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationRetrieve(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationRetrieve(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         * Sækja yfirlit yfir fæðingarár umsókna á sveitaféælagi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationBirthYearLookups(options?: any): AxiosPromise<Array<number>> {
            return localVarFp.applicationBirthYearLookups(options).then((request) => request(axios, basePath));
        },
        /**
         * Færa umsókn um stöðu
         * @param {string} uuid 
         * @param {ApplicationChangeStatusRequest} applicationChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationChangeStatus(uuid: string, applicationChangeStatusRequest: ApplicationChangeStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.applicationChangeStatus(uuid, applicationChangeStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja allar umsóknir í Excel
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [email] email
         * @param {string} [endDate] endDate
         * @param {Array<string>} [ids] ids
         * @param {string} [name] name
         * @param {'ssn' | '-ssn' | 'name' | '-name' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [ssn] ssn
         * @param {string} [startDate] startDate
         * @param {Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>} [status] status
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationExcel(birthYear?: Array<string>, email?: string, endDate?: string, ids?: Array<string>, name?: string, orderBy?: 'ssn' | '-ssn' | 'name' | '-name' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated', period?: string, periodPart?: string, ssn?: string, startDate?: string, status?: Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.applicationExcel(birthYear, email, endDate, ids, name, orderBy, period, periodPart, ssn, startDate, status, workplace, workplaceDistrict, workplaceType, options).then((request) => request(axios, basePath));
        },
        /**
         * Sýsla með umsóknir.
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [email] email
         * @param {string} [postalCode] postalCode
         * @param {string} [endDate] endDate
         * @param {Array<string>} [ids] ids
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'ssn' | '-ssn' | 'name' | '-name' | 'email' | '-email' | 'postalCode' | '-postalCode' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [ssn] ssn
         * @param {string} [startDate] startDate
         * @param {Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>} [status] status
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationList(birthYear?: Array<string>, email?: string, postalCode?: string, endDate?: string, ids?: Array<string>, limit?: number, name?: string, orderBy?: 'ssn' | '-ssn' | 'name' | '-name' | 'email' | '-email' | 'postalCode' | '-postalCode' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated', page?: number, period?: string, periodPart?: string, ssn?: string, startDate?: string, status?: Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options?: any): AxiosPromise<Array<ApplicationList>> {
            return localVarFp.applicationList(birthYear, email, postalCode, endDate, ids, limit, name, orderBy, page, period, periodPart, ssn, startDate, status, workplace, workplaceDistrict, workplaceType, options).then((request) => request(axios, basePath));
        },
        /**
         * Sýsla með umsóknir.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationRetrieve(uuid: string, options?: any): AxiosPromise<ApplicationDetail> {
            return localVarFp.applicationRetrieve(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applicationChangeStatus operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationChangeStatusRequest
 */
export interface ApplicationApiApplicationChangeStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationApiApplicationChangeStatus
     */
    readonly uuid: string

    /**
     * 
     * @type {ApplicationChangeStatusRequest}
     * @memberof ApplicationApiApplicationChangeStatus
     */
    readonly applicationChangeStatusRequest: ApplicationChangeStatusRequest
}

/**
 * Request parameters for applicationExcel operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationExcelRequest
 */
export interface ApplicationApiApplicationExcelRequest {
    /**
     * birthYear
     * @type {Array<string>}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly birthYear?: Array<string>

    /**
     * email
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly email?: string

    /**
     * endDate
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly endDate?: string

    /**
     * ids
     * @type {Array<string>}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly ids?: Array<string>

    /**
     * name
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly name?: string

    /**
     * Ordering
     * @type {'ssn' | '-ssn' | 'name' | '-name' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly orderBy?: 'ssn' | '-ssn' | 'name' | '-name' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'

    /**
     * period
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly period?: string

    /**
     * periodPart
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly periodPart?: string

    /**
     * ssn
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly ssn?: string

    /**
     * startDate
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly startDate?: string

    /**
     * status
     * @type {Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly status?: Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>

    /**
     * workplace
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly workplace?: string

    /**
     * workplaceDistrict
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly workplaceDistrict?: string

    /**
     * workplaceType
     * @type {string}
     * @memberof ApplicationApiApplicationExcel
     */
    readonly workplaceType?: string
}

/**
 * Request parameters for applicationList operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationListRequest
 */
export interface ApplicationApiApplicationListRequest {
    /**
     * birthYear
     * @type {Array<string>}
     * @memberof ApplicationApiApplicationList
     */
    readonly birthYear?: Array<string>

    /**
     * email
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly email?: string

    /**
     * postalCode
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly postalCode?: string

    /**
     * endDate
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly endDate?: string

    /**
     * ids
     * @type {Array<string>}
     * @memberof ApplicationApiApplicationList
     */
    readonly ids?: Array<string>

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApplicationApiApplicationList
     */
    readonly limit?: number

    /**
     * name
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly name?: string

    /**
     * Ordering
     * @type {'ssn' | '-ssn' | 'name' | '-name' | 'email' | '-email' | 'postalCode' | '-postalCode' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'}
     * @memberof ApplicationApiApplicationList
     */
    readonly orderBy?: 'ssn' | '-ssn' | 'name' | '-name' | 'email' | '-email' | 'postalCode' | '-postalCode' | 'status' | '-status' | 'periodName' | '-periodName' | 'periodDate' | '-periodDate' | 'ssnYear' | '-ssnYear' | 'dateCreated' | '-dateCreated'

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApplicationApiApplicationList
     */
    readonly page?: number

    /**
     * period
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly period?: string

    /**
     * periodPart
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly periodPart?: string

    /**
     * ssn
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly ssn?: string

    /**
     * startDate
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly startDate?: string

    /**
     * status
     * @type {Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>}
     * @memberof ApplicationApiApplicationList
     */
    readonly status?: Array<'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_APPROVED' | 'SEMI_ACCEPTED' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'WITHDRAWN' | 'COMPLETED'>

    /**
     * workplace
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly workplace?: string

    /**
     * workplaceDistrict
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly workplaceDistrict?: string

    /**
     * workplaceType
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly workplaceType?: string
}

/**
 * Request parameters for applicationRetrieve operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationRetrieveRequest
 */
export interface ApplicationApiApplicationRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationApiApplicationRetrieve
     */
    readonly uuid: string
}

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * Sækja yfirlit yfir fæðingarár umsókna á sveitaféælagi.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationBirthYearLookups(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationBirthYearLookups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Færa umsókn um stöðu
     * @param {ApplicationApiApplicationChangeStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationChangeStatus(requestParameters: ApplicationApiApplicationChangeStatusRequest, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationChangeStatus(requestParameters.uuid, requestParameters.applicationChangeStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja allar umsóknir í Excel
     * @param {ApplicationApiApplicationExcelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationExcel(requestParameters: ApplicationApiApplicationExcelRequest = {}, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationExcel(requestParameters.birthYear, requestParameters.email, requestParameters.endDate, requestParameters.ids, requestParameters.name, requestParameters.orderBy, requestParameters.period, requestParameters.periodPart, requestParameters.ssn, requestParameters.startDate, requestParameters.status, requestParameters.workplace, requestParameters.workplaceDistrict, requestParameters.workplaceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sýsla með umsóknir.
     * @param {ApplicationApiApplicationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationList(requestParameters: ApplicationApiApplicationListRequest = {}, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationList(requestParameters.birthYear, requestParameters.email, requestParameters.postalCode, requestParameters.endDate, requestParameters.ids, requestParameters.limit, requestParameters.name, requestParameters.orderBy, requestParameters.page, requestParameters.period, requestParameters.periodPart, requestParameters.ssn, requestParameters.startDate, requestParameters.status, requestParameters.workplace, requestParameters.workplaceDistrict, requestParameters.workplaceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sýsla með umsóknir.
     * @param {ApplicationApiApplicationRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationRetrieve(requestParameters: ApplicationApiApplicationRetrieveRequest, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationRetrieve(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Island.is
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        islandCallback: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('islandCallback', 'token', token)
            const localVarPath = `/web-v3/auth/island`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (token !== undefined) { 
                localVarFormParams.append('token', token as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Island.is
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        islandRedirect: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/auth/island`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Skrá út
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja innskráðan notanda
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/auth/my-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tryggja að CSRF kóði sé settur.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCsrf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/auth/set-csrf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Notendur á sveitarfélagi.
         * @param {Array<string>} [groups] groups
         * @param {string} [isActive] isActive
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'email' | '-email' | 'tel' | '-tel' | 'ssn' | '-ssn' | 'name' | '-name' | 'isActive' | '-isActive'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [ssn] ssn
         * @param {Array<string>} [workplaces] workplaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userList: async (groups?: Array<string>, isActive?: string, limit?: number, name?: string, orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'ssn' | '-ssn' | 'name' | '-name' | 'isActive' | '-isActive', page?: number, ssn?: string, workplaces?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/auth/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (groups) {
                localVarQueryParameter['groups'] = groups;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (ssn !== undefined) {
                localVarQueryParameter['ssn'] = ssn;
            }

            if (workplaces) {
                localVarQueryParameter['workplaces'] = workplaces;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Notendur á sveitarfélagi.
         * @param {string} uuid 
         * @param {PatchedUserDetailRequest} [patchedUserDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPartialUpdate: async (uuid: string, patchedUserDetailRequest?: PatchedUserDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('userPartialUpdate', 'uuid', uuid)
            const localVarPath = `/web-v3/auth/user/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Notendur á sveitarfélagi.
         * @param {string} uuid 
         * @param {UserDetailRequest} userDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (uuid: string, userDetailRequest: UserDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('userUpdate', 'uuid', uuid)
            // verify required parameter 'userDetailRequest' is not null or undefined
            assertParamExists('userUpdate', 'userDetailRequest', userDetailRequest)
            const localVarPath = `/web-v3/auth/user/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Island.is
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async islandCallback(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.islandCallback(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Island.is
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async islandRedirect(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.islandRedirect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Skrá út
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja innskráðan notanda
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tryggja að CSRF kóði sé settur.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCsrf(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCsrf(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Notendur á sveitarfélagi.
         * @param {Array<string>} [groups] groups
         * @param {string} [isActive] isActive
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'email' | '-email' | 'tel' | '-tel' | 'ssn' | '-ssn' | 'name' | '-name' | 'isActive' | '-isActive'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [ssn] ssn
         * @param {Array<string>} [workplaces] workplaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userList(groups?: Array<string>, isActive?: string, limit?: number, name?: string, orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'ssn' | '-ssn' | 'name' | '-name' | 'isActive' | '-isActive', page?: number, ssn?: string, workplaces?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userList(groups, isActive, limit, name, orderBy, page, ssn, workplaces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Notendur á sveitarfélagi.
         * @param {string} uuid 
         * @param {PatchedUserDetailRequest} [patchedUserDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPartialUpdate(uuid: string, patchedUserDetailRequest?: PatchedUserDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPartialUpdate(uuid, patchedUserDetailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Notendur á sveitarfélagi.
         * @param {string} uuid 
         * @param {UserDetailRequest} userDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(uuid: string, userDetailRequest: UserDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(uuid, userDetailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Island.is
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        islandCallback(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.islandCallback(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Island.is
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        islandRedirect(options?: any): AxiosPromise<void> {
            return localVarFp.islandRedirect(options).then((request) => request(axios, basePath));
        },
        /**
         * Skrá út
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja innskráðan notanda
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myAccount(options?: any): AxiosPromise<MyAccount> {
            return localVarFp.myAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * Tryggja að CSRF kóði sé settur.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCsrf(options?: any): AxiosPromise<void> {
            return localVarFp.setCsrf(options).then((request) => request(axios, basePath));
        },
        /**
         * Notendur á sveitarfélagi.
         * @param {Array<string>} [groups] groups
         * @param {string} [isActive] isActive
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'email' | '-email' | 'tel' | '-tel' | 'ssn' | '-ssn' | 'name' | '-name' | 'isActive' | '-isActive'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [ssn] ssn
         * @param {Array<string>} [workplaces] workplaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userList(groups?: Array<string>, isActive?: string, limit?: number, name?: string, orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'ssn' | '-ssn' | 'name' | '-name' | 'isActive' | '-isActive', page?: number, ssn?: string, workplaces?: Array<string>, options?: any): AxiosPromise<Array<UserList>> {
            return localVarFp.userList(groups, isActive, limit, name, orderBy, page, ssn, workplaces, options).then((request) => request(axios, basePath));
        },
        /**
         * Notendur á sveitarfélagi.
         * @param {string} uuid 
         * @param {PatchedUserDetailRequest} [patchedUserDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPartialUpdate(uuid: string, patchedUserDetailRequest?: PatchedUserDetailRequest, options?: any): AxiosPromise<UserDetail> {
            return localVarFp.userPartialUpdate(uuid, patchedUserDetailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Notendur á sveitarfélagi.
         * @param {string} uuid 
         * @param {UserDetailRequest} userDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(uuid: string, userDetailRequest: UserDetailRequest, options?: any): AxiosPromise<UserDetail> {
            return localVarFp.userUpdate(uuid, userDetailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for islandCallback operation in AuthApi.
 * @export
 * @interface AuthApiIslandCallbackRequest
 */
export interface AuthApiIslandCallbackRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiIslandCallback
     */
    readonly token: string
}

/**
 * Request parameters for userList operation in AuthApi.
 * @export
 * @interface AuthApiUserListRequest
 */
export interface AuthApiUserListRequest {
    /**
     * groups
     * @type {Array<string>}
     * @memberof AuthApiUserList
     */
    readonly groups?: Array<string>

    /**
     * isActive
     * @type {string}
     * @memberof AuthApiUserList
     */
    readonly isActive?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AuthApiUserList
     */
    readonly limit?: number

    /**
     * name
     * @type {string}
     * @memberof AuthApiUserList
     */
    readonly name?: string

    /**
     * Ordering
     * @type {'email' | '-email' | 'tel' | '-tel' | 'ssn' | '-ssn' | 'name' | '-name' | 'isActive' | '-isActive'}
     * @memberof AuthApiUserList
     */
    readonly orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'ssn' | '-ssn' | 'name' | '-name' | 'isActive' | '-isActive'

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof AuthApiUserList
     */
    readonly page?: number

    /**
     * ssn
     * @type {string}
     * @memberof AuthApiUserList
     */
    readonly ssn?: string

    /**
     * workplaces
     * @type {Array<string>}
     * @memberof AuthApiUserList
     */
    readonly workplaces?: Array<string>
}

/**
 * Request parameters for userPartialUpdate operation in AuthApi.
 * @export
 * @interface AuthApiUserPartialUpdateRequest
 */
export interface AuthApiUserPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiUserPartialUpdate
     */
    readonly uuid: string

    /**
     * 
     * @type {PatchedUserDetailRequest}
     * @memberof AuthApiUserPartialUpdate
     */
    readonly patchedUserDetailRequest?: PatchedUserDetailRequest
}

/**
 * Request parameters for userUpdate operation in AuthApi.
 * @export
 * @interface AuthApiUserUpdateRequest
 */
export interface AuthApiUserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiUserUpdate
     */
    readonly uuid: string

    /**
     * 
     * @type {UserDetailRequest}
     * @memberof AuthApiUserUpdate
     */
    readonly userDetailRequest: UserDetailRequest
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Island.is
     * @param {AuthApiIslandCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public islandCallback(requestParameters: AuthApiIslandCallbackRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).islandCallback(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Island.is
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public islandRedirect(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).islandRedirect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Skrá út
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja innskráðan notanda
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public myAccount(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).myAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tryggja að CSRF kóði sé settur.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public setCsrf(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).setCsrf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Notendur á sveitarfélagi.
     * @param {AuthApiUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public userList(requestParameters: AuthApiUserListRequest = {}, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).userList(requestParameters.groups, requestParameters.isActive, requestParameters.limit, requestParameters.name, requestParameters.orderBy, requestParameters.page, requestParameters.ssn, requestParameters.workplaces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Notendur á sveitarfélagi.
     * @param {AuthApiUserPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public userPartialUpdate(requestParameters: AuthApiUserPartialUpdateRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).userPartialUpdate(requestParameters.uuid, requestParameters.patchedUserDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Notendur á sveitarfélagi.
     * @param {AuthApiUserUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public userUpdate(requestParameters: AuthApiUserUpdateRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).userUpdate(requestParameters.uuid, requestParameters.userDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoreApi - axios parameter creator
 * @export
 */
export const CoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Hjálp.
         * @param {HelpCategoryRequest} helpCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryCreate: async (helpCategoryRequest: HelpCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'helpCategoryRequest' is not null or undefined
            assertParamExists('helpCategoryCreate', 'helpCategoryRequest', helpCategoryRequest)
            const localVarPath = `/web-v3/core/help-category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(helpCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryDestroy: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('helpCategoryDestroy', 'uuid', uuid)
            const localVarPath = `/web-v3/core/help-category/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hjálp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/help-category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {PatchedHelpCategoryRequest} [patchedHelpCategoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryPartialUpdate: async (uuid: string, patchedHelpCategoryRequest?: PatchedHelpCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('helpCategoryPartialUpdate', 'uuid', uuid)
            const localVarPath = `/web-v3/core/help-category/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedHelpCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryRetrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('helpCategoryRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/core/help-category/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {HelpCategoryRequest} helpCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryUpdate: async (uuid: string, helpCategoryRequest: HelpCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('helpCategoryUpdate', 'uuid', uuid)
            // verify required parameter 'helpCategoryRequest' is not null or undefined
            assertParamExists('helpCategoryUpdate', 'helpCategoryRequest', helpCategoryRequest)
            const localVarPath = `/web-v3/core/help-category/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(helpCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDocumentDestroy: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('helpDocumentDestroy', 'uuid', uuid)
            const localVarPath = `/web-v3/core/help-document/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja skrá.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDocumentDownload: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('helpDocumentDownload', 'uuid', uuid)
            const localVarPath = `/web-v3/core/help-document/{uuid}/download/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hlaða upp skrá.
         * @param {string} name 
         * @param {string} category 
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDocumentUpload: async (name: string, category: string, document: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('helpDocumentUpload', 'name', name)
            // verify required parameter 'category' is not null or undefined
            assertParamExists('helpDocumentUpload', 'category', category)
            // verify required parameter 'document' is not null or undefined
            assertParamExists('helpDocumentUpload', 'document', document)
            const localVarPath = `/web-v3/core/help-document/upload/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (category !== undefined) { 
                localVarFormParams.append('category', category as any);
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('document', document as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tímabil - Fellilisti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodDropdownList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/period-dropdown/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Partar af tímabili - Fellilisti
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodDropdownPeriodPartDropdownList: async (period: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('periodDropdownPeriodPartDropdownList', 'period', period)
            const localVarPath = `/web-v3/core/period-dropdown/{period}/period-part-dropdown/`
                .replace(`{${"period"}}`, encodeURIComponent(String(period)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tímabil.
         * @param {string} [apply] apply
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodList: async (apply?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/period/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (apply !== undefined) {
                localVarQueryParameter['apply'] = apply;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Partar af tímabili.
         * @param {string} period 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodPartList: async (period: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('periodPartList', 'period', period)
            const localVarPath = `/web-v3/core/period/{period}/part/`
                .replace(`{${"period"}}`, encodeURIComponent(String(period)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Partar af tímabili.
         * @param {string} period 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodPartRetrieve: async (period: string, uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('periodPartRetrieve', 'period', period)
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('periodPartRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/core/period/{period}/part/{uuid}/`
                .replace(`{${"period"}}`, encodeURIComponent(String(period)))
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tímabil.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodRetrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('periodRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/core/period/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja tölfræði úr kerfinu
         * @param {string} date 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsSummary: async (date: string, period: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('statisticsSummary', 'date', date)
            // verify required parameter 'period' is not null or undefined
            assertParamExists('statisticsSummary', 'period', period)
            const localVarPath = `/web-v3/core/statistics/summary/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja tölfræði úr kerfinu
         * @param {string} date 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsSummaryExcel: async (date: string, period: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('statisticsSummaryExcel', 'date', date)
            // verify required parameter 'period' is not null or undefined
            assertParamExists('statisticsSummaryExcel', 'period', period)
            const localVarPath = `/web-v3/core/statistics/summary-excel/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        townList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/town/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Útgáfur.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionList: async (limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/version/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hverfi - Fellilisti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceDistrictDropdownList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/workplace-district-dropdown/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hverfi.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceDistrictList: async (limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/workplace-district/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hverfi.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceDistrictRetrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('workplaceDistrictRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/core/workplace-district/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starfsstaður - Fellilisti
         * @param {string} [district] district
         * @param {string} [enabled] enabled
         * @param {string} [name] name
         * @param {'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceDropdownList: async (district?: string, enabled?: string, name?: string, orderBy?: 'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated', type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/workplace-dropdown/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (district !== undefined) {
                localVarQueryParameter['district'] = district;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starfsstaðir.
         * @param {string} [district] district
         * @param {string} [enabled] enabled
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceList: async (district?: string, enabled?: string, limit?: number, name?: string, orderBy?: 'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated', page?: number, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/workplace/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (district !== undefined) {
                localVarQueryParameter['district'] = district;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starfsstaðir.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceRetrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('workplaceRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/core/workplace/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flokkun starfsstaða - Fellilisti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceTypeDropdownList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/workplace-type-dropdown/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flokkun starfsstaða.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceTypeList: async (limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/core/workplace-type/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flokkun starfsstaða.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceTypeRetrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('workplaceTypeRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/core/workplace-type/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoreApi - functional programming interface
 * @export
 */
export const CoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoreApiAxiosParamCreator(configuration)
    return {
        /**
         * Hjálp.
         * @param {HelpCategoryRequest} helpCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpCategoryCreate(helpCategoryRequest: HelpCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpCategoryCreate(helpCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpCategoryDestroy(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpCategoryDestroy(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hjálp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpCategoryList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HelpCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpCategoryList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {PatchedHelpCategoryRequest} [patchedHelpCategoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpCategoryPartialUpdate(uuid: string, patchedHelpCategoryRequest?: PatchedHelpCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpCategoryPartialUpdate(uuid, patchedHelpCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpCategoryRetrieve(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpCategoryRetrieve(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {HelpCategoryRequest} helpCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpCategoryUpdate(uuid: string, helpCategoryRequest: HelpCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpCategoryUpdate(uuid, helpCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpDocumentDestroy(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpDocumentDestroy(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja skrá.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpDocumentDownload(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpDocumentDownload(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hlaða upp skrá.
         * @param {string} name 
         * @param {string} category 
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpDocumentUpload(name: string, category: string, document: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpDocumentUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpDocumentUpload(name, category, document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tímabil - Fellilisti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async periodDropdownList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeriodDropdown>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.periodDropdownList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Partar af tímabili - Fellilisti
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async periodDropdownPeriodPartDropdownList(period: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeriodPartDropdown>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.periodDropdownPeriodPartDropdownList(period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tímabil.
         * @param {string} [apply] apply
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async periodList(apply?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeriodList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.periodList(apply, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Partar af tímabili.
         * @param {string} period 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async periodPartList(period: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeriodPartList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.periodPartList(period, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Partar af tímabili.
         * @param {string} period 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async periodPartRetrieve(period: string, uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeriodPartDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.periodPartRetrieve(period, uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tímabil.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async periodRetrieve(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeriodDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.periodRetrieve(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja tölfræði úr kerfinu
         * @param {string} date 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsSummary(date: string, period: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatisticsSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsSummary(date, period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja tölfræði úr kerfinu
         * @param {string} date 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsSummaryExcel(date: string, period: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsSummaryExcel(date, period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async townList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Town>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.townList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Útgáfur.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionList(limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Version>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionList(limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hverfi - Fellilisti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workplaceDistrictDropdownList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkplaceDistrictDropdown>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workplaceDistrictDropdownList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hverfi.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workplaceDistrictList(limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkplaceDistrictList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workplaceDistrictList(limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hverfi.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workplaceDistrictRetrieve(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkplaceDistrictDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workplaceDistrictRetrieve(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starfsstaður - Fellilisti
         * @param {string} [district] district
         * @param {string} [enabled] enabled
         * @param {string} [name] name
         * @param {'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workplaceDropdownList(district?: string, enabled?: string, name?: string, orderBy?: 'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated', type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkplaceDropdown>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workplaceDropdownList(district, enabled, name, orderBy, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starfsstaðir.
         * @param {string} [district] district
         * @param {string} [enabled] enabled
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workplaceList(district?: string, enabled?: string, limit?: number, name?: string, orderBy?: 'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated', page?: number, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkplaceList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workplaceList(district, enabled, limit, name, orderBy, page, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starfsstaðir.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workplaceRetrieve(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkplaceDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workplaceRetrieve(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Flokkun starfsstaða - Fellilisti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workplaceTypeDropdownList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkplaceTypeDropdown>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workplaceTypeDropdownList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Flokkun starfsstaða.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workplaceTypeList(limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkplaceTypeList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workplaceTypeList(limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Flokkun starfsstaða.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workplaceTypeRetrieve(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkplaceTypeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workplaceTypeRetrieve(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoreApi - factory interface
 * @export
 */
export const CoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoreApiFp(configuration)
    return {
        /**
         * Hjálp.
         * @param {HelpCategoryRequest} helpCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryCreate(helpCategoryRequest: HelpCategoryRequest, options?: any): AxiosPromise<HelpCategory> {
            return localVarFp.helpCategoryCreate(helpCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryDestroy(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.helpCategoryDestroy(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Hjálp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryList(options?: any): AxiosPromise<Array<HelpCategory>> {
            return localVarFp.helpCategoryList(options).then((request) => request(axios, basePath));
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {PatchedHelpCategoryRequest} [patchedHelpCategoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryPartialUpdate(uuid: string, patchedHelpCategoryRequest?: PatchedHelpCategoryRequest, options?: any): AxiosPromise<HelpCategory> {
            return localVarFp.helpCategoryPartialUpdate(uuid, patchedHelpCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryRetrieve(uuid: string, options?: any): AxiosPromise<HelpCategory> {
            return localVarFp.helpCategoryRetrieve(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {HelpCategoryRequest} helpCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpCategoryUpdate(uuid: string, helpCategoryRequest: HelpCategoryRequest, options?: any): AxiosPromise<HelpCategory> {
            return localVarFp.helpCategoryUpdate(uuid, helpCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Hjálp.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDocumentDestroy(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.helpDocumentDestroy(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja skrá.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDocumentDownload(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.helpDocumentDownload(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Hlaða upp skrá.
         * @param {string} name 
         * @param {string} category 
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDocumentUpload(name: string, category: string, document: any, options?: any): AxiosPromise<HelpDocumentUpload> {
            return localVarFp.helpDocumentUpload(name, category, document, options).then((request) => request(axios, basePath));
        },
        /**
         * Tímabil - Fellilisti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodDropdownList(options?: any): AxiosPromise<Array<PeriodDropdown>> {
            return localVarFp.periodDropdownList(options).then((request) => request(axios, basePath));
        },
        /**
         * Partar af tímabili - Fellilisti
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodDropdownPeriodPartDropdownList(period: string, options?: any): AxiosPromise<Array<PeriodPartDropdown>> {
            return localVarFp.periodDropdownPeriodPartDropdownList(period, options).then((request) => request(axios, basePath));
        },
        /**
         * Tímabil.
         * @param {string} [apply] apply
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodList(apply?: string, limit?: number, page?: number, options?: any): AxiosPromise<Array<PeriodList>> {
            return localVarFp.periodList(apply, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Partar af tímabili.
         * @param {string} period 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodPartList(period: string, limit?: number, page?: number, options?: any): AxiosPromise<Array<PeriodPartList>> {
            return localVarFp.periodPartList(period, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Partar af tímabili.
         * @param {string} period 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodPartRetrieve(period: string, uuid: string, options?: any): AxiosPromise<PeriodPartDetail> {
            return localVarFp.periodPartRetrieve(period, uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Tímabil.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodRetrieve(uuid: string, options?: any): AxiosPromise<PeriodDetail> {
            return localVarFp.periodRetrieve(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja tölfræði úr kerfinu
         * @param {string} date 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsSummary(date: string, period: string, options?: any): AxiosPromise<StatisticsSummary> {
            return localVarFp.statisticsSummary(date, period, options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja tölfræði úr kerfinu
         * @param {string} date 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsSummaryExcel(date: string, period: string, options?: any): AxiosPromise<any> {
            return localVarFp.statisticsSummaryExcel(date, period, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        townList(options?: any): AxiosPromise<Array<Town>> {
            return localVarFp.townList(options).then((request) => request(axios, basePath));
        },
        /**
         * Útgáfur.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionList(limit?: number, page?: number, options?: any): AxiosPromise<Array<Version>> {
            return localVarFp.versionList(limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Hverfi - Fellilisti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceDistrictDropdownList(options?: any): AxiosPromise<Array<WorkplaceDistrictDropdown>> {
            return localVarFp.workplaceDistrictDropdownList(options).then((request) => request(axios, basePath));
        },
        /**
         * Hverfi.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceDistrictList(limit?: number, page?: number, options?: any): AxiosPromise<Array<WorkplaceDistrictList>> {
            return localVarFp.workplaceDistrictList(limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Hverfi.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceDistrictRetrieve(uuid: string, options?: any): AxiosPromise<WorkplaceDistrictDetail> {
            return localVarFp.workplaceDistrictRetrieve(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Starfsstaður - Fellilisti
         * @param {string} [district] district
         * @param {string} [enabled] enabled
         * @param {string} [name] name
         * @param {'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceDropdownList(district?: string, enabled?: string, name?: string, orderBy?: 'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated', type?: string, options?: any): AxiosPromise<Array<WorkplaceDropdown>> {
            return localVarFp.workplaceDropdownList(district, enabled, name, orderBy, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Starfsstaðir.
         * @param {string} [district] district
         * @param {string} [enabled] enabled
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceList(district?: string, enabled?: string, limit?: number, name?: string, orderBy?: 'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated', page?: number, type?: string, options?: any): AxiosPromise<Array<WorkplaceList>> {
            return localVarFp.workplaceList(district, enabled, limit, name, orderBy, page, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Starfsstaðir.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceRetrieve(uuid: string, options?: any): AxiosPromise<WorkplaceDetail> {
            return localVarFp.workplaceRetrieve(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Flokkun starfsstaða - Fellilisti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceTypeDropdownList(options?: any): AxiosPromise<Array<WorkplaceTypeDropdown>> {
            return localVarFp.workplaceTypeDropdownList(options).then((request) => request(axios, basePath));
        },
        /**
         * Flokkun starfsstaða.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceTypeList(limit?: number, page?: number, options?: any): AxiosPromise<Array<WorkplaceTypeList>> {
            return localVarFp.workplaceTypeList(limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Flokkun starfsstaða.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workplaceTypeRetrieve(uuid: string, options?: any): AxiosPromise<WorkplaceTypeDetail> {
            return localVarFp.workplaceTypeRetrieve(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for helpCategoryCreate operation in CoreApi.
 * @export
 * @interface CoreApiHelpCategoryCreateRequest
 */
export interface CoreApiHelpCategoryCreateRequest {
    /**
     * 
     * @type {HelpCategoryRequest}
     * @memberof CoreApiHelpCategoryCreate
     */
    readonly helpCategoryRequest: HelpCategoryRequest
}

/**
 * Request parameters for helpCategoryDestroy operation in CoreApi.
 * @export
 * @interface CoreApiHelpCategoryDestroyRequest
 */
export interface CoreApiHelpCategoryDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiHelpCategoryDestroy
     */
    readonly uuid: string
}

/**
 * Request parameters for helpCategoryPartialUpdate operation in CoreApi.
 * @export
 * @interface CoreApiHelpCategoryPartialUpdateRequest
 */
export interface CoreApiHelpCategoryPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiHelpCategoryPartialUpdate
     */
    readonly uuid: string

    /**
     * 
     * @type {PatchedHelpCategoryRequest}
     * @memberof CoreApiHelpCategoryPartialUpdate
     */
    readonly patchedHelpCategoryRequest?: PatchedHelpCategoryRequest
}

/**
 * Request parameters for helpCategoryRetrieve operation in CoreApi.
 * @export
 * @interface CoreApiHelpCategoryRetrieveRequest
 */
export interface CoreApiHelpCategoryRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiHelpCategoryRetrieve
     */
    readonly uuid: string
}

/**
 * Request parameters for helpCategoryUpdate operation in CoreApi.
 * @export
 * @interface CoreApiHelpCategoryUpdateRequest
 */
export interface CoreApiHelpCategoryUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiHelpCategoryUpdate
     */
    readonly uuid: string

    /**
     * 
     * @type {HelpCategoryRequest}
     * @memberof CoreApiHelpCategoryUpdate
     */
    readonly helpCategoryRequest: HelpCategoryRequest
}

/**
 * Request parameters for helpDocumentDestroy operation in CoreApi.
 * @export
 * @interface CoreApiHelpDocumentDestroyRequest
 */
export interface CoreApiHelpDocumentDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiHelpDocumentDestroy
     */
    readonly uuid: string
}

/**
 * Request parameters for helpDocumentDownload operation in CoreApi.
 * @export
 * @interface CoreApiHelpDocumentDownloadRequest
 */
export interface CoreApiHelpDocumentDownloadRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiHelpDocumentDownload
     */
    readonly uuid: string
}

/**
 * Request parameters for helpDocumentUpload operation in CoreApi.
 * @export
 * @interface CoreApiHelpDocumentUploadRequest
 */
export interface CoreApiHelpDocumentUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiHelpDocumentUpload
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiHelpDocumentUpload
     */
    readonly category: string

    /**
     * 
     * @type {any}
     * @memberof CoreApiHelpDocumentUpload
     */
    readonly document: any
}

/**
 * Request parameters for periodDropdownPeriodPartDropdownList operation in CoreApi.
 * @export
 * @interface CoreApiPeriodDropdownPeriodPartDropdownListRequest
 */
export interface CoreApiPeriodDropdownPeriodPartDropdownListRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiPeriodDropdownPeriodPartDropdownList
     */
    readonly period: string
}

/**
 * Request parameters for periodList operation in CoreApi.
 * @export
 * @interface CoreApiPeriodListRequest
 */
export interface CoreApiPeriodListRequest {
    /**
     * apply
     * @type {string}
     * @memberof CoreApiPeriodList
     */
    readonly apply?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiPeriodList
     */
    readonly limit?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CoreApiPeriodList
     */
    readonly page?: number
}

/**
 * Request parameters for periodPartList operation in CoreApi.
 * @export
 * @interface CoreApiPeriodPartListRequest
 */
export interface CoreApiPeriodPartListRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiPeriodPartList
     */
    readonly period: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiPeriodPartList
     */
    readonly limit?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CoreApiPeriodPartList
     */
    readonly page?: number
}

/**
 * Request parameters for periodPartRetrieve operation in CoreApi.
 * @export
 * @interface CoreApiPeriodPartRetrieveRequest
 */
export interface CoreApiPeriodPartRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiPeriodPartRetrieve
     */
    readonly period: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiPeriodPartRetrieve
     */
    readonly uuid: string
}

/**
 * Request parameters for periodRetrieve operation in CoreApi.
 * @export
 * @interface CoreApiPeriodRetrieveRequest
 */
export interface CoreApiPeriodRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiPeriodRetrieve
     */
    readonly uuid: string
}

/**
 * Request parameters for statisticsSummary operation in CoreApi.
 * @export
 * @interface CoreApiStatisticsSummaryRequest
 */
export interface CoreApiStatisticsSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiStatisticsSummary
     */
    readonly date: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiStatisticsSummary
     */
    readonly period: string
}

/**
 * Request parameters for statisticsSummaryExcel operation in CoreApi.
 * @export
 * @interface CoreApiStatisticsSummaryExcelRequest
 */
export interface CoreApiStatisticsSummaryExcelRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiStatisticsSummaryExcel
     */
    readonly date: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiStatisticsSummaryExcel
     */
    readonly period: string
}

/**
 * Request parameters for versionList operation in CoreApi.
 * @export
 * @interface CoreApiVersionListRequest
 */
export interface CoreApiVersionListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiVersionList
     */
    readonly limit?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CoreApiVersionList
     */
    readonly page?: number
}

/**
 * Request parameters for workplaceDistrictList operation in CoreApi.
 * @export
 * @interface CoreApiWorkplaceDistrictListRequest
 */
export interface CoreApiWorkplaceDistrictListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiWorkplaceDistrictList
     */
    readonly limit?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CoreApiWorkplaceDistrictList
     */
    readonly page?: number
}

/**
 * Request parameters for workplaceDistrictRetrieve operation in CoreApi.
 * @export
 * @interface CoreApiWorkplaceDistrictRetrieveRequest
 */
export interface CoreApiWorkplaceDistrictRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiWorkplaceDistrictRetrieve
     */
    readonly uuid: string
}

/**
 * Request parameters for workplaceDropdownList operation in CoreApi.
 * @export
 * @interface CoreApiWorkplaceDropdownListRequest
 */
export interface CoreApiWorkplaceDropdownListRequest {
    /**
     * district
     * @type {string}
     * @memberof CoreApiWorkplaceDropdownList
     */
    readonly district?: string

    /**
     * enabled
     * @type {string}
     * @memberof CoreApiWorkplaceDropdownList
     */
    readonly enabled?: string

    /**
     * name
     * @type {string}
     * @memberof CoreApiWorkplaceDropdownList
     */
    readonly name?: string

    /**
     * Ordering
     * @type {'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'}
     * @memberof CoreApiWorkplaceDropdownList
     */
    readonly orderBy?: 'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'

    /**
     * type
     * @type {string}
     * @memberof CoreApiWorkplaceDropdownList
     */
    readonly type?: string
}

/**
 * Request parameters for workplaceList operation in CoreApi.
 * @export
 * @interface CoreApiWorkplaceListRequest
 */
export interface CoreApiWorkplaceListRequest {
    /**
     * district
     * @type {string}
     * @memberof CoreApiWorkplaceList
     */
    readonly district?: string

    /**
     * enabled
     * @type {string}
     * @memberof CoreApiWorkplaceList
     */
    readonly enabled?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiWorkplaceList
     */
    readonly limit?: number

    /**
     * name
     * @type {string}
     * @memberof CoreApiWorkplaceList
     */
    readonly name?: string

    /**
     * Ordering
     * @type {'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'}
     * @memberof CoreApiWorkplaceList
     */
    readonly orderBy?: 'districtName' | '-districtName' | 'name' | '-name' | 'enabled' | '-enabled' | 'dateCreated' | '-dateCreated'

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CoreApiWorkplaceList
     */
    readonly page?: number

    /**
     * type
     * @type {string}
     * @memberof CoreApiWorkplaceList
     */
    readonly type?: string
}

/**
 * Request parameters for workplaceRetrieve operation in CoreApi.
 * @export
 * @interface CoreApiWorkplaceRetrieveRequest
 */
export interface CoreApiWorkplaceRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiWorkplaceRetrieve
     */
    readonly uuid: string
}

/**
 * Request parameters for workplaceTypeList operation in CoreApi.
 * @export
 * @interface CoreApiWorkplaceTypeListRequest
 */
export interface CoreApiWorkplaceTypeListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiWorkplaceTypeList
     */
    readonly limit?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CoreApiWorkplaceTypeList
     */
    readonly page?: number
}

/**
 * Request parameters for workplaceTypeRetrieve operation in CoreApi.
 * @export
 * @interface CoreApiWorkplaceTypeRetrieveRequest
 */
export interface CoreApiWorkplaceTypeRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiWorkplaceTypeRetrieve
     */
    readonly uuid: string
}

/**
 * CoreApi - object-oriented interface
 * @export
 * @class CoreApi
 * @extends {BaseAPI}
 */
export class CoreApi extends BaseAPI {
    /**
     * Hjálp.
     * @param {CoreApiHelpCategoryCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public helpCategoryCreate(requestParameters: CoreApiHelpCategoryCreateRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).helpCategoryCreate(requestParameters.helpCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hjálp.
     * @param {CoreApiHelpCategoryDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public helpCategoryDestroy(requestParameters: CoreApiHelpCategoryDestroyRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).helpCategoryDestroy(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hjálp.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public helpCategoryList(options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).helpCategoryList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hjálp.
     * @param {CoreApiHelpCategoryPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public helpCategoryPartialUpdate(requestParameters: CoreApiHelpCategoryPartialUpdateRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).helpCategoryPartialUpdate(requestParameters.uuid, requestParameters.patchedHelpCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hjálp.
     * @param {CoreApiHelpCategoryRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public helpCategoryRetrieve(requestParameters: CoreApiHelpCategoryRetrieveRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).helpCategoryRetrieve(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hjálp.
     * @param {CoreApiHelpCategoryUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public helpCategoryUpdate(requestParameters: CoreApiHelpCategoryUpdateRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).helpCategoryUpdate(requestParameters.uuid, requestParameters.helpCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hjálp.
     * @param {CoreApiHelpDocumentDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public helpDocumentDestroy(requestParameters: CoreApiHelpDocumentDestroyRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).helpDocumentDestroy(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja skrá.
     * @param {CoreApiHelpDocumentDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public helpDocumentDownload(requestParameters: CoreApiHelpDocumentDownloadRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).helpDocumentDownload(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hlaða upp skrá.
     * @param {CoreApiHelpDocumentUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public helpDocumentUpload(requestParameters: CoreApiHelpDocumentUploadRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).helpDocumentUpload(requestParameters.name, requestParameters.category, requestParameters.document, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tímabil - Fellilisti
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public periodDropdownList(options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).periodDropdownList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Partar af tímabili - Fellilisti
     * @param {CoreApiPeriodDropdownPeriodPartDropdownListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public periodDropdownPeriodPartDropdownList(requestParameters: CoreApiPeriodDropdownPeriodPartDropdownListRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).periodDropdownPeriodPartDropdownList(requestParameters.period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tímabil.
     * @param {CoreApiPeriodListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public periodList(requestParameters: CoreApiPeriodListRequest = {}, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).periodList(requestParameters.apply, requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Partar af tímabili.
     * @param {CoreApiPeriodPartListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public periodPartList(requestParameters: CoreApiPeriodPartListRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).periodPartList(requestParameters.period, requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Partar af tímabili.
     * @param {CoreApiPeriodPartRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public periodPartRetrieve(requestParameters: CoreApiPeriodPartRetrieveRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).periodPartRetrieve(requestParameters.period, requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tímabil.
     * @param {CoreApiPeriodRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public periodRetrieve(requestParameters: CoreApiPeriodRetrieveRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).periodRetrieve(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja tölfræði úr kerfinu
     * @param {CoreApiStatisticsSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public statisticsSummary(requestParameters: CoreApiStatisticsSummaryRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).statisticsSummary(requestParameters.date, requestParameters.period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja tölfræði úr kerfinu
     * @param {CoreApiStatisticsSummaryExcelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public statisticsSummaryExcel(requestParameters: CoreApiStatisticsSummaryExcelRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).statisticsSummaryExcel(requestParameters.date, requestParameters.period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public townList(options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).townList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Útgáfur.
     * @param {CoreApiVersionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public versionList(requestParameters: CoreApiVersionListRequest = {}, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).versionList(requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hverfi - Fellilisti
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public workplaceDistrictDropdownList(options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).workplaceDistrictDropdownList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hverfi.
     * @param {CoreApiWorkplaceDistrictListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public workplaceDistrictList(requestParameters: CoreApiWorkplaceDistrictListRequest = {}, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).workplaceDistrictList(requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hverfi.
     * @param {CoreApiWorkplaceDistrictRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public workplaceDistrictRetrieve(requestParameters: CoreApiWorkplaceDistrictRetrieveRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).workplaceDistrictRetrieve(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starfsstaður - Fellilisti
     * @param {CoreApiWorkplaceDropdownListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public workplaceDropdownList(requestParameters: CoreApiWorkplaceDropdownListRequest = {}, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).workplaceDropdownList(requestParameters.district, requestParameters.enabled, requestParameters.name, requestParameters.orderBy, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starfsstaðir.
     * @param {CoreApiWorkplaceListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public workplaceList(requestParameters: CoreApiWorkplaceListRequest = {}, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).workplaceList(requestParameters.district, requestParameters.enabled, requestParameters.limit, requestParameters.name, requestParameters.orderBy, requestParameters.page, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starfsstaðir.
     * @param {CoreApiWorkplaceRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public workplaceRetrieve(requestParameters: CoreApiWorkplaceRetrieveRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).workplaceRetrieve(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flokkun starfsstaða - Fellilisti
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public workplaceTypeDropdownList(options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).workplaceTypeDropdownList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flokkun starfsstaða.
     * @param {CoreApiWorkplaceTypeListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public workplaceTypeList(requestParameters: CoreApiWorkplaceTypeListRequest = {}, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).workplaceTypeList(requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flokkun starfsstaða.
     * @param {CoreApiWorkplaceTypeRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public workplaceTypeRetrieve(requestParameters: CoreApiWorkplaceTypeRetrieveRequest, options?: AxiosRequestConfig) {
        return CoreApiFp(this.configuration).workplaceTypeRetrieve(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Búa til aukabunka
         * @param {CreateExtraBatchRequest} createExtraBatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExtraBatch: async (createExtraBatchRequest: CreateExtraBatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExtraBatchRequest' is not null or undefined
            assertParamExists('createExtraBatch', 'createExtraBatchRequest', createExtraBatchRequest)
            const localVarPath = `/web-v3/payment/batch/create-extra/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExtraBatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stofna starfsmann í h3.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInH3: async (period: string, person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('createInH3', 'period', period)
            // verify required parameter 'person' is not null or undefined
            assertParamExists('createInH3', 'person', person)
            const localVarPath = `/web-v3/payment/payrollemployeeh3/create-in-h3/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (person !== undefined) {
                localVarQueryParameter['person'] = (person as any instanceof Date) ?
                    (person as any).toISOString().substr(0,10) :
                    person;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stofna starfsmann í kjarna.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInKjarni: async (period: string, person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('createInKjarni', 'period', period)
            // verify required parameter 'person' is not null or undefined
            assertParamExists('createInKjarni', 'person', person)
            const localVarPath = `/web-v3/payment/payrollemployeekjarni/create-in-kjarni/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (person !== undefined) {
                localVarQueryParameter['person'] = (person as any instanceof Date) ?
                    (person as any).toISOString().substr(0,10) :
                    person;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja excel yfirlit
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollemployeeh3Excel: async (period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/payment/payrollemployeeh3excel/excel/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (periodPart !== undefined) {
                localVarQueryParameter['periodPart'] = periodPart;
            }

            if (person !== undefined) {
                localVarQueryParameter['person'] = person;
            }

            if (placementGroup !== undefined) {
                localVarQueryParameter['placementGroup'] = placementGroup;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }

            if (workplaceType !== undefined) {
                localVarQueryParameter['workplaceType'] = workplaceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [exists] exists
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollemployeeh3List: async (exists?: string, period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/payment/payrollemployeeh3/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (exists !== undefined) {
                localVarQueryParameter['exists'] = exists;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (periodPart !== undefined) {
                localVarQueryParameter['periodPart'] = periodPart;
            }

            if (person !== undefined) {
                localVarQueryParameter['person'] = person;
            }

            if (placementGroup !== undefined) {
                localVarQueryParameter['placementGroup'] = placementGroup;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }

            if (workplaceType !== undefined) {
                localVarQueryParameter['workplaceType'] = workplaceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollemployeeh3excelList: async (period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/payment/payrollemployeeh3excel/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (periodPart !== undefined) {
                localVarQueryParameter['periodPart'] = periodPart;
            }

            if (person !== undefined) {
                localVarQueryParameter['person'] = person;
            }

            if (placementGroup !== undefined) {
                localVarQueryParameter['placementGroup'] = placementGroup;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }

            if (workplaceType !== undefined) {
                localVarQueryParameter['workplaceType'] = workplaceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [exists] exists
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollemployeekjarniList: async (exists?: string, period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/payment/payrollemployeekjarni/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (exists !== undefined) {
                localVarQueryParameter['exists'] = exists;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (periodPart !== undefined) {
                localVarQueryParameter['periodPart'] = periodPart;
            }

            if (person !== undefined) {
                localVarQueryParameter['person'] = person;
            }

            if (placementGroup !== undefined) {
                localVarQueryParameter['placementGroup'] = placementGroup;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }

            if (workplaceType !== undefined) {
                localVarQueryParameter['workplaceType'] = workplaceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stofna starfsmann í H3.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rehireInH3: async (period: string, person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('rehireInH3', 'period', period)
            // verify required parameter 'person' is not null or undefined
            assertParamExists('rehireInH3', 'person', person)
            const localVarPath = `/web-v3/payment/payrollemployeeh3/rehire-in-h3/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (person !== undefined) {
                localVarQueryParameter['person'] = (person as any instanceof Date) ?
                    (person as any).toISOString().substr(0,10) :
                    person;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stofna starfsmann í kjarna.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rehireInKjarni: async (period: string, person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('rehireInKjarni', 'period', period)
            // verify required parameter 'person' is not null or undefined
            assertParamExists('rehireInKjarni', 'person', person)
            const localVarPath = `/web-v3/payment/payrollemployeekjarni/rehire-in-kjarni/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (person !== undefined) {
                localVarQueryParameter['person'] = (person as any instanceof Date) ?
                    (person as any).toISOString().substr(0,10) :
                    person;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Innlestur bunka í Kjarna
         * @param {string} batch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitToKjarni: async (batch: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batch' is not null or undefined
            assertParamExists('submitToKjarni', 'batch', batch)
            const localVarPath = `/web-v3/payment/payrollemployeekjarni/submit-to-kjarni/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (batch !== undefined) {
                localVarQueryParameter['batch'] = batch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * Búa til aukabunka
         * @param {CreateExtraBatchRequest} createExtraBatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExtraBatch(createExtraBatchRequest: CreateExtraBatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateExtraBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExtraBatch(createExtraBatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stofna starfsmann í h3.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInH3(period: string, person: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInH3(period, person, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stofna starfsmann í kjarna.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInKjarni(period: string, person: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInKjarni(period, person, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja excel yfirlit
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollemployeeh3Excel(period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollemployeeh3Excel(period, periodPart, person, placementGroup, workplace, workplaceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [exists] exists
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollemployeeh3List(exists?: string, period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PayrollEmployeeH3List>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollemployeeh3List(exists, period, periodPart, person, placementGroup, workplace, workplaceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollemployeeh3excelList(period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PayrollEmployeeH3>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollemployeeh3excelList(period, periodPart, person, placementGroup, workplace, workplaceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [exists] exists
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollemployeekjarniList(exists?: string, period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PayrollEmployeeKjarni>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollemployeekjarniList(exists, period, periodPart, person, placementGroup, workplace, workplaceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stofna starfsmann í H3.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rehireInH3(period: string, person: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rehireInH3(period, person, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stofna starfsmann í kjarna.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rehireInKjarni(period: string, person: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rehireInKjarni(period, person, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Innlestur bunka í Kjarna
         * @param {string} batch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitToKjarni(batch: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitToKjarni(batch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * Búa til aukabunka
         * @param {CreateExtraBatchRequest} createExtraBatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExtraBatch(createExtraBatchRequest: CreateExtraBatchRequest, options?: any): AxiosPromise<CreateExtraBatch> {
            return localVarFp.createExtraBatch(createExtraBatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Stofna starfsmann í h3.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInH3(period: string, person: string, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.createInH3(period, person, options).then((request) => request(axios, basePath));
        },
        /**
         * Stofna starfsmann í kjarna.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInKjarni(period: string, person: string, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.createInKjarni(period, person, options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja excel yfirlit
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollemployeeh3Excel(period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.payrollemployeeh3Excel(period, periodPart, person, placementGroup, workplace, workplaceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [exists] exists
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollemployeeh3List(exists?: string, period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options?: any): AxiosPromise<Array<PayrollEmployeeH3List>> {
            return localVarFp.payrollemployeeh3List(exists, period, periodPart, person, placementGroup, workplace, workplaceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollemployeeh3excelList(period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options?: any): AxiosPromise<Array<PayrollEmployeeH3>> {
            return localVarFp.payrollemployeeh3excelList(period, periodPart, person, placementGroup, workplace, workplaceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [exists] exists
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [person] person
         * @param {string} [placementGroup] placementGroup
         * @param {string} [workplace] workplace
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollemployeekjarniList(exists?: string, period?: string, periodPart?: string, person?: string, placementGroup?: string, workplace?: string, workplaceType?: string, options?: any): AxiosPromise<Array<PayrollEmployeeKjarni>> {
            return localVarFp.payrollemployeekjarniList(exists, period, periodPart, person, placementGroup, workplace, workplaceType, options).then((request) => request(axios, basePath));
        },
        /**
         * Stofna starfsmann í H3.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rehireInH3(period: string, person: string, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.rehireInH3(period, person, options).then((request) => request(axios, basePath));
        },
        /**
         * Stofna starfsmann í kjarna.
         * @param {string} period 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rehireInKjarni(period: string, person: string, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.rehireInKjarni(period, person, options).then((request) => request(axios, basePath));
        },
        /**
         * Innlestur bunka í Kjarna
         * @param {string} batch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitToKjarni(batch: string, options?: any): AxiosPromise<string> {
            return localVarFp.submitToKjarni(batch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createExtraBatch operation in PaymentApi.
 * @export
 * @interface PaymentApiCreateExtraBatchRequest
 */
export interface PaymentApiCreateExtraBatchRequest {
    /**
     * 
     * @type {CreateExtraBatchRequest}
     * @memberof PaymentApiCreateExtraBatch
     */
    readonly createExtraBatchRequest: CreateExtraBatchRequest
}

/**
 * Request parameters for createInH3 operation in PaymentApi.
 * @export
 * @interface PaymentApiCreateInH3Request
 */
export interface PaymentApiCreateInH3Request {
    /**
     * 
     * @type {string}
     * @memberof PaymentApiCreateInH3
     */
    readonly period: string

    /**
     * 
     * @type {string}
     * @memberof PaymentApiCreateInH3
     */
    readonly person: string
}

/**
 * Request parameters for createInKjarni operation in PaymentApi.
 * @export
 * @interface PaymentApiCreateInKjarniRequest
 */
export interface PaymentApiCreateInKjarniRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentApiCreateInKjarni
     */
    readonly period: string

    /**
     * 
     * @type {string}
     * @memberof PaymentApiCreateInKjarni
     */
    readonly person: string
}

/**
 * Request parameters for payrollemployeeh3Excel operation in PaymentApi.
 * @export
 * @interface PaymentApiPayrollemployeeh3ExcelRequest
 */
export interface PaymentApiPayrollemployeeh3ExcelRequest {
    /**
     * period
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3Excel
     */
    readonly period?: string

    /**
     * periodPart
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3Excel
     */
    readonly periodPart?: string

    /**
     * person
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3Excel
     */
    readonly person?: string

    /**
     * placementGroup
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3Excel
     */
    readonly placementGroup?: string

    /**
     * workplace
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3Excel
     */
    readonly workplace?: string

    /**
     * workplaceType
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3Excel
     */
    readonly workplaceType?: string
}

/**
 * Request parameters for payrollemployeeh3List operation in PaymentApi.
 * @export
 * @interface PaymentApiPayrollemployeeh3ListRequest
 */
export interface PaymentApiPayrollemployeeh3ListRequest {
    /**
     * exists
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3List
     */
    readonly exists?: string

    /**
     * period
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3List
     */
    readonly period?: string

    /**
     * periodPart
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3List
     */
    readonly periodPart?: string

    /**
     * person
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3List
     */
    readonly person?: string

    /**
     * placementGroup
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3List
     */
    readonly placementGroup?: string

    /**
     * workplace
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3List
     */
    readonly workplace?: string

    /**
     * workplaceType
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3List
     */
    readonly workplaceType?: string
}

/**
 * Request parameters for payrollemployeeh3excelList operation in PaymentApi.
 * @export
 * @interface PaymentApiPayrollemployeeh3excelListRequest
 */
export interface PaymentApiPayrollemployeeh3excelListRequest {
    /**
     * period
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3excelList
     */
    readonly period?: string

    /**
     * periodPart
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3excelList
     */
    readonly periodPart?: string

    /**
     * person
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3excelList
     */
    readonly person?: string

    /**
     * placementGroup
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3excelList
     */
    readonly placementGroup?: string

    /**
     * workplace
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3excelList
     */
    readonly workplace?: string

    /**
     * workplaceType
     * @type {string}
     * @memberof PaymentApiPayrollemployeeh3excelList
     */
    readonly workplaceType?: string
}

/**
 * Request parameters for payrollemployeekjarniList operation in PaymentApi.
 * @export
 * @interface PaymentApiPayrollemployeekjarniListRequest
 */
export interface PaymentApiPayrollemployeekjarniListRequest {
    /**
     * exists
     * @type {string}
     * @memberof PaymentApiPayrollemployeekjarniList
     */
    readonly exists?: string

    /**
     * period
     * @type {string}
     * @memberof PaymentApiPayrollemployeekjarniList
     */
    readonly period?: string

    /**
     * periodPart
     * @type {string}
     * @memberof PaymentApiPayrollemployeekjarniList
     */
    readonly periodPart?: string

    /**
     * person
     * @type {string}
     * @memberof PaymentApiPayrollemployeekjarniList
     */
    readonly person?: string

    /**
     * placementGroup
     * @type {string}
     * @memberof PaymentApiPayrollemployeekjarniList
     */
    readonly placementGroup?: string

    /**
     * workplace
     * @type {string}
     * @memberof PaymentApiPayrollemployeekjarniList
     */
    readonly workplace?: string

    /**
     * workplaceType
     * @type {string}
     * @memberof PaymentApiPayrollemployeekjarniList
     */
    readonly workplaceType?: string
}

/**
 * Request parameters for rehireInH3 operation in PaymentApi.
 * @export
 * @interface PaymentApiRehireInH3Request
 */
export interface PaymentApiRehireInH3Request {
    /**
     * 
     * @type {string}
     * @memberof PaymentApiRehireInH3
     */
    readonly period: string

    /**
     * 
     * @type {string}
     * @memberof PaymentApiRehireInH3
     */
    readonly person: string
}

/**
 * Request parameters for rehireInKjarni operation in PaymentApi.
 * @export
 * @interface PaymentApiRehireInKjarniRequest
 */
export interface PaymentApiRehireInKjarniRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentApiRehireInKjarni
     */
    readonly period: string

    /**
     * 
     * @type {string}
     * @memberof PaymentApiRehireInKjarni
     */
    readonly person: string
}

/**
 * Request parameters for submitToKjarni operation in PaymentApi.
 * @export
 * @interface PaymentApiSubmitToKjarniRequest
 */
export interface PaymentApiSubmitToKjarniRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentApiSubmitToKjarni
     */
    readonly batch: string
}

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * Búa til aukabunka
     * @param {PaymentApiCreateExtraBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public createExtraBatch(requestParameters: PaymentApiCreateExtraBatchRequest, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).createExtraBatch(requestParameters.createExtraBatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stofna starfsmann í h3.
     * @param {PaymentApiCreateInH3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public createInH3(requestParameters: PaymentApiCreateInH3Request, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).createInH3(requestParameters.period, requestParameters.person, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stofna starfsmann í kjarna.
     * @param {PaymentApiCreateInKjarniRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public createInKjarni(requestParameters: PaymentApiCreateInKjarniRequest, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).createInKjarni(requestParameters.period, requestParameters.person, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja excel yfirlit
     * @param {PaymentApiPayrollemployeeh3ExcelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public payrollemployeeh3Excel(requestParameters: PaymentApiPayrollemployeeh3ExcelRequest = {}, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).payrollemployeeh3Excel(requestParameters.period, requestParameters.periodPart, requestParameters.person, requestParameters.placementGroup, requestParameters.workplace, requestParameters.workplaceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentApiPayrollemployeeh3ListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public payrollemployeeh3List(requestParameters: PaymentApiPayrollemployeeh3ListRequest = {}, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).payrollemployeeh3List(requestParameters.exists, requestParameters.period, requestParameters.periodPart, requestParameters.person, requestParameters.placementGroup, requestParameters.workplace, requestParameters.workplaceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentApiPayrollemployeeh3excelListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public payrollemployeeh3excelList(requestParameters: PaymentApiPayrollemployeeh3excelListRequest = {}, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).payrollemployeeh3excelList(requestParameters.period, requestParameters.periodPart, requestParameters.person, requestParameters.placementGroup, requestParameters.workplace, requestParameters.workplaceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentApiPayrollemployeekjarniListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public payrollemployeekjarniList(requestParameters: PaymentApiPayrollemployeekjarniListRequest = {}, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).payrollemployeekjarniList(requestParameters.exists, requestParameters.period, requestParameters.periodPart, requestParameters.person, requestParameters.placementGroup, requestParameters.workplace, requestParameters.workplaceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stofna starfsmann í H3.
     * @param {PaymentApiRehireInH3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public rehireInH3(requestParameters: PaymentApiRehireInH3Request, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).rehireInH3(requestParameters.period, requestParameters.person, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stofna starfsmann í kjarna.
     * @param {PaymentApiRehireInKjarniRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public rehireInKjarni(requestParameters: PaymentApiRehireInKjarniRequest, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).rehireInKjarni(requestParameters.period, requestParameters.person, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Innlestur bunka í Kjarna
     * @param {PaymentApiSubmitToKjarniRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public submitToKjarni(requestParameters: PaymentApiSubmitToKjarniRequest, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).submitToKjarni(requestParameters.batch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlacementApi - axios parameter creator
 * @export
 */
export const PlacementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Uppfæra birtinganafn á persónu.
         * @param {string} uuid 
         * @param {PersonUpdateNameRequest} personUpdateNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personUpdateName: async (uuid: string, personUpdateNameRequest: PersonUpdateNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('personUpdateName', 'uuid', uuid)
            // verify required parameter 'personUpdateNameRequest' is not null or undefined
            assertParamExists('personUpdateName', 'personUpdateNameRequest', personUpdateNameRequest)
            const localVarPath = `/web-v3/placement/person/{uuid}/update-name/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personUpdateNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Leiðbeinendur hóps.
         * @param {string} placementGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupBirthYearList: async (placementGroup: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placementGroup' is not null or undefined
            assertParamExists('placementGroupBirthYearList', 'placementGroup', placementGroup)
            const localVarPath = `/web-v3/placement/placement-group/{placement_group}/birth-year/`
                .replace(`{${"placement_group"}}`, encodeURIComponent(String(placementGroup)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dagbók hóps.
         * @param {string} placementGroup 
         * @param {PlacementGroupDiaryDetailRequest} placementGroupDiaryDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupDiaryCreate: async (placementGroup: string, placementGroupDiaryDetailRequest: PlacementGroupDiaryDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placementGroup' is not null or undefined
            assertParamExists('placementGroupDiaryCreate', 'placementGroup', placementGroup)
            // verify required parameter 'placementGroupDiaryDetailRequest' is not null or undefined
            assertParamExists('placementGroupDiaryCreate', 'placementGroupDiaryDetailRequest', placementGroupDiaryDetailRequest)
            const localVarPath = `/web-v3/placement/placement-group/{placement_group}/diary/`
                .replace(`{${"placement_group"}}`, encodeURIComponent(String(placementGroup)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(placementGroupDiaryDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dagbók hóps.
         * @param {string} placementGroup 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupDiaryList: async (placementGroup: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placementGroup' is not null or undefined
            assertParamExists('placementGroupDiaryList', 'placementGroup', placementGroup)
            const localVarPath = `/web-v3/placement/placement-group/{placement_group}/diary/`
                .replace(`{${"placement_group"}}`, encodeURIComponent(String(placementGroup)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dagbók hóps.
         * @param {string} date 
         * @param {string} placementGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupDiaryRetrieve: async (date: string, placementGroup: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('placementGroupDiaryRetrieve', 'date', date)
            // verify required parameter 'placementGroup' is not null or undefined
            assertParamExists('placementGroupDiaryRetrieve', 'placementGroup', placementGroup)
            const localVarPath = `/web-v3/placement/placement-group/{placement_group}/diary/{date}/`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)))
                .replace(`{${"placement_group"}}`, encodeURIComponent(String(placementGroup)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Leiðbeinendur hóps.
         * @param {string} placementGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupInstructorList: async (placementGroup: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placementGroup' is not null or undefined
            assertParamExists('placementGroupInstructorList', 'placementGroup', placementGroup)
            const localVarPath = `/web-v3/placement/placement-group/{placement_group}/instructor/`
                .replace(`{${"placement_group"}}`, encodeURIComponent(String(placementGroup)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hópur.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupList: async (limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/placement/placement-group/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja summutölur yfir viðveru.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonAttendanceSummary: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('placementGroupPersonAttendanceSummary', 'uuid', uuid)
            const localVarPath = `/web-v3/placement/placement-group-person/{uuid}/attendance-summary/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja yfirlit yfir fæðingarár umsókna á sveitaféælagi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonBirthYearLookups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/placement/placement-group-person/birth-year-lookups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja alla aðstandendur í Excel
         * @param {string} [date] Viðmiðunardagsetning
         * @param {string} [workplace] workplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonContactExcel: async (date?: string, workplace?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/placement/placement-group-person-contact/excel/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Aðstandendur.
         * @param {string} [date] Viðmiðunardagsetning
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [workplace] workplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonContactList: async (date?: string, limit?: number, page?: number, workplace?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/placement/placement-group-person-contact/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja alla þátttakendur í Excel
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [date] Viðmiðunardagsetning
         * @param {string} [name] name
         * @param {'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'} [orderBy] Ordering
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [placementGroup] placementGroup
         * @param {string} [ssn] ssn
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonExcel: async (birthYear?: Array<string>, date?: string, name?: string, orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName', period?: string, periodPart?: string, placementGroup?: string, ssn?: string, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/placement/placement-group-person/excel/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (birthYear) {
                localVarQueryParameter['birthYear'] = birthYear;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (periodPart !== undefined) {
                localVarQueryParameter['periodPart'] = periodPart;
            }

            if (placementGroup !== undefined) {
                localVarQueryParameter['placementGroup'] = placementGroup;
            }

            if (ssn !== undefined) {
                localVarQueryParameter['ssn'] = ssn;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }

            if (workplaceDistrict !== undefined) {
                localVarQueryParameter['workplaceDistrict'] = workplaceDistrict;
            }

            if (workplaceType !== undefined) {
                localVarQueryParameter['workplaceType'] = workplaceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Þátttakendur.
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [date] Viðmiðunardagsetning
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [placementGroup] placementGroup
         * @param {string} [ssn] ssn
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonList: async (birthYear?: Array<string>, date?: string, limit?: number, name?: string, orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName', page?: number, period?: string, periodPart?: string, placementGroup?: string, ssn?: string, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/placement/placement-group-person/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (birthYear) {
                localVarQueryParameter['birthYear'] = birthYear;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (periodPart !== undefined) {
                localVarQueryParameter['periodPart'] = periodPart;
            }

            if (placementGroup !== undefined) {
                localVarQueryParameter['placementGroup'] = placementGroup;
            }

            if (ssn !== undefined) {
                localVarQueryParameter['ssn'] = ssn;
            }

            if (workplace !== undefined) {
                localVarQueryParameter['workplace'] = workplace;
            }

            if (workplaceDistrict !== undefined) {
                localVarQueryParameter['workplaceDistrict'] = workplaceDistrict;
            }

            if (workplaceType !== undefined) {
                localVarQueryParameter['workplaceType'] = workplaceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Þátttakendur.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonRetrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('placementGroupPersonRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/placement/placement-group-person/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Skrá mat á frammistöðu
         * @param {string} uuid 
         * @param {PlacementGroupPersonReviewRequest} placementGroupPersonReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonReview: async (uuid: string, placementGroupPersonReviewRequest: PlacementGroupPersonReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('placementGroupPersonReview', 'uuid', uuid)
            // verify required parameter 'placementGroupPersonReviewRequest' is not null or undefined
            assertParamExists('placementGroupPersonReview', 'placementGroupPersonReviewRequest', placementGroupPersonReviewRequest)
            const localVarPath = `/web-v3/placement/placement-group-person/{uuid}/review/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(placementGroupPersonReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hópur.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupRetrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('placementGroupRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/placement/placement-group/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {ReviewQuestionDetailRequest} reviewQuestionDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionCreate: async (reviewQuestionDetailRequest: ReviewQuestionDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewQuestionDetailRequest' is not null or undefined
            assertParamExists('reviewQuestionCreate', 'reviewQuestionDetailRequest', reviewQuestionDetailRequest)
            const localVarPath = `/web-v3/placement/review-question/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewQuestionDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kvarði á frammistöðumati. - Fellilisti
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionDropdownList: async (period?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/placement/review-question-dropdown/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionList: async (limit?: number, page?: number, period?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web-v3/placement/review-question/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {string} uuid 
         * @param {PatchedReviewQuestionDetailUpdateRequest} [patchedReviewQuestionDetailUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionPartialUpdate: async (uuid: string, patchedReviewQuestionDetailUpdateRequest?: PatchedReviewQuestionDetailUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('reviewQuestionPartialUpdate', 'uuid', uuid)
            const localVarPath = `/web-v3/placement/review-question/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedReviewQuestionDetailUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionRetrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('reviewQuestionRetrieve', 'uuid', uuid)
            const localVarPath = `/web-v3/placement/review-question/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {string} uuid 
         * @param {ReviewQuestionDetailUpdateRequest} reviewQuestionDetailUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionUpdate: async (uuid: string, reviewQuestionDetailUpdateRequest: ReviewQuestionDetailUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('reviewQuestionUpdate', 'uuid', uuid)
            // verify required parameter 'reviewQuestionDetailUpdateRequest' is not null or undefined
            assertParamExists('reviewQuestionUpdate', 'reviewQuestionDetailUpdateRequest', reviewQuestionDetailUpdateRequest)
            const localVarPath = `/web-v3/placement/review-question/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VinnuskoliJSONWebTokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewQuestionDetailUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlacementApi - functional programming interface
 * @export
 */
export const PlacementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlacementApiAxiosParamCreator(configuration)
    return {
        /**
         * Uppfæra birtinganafn á persónu.
         * @param {string} uuid 
         * @param {PersonUpdateNameRequest} personUpdateNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personUpdateName(uuid: string, personUpdateNameRequest: PersonUpdateNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personUpdateName(uuid, personUpdateNameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Leiðbeinendur hóps.
         * @param {string} placementGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupBirthYearList(placementGroup: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlacementGroupBirthYearList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupBirthYearList(placementGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Dagbók hóps.
         * @param {string} placementGroup 
         * @param {PlacementGroupDiaryDetailRequest} placementGroupDiaryDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupDiaryCreate(placementGroup: string, placementGroupDiaryDetailRequest: PlacementGroupDiaryDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlacementGroupDiaryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupDiaryCreate(placementGroup, placementGroupDiaryDetailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Dagbók hóps.
         * @param {string} placementGroup 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupDiaryList(placementGroup: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlacementGroupDiaryList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupDiaryList(placementGroup, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Dagbók hóps.
         * @param {string} date 
         * @param {string} placementGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupDiaryRetrieve(date: string, placementGroup: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlacementGroupDiaryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupDiaryRetrieve(date, placementGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Leiðbeinendur hóps.
         * @param {string} placementGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupInstructorList(placementGroup: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlacementGroupInstructorList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupInstructorList(placementGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hópur.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupList(limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlacementGroupList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupList(limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja summutölur yfir viðveru.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupPersonAttendanceSummary(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendanceSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupPersonAttendanceSummary(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja yfirlit yfir fæðingarár umsókna á sveitaféælagi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupPersonBirthYearLookups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupPersonBirthYearLookups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja alla aðstandendur í Excel
         * @param {string} [date] Viðmiðunardagsetning
         * @param {string} [workplace] workplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupPersonContactExcel(date?: string, workplace?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupPersonContactExcel(date, workplace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Aðstandendur.
         * @param {string} [date] Viðmiðunardagsetning
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [workplace] workplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupPersonContactList(date?: string, limit?: number, page?: number, workplace?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlacementGroupPersonContact>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupPersonContactList(date, limit, page, workplace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja alla þátttakendur í Excel
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [date] Viðmiðunardagsetning
         * @param {string} [name] name
         * @param {'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'} [orderBy] Ordering
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [placementGroup] placementGroup
         * @param {string} [ssn] ssn
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupPersonExcel(birthYear?: Array<string>, date?: string, name?: string, orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName', period?: string, periodPart?: string, placementGroup?: string, ssn?: string, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupPersonExcel(birthYear, date, name, orderBy, period, periodPart, placementGroup, ssn, workplace, workplaceDistrict, workplaceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Þátttakendur.
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [date] Viðmiðunardagsetning
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [placementGroup] placementGroup
         * @param {string} [ssn] ssn
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupPersonList(birthYear?: Array<string>, date?: string, limit?: number, name?: string, orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName', page?: number, period?: string, periodPart?: string, placementGroup?: string, ssn?: string, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlacementGroupPersonList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupPersonList(birthYear, date, limit, name, orderBy, page, period, periodPart, placementGroup, ssn, workplace, workplaceDistrict, workplaceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Þátttakendur.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupPersonRetrieve(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlacementGroupPersonDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupPersonRetrieve(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Skrá mat á frammistöðu
         * @param {string} uuid 
         * @param {PlacementGroupPersonReviewRequest} placementGroupPersonReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupPersonReview(uuid: string, placementGroupPersonReviewRequest: PlacementGroupPersonReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupPersonReview(uuid, placementGroupPersonReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hópur.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placementGroupRetrieve(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlacementGroupDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placementGroupRetrieve(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {ReviewQuestionDetailRequest} reviewQuestionDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewQuestionCreate(reviewQuestionDetailRequest: ReviewQuestionDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewQuestionDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewQuestionCreate(reviewQuestionDetailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Kvarði á frammistöðumati. - Fellilisti
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewQuestionDropdownList(period?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewQuestionDropdown>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewQuestionDropdownList(period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewQuestionList(limit?: number, page?: number, period?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewQuestionList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewQuestionList(limit, page, period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {string} uuid 
         * @param {PatchedReviewQuestionDetailUpdateRequest} [patchedReviewQuestionDetailUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewQuestionPartialUpdate(uuid: string, patchedReviewQuestionDetailUpdateRequest?: PatchedReviewQuestionDetailUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewQuestionDetailUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewQuestionPartialUpdate(uuid, patchedReviewQuestionDetailUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewQuestionRetrieve(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewQuestionDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewQuestionRetrieve(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {string} uuid 
         * @param {ReviewQuestionDetailUpdateRequest} reviewQuestionDetailUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewQuestionUpdate(uuid: string, reviewQuestionDetailUpdateRequest: ReviewQuestionDetailUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewQuestionDetailUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewQuestionUpdate(uuid, reviewQuestionDetailUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlacementApi - factory interface
 * @export
 */
export const PlacementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlacementApiFp(configuration)
    return {
        /**
         * Uppfæra birtinganafn á persónu.
         * @param {string} uuid 
         * @param {PersonUpdateNameRequest} personUpdateNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personUpdateName(uuid: string, personUpdateNameRequest: PersonUpdateNameRequest, options?: any): AxiosPromise<void> {
            return localVarFp.personUpdateName(uuid, personUpdateNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Leiðbeinendur hóps.
         * @param {string} placementGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupBirthYearList(placementGroup: string, options?: any): AxiosPromise<Array<PlacementGroupBirthYearList>> {
            return localVarFp.placementGroupBirthYearList(placementGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Dagbók hóps.
         * @param {string} placementGroup 
         * @param {PlacementGroupDiaryDetailRequest} placementGroupDiaryDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupDiaryCreate(placementGroup: string, placementGroupDiaryDetailRequest: PlacementGroupDiaryDetailRequest, options?: any): AxiosPromise<PlacementGroupDiaryDetail> {
            return localVarFp.placementGroupDiaryCreate(placementGroup, placementGroupDiaryDetailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Dagbók hóps.
         * @param {string} placementGroup 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupDiaryList(placementGroup: string, limit?: number, page?: number, options?: any): AxiosPromise<Array<PlacementGroupDiaryList>> {
            return localVarFp.placementGroupDiaryList(placementGroup, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Dagbók hóps.
         * @param {string} date 
         * @param {string} placementGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupDiaryRetrieve(date: string, placementGroup: string, options?: any): AxiosPromise<PlacementGroupDiaryDetail> {
            return localVarFp.placementGroupDiaryRetrieve(date, placementGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Leiðbeinendur hóps.
         * @param {string} placementGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupInstructorList(placementGroup: string, options?: any): AxiosPromise<Array<PlacementGroupInstructorList>> {
            return localVarFp.placementGroupInstructorList(placementGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Hópur.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupList(limit?: number, page?: number, options?: any): AxiosPromise<Array<PlacementGroupList>> {
            return localVarFp.placementGroupList(limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja summutölur yfir viðveru.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonAttendanceSummary(uuid: string, options?: any): AxiosPromise<AttendanceSummary> {
            return localVarFp.placementGroupPersonAttendanceSummary(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja yfirlit yfir fæðingarár umsókna á sveitaféælagi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonBirthYearLookups(options?: any): AxiosPromise<Array<number>> {
            return localVarFp.placementGroupPersonBirthYearLookups(options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja alla aðstandendur í Excel
         * @param {string} [date] Viðmiðunardagsetning
         * @param {string} [workplace] workplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonContactExcel(date?: string, workplace?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.placementGroupPersonContactExcel(date, workplace, options).then((request) => request(axios, basePath));
        },
        /**
         * Aðstandendur.
         * @param {string} [date] Viðmiðunardagsetning
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [workplace] workplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonContactList(date?: string, limit?: number, page?: number, workplace?: string, options?: any): AxiosPromise<Array<PlacementGroupPersonContact>> {
            return localVarFp.placementGroupPersonContactList(date, limit, page, workplace, options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja alla þátttakendur í Excel
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [date] Viðmiðunardagsetning
         * @param {string} [name] name
         * @param {'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'} [orderBy] Ordering
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [placementGroup] placementGroup
         * @param {string} [ssn] ssn
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonExcel(birthYear?: Array<string>, date?: string, name?: string, orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName', period?: string, periodPart?: string, placementGroup?: string, ssn?: string, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.placementGroupPersonExcel(birthYear, date, name, orderBy, period, periodPart, placementGroup, ssn, workplace, workplaceDistrict, workplaceType, options).then((request) => request(axios, basePath));
        },
        /**
         * Þátttakendur.
         * @param {Array<string>} [birthYear] birthYear
         * @param {string} [date] Viðmiðunardagsetning
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] name
         * @param {'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'} [orderBy] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [period] period
         * @param {string} [periodPart] periodPart
         * @param {string} [placementGroup] placementGroup
         * @param {string} [ssn] ssn
         * @param {string} [workplace] workplace
         * @param {string} [workplaceDistrict] workplaceDistrict
         * @param {string} [workplaceType] workplaceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonList(birthYear?: Array<string>, date?: string, limit?: number, name?: string, orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName', page?: number, period?: string, periodPart?: string, placementGroup?: string, ssn?: string, workplace?: string, workplaceDistrict?: string, workplaceType?: string, options?: any): AxiosPromise<Array<PlacementGroupPersonList>> {
            return localVarFp.placementGroupPersonList(birthYear, date, limit, name, orderBy, page, period, periodPart, placementGroup, ssn, workplace, workplaceDistrict, workplaceType, options).then((request) => request(axios, basePath));
        },
        /**
         * Þátttakendur.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonRetrieve(uuid: string, options?: any): AxiosPromise<PlacementGroupPersonDetail> {
            return localVarFp.placementGroupPersonRetrieve(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Skrá mat á frammistöðu
         * @param {string} uuid 
         * @param {PlacementGroupPersonReviewRequest} placementGroupPersonReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupPersonReview(uuid: string, placementGroupPersonReviewRequest: PlacementGroupPersonReviewRequest, options?: any): AxiosPromise<void> {
            return localVarFp.placementGroupPersonReview(uuid, placementGroupPersonReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Hópur.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placementGroupRetrieve(uuid: string, options?: any): AxiosPromise<PlacementGroupDetail> {
            return localVarFp.placementGroupRetrieve(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {ReviewQuestionDetailRequest} reviewQuestionDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionCreate(reviewQuestionDetailRequest: ReviewQuestionDetailRequest, options?: any): AxiosPromise<ReviewQuestionDetail> {
            return localVarFp.reviewQuestionCreate(reviewQuestionDetailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Kvarði á frammistöðumati. - Fellilisti
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionDropdownList(period?: string, options?: any): AxiosPromise<Array<ReviewQuestionDropdown>> {
            return localVarFp.reviewQuestionDropdownList(period, options).then((request) => request(axios, basePath));
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionList(limit?: number, page?: number, period?: string, options?: any): AxiosPromise<Array<ReviewQuestionList>> {
            return localVarFp.reviewQuestionList(limit, page, period, options).then((request) => request(axios, basePath));
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {string} uuid 
         * @param {PatchedReviewQuestionDetailUpdateRequest} [patchedReviewQuestionDetailUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionPartialUpdate(uuid: string, patchedReviewQuestionDetailUpdateRequest?: PatchedReviewQuestionDetailUpdateRequest, options?: any): AxiosPromise<ReviewQuestionDetailUpdate> {
            return localVarFp.reviewQuestionPartialUpdate(uuid, patchedReviewQuestionDetailUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionRetrieve(uuid: string, options?: any): AxiosPromise<ReviewQuestionDetail> {
            return localVarFp.reviewQuestionRetrieve(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Kvarði á frammistöðumati.
         * @param {string} uuid 
         * @param {ReviewQuestionDetailUpdateRequest} reviewQuestionDetailUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewQuestionUpdate(uuid: string, reviewQuestionDetailUpdateRequest: ReviewQuestionDetailUpdateRequest, options?: any): AxiosPromise<ReviewQuestionDetailUpdate> {
            return localVarFp.reviewQuestionUpdate(uuid, reviewQuestionDetailUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for personUpdateName operation in PlacementApi.
 * @export
 * @interface PlacementApiPersonUpdateNameRequest
 */
export interface PlacementApiPersonUpdateNameRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPersonUpdateName
     */
    readonly uuid: string

    /**
     * 
     * @type {PersonUpdateNameRequest}
     * @memberof PlacementApiPersonUpdateName
     */
    readonly personUpdateNameRequest: PersonUpdateNameRequest
}

/**
 * Request parameters for placementGroupBirthYearList operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupBirthYearListRequest
 */
export interface PlacementApiPlacementGroupBirthYearListRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupBirthYearList
     */
    readonly placementGroup: string
}

/**
 * Request parameters for placementGroupDiaryCreate operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupDiaryCreateRequest
 */
export interface PlacementApiPlacementGroupDiaryCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupDiaryCreate
     */
    readonly placementGroup: string

    /**
     * 
     * @type {PlacementGroupDiaryDetailRequest}
     * @memberof PlacementApiPlacementGroupDiaryCreate
     */
    readonly placementGroupDiaryDetailRequest: PlacementGroupDiaryDetailRequest
}

/**
 * Request parameters for placementGroupDiaryList operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupDiaryListRequest
 */
export interface PlacementApiPlacementGroupDiaryListRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupDiaryList
     */
    readonly placementGroup: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PlacementApiPlacementGroupDiaryList
     */
    readonly limit?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PlacementApiPlacementGroupDiaryList
     */
    readonly page?: number
}

/**
 * Request parameters for placementGroupDiaryRetrieve operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupDiaryRetrieveRequest
 */
export interface PlacementApiPlacementGroupDiaryRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupDiaryRetrieve
     */
    readonly date: string

    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupDiaryRetrieve
     */
    readonly placementGroup: string
}

/**
 * Request parameters for placementGroupInstructorList operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupInstructorListRequest
 */
export interface PlacementApiPlacementGroupInstructorListRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupInstructorList
     */
    readonly placementGroup: string
}

/**
 * Request parameters for placementGroupList operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupListRequest
 */
export interface PlacementApiPlacementGroupListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PlacementApiPlacementGroupList
     */
    readonly limit?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PlacementApiPlacementGroupList
     */
    readonly page?: number
}

/**
 * Request parameters for placementGroupPersonAttendanceSummary operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupPersonAttendanceSummaryRequest
 */
export interface PlacementApiPlacementGroupPersonAttendanceSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonAttendanceSummary
     */
    readonly uuid: string
}

/**
 * Request parameters for placementGroupPersonContactExcel operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupPersonContactExcelRequest
 */
export interface PlacementApiPlacementGroupPersonContactExcelRequest {
    /**
     * Viðmiðunardagsetning
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonContactExcel
     */
    readonly date?: string

    /**
     * workplace
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonContactExcel
     */
    readonly workplace?: string
}

/**
 * Request parameters for placementGroupPersonContactList operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupPersonContactListRequest
 */
export interface PlacementApiPlacementGroupPersonContactListRequest {
    /**
     * Viðmiðunardagsetning
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonContactList
     */
    readonly date?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PlacementApiPlacementGroupPersonContactList
     */
    readonly limit?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PlacementApiPlacementGroupPersonContactList
     */
    readonly page?: number

    /**
     * workplace
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonContactList
     */
    readonly workplace?: string
}

/**
 * Request parameters for placementGroupPersonExcel operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupPersonExcelRequest
 */
export interface PlacementApiPlacementGroupPersonExcelRequest {
    /**
     * birthYear
     * @type {Array<string>}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly birthYear?: Array<string>

    /**
     * Viðmiðunardagsetning
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly date?: string

    /**
     * name
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly name?: string

    /**
     * Ordering
     * @type {'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'

    /**
     * period
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly period?: string

    /**
     * periodPart
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly periodPart?: string

    /**
     * placementGroup
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly placementGroup?: string

    /**
     * ssn
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly ssn?: string

    /**
     * workplace
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly workplace?: string

    /**
     * workplaceDistrict
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly workplaceDistrict?: string

    /**
     * workplaceType
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonExcel
     */
    readonly workplaceType?: string
}

/**
 * Request parameters for placementGroupPersonList operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupPersonListRequest
 */
export interface PlacementApiPlacementGroupPersonListRequest {
    /**
     * birthYear
     * @type {Array<string>}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly birthYear?: Array<string>

    /**
     * Viðmiðunardagsetning
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly date?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly limit?: number

    /**
     * name
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly name?: string

    /**
     * Ordering
     * @type {'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly orderBy?: 'email' | '-email' | 'tel' | '-tel' | 'birthYear' | '-birthYear' | 'personSsn' | '-personSsn' | 'personName' | '-personName' | 'workplaceName' | '-workplaceName' | 'periodName' | '-periodName' | 'placementGroupName' | '-placementGroupName'

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly page?: number

    /**
     * period
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly period?: string

    /**
     * periodPart
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly periodPart?: string

    /**
     * placementGroup
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly placementGroup?: string

    /**
     * ssn
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly ssn?: string

    /**
     * workplace
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly workplace?: string

    /**
     * workplaceDistrict
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly workplaceDistrict?: string

    /**
     * workplaceType
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonList
     */
    readonly workplaceType?: string
}

/**
 * Request parameters for placementGroupPersonRetrieve operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupPersonRetrieveRequest
 */
export interface PlacementApiPlacementGroupPersonRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonRetrieve
     */
    readonly uuid: string
}

/**
 * Request parameters for placementGroupPersonReview operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupPersonReviewRequest
 */
export interface PlacementApiPlacementGroupPersonReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupPersonReview
     */
    readonly uuid: string

    /**
     * 
     * @type {PlacementGroupPersonReviewRequest}
     * @memberof PlacementApiPlacementGroupPersonReview
     */
    readonly placementGroupPersonReviewRequest: PlacementGroupPersonReviewRequest
}

/**
 * Request parameters for placementGroupRetrieve operation in PlacementApi.
 * @export
 * @interface PlacementApiPlacementGroupRetrieveRequest
 */
export interface PlacementApiPlacementGroupRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiPlacementGroupRetrieve
     */
    readonly uuid: string
}

/**
 * Request parameters for reviewQuestionCreate operation in PlacementApi.
 * @export
 * @interface PlacementApiReviewQuestionCreateRequest
 */
export interface PlacementApiReviewQuestionCreateRequest {
    /**
     * 
     * @type {ReviewQuestionDetailRequest}
     * @memberof PlacementApiReviewQuestionCreate
     */
    readonly reviewQuestionDetailRequest: ReviewQuestionDetailRequest
}

/**
 * Request parameters for reviewQuestionDropdownList operation in PlacementApi.
 * @export
 * @interface PlacementApiReviewQuestionDropdownListRequest
 */
export interface PlacementApiReviewQuestionDropdownListRequest {
    /**
     * period
     * @type {string}
     * @memberof PlacementApiReviewQuestionDropdownList
     */
    readonly period?: string
}

/**
 * Request parameters for reviewQuestionList operation in PlacementApi.
 * @export
 * @interface PlacementApiReviewQuestionListRequest
 */
export interface PlacementApiReviewQuestionListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PlacementApiReviewQuestionList
     */
    readonly limit?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PlacementApiReviewQuestionList
     */
    readonly page?: number

    /**
     * period
     * @type {string}
     * @memberof PlacementApiReviewQuestionList
     */
    readonly period?: string
}

/**
 * Request parameters for reviewQuestionPartialUpdate operation in PlacementApi.
 * @export
 * @interface PlacementApiReviewQuestionPartialUpdateRequest
 */
export interface PlacementApiReviewQuestionPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiReviewQuestionPartialUpdate
     */
    readonly uuid: string

    /**
     * 
     * @type {PatchedReviewQuestionDetailUpdateRequest}
     * @memberof PlacementApiReviewQuestionPartialUpdate
     */
    readonly patchedReviewQuestionDetailUpdateRequest?: PatchedReviewQuestionDetailUpdateRequest
}

/**
 * Request parameters for reviewQuestionRetrieve operation in PlacementApi.
 * @export
 * @interface PlacementApiReviewQuestionRetrieveRequest
 */
export interface PlacementApiReviewQuestionRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiReviewQuestionRetrieve
     */
    readonly uuid: string
}

/**
 * Request parameters for reviewQuestionUpdate operation in PlacementApi.
 * @export
 * @interface PlacementApiReviewQuestionUpdateRequest
 */
export interface PlacementApiReviewQuestionUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlacementApiReviewQuestionUpdate
     */
    readonly uuid: string

    /**
     * 
     * @type {ReviewQuestionDetailUpdateRequest}
     * @memberof PlacementApiReviewQuestionUpdate
     */
    readonly reviewQuestionDetailUpdateRequest: ReviewQuestionDetailUpdateRequest
}

/**
 * PlacementApi - object-oriented interface
 * @export
 * @class PlacementApi
 * @extends {BaseAPI}
 */
export class PlacementApi extends BaseAPI {
    /**
     * Uppfæra birtinganafn á persónu.
     * @param {PlacementApiPersonUpdateNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public personUpdateName(requestParameters: PlacementApiPersonUpdateNameRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).personUpdateName(requestParameters.uuid, requestParameters.personUpdateNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Leiðbeinendur hóps.
     * @param {PlacementApiPlacementGroupBirthYearListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupBirthYearList(requestParameters: PlacementApiPlacementGroupBirthYearListRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupBirthYearList(requestParameters.placementGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dagbók hóps.
     * @param {PlacementApiPlacementGroupDiaryCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupDiaryCreate(requestParameters: PlacementApiPlacementGroupDiaryCreateRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupDiaryCreate(requestParameters.placementGroup, requestParameters.placementGroupDiaryDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dagbók hóps.
     * @param {PlacementApiPlacementGroupDiaryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupDiaryList(requestParameters: PlacementApiPlacementGroupDiaryListRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupDiaryList(requestParameters.placementGroup, requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dagbók hóps.
     * @param {PlacementApiPlacementGroupDiaryRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupDiaryRetrieve(requestParameters: PlacementApiPlacementGroupDiaryRetrieveRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupDiaryRetrieve(requestParameters.date, requestParameters.placementGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Leiðbeinendur hóps.
     * @param {PlacementApiPlacementGroupInstructorListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupInstructorList(requestParameters: PlacementApiPlacementGroupInstructorListRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupInstructorList(requestParameters.placementGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hópur.
     * @param {PlacementApiPlacementGroupListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupList(requestParameters: PlacementApiPlacementGroupListRequest = {}, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupList(requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja summutölur yfir viðveru.
     * @param {PlacementApiPlacementGroupPersonAttendanceSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupPersonAttendanceSummary(requestParameters: PlacementApiPlacementGroupPersonAttendanceSummaryRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupPersonAttendanceSummary(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja yfirlit yfir fæðingarár umsókna á sveitaféælagi.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupPersonBirthYearLookups(options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupPersonBirthYearLookups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja alla aðstandendur í Excel
     * @param {PlacementApiPlacementGroupPersonContactExcelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupPersonContactExcel(requestParameters: PlacementApiPlacementGroupPersonContactExcelRequest = {}, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupPersonContactExcel(requestParameters.date, requestParameters.workplace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Aðstandendur.
     * @param {PlacementApiPlacementGroupPersonContactListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupPersonContactList(requestParameters: PlacementApiPlacementGroupPersonContactListRequest = {}, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupPersonContactList(requestParameters.date, requestParameters.limit, requestParameters.page, requestParameters.workplace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja alla þátttakendur í Excel
     * @param {PlacementApiPlacementGroupPersonExcelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupPersonExcel(requestParameters: PlacementApiPlacementGroupPersonExcelRequest = {}, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupPersonExcel(requestParameters.birthYear, requestParameters.date, requestParameters.name, requestParameters.orderBy, requestParameters.period, requestParameters.periodPart, requestParameters.placementGroup, requestParameters.ssn, requestParameters.workplace, requestParameters.workplaceDistrict, requestParameters.workplaceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Þátttakendur.
     * @param {PlacementApiPlacementGroupPersonListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupPersonList(requestParameters: PlacementApiPlacementGroupPersonListRequest = {}, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupPersonList(requestParameters.birthYear, requestParameters.date, requestParameters.limit, requestParameters.name, requestParameters.orderBy, requestParameters.page, requestParameters.period, requestParameters.periodPart, requestParameters.placementGroup, requestParameters.ssn, requestParameters.workplace, requestParameters.workplaceDistrict, requestParameters.workplaceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Þátttakendur.
     * @param {PlacementApiPlacementGroupPersonRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupPersonRetrieve(requestParameters: PlacementApiPlacementGroupPersonRetrieveRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupPersonRetrieve(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Skrá mat á frammistöðu
     * @param {PlacementApiPlacementGroupPersonReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupPersonReview(requestParameters: PlacementApiPlacementGroupPersonReviewRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupPersonReview(requestParameters.uuid, requestParameters.placementGroupPersonReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hópur.
     * @param {PlacementApiPlacementGroupRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public placementGroupRetrieve(requestParameters: PlacementApiPlacementGroupRetrieveRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).placementGroupRetrieve(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kvarði á frammistöðumati.
     * @param {PlacementApiReviewQuestionCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public reviewQuestionCreate(requestParameters: PlacementApiReviewQuestionCreateRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).reviewQuestionCreate(requestParameters.reviewQuestionDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kvarði á frammistöðumati. - Fellilisti
     * @param {PlacementApiReviewQuestionDropdownListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public reviewQuestionDropdownList(requestParameters: PlacementApiReviewQuestionDropdownListRequest = {}, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).reviewQuestionDropdownList(requestParameters.period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kvarði á frammistöðumati.
     * @param {PlacementApiReviewQuestionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public reviewQuestionList(requestParameters: PlacementApiReviewQuestionListRequest = {}, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).reviewQuestionList(requestParameters.limit, requestParameters.page, requestParameters.period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kvarði á frammistöðumati.
     * @param {PlacementApiReviewQuestionPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public reviewQuestionPartialUpdate(requestParameters: PlacementApiReviewQuestionPartialUpdateRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).reviewQuestionPartialUpdate(requestParameters.uuid, requestParameters.patchedReviewQuestionDetailUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kvarði á frammistöðumati.
     * @param {PlacementApiReviewQuestionRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public reviewQuestionRetrieve(requestParameters: PlacementApiReviewQuestionRetrieveRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).reviewQuestionRetrieve(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kvarði á frammistöðumati.
     * @param {PlacementApiReviewQuestionUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacementApi
     */
    public reviewQuestionUpdate(requestParameters: PlacementApiReviewQuestionUpdateRequest, options?: AxiosRequestConfig) {
        return PlacementApiFp(this.configuration).reviewQuestionUpdate(requestParameters.uuid, requestParameters.reviewQuestionDetailUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


