
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { PeriodDropdown, ReviewQuestionList } from '@/api/openapi';
import Pagination from '@/components/Pagination.vue';
import { parsePagination } from '@/utils';

import ReviewQuestionModal from '@/components/reviewquestions/ReviewQuestionModal.vue';

@Component({
  components: { Pagination, ReviewQuestionModal },
})
export default class ReviewQuestions extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  @Getter loggedInUserHasAnyPermission: any;

  data: Array<ReviewQuestionList> | null = null;
  loading: boolean = true;
  pagination: any | null = null;

  form: {
    period: string | undefined;
  } = {
    period: undefined,
  };

  periods: {
    loading: boolean;
    error: boolean;
    data: Array<PeriodDropdown> | null;
  } = {
    loading: true,
    error: false,
    data: null,
  };

  $refs!: {
    modal: ReviewQuestionModal;
  };

  async loadList(page?: number, limit?: number) {
    const variables = {
      page: page || undefined,
      limit: limit || undefined,
      period: this.form.period,
    };

    this.loading = true;
    this.data = [];

    try {
      const response = await this.$placementApi.reviewQuestionList(variables);

      this.data = response.data;

      this.pagination = parsePagination(response);
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading = false;
    }
  }

  async loadPeriods() {
    this.periods.loading = true;
    this.periods.data = [];
    this.periods.error = false;

    try {
      const response = await this.$coreApi.periodDropdownList();

      this.periods.data = response.data;
    } catch (e) {
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      this.periods.error = true;
    } finally {
      this.periods.loading = false;
    }
  }

  changeLimit(limit: number) {
    this.loadList(undefined, limit);
  }

  changePage(page: number) {
    this.loadList(page, this.pagination.currentLimit);
  }

  async created() {
    this.loadPeriods();
    this.loadList();
  }

  showModal(id?: string) {
    this.$refs.modal.show(id);
  }
}
