<template>
  <div id="wrapper">
    <side-nav :side-nav-toggled="sideNavToggled" :set-side-nav-toggled="setSideNavToggled" />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <top-bar :side-nav-toggled="sideNavToggled" :set-side-nav-toggled="setSideNavToggled" />
        <b-container fluid>
          <b-alert v-if="loggedInUser && loggedInUser.isOnBehalf" variant="warning" show class="text-center">
            Ath. Þú ert inni sem {{ loggedInUser.name }}. <a href="/advania-hijack/release/">Smelltu hér til að sleppa aðgangi.</a>
          </b-alert>
          <div class="px-lg-5 pt-2">
            <slot></slot>
          </div>
        </b-container>
        <slot name="modal-footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import TopBar from './TopBar.vue';
import SideNav from './SideNav.vue';

export default {
  name: 'vinnuskoli-page',
  components: {
    TopBar,
    SideNav,
  },
  data() {
    return {
      sideNavToggled: false,
    };
  },
  methods: {
    setSideNavToggled(sideNavToggled) {
      this.sideNavToggled = sideNavToggled;
    },
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
};
</script>
