<template>
  <tr>
    <td v-if="loggedInUserHasAnyPermission('read_person')">
      <b-link :to="{ name: 'StudentWithGroup', params: { id: item.person.id, group: groupId } }" target="_blank">
        <i class="fa fa-fw fa-external-link"></i>
        {{ item.person.name }}
      </b-link>
    </td>
    <td v-else>
      {{ item.person.name }}
    </td>
    <td>{{ item.person.ssn }}</td>
    <td>{{ item.special_needs }}</td>
    <td v-if="!item.attendance_type">
      <b-form-input
        :id="`student${item.id}`"
        :name="`student${item.id}`"
        v-if="loggedInUserHasAnyPermission('write_attendance')"
        type="text"
        v-model="item.hours"
        style="width: 55px; padding-top: 0.1rem; padding-bottom: 0.1rem"
        :disabled="batch"
        @change="save()"
      ></b-form-input>
      <div v-else>
        <span v-if="!item.hours"><i>Ekki skráð</i></span>
        {{ item.hours }}
      </div>
    </td>
    <td v-if="item.attendance_type">
      <span><i>Fjarvist: </i> {{ item.attendance_type.name }}</span>
    </td>
    <td v-if="projectList.length > 0">
      <b-form-select
        :id="`student${item.id}`"
        :name="`student${item.id}`"
        v-model="attendance_project_id"
        style="width: 200px"
        :disabled="batch"
        @change="save()"
      >
        <option :value="null">-- Veldu verkefni --</option>
        <option v-for="project in projectList" :key="project.id" :value="project.id">
          {{ project.name }}
        </option>
      </b-form-select>
    </td>
    <td :class="item.period_max_hours - item.period_hours_total <= 10 && item.period_max_hours !== null ? 'text-danger' : ''">
      {{ item.period_hours_total }}
      <span v-if="item.period_max_hours">/{{ Number(item.period_max_hours) }}</span>
    </td>
    <!-- <td v-if="item.attendance_type"><i>Fjarvist: </i> {{ item.attendance_type.name }}</td> -->
    <td>
      <a @click.prevent="registerAbsenceModal()" v-if="!item.attendance_type && !batch && loggedInUserHasAnyPermission('write_attendance')">
        <i class="fa fa-fw fa-plus-circle text-success" style="font-size: 20px"></i>
      </a>
      <a @click.prevent="removeAbsence()" v-if="item.attendance_type && !batch && loggedInUserHasAnyPermission('write_attendance')">
        <i class="fa fa-fw fa-times-circle text-danger" style="font-size: 20px"></i>
      </a>
    </td>
    <td>
      <div v-b-tooltip.hover :title="item.comment">
        {{ item.comment | limitTo(20) }}
        <a @click.prevent="registerCommentModal()" v-if="!batch && loggedInUserHasAnyPermission('write_attendance')">
          <i class="fa fa-fw fa-pencil"></i>
        </a>
      </div>
    </td>
    <register-absence-modal ref="registerAbsenceModal" @successful="saveAttendanceType"></register-absence-modal>
    <register-comment-modal ref="registerCommentModal" @successful="saveComment"></register-comment-modal>
  </tr>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import attendance from '@/api/attendance';
import RegisterAbsenceModal from '@/components/attendance/RegisterAbsenceModal.vue';
import RegisterCommentModal from '@/components/attendance/RegisterCommentModal.vue';

export default {
  name: 'attendance-item',
  props: ['item', 'date', 'saveInfo', 'batch', 'maxHours', 'weekOld', 'projectList', 'groupId'],
  components: {
    RegisterAbsenceModal,
    RegisterCommentModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      loading: false,
      attendance_project_id: null,
    };
  },
  methods: {
    async reload() {
      try {
        const response = await attendance.attendanceDetail(this.item.attendance_id);
        this.$set(this.item, 'hours', response.data.hours);
        this.$set(this.item, 'comment', response.data.comment);
        this.$set(this.item, 'attendance_type', response.data.attendance_type);
        this.$set(this.item, 'attendance_project', response.data.attendance_project);
        this.$set(this.item, 'period_hours_total', response.data.period_hours_total);
        this.attendance_project_id = response.data.attendance_project ? response.data.attendance_project.id : null;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        const data = {
          date: moment(this.date).format('YYYY-MM-DD'),
          person: this.item.id,
          attendance_type: this.item.attendance_type ? this.item.attendance_type.id : null,
          attendance_project: this.attendance_project_id ? this.attendance_project_id : null,
          hours: this.item.hours && this.item.hours !== '' ? this.item.hours.toString().replace(/,/g, '.') : '0',
          comment: this.item.comment,
        };
        if ((this.maxHours == null || this.maxHours >= data.hours) && data.hours >= 0) {
          if (this.item.attendance_id) {
            await attendance.attendanceUpdate(this.item.attendance_id, data);
            this.displaySuccess(`Viðveru fyrir „${this.item.person.name}“ hefur verið breytt.`);
            this.reload();
          } else {
            const response = await attendance.attendanceCreate(data);
            this.$set(this.item, 'attendance_id', response.data.id);
            this.displaySuccess(`Viðvera fyrir „${this.item.person.name}“ hefur verið vistuð.`);
            this.reload();
          }
        } else {
          this.displayError(`Skráning tíma mistókst. Hámarks fjöldi tíma hópsins á þessum degi er: ${this.maxHours}.`);
          this.item.hours = '';
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        this.$log.debug(e);
      }
    },
    removeAbsence() {
      // eslint-disable-next-line
      const conf = confirm(
        `Ertu viss um að þú viljir eyða fjarvistinni ${this.item.attendance_type.name} af þátttakandanum ${this.item.person.name}`,
      ); //eslint-disable-line
      if (conf) {
        this.$set(this.item, 'attendance_type', null);
        this.save();
      }
    },
    saveComment(data) {
      if (data.comment) {
        this.$set(this.item, 'comment', data.comment);
        this.save();
      }
    },
    saveAttendanceType(data) {
      if (data.attendance_type) {
        this.$set(this.item, 'hours', '0');
        this.$set(this.item, 'attendance_type', data.attendance_type);
        this.save();
      }
    },
    /*
     * Modal gluggar
     */
    registerAbsenceModal() {
      this.$refs.registerAbsenceModal.show(this.item);
    },
    registerCommentModal() {
      this.$refs.registerCommentModal.show(this.item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    saveInfo(val) {
      if (val === true) {
        this.attendance_project_id = this.item.attendance_project ? this.item.attendance_project.id : null;
        this.save();
      }
    },
  },
  created() {
    this.attendance_project_id = this.item.attendance_project ? this.item.attendance_project.id : null;
  },
};
</script>

<style></style>
