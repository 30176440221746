
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import { parsePagination } from '@/utils';
import Pagination from '@/components/Pagination.vue';
import { PlacementApiAxiosParamCreator, PlacementGroupPersonContact, WorkplaceDropdown } from '@/api/openapi';

@Component({
  components: { Pagination, Datepicker },
})
export default class PlacementGroupPersonContacts extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;

  data: Array<PlacementGroupPersonContact> | null = null;
  loading: boolean = true;
  pagination: any | null = null;
  lang = is;

  form: {
    date: Date | undefined;
    workplace: string | undefined;
  } = {
    date: new Date(),
    workplace: undefined,
  };

  workplaces: {
    loading: boolean;
    error: boolean;
    data: Array<WorkplaceDropdown> | null;
  } = {
    loading: true,
    error: false,
    data: null,
  };

  async loadList(page?: number, limit?: number) {
    const variables = {
      page: page || undefined,
      limit: limit || undefined,
      date: this.form.date ? moment(this.form.date).format('YYYY-MM-DD') : undefined,
      workplace: this.form.workplace,
    };

    this.loading = true;
    this.data = [];

    try {
      const response = await this.$placementApi.placementGroupPersonContactList(variables);

      this.data = response.data;

      this.pagination = parsePagination(response);
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading = false;
    }
  }

  async loadWorkplaces() {
    this.workplaces.loading = true;
    this.workplaces.data = [];
    this.workplaces.error = false;

    try {
      const response = await this.$coreApi.workplaceDropdownList();

      this.workplaces.data = response.data;
    } catch (e) {
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      this.workplaces.error = true;
    } finally {
      this.workplaces.loading = false;
    }
  }

  changeLimit(limit: number) {
    this.loadList(undefined, limit);
  }

  changePage(page: number) {
    this.loadList(page, this.pagination.currentLimit);
  }

  async created() {
    this.loadWorkplaces();
    this.loadList();
  }

  async excel() {
    const excelData = await PlacementApiAxiosParamCreator().placementGroupPersonContactExcel(
      this.form.date ? moment(this.form.date).format('YYYY-MM-DD') : undefined, // date
      this.form.workplace, // workplace
    );

    window.location.href = excelData.url;
  }
}
