<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Launakerfi</div>
      </b-col>
    </b-row>

    <b-tabs v-model="tabIndex">
      <b-tab title="Launamenn">
        <payroll-payment-employees-h3 />
      </b-tab>
      <b-tab title="Starfsmenn">
        <payroll-employees-h3 />
      </b-tab>
      <b-tab title="Kjarni" v-if="loggedInUser.town.hasKjarni" lazy>
        <payroll-employees-kjarni />
      </b-tab>
      <b-tab title="H3" v-if="loggedInUser.town.hasH3" lazy>
        <payroll-employees-h3-new />
      </b-tab>
    </b-tabs>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PayrollPaymentEmployeesH3 from '../components/payroll/PayrollPaymentEmployeesH3.vue';
import PayrollEmployeesH3 from '../components/payroll/PayrollEmployeesH3.vue';
import PayrollEmployeesKjarni from '../components/payroll/PayrollEmployeesKjarni.vue';
import PayrollEmployeesH3New from '../components/payroll/PayrollEmployeesH3New.vue';

export default {
  name: 'payroll',
  components: {
    PayrollPaymentEmployeesH3,
    PayrollEmployeesH3,
    PayrollEmployeesKjarni,
    PayrollEmployeesH3New,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style></style>
