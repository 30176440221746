<template>
  <div>
    <b-modal id="viewEmailModal" title="Skoða póst" size="lg" lazy :no-close-on-backdrop="true" ok-title="Loka" ok-only ref="createModal">
      <div>
        <table class="table white-table">
          <tr>
            <td><strong>Sent á</strong></td>
            <td>{{ email.email_to }}</td>
          </tr>
          <tr>
            <td><strong>Dags.</strong></td>
            <td>{{ email.date_created | moment('DD.MM.YYYY hh:mm:ss') }}</td>
          </tr>
          <tr>
            <td><strong>Staða</strong></td>
            <td>{{ getEmailStatus(email.status) }}</td>
          </tr>
          <tr>
            <td><strong>Titill</strong></td>
            <td>{{ email.subject }}</td>
          </tr>
          <tr>
            <td><strong>Meginmál</strong></td>
            <td>
              <span style="white-space: pre-wrap">{{ email.text }}</span>
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

export default {
  name: 'district-create-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      emailId: '',
      email: {},
    };
  },
  methods: {
    getEmailStatus(status) {
      switch (status) {
        case 'NEW':
          return 'Nýskráð';
        case 'DELIVERED':
          return 'Sent';
        case 'NOT_DELIVERED':
          return 'Ekki sent';
        default:
          return '';
      }
    },
    async load() {
      this.loading = true;
      try {
        const response = await core.emailMessageDetail(this.id);
        this.email = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    show(id) {
      this.email = {};
      this.submitted = false;
      this.loading = false;
      this.id = id;
      this.load();
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
