<template>
  <div>
    <b-modal
      id="registerAttendanceModal"
      :title="`Skrá viðveru ( ${groupName} )`"
      size="xl"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-row v-for="(attendance, index) in form.attendance" :key="index">
            <b-col cols="2">
              <b-form-group
                :state="submitted && (errors.has(`date${index}`) || form.datesInValid) ? false : ''"
                :invalid-feedback="errors.first(`date${index}`)"
              >
                <label :for="`date${index}`">
                  Dagsetning
                  <b-link @click.prevent="removeDate(index)" v-if="form.attendance.length > 1">
                    <i class="fa fa-trash"></i>
                  </b-link>
                </label>
                <datepicker
                  :id="`date${index}`"
                  :name="`date${index}`"
                  placeholder="Veldu dagsetningu"
                  :class="{ 'is-invalid': submitted && errors.has(`date${index}`) }"
                  v-model="attendance.date"
                  :language="lang"
                  :monday-first="true"
                  format="dd.MM.yyyy"
                  :typeable="false"
                  data-vv-as="dagsetning"
                  v-validate="'required'"
                  ref="datePickr"
                  :disabled-dates="disabledDates"
                  @input="isAttendance(attendance.date, attendance)"
                ></datepicker>
              </b-form-group>
            </b-col>
            <b-alert :show="!attendance.valid" style="padding-top: 20px">
              <i class="fa fa-info-circle"></i>
              {{ attendance.invalidMessage }}
            </b-alert>
            <b-col cols="1" v-if="attendance.valid">
              <b-form-group
                label="Tími"
                :label-for="`hours${index}`"
                :state="submitted && errors.has(`hours${index}`) ? false : ''"
                :invalid-feedback="errors.first(`hours${index}`)"
              >
                <b-form-input
                  :id="`hours${index}`"
                  :name="`hours${index}`"
                  v-if="loggedInUserHasAnyPermission('write_attendance')"
                  type="text"
                  v-model="attendance.hours"
                  :class="(maxHours == null || maxHours >= attendance.hours) && attendance.hours >= 0 ? '' : 'text-danger'"
                  :disabled="attendance.absence !== null || !attendance.valid"
                ></b-form-input>
              </b-form-group>
              <span v-if="maxHours < attendance.hours && maxHours !== null && attendance.hours >= 0">
                <i class="text-danger" style="font-size: small">Hámarks fjöldi tíma: {{ maxHours }}.</i>
              </span>
            </b-col>
            <b-col cols="3" v-if="attendance.valid">
              <b-form-group
                v-if="projectList.length > 0"
                label="Verkefni"
                :label-for="`project${index}`"
                :state="submitted && errors.has(`project${index}`) ? false : ''"
                :invalid-feedback="errors.first(`project${index}`)"
              >
                <b-form-select
                  :id="`project${index}`"
                  :name="`project${index}`"
                  v-model="attendance.project"
                  :disabled="attendance.absence !== null || !attendance.valid"
                >
                  <option :value="null">-- Veldu verkefni --</option>
                  <option v-for="project in projectList" :key="project.id" :value="project.id">
                    {{ project.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="3" v-if="attendance.valid">
              <b-form-group
                label="Fjarvist"
                :label-for="`absence${index}`"
                :state="submitted && errors.has(`absence${index}`) ? false : ''"
                :invalid-feedback="errors.first(`absence${index}`)"
              >
                <b-form-select
                  :id="`absence${index}`"
                  :name="`absence${index}`"
                  v-model="attendance.absence"
                  @input="clearTime(attendance)"
                  :disabled="!attendance.valid"
                >
                  <option :value="null">-- Veldu fjarvistartegund --</option>
                  <option v-for="absence in absenceList" :key="absence.id" :value="absence.id">
                    {{ absence.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="3" v-if="attendance.valid">
              <b-form-group
                label="Athugasemd"
                :label-for="`comment${index}`"
                :state="submitted && errors.has(`absence${index}`) ? false : ''"
                :invalid-feedback="errors.first(`absence${index}`)"
              >
                <b-form-input
                  :id="`comment${index}`"
                  :name="`comment${index}`"
                  v-if="loggedInUserHasAnyPermission('write_attendance')"
                  type="text"
                  v-model="attendance.comment"
                  :disabled="!attendance.valid"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <b-button size="sm" @click="addRow(form.attendance[form.attendance.length - 1].date)" variant="default">
          <i class="fa fa-fw fa-plus-circle"></i>
          Bæta við línu
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import attendance from '@/api/attendance';
import placement from '@/api/placement';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'register-attendance-modal',
  components: {
    Datepicker,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),

    disabledDates() {
      if (this.dateTo !== null && this.dateFrom !== null) {
        return {
          to: moment(this.dateFrom).toDate(),
          from: moment(this.dateTo).toDate(),
        };
      }
      return {};
    },
  },
  data() {
    return {
      loading: false,
      submitted: false,
      absenceList: [],
      projectList: [],
      attendanceList: [],
      info: null,
      maxHours: null,
      group: null,
      groupName: '',
      lang: is,
      dateFrom: null,
      dateTo: null,
      form: {
        attendance: [
          {
            attendance_id: null,
            date: null,
            hours: '',
            project: null,
            absence: null,
            comment: '',
            valid: true,
            invalidMessage: '',
          },
        ],
      },
    };
  },
  methods: {
    async loadAttendanceList() {
      this.attendanceList = [];
      this.loading = true;
      try {
        const response = await attendance.attendanceList(undefined, undefined, this.info.id);
        this.attendanceList = response.data;
        const format = 'YYYY-MM-DD';
        const dateFromCheck = moment(this.info.placement_group.date_from, format);
        const dateToCheck = moment(this.info.placement_group.date_to, format);
        const dateNow = moment(new Date(), format);
        if (moment(dateNow).isBefore(dateFromCheck) || moment(dateNow).isAfter(dateToCheck)) {
          this.$set(this.form.attendance[0], 'date', moment(dateFromCheck).toDate());
          this.isAttendance(moment(dateFromCheck).toDate(), this.form.attendance[0]);
          this.validateDate(moment(dateFromCheck).toDate(), this.form.attendance[0]);
        } else {
          this.$set(this.form.attendance[0], 'date', new Date());
          this.isAttendance(new Date(), this.form.attendance[0]);
          this.validateDate(new Date(), this.form.attendance[0]);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadAbsenceList() {
      this.loading = true;
      this.absenceList = [];
      try {
        const response = await attendance.attendanceOverviewTypeList();
        this.absenceList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadAttendanceProjects() {
      this.loading = true;
      this.projectList = [];
      try {
        const response = await attendance.attendanceOverviewProjectList();
        this.projectList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    isAttendance(date, attendanceObj) {
      const formatDate = moment(date).format('YYYY-MM-DD');
      const isAttendance = this.attendanceList.filter(x => x.date === formatDate);
      if (isAttendance.length > 0) {
        this.$set(attendanceObj, 'attendance_id', isAttendance[0].id);
        this.$set(attendanceObj, 'hours', isAttendance[0].hours);
        this.$set(attendanceObj, 'project', isAttendance[0].attendance_project ? isAttendance[0].attendance_project.id : null);
        this.$set(attendanceObj, 'absence', isAttendance[0].attendance_type ? isAttendance[0].attendance_type.id : null);
        this.$set(attendanceObj, 'comment', isAttendance[0].comment);
        this.$set(attendanceObj, 'valid', true);
        this.$set(attendanceObj, 'invalidMessage', '');
      } else {
        this.$set(attendanceObj, 'attendance_id', null);
        this.$set(attendanceObj, 'hours', '');
        this.$set(attendanceObj, 'project', null);
        this.$set(attendanceObj, 'absence', null);
        this.$set(attendanceObj, 'comment', '');
        this.$set(attendanceObj, 'valid', true);
        this.$set(attendanceObj, 'invalidMessage', '');
      }
      this.validateDate(date, attendanceObj);
    },
    addRow(date) {
      const getDate = new Date(date);
      const newDate = moment(getDate.setDate(getDate.getDate() + 1)).format('YYYY-MM-DD');
      const dateFromCheck = new Date(this.info.placement_group.date_from);
      const dateFrom = moment(dateFromCheck.setDate(dateFromCheck.getDate() - 1)).format('YYYY-MM-DD');
      const dateToCheck = moment(this.info.placement_group.date_to).toDate();
      if (moment(newDate).isAfter(dateToCheck)) {
        this.addRow(dateFrom);
      }
      if (!moment(newDate).isAfter(dateToCheck)) {
        this.form.attendance.push({
          attendance_id: null,
          date: newDate,
          hours: '',
          project: null,
          absence: null,
          comment: '',
          valid: true,
          invalidMessage: '',
        });
        this.isAttendance(newDate, this.form.attendance[this.form.attendance.length - 1]);
      }
    },
    clearTime(obj) {
      if (obj.absence !== null) {
        this.$set(obj, 'hours', '');
        this.$set(obj, 'project', null);
      }
    },
    async validateDate(date, item) {
      if (this.group) {
        try {
          const response = await placement.placementGroupDateValid(this.group, date);
          const validate = response.data;
          this.maxHours = validate.max_hours;
          const list = this.form.attendance.filter(
            x => moment(x.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') && x !== item,
          );
          if (!validate.weekday_valid || validate.vacation_day || validate.batch || (list.length > 0 && this.form.attendance.length > 1)) {
            this.$set(item, 'valid', false);
            if (validate && validate.vacation_day) {
              this.$set(item, 'invalidMessage', 'Ekki er hægt að skrá viðveru þar sem búið er að skilgreina frídag fyrir valinn dag.');
            }
            if (validate && !validate.weekday_valid) {
              this.$set(item, 'invalidMessage', 'Ekki er hægt að skrá viðveru þar sem þessi hópur á ekki að vinna á völdum degi.');
            }
            if (validate && validate.batch) {
              this.$set(item, 'invalidMessage', 'Ekki er hægt að breyta færslum þar sem búið er að læsa þeim vegna uppgjörs.');
            }
            if (list.length > 0 && this.form.attendance.length > 1) {
              this.$set(item, 'invalidMessage', 'Ekki er hægt að skrá sömu dagsetningu tvisvar.');
            }
          } else {
            this.$set(item, 'valid', true);
            this.$set(item, 'invalidMessage', '');
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      } else {
        this.$set(item, 'valid', false);
        this.$set(item, 'invalidMessage', 'Enginn hópur valinn');
      }
    },
    removeDate(index) {
      this.form.attendance.splice(index, 1);
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          const promises = [];
          const items = this.form.attendance.filter(x => x.valid && (this.maxHours == null || this.maxHours >= x.hours) && x.hours >= 0);

          // eslint-disable-next-line no-restricted-syntax
          for (const a of items) {
            const data = {
              date: moment(a.date).format('YYYY-MM-DD'),
              person: this.info.id,
              attendance_type: a.absence ? a.absence : null,
              attendance_project: a.project ? a.project : null,
              hours: a.hours && a.hours !== '' ? a.hours.toString().replace(/,/g, '.') : '0',
              comment: a.comment,
            };
            if (a.attendance_id !== null) {
              promises.push(attendance.attendanceUpdate(a.attendance_id, data));
            } else {
              promises.push(attendance.attendanceCreate(data));
            }
          }
          await Promise.all(promises);
          this.displaySuccess(`Viðvera fyrir „${this.groupName}“ hefur verið vistuð.`);
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.loading = false;
          this.form = {
            attendance: [{ date: null, hours: '', project: null, absence: null, comment: '', valid: true, invalidMessage: '' }],
          };
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(info) {
      this.form = {
        attendance: [{ date: null, hours: '', project: null, absence: null, comment: '', valid: true, invalidMessage: '' }],
      };
      this.submitted = false;
      this.loading = false;
      this.maxHours = null;
      this.info = info;
      this.group = info.placement_group.id;
      this.groupName = info.placement_group.name;
      this.dateFrom = info.placement_group.date_from;
      this.dateTo = info.placement_group.date_to;
      this.loadAttendanceList();
      this.loadAbsenceList();
      this.loadAttendanceProjects();
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
