<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Aðgangur</div>
      </b-col>
      <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_usergroup')">
        <b-btn variant="primary" @click.prevent="groupCreateModal" class="pull-right">
          <i class="fa fa-fw fa-plus-circle"></i>
          Búa til nýja starfstegund
        </b-btn>
      </b-col>
    </b-row>
    <b-card no-body border-variant="light" class="mb-2" style="overflow: auto">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Heiti</th>
            <th v-for="access in accessList" :key="access.name">
              <b-link @click.prevent="accessEditModal(access.name, access.title)" v-if="loggedInUserHasAnyPermission('write_usergroup')">
                {{ access.title }}
              </b-link>
              <span v-else>{{ access.title }}</span>
            </th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <td colspan="100"><loading /></td>
        </tbody>
        <tbody v-if="groupList.length == 0 && !loading">
          <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
        </tbody>
        <tbody v-if="groupList && groupList.length > 0">
          <tr v-for="(group, index) in groupList" :key="group.id">
            <td>
              <b-link @click.prevent="groupEditModal(group.id)" v-if="loggedInUserHasAnyPermission('write_usergroup')">
                {{ group.name }}
              </b-link>
              <span v-else>{{ group.name }}</span>
            </td>
            <td
              v-for="access in accessList"
              :key="access.name"
              :class="{
                'bg-write': getAccess(index, access.name) === 'write',
                'bg-read': getAccess(index, access.name) === 'read',
                'bg-none': getAccess(index, access.name) === 'none',
              }"
            >
              <span v-if="getAccess(index, access.name) === 'none'">Enginn</span>
              <span v-if="getAccess(index, access.name) === 'read'">Les</span>
              <span v-if="getAccess(index, access.name) === 'write'">Les+Skrif</span>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <access-edit-modal ref="accessEditModal" @successful="loadList"></access-edit-modal>
    <group-create-modal ref="groupCreateModal" @successful="loadList"></group-create-modal>
    <group-create-modal ref="groupEditModal" @successful="loadList"></group-create-modal>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import auth from '@/api/auth';
import accessEditModal from '@/components/access/AccessEditModal.vue';
import groupCreateModal from '@/components/access/GroupCreateModal.vue';

export default {
  name: 'access',
  components: {
    accessEditModal,
    groupCreateModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      groupList: [],
      loading: false,
      updateId: null,
      accessList: [
        { name: 'user', title: 'Notendur', permission: 'none' },
        { name: 'usergroup', title: 'Aðgangur', permission: 'none' },
        { name: 'template', title: 'Tilkynningar', permission: 'none' },
        { name: 'period', title: 'Tímabil', permission: 'none' },
        { name: 'workplacedistrict', title: 'Hverfi', permission: 'none' },
        { name: 'workplacetype', title: 'Flokkun starfsstaða', permission: 'none' },
        { name: 'workplace', title: 'Starfsstaður', permission: 'none' },
        { name: 'application', title: 'Umsóknir', permission: 'none' },
        { name: 'approve_application', title: 'Umsóknarsamþykktir', permission: 'none' },
        { name: 'placementgroup', title: 'Hópar', permission: 'none' },
        { name: 'person', title: 'Þátttakendur', permission: 'none' },
        { name: 'exemption', title: 'Undanþága', permission: 'none' },
        { name: 'attendance', title: 'Viðvera', permission: 'none' },
        { name: 'attendancetype', title: 'Fjarvistartegundir', permission: 'none' },
        { name: 'paymentperiod', title: 'Launatímabil', permission: 'none' },
        { name: 'wageperiod', title: 'Launtaxtar', permission: 'none' },
        { name: 'batch', title: 'Uppgjör', permission: 'none' },
        { name: 'history', title: 'Breytingasaga', permission: 'none' },
        { name: 'emailmessage', title: 'Tölvupóstar', permission: 'none' },
        { name: 'search', title: 'Leit', permission: 'none' },
        { name: 'attendanceproject', title: 'Verkefnategundir', permission: 'none' },
        { name: 'reviewquestion', title: 'Frammistöðumat', permission: 'none' },
        { name: 'helpdocument', title: 'Aðstoð', permission: 'none' },
      ],
    };
  },
  methods: {
    async loadList() {
      this.loading = true;
      this.groupList = [];
      try {
        const response = await auth.authGroupList();
        this.groupList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    getAccess(index, access) {
      return this.groupList[index].permissions.find(p => p.name === access) !== undefined
        ? this.groupList[index].permissions.find(p => p.name === access).permission
        : 'none';
    },
    /*
     * Modal gluggar
     */
    groupCreateModal() {
      this.$refs.groupCreateModal.show();
    },
    groupEditModal(id) {
      this.$refs.groupEditModal.show(id);
    },
    accessEditModal(id, name) {
      this.$refs.accessEditModal.show(id, name);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style>
.bg-write {
  background-color: #c9f1c9;
}
.bg-read {
  background-color: #ffd;
}
.bg-none {
  background-color: #ffe8e8;
}
</style>
