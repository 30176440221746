
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
// import { serverValidationToForm } from '@/utils';
import { BModal } from 'bootstrap-vue';
import { PeriodDropdown } from '@/api/openapi';

@Component
export default class ReviewQuestionModal extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;

  id: string | null = null;

  submitting = false;
  submitted = false;
  failed = false;

  errorList = [];

  form: {
    question: string;
    period: string | null;
  } = {
    question: '',
    period: null,
  };

  periods: {
    loading: boolean;
    error: boolean;
    data: Array<PeriodDropdown> | null;
  } = {
    loading: true,
    error: false,
    data: null,
  };

  $refs!: {
    modal: BModal;
  };

  async loadPeriods() {
    this.periods.loading = true;
    this.periods.data = [];
    this.periods.error = false;

    try {
      const response = await this.$coreApi.periodDropdownList();

      this.periods.data = response.data;
    } catch (e) {
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      this.periods.error = true;
    } finally {
      this.periods.loading = false;
    }
  }

  async show(id?: string) {
    // Hreinsa formið
    this.submitted = false;
    this.errorList = [];
    this.form.question = '';
    this.form.period = null;

    this.id = id || null;

    if (id) {
      try {
        const response = await this.$placementApi.reviewQuestionRetrieve({
          uuid: id,
        });

        this.form.question = response.data.question;
        this.form.period = null;
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      }
    } else {
      this.loadPeriods();
      this.form.question = '';
      this.form.period = null;
    }

    this.$refs.modal.show();
  }

  async validateBeforeSubmit() {
    try {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll().then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
      });

      // Engar villur í formi.
      try {
        this.submitting = true;

        if (this.id) {
          await this.$placementApi.reviewQuestionUpdate({
            uuid: this.id,
            reviewQuestionDetailUpdateRequest: {
              question: this.form.question,
            },
          });

          this.displaySuccess(`Frammistöðumatskvarði hefur verið uppfærður.`);
        } else {
          await this.$placementApi.reviewQuestionCreate({
            reviewQuestionDetailRequest: {
              question: this.form.question,
              period: {
                id: this.form.period || '',
              },
            },
          });

          this.displaySuccess(`Frammistöðumatskvarði hefur verið stofnaður.`);
        }

        this.submitted = false;
        this.$emit('successful', {});
        this.$refs.modal.hide();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    } catch (e) {
      // Villur í formi.
      this.$log.debug(e);
    }
  }
}
