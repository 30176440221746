<template>
  <div>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-row>
        <b-col cols="3">
          <strong>Viðtakendur</strong>
        </b-col>
        <b-col cols="9">
          <b-row class="mb-3">
            <b-col cols="3">
              <strong>Hópar virkir á tímabilinu </strong>
              <div>
                <datepicker
                  id="date_from"
                  name="date_from"
                  placeholder="Veldu dags frá"
                  :class="{ 'is-invalid': submitted && errors.has('date_from') }"
                  v-model="search.dateFrom"
                  :language="lang"
                  :monday-first="true"
                  format="dd.MM.yyyy"
                  :typeable="false"
                  data-vv-as="dags frá"
                  @input="dateFromChanged"
                  ref="dateFromPickr"
                  style="width: 185px; margin-right: 0px"
                  class="d-inline-flex"
                ></datepicker>
                -
                <datepicker
                  id="date_to"
                  name="date_to"
                  placeholder="Veldu dags til"
                  :class="{ 'is-invalid': submitted && errors.has('date_to') }"
                  v-model="search.dateTo"
                  :language="lang"
                  :monday-first="true"
                  format="dd.MM.yyyy"
                  :typeable="false"
                  @input="dateToChanged"
                  ref="dateToPickr"
                  style="width: 185px; margin-right: 0px"
                  class="d-inline-flex"
                ></datepicker>
              </div>
            </b-col>
            <b-col cols="3">
              <strong>Tímabil</strong>
              <v-select
                id="period"
                name="period"
                v-model="search.period"
                multiple
                :options="getPeriodList"
                style="width: 100%; background-color: white"
                label="name"
              ></v-select>
            </b-col>
            <b-col cols="3">
              <strong>Starfstímabil</strong>
              <v-select
                id="periodPart"
                name="periodPart"
                v-model="search.periodPart"
                multiple
                :options="getPeriodPartList"
                style="width: 100%; background-color: white"
                label="name"
              ></v-select>
            </b-col>

            <b-col cols="3">
              <strong>Flokkun starfsstaða</strong>
              <v-select
                id="workplacetypes"
                name="workplacetypes"
                v-model="search.workplaceType"
                multiple
                :options="getWorkplaceTypeList"
                style="width: 100%; background-color: white"
                label="name"
              ></v-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <strong>Starfsstaður</strong>
              <v-select
                id="workplace"
                name="workplace"
                v-model="search.workplace"
                :data-selected="search.workplace"
                multiple
                :options="getWorkplaceList"
                style="width: 100%; background-color: white"
                label="name"
              ></v-select>
            </b-col>

            <b-col cols="3">
              <strong>Hópar</strong>
              <v-select
                id="groups"
                name="groups"
                v-model="search.groups"
                multiple
                :options="getGroupList"
                style="width: 100%; background-color: white"
                label="label"
                :disabled="selectAllGroups"
              ></v-select>
              <b-form-checkbox
                id="allGroups"
                name="allGroups"
                type="checkbox"
                v-model="selectAllGroups"
                :disabled="search.groups.length > 0"
              >
                Velja alla hópa
              </b-form-checkbox>
            </b-col>
            <b-col cols="3">
              <strong>Fæðingarár þátttakenda</strong>
              <v-select
                id="year"
                name="year"
                v-model="search.year"
                multiple
                :options="yearList"
                label="name"
                style="background-color: white"
              ></v-select>
            </b-col>

            <b-col cols="0" class="mt-3 ml-3">
              <b-btn
                @click="searchRecipients"
                :disabled="search.groups.length < 1 && !selectAllGroups"
                :title="search.groups.length < 1 ? 'Veldu hóp til að leita að viðtakendum' : ''"
              >
                Leita að viðtakendum
              </b-btn></b-col
            >

            <b-col cols="1">
              <i
                class="fa fa-recycle fa-2x"
                style="color: #367bb5; float: left; margin-top: 22px"
                v-b-tooltip.hover
                title="Hreinsa síur"
                aria-hidden="true"
                @click="clearFilters()"
              ></i>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="3"></b-col>
        <b-col cols="9">
          <div v-if="recipientList.length > 0">
            <strong
              >Senda á
              <i
                class="fa fa-fw fa-search"
                @click.prevent="toggleRecipients"
                v-b-tooltip.hover
                title="Skoða valda viðtakendur"
                style="color: #367bb5"
              ></i
            ></strong>

            <div>
              <b-form-checkbox id="sendStudents" name="sendStudents" type="text" v-model="form.sendStudents"
                >Þátttakendur: {{ getRecipientLength }}</b-form-checkbox
              >
            </div>
            <div>
              <b-form-checkbox id="sendContacts" name="sendContacts" type="text" v-model="form.sendContacts"
                >Aðstandendur: {{ getRecipientContactLength }}</b-form-checkbox
              >
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3"> </b-col>
        <b-col cols="9">
          <div v-if="!firstTime">
            <i v-if="searching" class="fa fa-spinner fa-spin" />
            <div v-if="!searching">
              <div v-if="recipientList.length === 0">Engir viðtakendur fundust</div>
              <div v-else>
                <!--  <div v-if="!showRecipients">
                  <span class="pr-2">Þátttakendur: {{ getRecipientLength }}</span>
                  <span class="pr-2">Aðstandendur: {{ getRecipientContactLength }}</span>
                </div> -->
                <div v-if="showRecipients" class="mt-3">
                  <hr />
                  <i
                    class="fa fa-fw fa-remove pull-right"
                    style="color: red"
                    @click.prevent="toggleRecipients"
                    v-b-tooltip.hover
                    title="Fela viðtakendur"
                  ></i>
                  <div>
                    <table class="table table-no-border table-4px-padding">
                      <tr>
                        <th></th>
                        <th>Þátttakandi</th>
                        <th style="padding-left: 20px">Aðstandendur</th>
                      </tr>
                      <tr v-for="recipient in recipientList" :key="recipient.id">
                        <td>
                          <b-form-checkbox
                            :id="`student${recipient.id}`"
                            :name="`student${recipient.id}`"
                            type="text"
                            v-model="recipient.selected"
                          ></b-form-checkbox>
                        </td>
                        <td>{{ recipient.name }} ({{ recipient.year }}) - {{ recipient.email }}</td>
                        <td style="padding-left: 20px">
                          <div v-for="contact in recipient.contacts" :key="contact.id">{{ contact.name }} - {{ contact.email }}</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <hr />

    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-row>
        <b-col cols="3">
          <strong>Titill</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input
            id="subject"
            name="subject"
            type="text"
            v-model="form.subject"
            required
            :state="submitted && errors.has('subject') ? false : ''"
            v-validate="'required'"
            data-vv-as="titill"
          ></b-form-input> </b-col
      ></b-row>
    </b-form>

    <hr />

    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-row>
        <b-col cols="3"><strong>Meginmál</strong></b-col>
        <b-col cols="9">
          <b-form-textarea
            id="text"
            name="text"
            type="text"
            v-model="form.text"
            required
            :state="submitted && errors.has('text') ? false : ''"
            v-validate="'required'"
            :rows="8"
            data-vv-as="meginmál"
          ></b-form-textarea>

          <b-btn variant="primary" @click.prevent="validateBeforeSubmit" :disabled="submitting" class="mt-3">
            <i class="fa fa-fw fa-envelope"></i>
            Senda skilaboð
          </b-btn>
        </b-col></b-row
      >
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'massemail',
  components: {
    Datepicker,
  },
  data() {
    return {
      submitted: false,
      submitting: false,
      searching: false,
      firstTime: true,
      showRecipients: false,
      lang: is,
      selectAllGroups: false,
      search: {
        dateFrom: moment().startOf('week').isoWeekday(1).toDate(),
        dateTo: moment().endOf('week').toDate(),
        period: [],
        periodPart: [],
        workplace: [],
        groups: [],
        year: [],
        workplaceType: [],
      },
      loading: false,
      yearLoading: false,
      form: {
        subject: '',
        text: '',
        sendStudents: true,
        sendContacts: false,
      },
      groupList: [],
      workplaceList: [],
      periodList: [],
      periodPartList: [],
      recipientList: [],
      yearList: [],
      workplaceTypeList: [],
    };
  },
  computed: {
    ...mapGetters(['loggedInUserOptions', 'loggedInUserHasAnyPermission']),
    getRecipientLength() {
      return this.recipientList && this.form.sendStudents ? this.recipientList.filter(x => x.selected).length : 0;
    },
    getRecipientContactLength() {
      let count = 0;
      if (this.recipientList && this.form.sendContacts) {
        this.recipientList
          .filter(x => x.selected)
          .forEach(x => {
            count += x.contacts.length;
          });
      }
      return count;
    },
    getGroupList() {
      // Sía í burtu ef workplace, type, period eða period part er valið
      return this.groupList
        .filter(x => this.search.workplace.length === 0 || this.existsInArray(this.search.workplace, x.workplace.id))
        .filter(x => this.search.period.length === 0 || this.existsInArray(this.search.period, x.period.id))
        .filter(x => this.search.periodPart.length === 0 || (x.period_part && this.existsInArray(this.search.periodPart, x.period_part.id)))
        .filter(x => this.search.groups.length === 0 || !this.existsInArray(this.search.groups, x.id))
        .map(g => ({
          ...g,
          label: `${g.name} - ${moment(g.date_from).format('DD.MM.YYYY')} - ${moment(g.date_to).format('DD.MM.YYYY')}`,
        }));
    },
    getWorkplaceList() {
      // Sía í burtu ef type eða group er valið
      return this.workplaceList
        .filter(x => this.search.groups.length === 0 || this.existsInArray(this.search.groups, x.id, 'workplace'))
        .filter(x => this.search.workplace.length === 0 || !this.existsInArray(this.search.workplace, x.id))
        .filter(x => this.search.workplaceType.length === 0 || this.existsInArray(this.search.workplaceType, x.id, 'workplace'));
    },
    getPeriodList() {
      // Sía í burtu ef period part eða group er valið
      // Vantar group
      return this.periodList
        .filter(x => this.search.periodPart.length === 0 || this.existsInArray(this.search.periodPart, x.id, 'period'))
        .filter(x => this.search.groups.length === 0 || this.existsInArray(this.search.groups, x.id, 'period'))
        .filter(x => this.search.period.length === 0 || !this.existsInArray(this.search.period, x.id));
    },
    getPeriodPartList() {
      // Sía í burtu ef period eða group er valið
      // Vantar group
      return this.periodPartList
        .filter(x => this.search.period.length === 0 || this.existsInArray(this.search.period, x.period.id))
        .filter(x => this.search.groups.length === 0 || this.existsInArray(this.search.groups, x.id, 'period_part'))
        .filter(x => this.search.periodPart.length === 0 || !this.existsInArray(this.search.periodPart, x.id))
        .map(x => ({
          id: x.id,
          name: `${moment(x.date_from).format('DD.MM.YYYY')} - ${moment(x.date_to).format('DD.MM.YYYY')}`,
          period: x.period,
        }));
    },
    getWorkplaceTypeList() {
      return this.workplaceTypeList.map(x => ({
        id: x.id,
        name: x.name,
        workplace: x.workplace,
      }));
    },
  },
  methods: {
    toggleRecipients() {
      this.showRecipients = !this.showRecipients;
    },
    existsInArray(array, id, column, column2) {
      if (column2) {
        return array.find(x => x[column][column2].id === id);
      }
      if (column) {
        return array.find(x => x[column].id === id);
      }
      return array.find(x => x.id === id);
    },
    clearFilters() {
      this.$set(this.search, 'dateFrom', moment().startOf('week').isoWeekday(1).toDate());
      this.$set(this.search, 'dateTo', moment().endOf('week').toDate());
      this.$set(this.search, 'period', []);
      this.$set(this.search, 'periodPart', []);
      this.$set(this.search, 'workplace', []);
      this.$set(this.search, 'workplaceType', []);
      this.$set(this.search, 'groups', []);
      this.$set(this.search, 'year', '');
      this.recipientList = [];
      this.selectAllGroups = false;
      this.showRecipients = false;
    },
    dateFromChanged() {
      this.search.dateTo = moment(this.search.dateFrom).add('days', 7).toDate();
      this.loadGroups();
    },
    dateToChanged() {
      this.loadGroups();
    },
    async loadGroups() {
      this.loading = true;
      this.groupList = [];
      this.workplaceList = [];
      this.workplaceTypeList = [];
      this.periodList = [];
      this.periodPartList = [];
      try {
        const response = await core.emailGroupList(this.search.dateFrom, this.search.dateTo);
        this.groupList = response.data;
        this.groupList.forEach(g => {
          if (this.periodList.filter(x => x.id === g.period.id).length === 0) {
            this.periodList.push(g.period);
          }
          if (this.periodPartList.filter(x => x.id === g.period_part.id).length === 0) {
            this.periodPartList.push(g.period_part);
          }
          if (this.workplaceList.filter(x => x.id === g.workplace.id).length === 0) {
            this.workplaceList.push(g.workplace);
            g.workplace.types.forEach(t => {
              if (this.workplaceTypeList.filter(x => x.id === t.type.id).length === 0) {
                this.workplaceTypeList.push({ id: t.type.id, name: t.type.name, workplace: g.workplace });
              }
            });
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadBirthYears() {
      this.yearLoading = true;
      try {
        const response = await this.$placementApi.placementGroupPersonBirthYearLookups();

        this.yearList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom uppvið að sækja fæðingarár. Vinsamlegast reyndu aftur.`);
      } finally {
        this.yearLoading = false;
      }
    },
    async searchRecipients() {
      this.firstTime = false;
      this.recipientList = [];
      let groups = [];
      let years = [];

      if (this.selectAllGroups) {
        groups = this.getGroupList.map(x => x.id);
      } else {
        groups = this.search.groups.map(x => x.id);
      }
      if (this.search.year.length > 0) {
        years = this.search.year.map(y => y);
      }
      try {
        this.searching = true;
        const response = await core.emailRecipientsList(groups.join(','), years.join(','));
        this.recipientList = response.data;
        this.recipientList.forEach(s => this.$set(s, 'selected', true));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.searching = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.submitting = true;
          await core.emailMassSendMail({
            subject: this.form.subject,
            text: this.form.text,
            ids: this.recipientList.filter(x => x.selected).map(y => y.id),
            students: this.form.sendStudents,
            contacts: this.form.sendContacts,
          });
          this.submitted = false;

          // Hreinsa filtera og form
          this.clearFilters();
          this.form.subject = '';
          this.form.text = '';
          this.form.sendStudents = true;
          this.form.sendContacts = false;

          this.displaySuccess('Póstur hefur verið sendur');
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.submitting = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadGroups();
    this.loadBirthYears();
  },
};
</script>
