<template>
  <div>
    <div class="mb-2">
      <b-form-select
        id="selectedGroupAll"
        name="selectedGroupAll"
        v-model="selectedGroupAll"
        @change="selectGroupForAll"
        :disabled="!hasSelected"
      >
        <option :value="null">-- Veldu hóp á alla valda --</option>
        <option v-for="group in availableGroupsForAll" :key="group.id" :value="group.id">
          {{ group.workplace.name }} - {{ group.name }} - {{ group.date_from | moment('DD.MM.YYYY') }} -
          {{ group.date_to | moment('DD.MM.YYYY') }}
        </option>
      </b-form-select>
    </div>
    <b-card no-body border-variant="light" class="mb-2">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th style="width: 5%"><b-checkbox @change="setAllSelected" :disabled="submitted" /></th>
            <th style="width: 25%">Nafn</th>
            <th style="width: 15%">Fæðingarár</th>
            <th style="width: 15%">Vinnustaður</th>
            <th style="width: 40%">Hópur</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(application, index) in periodGroup.applicationGrouping" :key="`${periodGroup.id}${application.id}`">
            <td>
              <b-checkbox
                v-if="statuses[index] === 0 || statuses[index] === -1"
                v-model="selected[index]"
                :disabled="selected[index] === null || submitted"
              />
              <i v-if="statuses[index] === 1" class="fa fa-fw fa-spin fa-spinner"></i>
              <i v-if="statuses[index] === 2" class="fa fa-fw fa-check text-success"></i>
              <i v-if="statuses[index] === 3" class="fa fa-fw fa-remove text-danger"></i>
            </td>
            <td>{{ application.name }}</td>
            <td>
              {{ application.birthYear.birthYear }}
              <span class="small text-muted" v-if="application.birthYear.birthYearOverridden">
                ({{ application.birthYear.birthYearReal }})
              </span>
            </td>
            <td>
              <div v-for="workplace in application.allWorkplaces" :key="workplace.id" style="display: block">
                {{ workplace.workplace.name }}
              </div>
            </td>
            <td>
              <div v-if="application.allPeriodParts && application.allPeriodParts.length > 0">
                <div v-if="application.periodGroupingPart.status !== 'NEW'">
                  Eingöngu er hægt að velja hópa ef ekki er búið að meðhöndla umsóknina.
                </div>
                <b-form-select
                  :id="`group`"
                  :name="`group`"
                  v-model="selectedGroup[index]"
                  v-if="application.periodGroupingPart.status === 'NEW'"
                  :disabled="submitted"
                >
                  <option :value="null">-- Veldu hóp --</option>
                  <option v-for="group in application.periodGroupingPart.availableGroups" :key="group.id" :value="group.id">
                    {{ group.workplace.name }} - {{ group.name }} - {{ group.date_from | moment('DD.MM.YYYY') }} -
                    {{ group.date_to | moment('DD.MM.YYYY') }}
                  </option>
                </b-form-select>
              </div>
              <div v-else>
                <div
                  v-if="
                    application.status !== 'NEW' && application.status !== 'IN_PROGRESS' && application.status !== 'IN_PROGRESS_APPROVED'
                  "
                >
                  Eingöngu er hægt að velja hópa ef ekki er búið að meðhöndla umsóknina.
                </div>
                <b-form-select
                  :id="`group`"
                  :name="`group`"
                  v-model="selectedGroup[index]"
                  v-if="
                    application.status === 'NEW' || application.status === 'IN_PROGRESS' || application.status === 'IN_PROGRESS_APPROVED'
                  "
                  :disabled="submitted"
                >
                  <option :value="null">-- Veldu hóp --</option>
                  <option v-for="group in application.availableGroups" :key="group.id" :value="group.id">
                    {{ group.workplace.name }} - {{ group.name }} - {{ group.date_from | moment('DD.MM.YYYY') }} -
                    {{ group.date_to | moment('DD.MM.YYYY') }}
                  </option>
                </b-form-select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import appl from '@/api/application';

export default {
  name: 'ApproveApplicationItem',
  props: {
    periodGroup: Object,
  },
  data() {
    return {
      submitted: false,
      selectedGroupAll: null,
      selectedGroup: {},
      selected: {},
      statuses: {},
    };
  },
  computed: {
    availableGroupsForAll() {
      return [
        ...this.periodGroup.applicationGrouping
          .filter((_, index) => this.selected[index])
          .filter(a => a.allPeriodParts && a.allPeriodParts.length > 0)
          .flatMap(a => a.periodGroupingPart.availableGroups)
          .filter((v, i, a) => a.findIndex(v2 => v.id === v2.id) === i)
          .filter(ag =>
            this.periodGroup.applicationGrouping
              .filter((_, index) => this.selected[index])
              .every(a2 => a2.periodGroupingPart.availableGroups.map(v => v.id).includes(ag.id)),
          ),
        ...this.periodGroup.applicationGrouping
          .filter((_, index) => this.selected[index])
          .filter(a => !a.allPeriodParts || a.allPeriodParts.length === 0)
          .flatMap(a => a.availableGroups)
          .filter((v, i, a) => a.findIndex(v2 => v.id === v2.id) === i)
          .filter(ag =>
            this.periodGroup.applicationGrouping
              .filter((_, index) => this.selected[index])
              .every(a2 => a2.availableGroups.map(v => v.id).includes(ag.id)),
          ),
      ];
    },
    hasSelected() {
      return Object.values(this.selected).includes(true);
    },
  },
  methods: {
    setAllSelected(value) {
      this.$set(
        this,
        'selected',
        this.periodGroup.applicationGrouping
          .map((a, i) => {
            if (a.allPeriodParts && a.allPeriodParts.length > 0) {
              return [i, a.periodGroupingPart.status !== 'NEW' ? null : value];
            }
            return [i, a.status !== 'NEW' && a.status !== 'IN_PROGRESS' && a.status !== 'IN_PROGRESS_APPROVED' ? null : value];
          })
          .reduce((acc, curr) => ({ ...acc, [curr[0]]: curr[1] }), {}),
      );
    },
    setAllStatuses(value) {
      this.$set(
        this,
        'statuses',
        this.periodGroup.applicationGrouping.map((a, i) => i).reduce((acc, curr) => ({ ...acc, [curr]: value }), {}),
      );
    },
    selectGroupForAll(value) {
      this.selectedGroupAll = null;
      this.periodGroup.applicationGrouping.forEach((a, i) => {
        if (this.selected[i]) {
          this.selectedGroup[i] = value;
        }
      });
    },
    async doApprove() {
      this.submitted = true;

      window.scrollTo({ top: 0, behavior: 'smooth' });

      const itemsToApprove = this.periodGroup.applicationGrouping
        .map((item, index) => ({
          application: item,
          index,
          value: this.selectedGroup[index],
        }))
        .filter(v => v.value !== null);

      const promises = [];

      this.setAllStatuses(-1);

      itemsToApprove.forEach(item => {
        this.$set(this.statuses, item.index, 1);

        const itemToApprove = {
          period: item.application.period.databaseId,
          period_part: null,
          placement_group: item.value,
        };

        if (item.application.allPeriodParts && item.application.allPeriodParts.length > 0) {
          itemToApprove.period_part = item.application.periodGroupingPart.databaseId;
        }

        promises.push(
          appl
            .applicationAccept(item.application.databaseId, itemToApprove)
            .then(() => {
              this.$set(this.statuses, item.index, 2);
            })
            .catch(e => {
              this.$log.debug(e);
              this.$set(this.statuses, item.index, 3);
              this.displayError(`Óvænt villa kom upp hjá umsækjanda „${item.application.name}“.`);
            }),
        );
      });

      this.setAllSelected(false);

      return Promise.all(promises);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.setAllSelected(false);
    this.$set(
      this,
      'selectedGroup',
      this.periodGroup.applicationGrouping.map((a, i) => i).reduce((acc, curr) => ({ ...acc, [curr]: null }), {}),
    );
    this.setAllStatuses(0);
  },
};
</script>
