<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Hverfi</div>
      </b-col>
      <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_workplacedistrict')">
        <b-btn variant="primary" @click.prevent="districtCreateModal" class="pull-right">
          <i class="fa fa-fw fa-plus-circle"></i>
          Bæta við
        </b-btn>
      </b-col>
    </b-row>
    <b-card no-body border-variant="light" class="mb-2">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Heiti</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <td colspan="100"><loading /></td>
        </tbody>
        <tbody v-if="districtList.length == 0 && !loading">
          <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
        </tbody>
        <tbody v-if="!loading && districtList.length > 0">
          <tr v-for="district in districtList" :key="district.id">
            <td>{{ district.name }}</td>
            <td class="text-right">
              <b-btn
                :size="'sm'"
                variant="primary"
                @click.prevent="districtEditModal(district.id)"
                v-if="loggedInUserHasAnyPermission('write_workplacedistrict')"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </b-btn>
              <b-btn :size="'sm'" variant="danger" @click.prevent="deleteDistrict(district)">
                <i class="fa fa-fw fa-trash"></i>
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <district-create-modal ref="districtCreateModal" @successful="loadList"></district-create-modal>
    <district-create-modal ref="districtEditModal" @successful="loadList"></district-create-modal>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import core from '@/api/core';
import districtCreateModal from '@/components/district/DistrictCreateModal.vue';

export default {
  name: 'district',
  components: {
    districtCreateModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      districtList: [],
      loading: false,
      updateId: null,
    };
  },
  methods: {
    async loadList() {
      this.loading = true;
      this.districtList = [];
      try {
        const response = await core.districtList();
        this.districtList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Modal gluggar
     */
    districtCreateModal() {
      this.$refs.districtCreateModal.show();
    },
    districtEditModal(id) {
      this.$refs.districtEditModal.show(id);
    },
    async deleteDistrict(item) {
      const conf = confirm(`Ertu viss um að þú viljir eyða hverfi fyrir ${item.name}`); //eslint-disable-line
      if (conf) {
        try {
          await core.districtDelete(item.id);
          this.displaySuccess('Hverfi hefur verið eytt');
          this.loadList();
        } catch (e) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style></style>
