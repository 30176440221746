import Vue from 'vue';
import moment from 'moment';

import config from '../config';

export default {
  /**
   * Vefþjónusta til þess að skrá hóp.
   *
   * @param {object} data Gögn um nýjan hóp
   */
  placementGroupCreate(data) {
    return Vue.http
      .post(config.PLACEMENT_GROUP_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra hóp.
   *
   * @param {number} id Einkenni hóps til að uppfæra.
   * @param {object} data Gögn um breyttan hóp.
   */
  placementGroupUpdate(id, data) {
    return Vue.http
      .put(config.PLACEMENT_GROUP_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða hóp.
   *
   * @param {number} id Einkenni hóps til að eyða.
   */
  placementGroupDelete(id) {
    return Vue.http
      .delete(config.PLACEMENT_GROUP_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir undanþágur
   */
  placementExemptionList(page = null, limit = null) {
    return Vue.http
      .get(config.PLACEMENT_EXEMPTION_LIST_URL, {
        params: {
          page,
          limit,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eina undanþágu.
   *
   * @param {number} id Einkenni undanþágu til að sækja.
   */
  placementExemptionDetail(id) {
    return Vue.http
      .get(config.PLACEMENT_EXEMPTION_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá undanþágu.
   *
   * @param {object} data Gögn um nýja undanþágu
   */
  placementExemptionCreate(data) {
    return Vue.http
      .post(config.PLACEMENT_EXEMPTION_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra undanþágu.
   *
   * @param {number} id Einkenni undanþágu til að uppfæra.
   * @param {object} data Gögn um breyttan undanþágu.
   */
  placementExemptionUpdate(id, data) {
    return Vue.http
      .put(config.PLACEMENT_EXEMPTION_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða undanþágu.
   *
   * @param {number} id Einkenni undanþágu til að eyða.
   */
  placementExemptionDelete(id) {
    return Vue.http
      .delete(config.PLACEMENT_EXEMPTION_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að athuga undnaþágu á kennitölu
   */
  placementExemptionCheck(ssn) {
    return Vue.http
      .get(config.PLACEMENT_EXEMPTION_CHECK_URL, {
        params: {
          ssn,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja lista yfir nemendur í hóp
   */
  placementGroupPersonList(id) {
    return Vue.http
      .get(config.PLACEMENT_GROUP_PERSON_LIST_URL, {
        params: {
          placement_group: id,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja lista yfir nemendur í hóp
   */
  placementGroupPerson(id) {
    return Vue.http
      .get(config.PLACEMENT_GROUP_PERSON_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að senda póst á hóp
   *
   * @param {object} data Gögn um nýjan póst
   */
  placementSendMail(id, data) {
    return Vue.http
      .post(config.PLACEMENT_GROUP_MAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja lista yfir nemendur í hóp með fleiri upplýsingum en bara nafni
   */
  placementGroupPersonListDetails(id) {
    return Vue.http
      .get(config.PLACEMENT_GROUP_PERSON_DETAIL_LIST_URL(id), {
        params: {
          placement_group: id,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að athuga hvort megi skrá viðveru fyrir hóp á dagsetningu
   */
  placementGroupDateValid(id, date) {
    return Vue.http
      .get(config.PLACEMENT_GROUP_DATE_VALID_URL(id), {
        params: {
          date: moment(date).format('YYYY-MM-DD'),
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða nemanda úr hóp
   */
  placementGroupPersondDelete(id, full, date) {
    return Vue.http
      .delete(config.PLACEMENT_GROUP_PERSON_DETAIL_URL(id), {
        params: {
          full,
          date,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja lista yfir nemendur í hóp með fleiri upplýsingum en bara nafni
   */
  placementGroupPersonPatch(id, data) {
    return Vue.http
      .patch(config.PLACEMENT_GROUP_PERSON_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að færa þátttakenda um hóp
   */
  placementGroupPersonChangeGroup(id, data) {
    return Vue.http
      .put(config.PLACEMENT_GROUP_PERSON_CHANGE_GROUP_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að færa þátttakenda aftur í hóp
   */
  placementGroupPersonCopyGroup(id, data) {
    return Vue.http
      .post(config.PLACEMENT_GROUP_PERSON_COPY_GROUP_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tengiliði skráningar.
   */
  placementGroupPersonContactList(placementGroupPerson) {
    return Vue.http
      .get(config.PLACEMENT_GROUP_PERSON_CONTACT_LIST_URL, {
        params: {
          placement_group_person: placementGroupPerson,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn tengilið
   *
   * @param {number} id Einkenni tengiliðs til að sækja.
   */
  placementGroupPersonContactDetail(id) {
    return Vue.http
      .get(config.PLACEMENT_GROUP_PERSON_CONTACT_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að skrá tengilið.
   *
   * @param {object} data Gögn um tengilið
   */
  placementGroupPersonContactCreate(data) {
    return Vue.http
      .post(config.PLACEMENT_GROUP_PERSON_CONTACT_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   *
   * @param {number} id Einkenni tengiliðs til að uppfæra.
   * @param {object} data Gögn um breytta umsókn
   */
  placementGroupPersonContactUpdate(id, data) {
    return Vue.http
      .put(config.PLACEMENT_GROUP_PERSON_CONTACT_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða tengilið.
   *
   * @param {number} id Einkenni tengiliðs til að eyða.
   */
  placementGroupPersonContactDelete(id) {
    return Vue.http
      .delete(config.PLACEMENT_GROUP_PERSON_CONTACT_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir hópa leiðbeinanda
   */
  instructorPlacementGroupList(page, limit, date, year) {
    return Vue.http
      .get(config.INSTRUCTOR_PLACEMENT_GROUP_LIST_URL, {
        params: {
          page,
          limit,
          date: date ? moment(date).format('YYYY-MM-DD') : null,
          year,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
