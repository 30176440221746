<template>
  <div>
    <b-card no-body border-variant="light" class="mb-2 mt-3">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Nafn</th>
            <th>Kennitala</th>
            <th>Fæðingarár</th>
            <th class="text-right">Upphæð</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in studentList" :key="student.id">
            <td>{{ student.name }}</td>
            <td>{{ student.ssn }}</td>
            <td>
              {{ student.birth_year }}
              <strong v-if="student.birth_year_overridden">
                ({{ student.birth_year_real }})
                <i
                  class="fa fa-fw fa-info-circle"
                  v-b-tooltip.hover
                  :title="`Þessi þátttakandi er með undanþágu og á að vera tekið sem ${student.birth_year_real}`"
                ></i>
              </strong>
            </td>
            <td align="right">{{ student.wages | currency }}</td>
          </tr>
          <tr v-if="studentList.length === 0">
            <td colspan="100%" class="text-center"><i>Engar niðurstöður fundust</i></td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <pagination
      v-if="pagination && pagination.totalPages > 1"
      :pagination-response="pagination"
      @change-limit="changeLimit"
      @change-page="changePage"
    >
    </pagination>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import payment from '@/api/payment';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'participant-overview',
  props: ['active', 'year', 'search'],
  components: {
    Pagination,
  },
  mixins: [paginationMixin],
  data() {
    return {
      page: null,
      pagination: null,
      loading: false,
      studentList: [],
    };
  },
  methods: {
    async loadList(page = null, limit = null) {
      this.loading = true;
      this.studentList = [];
      try {
        const response = await payment.paymentBatchOverviewStudentList(page, limit, this.search, this.year);
        this.studentList = response.data;
        this.pagination = parsePagination(response);
        this.$emit('updateYear', this.year);
        // await this.setLoggedInUserOption({ option: 'application', value: this.options.application });
        // await this.saveLoggedInUserOptions();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    collapseRecords(student) {
      this.$set(student, 'collapsed', student.collapsed ? !student.collapsed : true);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    active(val) {
      if (val) {
        this.loadList();
      }
    },
    year(val) {
      if (val) {
        this.loadList();
      }
    },
    search(val) {
      if (val) {
        this.loadList();
      }
    }
  },
  created() {
    if (this.active) {
      this.loadList();
    }
  },
};
</script>
