// @ts-nocheck

import Vue from 'vue';

import axios from 'axios';

import { ApplicationApi, AuthApi, CoreApi, PaymentApi, PlacementApi } from '../api/openapi';

const instance = axios.create();

instance.defaults.headers['Accept-Language'] = 'is';
instance.defaults.xsrfHeaderName = 'X-CSRFToken';
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.withCredentials = true;

Vue.http = Vue.prototype.$http = instance; // eslint-disable-line no-multi-assign

/* eslint-disable */

Vue.$authApi = Vue.prototype.$authApi = new AuthApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);

Vue.$applicationApi = Vue.prototype.$applicationApi = new ApplicationApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);

Vue.$coreApi = Vue.prototype.$coreApi = new CoreApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);

Vue.$placementApi = Vue.prototype.$placementApi = new PlacementApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);

Vue.$paymentApi = Vue.prototype.$paymentApi = new PaymentApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);

/* eslint-enable */

declare module 'vue/types/vue' {
  // eslint-disable-next-line no-shadow
  interface Vue {
    $log: any;
    $applicationApi: ApplicationApi;
    $coreApi: CoreApi;
    $placementApi: PlacementApi;
    $paymentApi: PaymentApi;
  }
}
