<template>
  <vinnuskoli-page>
    <p>Vinsamlegast veldu sveitarfélag hér að ofan.</p>
  </vinnuskoli-page>
</template>

<script>
export default {
  name: 'select-town',
};
</script>
