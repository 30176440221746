
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
// import { serverValidationToForm } from '@/utils';
import { BModal } from 'bootstrap-vue';

@Component
export default class HelpCategoryModal extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;

  id: string | null = null;

  submitting = false;
  submitted = false;
  failed = false;

  errorList = [];

  form: {
    name: string;
  } = {
    name: '',
  };

  $refs!: {
    modal: BModal;
  };

  async show(id?: string) {
    // Hreinsa formið
    this.submitted = false;
    this.errorList = [];
    this.form.name = '';

    this.id = id || null;

    if (id) {
      try {
        const response = await this.$coreApi.helpCategoryRetrieve({
          uuid: id,
        });

        this.form.name = response.data.name;
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      }
    }

    this.$refs.modal.show();
  }

  async validateBeforeSubmit() {
    try {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll().then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
      });

      // Engar villur í formi.
      try {
        this.submitting = true;

        if (this.id) {
          await this.$coreApi.helpCategoryUpdate({
            uuid: this.id,
            helpCategoryRequest: {
              name: this.form.name,
            },
          });

          this.displaySuccess(`Flokkur hefur verið uppfærður.`);
        } else {
          await this.$coreApi.helpCategoryCreate({
            helpCategoryRequest: {
              name: this.form.name,
            },
          });

          this.displaySuccess(`Flokkur hefur verið stofnaður.`);
        }

        this.submitted = false;
        this.$emit('successful', {});
        this.$refs.modal.hide();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    } catch (e) {
      // Villur í formi.
      this.$log.debug(e);
    }
  }
}
