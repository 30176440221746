
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { PlacementGroupInstructorList, PlacementApiPlacementGroupInstructorListRequest } from '@/api/openapi';

@Component
export default class GroupInstructors extends Vue {
  @Prop() readonly id!: string;

  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  @Getter loggedInUserHasAnyPermission: any;

  data: Array<PlacementGroupInstructorList> | null = null;
  loading: boolean = true;

  async loadList() {
    const variables: PlacementApiPlacementGroupInstructorListRequest = {
      placementGroup: this.id,
    };

    this.loading = true;
    this.data = [];

    try {
      if (this.loggedInUserHasAnyPermission('read_person')) {
        const response = await this.$placementApi.placementGroupInstructorList(variables);

        this.data = response.data;
      }
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading = false;
    }
  }

  async created() {
    this.loadList();
  }
}
