<template>
  <vinnuskoli-page>
    <b-row>
      <b-col>
        <h5 class="page-title">
          Tímabil:
          {{ this.period.name }}
          ({{ this.period.dateFrom | moment('DD.MM.YYYY') }}-{{ this.period.dateTo | moment('DD.MM.YYYY') }})
        </h5>
      </b-col>
    </b-row>
    <full-calendar :event-sources="eventSources" @day-click="addDay" @event-selected="deleteDay" :config="config" ref="calendar">
    </full-calendar>
  </vinnuskoli-page>
</template>

<script>
import 'fullcalendar/dist/locale/is'; // eslint-disable-line

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { FullCalendar } from 'vue-full-calendar';
import core from '@/api/core';

export default {
  name: 'period-vacation-days',
  components: {
    FullCalendar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      periodId: '',
      dayList: [],
      loading: false,
      updateId: null,
      period: {
        name: '',
        dateFrom: '',
        dateTo: '',
      },
      lang: 'is',
      eventSources: [
        {
          events: (start, end, timezone, callback) => {
            if (this.periodId !== undefined) {
              core.periodVacationDaysList(this.periodId).then(response => {
                const list = [];
                response.data.forEach(e => {
                  list.push({
                    id: e.id,
                    start: e.date,
                    title: 'Frídagur',
                  });
                });
                callback(list);
              });
            }
          },
          textColor: 'white',
        },
      ],
      config: {
        editable: false,
        selectable: false,
        defaultView: 'month',
      },
    };
  },
  methods: {
    async loadPeriod() {
      try {
        const response = await this.$coreApi.periodRetrieve({
          uuid: this.periodId,
        });
        this.period.name = response.data.name;
        this.period.dateFrom = moment(response.data.dateFrom);
        this.period.dateTo = moment(response.data.dateTo);
        this.$refs.calendar.fireMethod('gotoDate', response.data.dateFrom);
        this.$refs.calendar.$emit('refetch-events');
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async addDay(date) {
      if (date >= this.period.dateFrom && date <= this.period.dateTo && this.loggedInUserHasAnyPermission('write_period')) {
        const conf = confirm(`Ertu viss um að þú viljir bæta við frídeginum ${moment(date).format('DD.MM.YYYY')}`); //eslint-disable-line
        if (conf) {
          try {
            await core.periodVacationDaysCreate({
              date: moment(date).format('YYYY-MM-DD'),
              period: this.periodId,
            });
            this.$refs.calendar.$emit('refetch-events');
          } catch (e) {
            this.$log.error(e);
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    async deleteDay(event) {
      if (this.loggedInUserHasAnyPermission('write_period')) {
        const conf = confirm(`Ertu viss um að þú viljir eyða frídeginum ${moment(event.start).format('DD.MM.YYYY')}`); //eslint-disable-line
        if (conf) {
          try {
            await core.periodVacationDaysDelete(event.id);
            this.$refs.calendar.$emit('refetch-events');
          } catch (e) {
            this.$log.error(e);
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.$route.params.period) {
      this.periodId = this.$route.params.period;
      this.loadPeriod();
    }
  },
};
</script>

<style lang="scss"></style>
