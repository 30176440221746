<template>
  <div>
    <b-modal
      id="templateCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Texti:"
            label-for="text"
            :state="submitted && errors.has('text') ? false : ''"
            :invalid-feedback="errors.first('text')"
            description="Efni tilkynningarinnar"
          >
            <b-form-textarea
              id="text"
              name="text"
              type="text"
              v-model="form.text"
              required
              :state="submitted && errors.has('text') ? false : ''"
              v-validate="'required'"
              data-vv-as="texti"
            ></b-form-textarea>
          </b-form-group>
          <div>
            <strong>Bæta við breytum: </strong>
            <a
              @click.prevent="appendVariable(variable)"
              v-for="variable in variables"
              :key="variable"
              class="d-block"
              style="cursor: pointer"
            >
              {{ variable }}
            </a>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'template-edit-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      title: '',
      templateId: '',
      variables: [
        '#NEMANDI#',
        '#KENNITALA#',
        '#DAGS#',
        '#TIMABIL#',
        '#STARFSTIMABIL#',
        '#STARFSSTADUR#',
        '#STARFSSTADUR_SAMÞYKKT#',
        '#HOPUR#',
      ],
      form: {
        type: '',
        typeName: '',
        text: '',
      },
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const response = await core.templateDetail(this.templateId);
        this.form.text = response.data.text;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    appendVariable(variable) {
      this.form.text = `${this.form.text}${variable}`;
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        // Engar villur í formi.
        try {
          this.loading = true;
          if (this.templateId) {
            await core.templateUpdate(this.templateId, {
              type: this.form.type,
              text: this.form.text,
            });
            this.displaySuccess(`Tilkynningu fyrir „${this.form.typeName}“ hefur verið breytt.`);
          } else {
            await core.templateCreate({
              type: this.form.type,
              text: this.form.text,
            });
            this.displaySuccess(`Tilkynning fyrir „${this.form.typeName}“ hefur verið stofnuð.`);
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
      }
    },
    show(template) {
      this.templateId = null;
      this.form.text = '';
      this.form.type = template.type;
      this.form.typeName = template.name;
      this.title = template.name;
      this.submitted = false;
      this.loading = false;
      if (template.id) {
        this.templateId = template.id;
        this.load();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
