<template>
  <div>
    <b-modal
      id="billingPeriodCreateModal"
      :title="title"
      size="lg"
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Heiti:"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Heiti tímabils"
          >
            <b-form-input
              id="name"
              name="name"
              autofocus
              type="text"
              v-model="form.name"
              required
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="nafn"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :state="submitted && (errors.has('date_from') || form.datesInValid) ? false : ''"
            :invalid-feedback="errors.first('date_from')"
          >
            <label label-for="date_from"> Dags. frá </label>
            <datepicker
              id="date_from"
              name="date_from"
              placeholder="Veldu dags frá"
              :class="{ 'is-invalid': submitted && (errors.has('date_from') || form.datesInValid) }"
              v-model="form.dateFrom"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              data-vv-as="dags frá"
              v-validate="'required'"
              ref="dateFromPickr"
              @selected="changeDateTo"
            ></datepicker>
          </b-form-group>
          <b-form-group
            :state="submitted && (errors.has('date_to') || form.datesInValid) ? false : ''"
            :invalid-feedback="errors.first('date_to')"
          >
            <label label-for="date_to"> Dags. til </label>
            <datepicker
              id="date_to"
              name="date_to"
              placeholder="Veldu dags til"
              :class="{ 'is-invalid': submitted && (errors.has('date_to') || form.datesInValid) }"
              v-model="form.dateTo"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              data-vv-as="dags til"
              v-validate="'required'"
              ref="dateToPickr"
            ></datepicker>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import payment from '@/api/payment';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'payment-period-create-modal',
  components: {
    Datepicker,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      periodId: '',
      title: 'Stofna launatímabil',
      form: {
        name: '',
        dateFrom: '',
        dateTo: '',
        datesInValid: false,
      },
      lang: is,
    };
  },
  mounted() {
    this.$refs.dateFromPickr.$el.querySelector('input').autocomplete = 'off';
    this.$refs.dateToPickr.$el.querySelector('input').autocomplete = 'off';
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const response = await payment.paymentPeriodDetail(this.periodId);
        this.form.name = response.data.name;
        this.form.dateFrom = moment(response.data.date_from).toDate();
        this.form.dateTo = moment(response.data.date_to).toDate();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response || this.validateDates() === false) {
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          if (this.periodId) {
            await payment.paymentPeriodUpdate(this.periodId, {
              name: this.form.name,
              date_from: moment(this.form.dateFrom).format('YYYY-MM-DD'),
              date_to: moment(this.form.dateTo).format('YYYY-MM-DD'),
            });
            this.displaySuccess(`Launatímabilinu „${this.form.name}“ hefur verið breytt.`);
          } else {
            await payment.paymentPeriodCreate({
              name: this.form.name,
              date_from: moment(this.form.dateFrom).format('YYYY-MM-DD'),
              date_to: moment(this.form.dateTo).format('YYYY-MM-DD'),
            });
            this.displaySuccess(`Launatímabilið „${this.form.name}“ hefur verið stofnað.`);
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
      }
    },
    changeDateTo(val) {
      if (this.form.dateTo === '') {
        this.form.dateTo = val;
      }
    },
    validateDates() {
      let valid = true;
      const dateFrom = moment(this.form.dateFrom);
      const dateTo = moment(this.form.dateTo);
      if (dateTo.isBefore(dateFrom)) {
        this.form.datesInValid = true;
        valid = false;
      } else {
        this.form.datesInValid = false;
      }
      return valid;
    },
    show(periodId) {
      this.form.name = '';
      this.form.dateFrom = '';
      this.form.dateTo = '';
      this.form.datesInValid = false;
      this.submitted = false;
      this.loading = false;
      if (periodId) {
        this.title = 'Breyta launatímabili';
        this.periodId = periodId;
        this.load();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
