import store from '@/store';

const storeInit = store.dispatch('getLoggedInUser').then(() => {
  store.dispatch('getTownList');
});

/**
 * Athuga gilda innskráningu.
 */
const doRequireAuth = (to, from, next, requireTown = false) => {
  storeInit
    .then(() => {
      if (store.state.loggedInUser != null) {
        if (requireTown && !store.state.loggedInUser.town) {
          next({ name: 'SelectTown' });
        } else {
          next();
        }
      } else {
        next({ name: 'Login' });
      }
    })
    .catch(() => {
      next({ name: 'Login' });
    });
};

export default {
  /**
   * Krefjast innskráningar.
   */
  requireAuth(to, from, next) {
    doRequireAuth(to, from, next);
  },
  /**
   * Krefjast innskráningar.
   */
  requireAuthAndTown(to, from, next) {
    doRequireAuth(to, from, next, true);
  },
};
