<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Notendur</div>
      </b-col>
      <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_user')">
        <b-btn variant="primary" @click.prevent="userCreateModal" class="pull-right">
          <i class="fa fa-fw fa-plus-circle"></i>
          Bæta við
        </b-btn>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="2">
        <strong>Nafn</strong>
        <b-form-input id="name" name="name" type="text" v-model="filter.name" @input="loadList()"></b-form-input>
      </b-col>
      <b-col cols="2">
        <strong>Kennitala</strong>
        <b-form-input id="ssn" name="name" type="text" v-model="filter.ssn" @input="loadList()"></b-form-input>
      </b-col>
      <b-col cols="2">
        <strong>Aðgangur</strong>
        <b-form-select id="group" name="group" v-model="filter.group" @input="loadList()">
          <option :value="undefined">-- Veldu aðgang --</option>
          <template v-if="!groups.loading && groups.data">
            <option v-for="group in groups.data" :key="group.id" :value="group.id">
              {{ group.name }}
            </option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <strong>Starfsstaður</strong>
        <b-form-select id="workplace" name="workplace" v-model="filter.workplace" @input="loadList()">
          <option :value="undefined">-- Veldu starfsstað --</option>
          <template v-if="!workplaces.loading && workplaces.data">
            <option v-for="workplace in workplaces.data" :key="workplace.id" :value="workplace.id">
              {{ workplace.name }}
            </option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-row class="mt-4">
          <b-form-checkbox class="mr-4 ml-3" v-model="isActive" @change="() => loadList()"> Virkir </b-form-checkbox>
          <b-form-checkbox class="mr-4" v-model="isNotActive" @change="() => loadList()"> Óvirkir </b-form-checkbox>
          <i
            class="fa fa-recycle fa-2x"
            style="color: #367bb5"
            v-b-tooltip.hover
            :title="`Hreinsa síur`"
            aria-hidden="true"
            @click="clearFilters()"
          ></i>
        </b-row>
      </b-col>
      <b-col cols="2" class="mt-4" v-if="loggedInUserHasAnyPermission('write_user')">
        <b-btn
          variant="success"
          @click.prevent="activateUsers"
          class="pull-right ml-2"
          @change="() => loadList()"
          title="Virkja þá notendur sem hakað hefur verið við"
        >
          Virkja valinn hóp
        </b-btn>

        <b-btn
          variant="danger"
          @click.prevent="inactivateUsers"
          class="pull-right"
          @change="() => loadList()"
          title="Óvirkja þá notendur sem hakað hefur verið við"
        >
          Óvirkja valinn hóp
        </b-btn>
      </b-col>
    </b-row>

    <b-card no-body border-variant="light" class="mb-2">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <td>
              <b-checkbox :checked="allSelected" @change="selectAll" v-if="loggedInUserHasAnyPermission('write_user')" />
            </td>
            <th @click.prevent="changeSort('name')" style="cursor: pointer">
              Nafn (<span v-if="pagination">{{ pagination.totalCount }}</span
              >)
            </th>
            <th @click.prevent="changeSort('ssn')" style="cursor: pointer">Kennitala</th>
            <th @click.prevent="changeSort('email')" style="cursor: pointer">Netfang</th>
            <th @click.prevent="changeSort('tel')" style="cursor: pointer">Farsími</th>
            <th>Aðgangur</th>
            <th>Starfsstaðir</th>
            <th>Virk/ur</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <td colspan="100"><loading /></td>
        </tbody>
        <tbody v-if="!loading && data && data.length === 0">
          <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
        </tbody>
        <tbody v-if="!loading && data && data.length > 0">
          <tr v-for="user in data" :key="user.id">
            <td>
              <b-checkbox v-model="user.isSelected" v-if="loggedInUserHasAnyPermission('write_user')" />
            </td>
            <td>{{ user.name }}</td>
            <td>{{ user.ssn }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.tel }}</td>
            <td>
              <span v-if="user.groups && user.groups.length > 0">
                {{ user.groups[0].name }}
              </span>
            </td>
            <td>
              <div :id="`userPopover${user.id}`">
                {{ user.workplaces.length }}
                <i class="fa fa-fw fa-search"></i>
              </div>
              <b-popover :target="`userPopover${user.id}`" triggers="hover" placement="left">
                <template slot="title">Starfsstaðir</template>
                <div v-for="w in user.workplaces" :key="w.id">
                  {{ w.workplace.name }}
                </div>
                <div v-if="user.workplaces.length === 0">Engir skráðir</div>
              </b-popover>
            </td>
            <td>
              <i class="fa fa-fw" :class="{ 'fa-check': user.isActive, 'fa-remove': !user.isActive }"></i>
            </td>
            <td class="text-right">
              <b-btn
                :size="'sm'"
                variant="primary"
                @click.prevent="userEditModal(user.id)"
                v-if="loggedInUserHasAnyPermission('write_user')"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>

    <pagination
      v-if="pagination && data && data.length > 0"
      :pagination-response="pagination"
      @change-limit="changeLimit"
      @change-page="changePage"
    >
    </pagination>
    <user-create-modal ref="userCreateModal" @successful="() => loadList()"></user-create-modal>
    <user-create-modal ref="userEditModal" @successful="() => loadList()"></user-create-modal>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Pagination from '@/components/Pagination.vue';
import { parsePagination } from '@/utils';
import core from '@/api/core';

import userCreateModal from '@/components/user/UserCreateModal.vue';

const DEFAULT_LIMIT = 25;

export default {
  name: 'users',
  components: {
    userCreateModal,
    Pagination,
  },
  computed: {
    allSelected() {
      return this.data.length > 0 ? this.data.every(x => x.isSelected) : false;
    },
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      page: parseInt(this.$route.query.page, 10) || 1,
      limit: parseInt(this.$route.query.limit, 10) || DEFAULT_LIMIT,
      isActive: true,
      isNotActive: false,
      orderBy: 'name',
      pagination: null,
      data: [],
      loading: false,
      error: false,
      filter: {
        ssn: '',
        name: '',
        group: null,
        workplace: null,
      },
      workplaces: {
        data: [],
        loading: false,
        error: false,
      },
      groups: {
        data: [],
        loading: false,
        error: false,
      },
    };
  },
  methods: {
    async loadList(page, limit) {
      this.loading = true;
      this.data = [];
      this.error = false;

      let isActive;

      if (this.isActive && !this.isNotActive) {
        isActive = true;
      } else if (!this.isActive && this.isNotActive) {
        isActive = false;
      }
      try {
        const response = await this.$authApi.userList({
          page: page || undefined,
          limit: limit || 10,
          isActive,
          orderBy: this.orderBy,
          ssn: this.filter.ssn,
          name: this.filter.name,
          groups: this.filter.group,
          workplaces: this.filter.workplace,
        });

        this.data = response.data.map(d => ({
          ...d,
          isSelected: false,
        }));
        this.pagination = parsePagination(response);
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    async loadWorkplaces() {
      this.workplaces.loading = true;
      this.workplaces.data = [];
      this.workplaces.error = false;

      try {
        const response = await this.$coreApi.workplaceDropdownList({
          type: undefined,
        });

        this.workplaces.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja starfsstaði. Vinsamlegast reyndu aftur.`);
        this.workplaces.error = true;
      } finally {
        this.workplaces.loading = false;
      }
    },
    async loadGroups() {
      this.groups.loading = true;
      this.groups.data = [];
      this.groups.error = false;

      try {
        const response = await core.dropdownAccessList();

        this.groups.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp við að sækja aðganga. Vinsamlegast reyndu aftur.`);
        this.groups.error = true;
      } finally {
        this.groups.loading = false;
      }
    },

    changeSort(clickedSortValue) {
      if (this.orderBy === clickedSortValue) {
        this.orderBy = `-${clickedSortValue}`;
      } else {
        this.orderBy = `${clickedSortValue}`;
      }

      this.loadList();
    },
    changeLimit(limit) {
      this.loadList(undefined, limit);
    },
    changePage(page) {
      this.loadList(page, this.pagination.currentLimit);
    },

    clearFilters() {
      this.$set(this, 'filter', {
        ssn: undefined,
        name: undefined,
      });
      this.isActive = true;
      this.isNotActive = false;
      this.loadList();
    },

    selectAll() {
      const allSelected = this.data.length > 0 ? this.data.every(x => x.isSelected) : false;

      this.data = this.data.map(d => ({
        ...d,
        isSelected: !allSelected,
      }));
    },

    selectAllWithValue(value) {
      this.data = this.data.map(d => ({
        ...d,
        isSelected: value,
      }));
    },

    async activateUsers() {
      const conf = confirm('Ertu viss um að þú viljir virkja valinn hóp?'); //eslint-disable-line
      if (conf) {
        try {
          const promises = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const user of this.data.filter(x => x.isSelected)) {
            promises.push(
              this.$authApi.userPartialUpdate({
                uuid: user.id,
                patchedUserDetailRequest: {
                  isActive: true,
                },
              }),
            );
          }
          await Promise.all(promises);
          this.selectAllWithValue(false);
          this.displaySuccess(`Hópur hefur verið virkjaður`);
        } catch (e) {
          this.displayError(`Villa við að virkja hóp. Vinsamlegast reyndu aftur`);
        } finally {
          this.loadList();
        }
      }
    },
    async inactivateUsers() {
      const conf = confirm('Ertu viss um að þú viljir óvirkja valinn hóp?'); //eslint-disable-line
      if (conf) {
        try {
          const promises = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const user of this.data.filter(x => x.isSelected)) {
            promises.push(
              this.$authApi.userPartialUpdate({
                uuid: user.id,
                patchedUserDetailRequest: {
                  isActive: false,
                },
              }),
            );
          }
          await Promise.all(promises);
          this.selectAllWithValue(false);
          this.displaySuccess(`Hópur hefur verið óvirkjaður`);
        } catch (e) {
          this.displayError(`Villa við að óvirkja hóp. Vinsamlegast reyndu aftur`);
        } finally {
          this.loadList();
        }
      }
    },

    /*
     * Modal gluggar
     */
    userCreateModal() {
      this.$refs.userCreateModal.show();
    },
    userEditModal(id) {
      this.$refs.userEditModal.show(id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
    this.loadWorkplaces();
    this.loadGroups();
  },
};
</script>

<style></style>
