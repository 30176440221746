<template>
  <vinnuskoli-page>
    <div v-if="loading"><loading /></div>
    <div v-if="data">
      <div class="mb-3 clearfix">
        <b-btn-group class="pull-left">
          <b-link class="btn btn-primary mr-2" :to="{ name: 'SendMessage', params: { group: data.id } }">
            <i class="fa fa-fw fa-envelope"></i> Senda skilaboð á hóp
          </b-link>
          <!-- <b-btn
            variant="primary"
            @click.prevent="groupPersonCreateModal($event)"
            v-if="loggedInUserHasAnyPermission('write_placementgroup')"
          >
            <i class="fa fa-fw fa-male"></i> Skrá þátttakanda í hóp án umsóknar
          </b-btn> -->
        </b-btn-group>

        <b-btn-group class="pull-right">
          <b-btn class="mr-2" @click.prevent="groupCreateModal($event)" v-if="loggedInUserHasAnyPermission('write_placementgroup')">
            <i class="fa fa-fw fa-pencil"></i> Breyta hóp
          </b-btn>

          <b-btn variant="danger" @click.prevent="groupDelete($event)" v-if="loggedInUserHasAnyPermission('write_placementgroup')">
            <i class="fa fa-fw fa-trash"></i> Eyða hóp
          </b-btn>
        </b-btn-group>
      </div>

      <b-card class="card-primary-header">
        <div slot="header">
          <div>Hópur</div>
        </div>
        <b-row>
          <b-col>
            <strong>Heiti</strong>
            <div>{{ data.name }}</div>
          </b-col>
          <b-col>
            <strong>Hópatímabil</strong>
            <div>{{ data.dateFrom | moment('DD.MM.YYYY') }} - {{ data.dateTo | moment('DD.MM.YYYY') }}</div>
          </b-col>
          <b-col>
            <strong>Hámarks vinnutímar á dag</strong>
            <div>
              <span v-if="data.maxHours">{{ data.maxHours }}</span> <span v-else><i>Ekkert hámark skráð</i></span>
            </div>
          </b-col>
          <b-col>
            <strong>Vinnutími</strong>
            <div>
              <span v-if="data.timeFrom || data.timeTo">
                {{ [data.timeFrom, 'HH:mm:ss'] | moment('HH:mm') }} -
                {{ [data.timeTo, 'HH:mm:ss'] | moment('HH:mm') }}
              </span>
              <span v-else><i>Enginn vinnutími skráður</i></span>
            </div>
          </b-col>
          <b-col>
            <strong>Takmörkun á fæðingarári</strong>
            <div class="m-4" v-if="birthYears.loading"><loading /></div>
            <div v-else>
              <span v-if="birthYears.data.length > 0">{{ birthYears.data.map(b => b.birthYear).join(', ') }}</span>
              <span v-else><i>Enginn takmörkun skráð</i></span>
            </div>
          </b-col>
          <b-col>
            <strong>Vikudagar sem hópur vinnur</strong>
            <div>
              <div>
                <i class="fa fa-fw" :class="{ 'fa-check text-success': data.monday, 'fa-remove text-danger': !data.monday }" />
                Mánudagar
              </div>
              <div>
                <i class="fa fa-fw" :class="{ 'fa-check text-success': data.tuesday, 'fa-remove text-danger': !data.tuesday }" />
                Þriðjudagar
              </div>
              <div>
                <i class="fa fa-fw" :class="{ 'fa-check text-success': data.wednesday, 'fa-remove text-danger': !data.wednesday }" />
                Miðvikudagar
              </div>
              <div>
                <i class="fa fa-fw" :class="{ 'fa-check text-success': data.thursday, 'fa-remove text-danger': !data.thursday }" />
                Fimmtudagar
              </div>
              <div>
                <i class="fa fa-fw" :class="{ 'fa-check text-success': data.friday, 'fa-remove text-danger': !data.friday }" />
                Föstudagar
              </div>
              <div>
                <i class="fa fa-fw" :class="{ 'fa-check text-success': data.saturday, 'fa-remove text-danger': !data.saturday }" />
                Laugardagar
              </div>
              <div>
                <i class="fa fa-fw" :class="{ 'fa-check text-success': data.sunday, 'fa-remove text-danger': !data.sunday }" />
                Sunnudagar
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <group-instructors :id="id" />
      <group-persons :id="id" />
      <group-diary :id="id" />
      <group-create-modal ref="groupCreateModal" @successful="() => doReload()" />
      <group-person-create-modal ref="groupPersonCreateModal" @successful="() => doReload()" />
    </div>
  </vinnuskoli-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import placement from '@/api/placement';

import GroupCreateModal from '@/components/groups/GroupCreateModal.vue';
import GroupPersonCreateModal from '@/components/groups/GroupPersonCreateModal.vue';
import GroupDiary from '@/components/groups/GroupDiary.vue';
import GroupPersons from '@/components/groups/GroupPersons.vue';
import GroupInstructors from '@/components/groups/GroupInstructors.vue';

export default {
  name: 'Group',
  components: {
    GroupCreateModal,
    GroupPersonCreateModal,
    GroupDiary,
    GroupInstructors,
    GroupPersons,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      id: null,
      loading: false,
      error: false,
      data: null,
      birthYears: {
        data: [],
        loading: false,
        error: false,
      },
    };
  },
  methods: {
    groupCreateModal(event) {
      if (event) event.stopPropagation();
      this.$refs.groupCreateModal.show(this.data.id);
    },
    groupPersonCreateModal(event) {
      if (event) event.stopPropagation();
      this.$refs.groupPersonCreateModal.show(this.data.id);
    },
    async groupDelete(event) {
      if (event) event.stopPropagation();
      const conf = confirm(`Ertu viss um að þú viljir eyða hópnum ${this.data.name}`); //eslint-disable-line
      if (conf) {
        try {
          await placement.placementGroupDelete(this.data.id);
          this.displaySuccess('Hóp hefur verið eytt');
          this.$router.push({ name: 'Groups' });
        } catch (e) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async doReload() {
      this.loadGroup();
      this.loadBirthYears();
    },
    async loadGroup() {
      this.loading = true;
      this.data = null;
      this.error = false;

      try {
        const response = await this.$placementApi.placementGroupRetrieve({
          uuid: this.id,
        });

        this.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async loadBirthYears() {
      this.birthYears.loading = true;
      this.birthYears.data = [];
      this.birthYears.error = false;

      try {
        const response = await this.$placementApi.placementGroupBirthYearList({
          placementGroup: this.id,
        });

        this.birthYears.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.birthYears.error = true;
      } finally {
        this.birthYears.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.id = this.$route.params.id;
    this.loadGroup();
    this.loadBirthYears();
  },
};
</script>
