<template>
  <div>
    <b-modal
      id="correctionCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Launatímabil*:"
            label-for="payment_period"
            :state="submitted && errors.has('payment_period') ? false : ''"
            :invalid-feedback="errors.first('payment_period')"
            v-if="correctionId == null"
            description="Launatímabil sem verið er að lagfæra. Ath. Eingöngu er hægt að velja launatímabil með staðfestar færslur"
          >
            <b-form-select
              id="payment_period"
              name="payment_period"
              type="text"
              v-model="form.payment_period"
              required
              :state="submitted && errors.has('payment_period') ? false : ''"
              v-validate="'required'"
              data-vv-as="launatímabil"
              @input="loadLines"
            >
              <option :value="null">-- Veldu launatímabil --</option>
              <option v-for="period in paymentPeriodList" :key="period.id" :value="period">
                {{ period.name }}
              </option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Færsla*:"
            label-for="person"
            :state="submitted && errors.has('person') ? false : ''"
            :invalid-feedback="errors.first('person')"
            v-if="correctionId == null"
            description="Færsla í bunka sem var ekki rétt og á að stofna leiðréttingu fyrir"
          >
            <v-select
              id="person"
              name="person"
              v-model="form.person"
              :state="submitted && errors.has('person') ? false : ''"
              data-vv-as="færsla"
              v-validate="'required'"
              :options="batchLines"
              label="title"
            ></v-select>
          </b-form-group>
          <div v-if="correctionId != null">
            <div v-if="period">
              <label class="mb-0">Launatímabil</label>
              <div class="mb-2">
                <strong>{{ period.name }}</strong>
              </div>
            </div>
            <div v-if="person">
              <label class="mb-0">Þátttakandi</label>
              <div class="mb-3">
                <strong
                  >{{ person.name }} kt.{{ person.ssn }} - {{ person.placement_group.workplace.name }} {{ person.placement_group.name }}
                </strong>
              </div>
            </div>
          </div>
          <b-form-group
            label="Fj. tíma*:"
            label-for="hours"
            :state="submitted && errors.has('hours') ? false : ''"
            :invalid-feedback="errors.first('hours')"
            description="Fjöldi tíma sem verður bætt við/dregið frá í næsta uppgjöri"
          >
            <b-form-input
              id="hours"
              name="hours"
              type="text"
              v-model="form.hours"
              required
              :state="submitted && errors.has('hours') ? false : ''"
              v-validate="'required'"
              data-vv-as="fj. tíma"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Upphæð*:"
            label-for="amount"
            :state="submitted && errors.has('amount') ? false : ''"
            :invalid-feedback="errors.first('amount')"
            description="Upphæð sem verður bætt við/dregið frá í næsta uppgjöri"
          >
            <b-form-input
              id="amount"
              name="amount"
              type="text"
              v-model="form.amount"
              required
              :state="submitted && errors.has('amount') ? false : ''"
              v-validate="'required'"
              data-vv-as="upphæð"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Ástæða*:"
            label-for="comment"
            :state="submitted && errors.has('comment') ? false : ''"
            :invalid-feedback="errors.first('comment')"
            description="Ástæða fyrir leiðréttingarfærslunni. Skilyrt að fylla út."
          >
            <b-form-textarea
              id="comment"
              name="comment"
              type="text"
              v-model="form.comment"
              required
              :state="submitted && errors.has('comment') ? false : ''"
              v-validate="'required'"
              data-vv-as="ástæða"
            >
            </b-form-textarea>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import payment from '@/api/payment';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'correction-create-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      paymentPeriodList: [],
      batchLineList: [],
      correctionId: null,
      title: 'Stofna leiðréttingarfærslu',
      person: null,
      period: null,
      form: {
        period_id: '',
        person_group_id: '',
        period: null,
        person: null,
        amount: '',
        hours: '',
        comment: '',
      },
    };
  },
  computed: {
    batchLines() {
      return this.batchLineList.filter(p => !this.form.person || p.id !== this.form.person.id);
    },
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const response = await payment.paymentBatchCorrectionDetail(this.correctionId);
        this.form.hours = response.data.hours;
        this.form.amount = response.data.amount;
        this.form.comment = response.data.comment;
        this.form.period_id = response.data.payment_period.id;
        this.period = response.data.payment_period;
        this.form.person_group_id = response.data.person.id;
        this.person = response.data.person;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadPaymentPeriod() {
      this.loading = true;
      this.paymentPeriodList = [];
      try {
        const response = await core.dropdownPaymentperiodList();
        this.paymentPeriodList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadLines() {
      this.batchLineList = [];
      if (this.form.payment_period) {
        this.loading = true;
        try {
          const response = await payment.paymentBatchLineList(null, null, this.form.payment_period.id, false);
          this.batchLineList = response.data;
          this.batchLines.forEach(b => {
            this.$set(b, 'title', `${b.name} kt.${b.ssn} - ${b.workplace_name} ${b.placement_group_name} - ${b.wages}kr.`);
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          if (this.correctionId) {
            await payment.paymentBatchCorrectionUpdate(this.correctionId, {
              payment_period: this.form.period_id,
              person: this.form.person_group_id,
              hours: this.form.hours,
              amount: this.form.amount,
              comment: this.form.comment,
            });
            this.displaySuccess('Leiðréttingarfærslu hefur verið breytt.');
          } else {
            await payment.paymentBatchCorrectionCreate({
              payment_period: this.form.payment_period.id,
              person: this.form.person.placement_group_person_id,
              hours: this.form.hours,
              amount: this.form.amount,
              comment: this.form.comment,
            });
            this.displaySuccess('Leiðréttingarfærsla hefur verið stofnuð.');
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
      }
    },
    show(correctionId) {
      this.correctionId = null;
      this.form.hours = '';
      this.form.amount = '';
      this.form.comment = '';
      this.form.period_id = '';
      this.form.person_group_id = '';
      this.form.payment_period = null;
      this.form.person = null;
      this.submitted = false;
      this.loading = false;
      this.period = null;
      this.person = null;
      if (correctionId) {
        this.title = 'Breyta leiðréttingarfærslu';
        this.correctionId = correctionId;
        this.load();
      } else {
        this.loadPaymentPeriod();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
