var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-card',{staticClass:"card-primary-header mt-3",attrs:{"no-body":""}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',[_vm._v(" Dagbók "),(_vm.loggedInUserHasAnyPermission('write_placementgroup'))?_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-primary",staticStyle:{"color":"#fff"},attrs:{"title":"Skrá dagbók hóps","to":{
          name: 'DiaryWithParams',
          params: { date: _vm.today, group: _vm.id },
        }}},[_c('i',{staticClass:"fa fa-fw fa-pencil"})]):_vm._e()],1)]),_c('div',[(_vm.loading)?_c('div',{staticClass:"m-4"},[_c('loading')],1):_vm._e(),(!_vm.loading && _vm.data && _vm.data.length === 0)?_c('div',{staticClass:"m-4"},[_c('i',[_vm._v("Engar leitarniðurstöður fundust.")])]):_vm._e(),(!_vm.loading && _vm.data && _vm.data.length > 0)?_c('div',[_c('table',{staticClass:"table white-table ml-2 table-hover"},[_c('thead',[_c('tr',{staticClass:"small"},[_c('th',[_vm._v("Dags.")]),_c('th',[_vm._v("Færsla")]),_c('th')])]),_c('tbody',_vm._l((_vm.data),function(q){return _c('tr',{key:q.id},[_c('td',[_vm._v(_vm._s(_vm._f("moment")(q.date,'DD.MM.YYYY')))]),_c('td',[_vm._v(_vm._s(q.text))]),_c('td',[(_vm.loggedInUserHasAnyPermission('write_placementgroup'))?_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Breyta dagbók","to":{
                  name: 'DiaryWithParams',
                  params: { date: q.date, group: _vm.id },
                }}},[_c('i',{staticClass:"fa fa-fw fa-pencil"})]):_vm._e()],1)])}),0)])]):_vm._e()]),_c('div',{staticClass:"px-4"},[(_vm.pagination && _vm.data && _vm.data.length > 0)?_c('pagination',{attrs:{"pagination-response":_vm.pagination},on:{"change-limit":_vm.changeLimit,"change-page":_vm.changePage}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }