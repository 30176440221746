<template>
  <div>
    <b-modal
      id="finishApplicationModal"
      title="Klára umsóknir"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Klára"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <h5>Ertu viss um að þú viljir klára eftirfarandi umsókn/ir?</h5>
        <div>Með því að klára umsókn þá ertu að samþykkja umsóknina án þess að full afgreiða hana.</div>
        <table class="table white-table">
          <tr>
            <th>Nafn</th>
            <th>Fæðingarár</th>
            <th>Tímabil</th>
            <th>Vinnustaður</th>
            <th></th>
          </tr>
          <tr v-for="application in applications" :key="application.id" :class="{ 'text-danger': application.status !== 'SEMI_ACCEPTED' }">
            <td>{{ application.name }}</td>
            <td>{{ application.birthYear.birthYear }}</td>
            <td>{{ application.period.name }}</td>
            <td>
              <div v-for="(workplace, index) in application.allWorkplaces" :key="workplace.id">
                <span v-if="index != 0">, </span>
                {{ workplace.workplace.name }}
              </div>
            </td>
            <td>
              <i
                v-if="application.status !== 'SEMI_ACCEPTED'"
                class="fa fa-exclamation-triangle"
                v-b-tooltip.hover
                title="Ekki er hægt að klára umsókn sem er afgreid að hluta"
              ></i>
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import appl from '@/api/application';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'finish-application-modal',
  data() {
    return {
      loading: false,
      applications: [],
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.errorList = [];
        const promises = [];

        this.applications
          .filter(x => x.status === 'SEMI_ACCEPTED')
          .forEach(a => {
            promises.push(appl.applicationFinish(a.id));
          });
        await Promise.all(promises);
        this.$emit('successful', {});
        this.$refs.createModal.hide();
      } catch (e) {
        // Aðgerð mistókst.
        this.saving = true;
        this.$log.debug(e);
        if (e.response && e.response.status === 400) {
          djangoErrorsToVee(e.response.data, this.errors, this.errorList);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    show(applications) {
      this.applications = applications;
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
