<template>
  <div>
    <b-container class="pt-5">
      <img src="../assets/Logo-black-1.svg" class="d-block mx-auto" />

      <strong class="text-center mt-3 d-block" style="text-transform: uppercase">Vinnuskóli</strong>

      <div v-if="status && status.length > 0" class="mt-3">
        <b-alert show v-for="(s, i) in status" :variant="s.type" :key="i">
          {{ s.text }}
        </b-alert>
      </div>

      <b-row class="justify-content-center">
        <b-col xl="10" lg="12" md="9">
          <b-card class="o-hidden my-5" no-body>
            <b-row>
              <b-col
                lg="6"
                class="d-none d-lg-block"
                :style="{
                  background: `url(${require('@/assets/vinnuskoliMyndCut.jpg')})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }"
              ></b-col>
              <b-col lg="6">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Stjórnendur</h1>
                  </div>

                  <button class="btn btn-primary w-100" @click="island()">
                    <span class="d-inline-block mt-1">Innskráning með island.is</span>
                  </button>

                  <hr />

                  <div class="text-center">
                    <a class="small" href="https://www.vala.is"> Til baka á forsíðu Völu </a>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import auth from '@/api/auth';

export default {
  name: 'login',
  data() {
    return {
      status: [],
    };
  },
  methods: {
    island() {
      window.location = '/web-v3/auth/island';
      // window.location = '/api/auth/island';
    },
    async loadStatus() {
      try {
        const statusResponse = await auth.status();

        this.status = statusResponse.data;
      } catch (e) {
        this.status = [];
      }
    },
  },
  async created() {
    this.loadStatus();
  },
};
</script>
