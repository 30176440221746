<template>
  <div>
    <b-card class="card-primary-header mt-3">
      <div slot="header">
        <div>Breytingasaga</div>
      </div>
      <div v-if="historyList.length === 0 && !loading">
        <i>Engar færslur fundust</i>
      </div>
      <table class="table white-table" v-else>
        <tr>
          <th>Lýsing</th>
          <th>Gildi fyrir</th>
          <th>Gildi eftir</th>
          <th>Dags</th>
          <th>Breytt af</th>
        </tr>
        <tr v-for="(h, index) in historyList" :key="index">
          <td>{{ h.description }}</td>
          <td>{{ h.before }}</td>
          <td>{{ h.after }}</td>
          <td>{{ h.date_created | moment('DD.MM.YYYY hh:mm:ss') }}</td>
          <td>{{ h.created_by }}{{ h.admin_created_by }}</td>
        </tr>
      </table>

      <pagination
        v-if="pagination && pagination.totalPages > 1"
        :pagination-response="pagination"
        @change-limit="changeLimit"
        @change-page="changePage"
      >
      </pagination>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import core from '@/api/core';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'student-history',
  props: ['reload', 'item'],
  components: {
    Pagination,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      historyList: [],
      page: null,
      pagination: null,
    };
  },
  methods: {
    async loadList(page = null, limit = null) {
      this.historyList = [];
      if (this.item && this.item.id) {
        this.loading = true;
        try {
          const response = await core.historyList(page, limit, null, this.item.id);
          this.historyList = response.data;
          this.pagination = parsePagination(response);
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    reload(val) {
      if (val) {
        this.loadList();
      }
    },
  },
  created() {
    this.loadList();
  },
};
</script>
