<template>
  <div>
    <b-modal
      id="periodDeleteModal"
      :title="title"
      size="lg"
      :no-close-on-backdrop="true"
      ok-title="Eyða"
      cancel-title="Hætta við"
      @ok.prevent="deletePeriod"
      :ok-disabled="loading"
      ref="deleteModal"
    >
      <div>
        <div>
          Ertu viss um að þú viljir eyða tímabilinu
          <i style="font-weight: bold; font-style: normal">{{ name }}</i>
          <i style="font-style: italic">({{ dateFrom }} - {{ dateTo }})</i> ?
        </div>
        <br />
        <div v-if="count > 1">
          Athugið að <i style="text-decoration: underline"> {{ count }} hópar</i> eru skráðir á tímabilið og munu þeir
          <i style="text-decoration: underline">eyðast</i> með tímabilinu.
        </div>
        <div v-if="count == 1">
          Athugið að <i style="text-decoration: underline"> {{ count }} hópur</i> eru skráður á tímabilið og mun hann
          <i style="text-decoration: underline">eyðast</i> með tímabilinu.
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'period-delete-modal',
  data() {
    return {
      title: 'Eyða tímabili',
      name: '',
      count: '',
      dateFrom: '',
      dateTo: '',
      hasParts: '',
      openForApplication: '',
      numPartsApplication: '',
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const response = await this.$coreApi.periodRetrieve({
          uuid: this.periodId,
        });
        this.name = response.data.name;
        this.count = response.data.groupCount;
        this.dateFrom = moment(response.data.dateFrom).format('DD.MM.YYYY');
        this.dateTo = moment(response.data.dateTo).format('DD.MM.YYYY');
        this.hasParts = response.data.hasParts;
        this.openForApplication = response.data.openForApplication;
        this.numPartsApplication = response.data.numPartsApplication;
        this.ageExclusionParts = response.data.ageExclusionParts;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    show(periodId) {
      this.submitted = false;
      this.loading = false;
      if (periodId) {
        this.title = 'Eyða tímabili';
        this.periodId = periodId;
        this.load();
      }
      this.$refs.deleteModal.show();
    },
    async deletePeriod() {
      try {
        await core.periodDelete(this.periodId);
        this.displaySuccess('Tímabili hefur verið eytt');
        this.$emit('successful', {});
        this.$refs.deleteModal.hide();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.loading = false;
        if (e.response && e.response.status === 400) {
          djangoErrorsToVee(e.response.data, this.errors, this.errorList);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
