<template>
  <div>
    <b-modal
      id="groupPersonCreateModal"
      title="Skrá þátttakanda í hóp án umsóknar"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <div class="alert alert-danger" v-if="errorList.length > 0">
          <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
        </div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Kennitala*"
            label-for="ssn"
            :state="(submitted || (form.ssn && form.ssn.length === 10)) && errors.has('ssn') ? false : ''"
            :invalid-feedback="errors.first('ssn')"
            description="Kennitala þátttakanda"
          >
            <b-form-input
              id="ssn"
              name="ssn"
              autofocus
              type="text"
              v-model="form.ssn"
              required
              :state="submitted && errors.has('ssn') ? false : ''"
              v-validate="'required|length:10|checkSsn'"
              data-vv-as="kennitala"
              @input="() => checkSSN(null)"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Nafn" label-for="name" description="Nafn þátttakanda">
            <b-form-input id="name" name="name" type="text" v-model="form.name" disabled></b-form-input>
          </b-form-group>

          <b-form-group
            label="Netfang*"
            label-for="email"
            :state="submitted && errors.has('email') ? false : ''"
            :invalid-feedback="errors.first('email')"
            description="Netfang þátttakanda"
          >
            <b-form-input
              id="email"
              name="email"
              type="email"
              ref="emailField"
              v-model="form.email"
              required
              :state="submitted && errors.has('email') ? false : ''"
              v-validate="'required|email'"
              data-vv-as="netfang"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Farsími*"
            label-for="tel"
            :state="submitted && errors.has('tel') ? false : ''"
            :invalid-feedback="errors.first('tel')"
            description="Farsími þátttakanda"
          >
            <b-form-input
              id="tel"
              name="tel"
              type="text"
              v-model="form.tel"
              required
              :state="submitted && errors.has('tel') ? false : ''"
              v-validate="'required'"
              data-vv-as="farsími"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Bankareikningur*:"
            label-for="account"
            :state="submitted && errors.has('account') ? false : ''"
            :invalid-feedback="errors.first('account')"
            description="Bankareikningur þátttakanda"
          >
            <masked-input
              type="text"
              id="account"
              name="account"
              class="form-control"
              v-model="form.account"
              :mask="[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
              :guide="true"
              :showMask="false"
              :class="{ 'is-invalid': submitted && errors.has('account') }"
              v-validate="'required'"
              data-vv-as="bankareikning"
            ></masked-input>
          </b-form-group>

          <b-form-group
            label="Sérþarfir"
            label-for="specialNeeds"
            :state="submitted && errors.has('specialNeeds') ? false : ''"
            :invalid-feedback="errors.first('specialNeeds')"
            description="Sérþarfir þátttakanda ef á við"
          >
            <b-form-textarea id="specialNeeds" name="specialNeeds" type="text" v-model="form.specialNeeds"></b-form-textarea>
          </b-form-group>

          <hr />

          <h5>Aðstandendur</h5>

          <div v-for="(contact, index) in form.contacts" :key="index">
            <b-row>
              <b-col sm="12" md="6">
                <b-form-group
                  :state="
                    (submitted || (form.contacts[index].ssn && form.contacts[index].ssn.length === 10)) && errors.has(`ssn${index}`)
                      ? false
                      : ''
                  "
                  :invalid-feedback="errors.first(`ssn${index}`)"
                  description="Kennitala aðstandanda"
                >
                  <label :for="`ssn${index}`">
                    Kennitala*
                    <b-link @click.prevent="removeContact(index)" v-if="form.contacts.length > 1">
                      <i class="fa fa-trash"></i>
                    </b-link>
                  </label>
                  <b-form-input
                    :id="`ssn${index}`"
                    :name="`ssn${index}`"
                    type="text"
                    v-model="contact.ssn"
                    :state="submitted && errors.has(`ssn${index}`) ? false : ''"
                    v-validate="'required|length:10|checkSsn:' + index"
                    maxlength="10"
                    data-vv-as="kennitala"
                    @input="() => checkSSN(index)"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group label="Nafn*:" :label-for="`name${index}`" description="Nafn aðstandanda">
                  <b-form-input :id="`name${index}`" :name="`name${index}`" type="text" v-model="contact.name" readonly></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="6">
                <b-form-group
                  label="Netfang*:"
                  :label-for="`email${index}`"
                  :state="submitted && errors.has(`email${index}`) ? false : ''"
                  :invalid-feedback="errors.first(`email${index}`)"
                  description="Netfang aðstandanda"
                >
                  <b-form-input
                    :id="`email${index}`"
                    :name="`email${index}`"
                    ref="contactEmailField"
                    type="email"
                    v-model="contact.email"
                    :state="submitted && errors.has(`email${index}`) ? false : ''"
                    v-validate="'required'"
                    data-vv-as="netfang"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  label="Farsími*:"
                  :label-for="`mobile${index}`"
                  :state="submitted && errors.has(`mobile${index}`) ? false : ''"
                  :invalid-feedback="errors.first(`mobile${index}`)"
                  description="Farsímanúmer aðstandanda"
                >
                  <b-form-input
                    :id="`mobile${index}`"
                    :name="`mobile${index}`"
                    type="text"
                    v-model="contact.tel"
                    :state="submitted && errors.has(`mobile${index}`) ? false : ''"
                    v-validate="'required'"
                    data-vv-as="farsími"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <b-btn type="button" class="float-right" @click.prevent="addContact" v-if="form.contacts.length <= 3">
            <i class="fa fa-plus-circle"></i>
            Bæta við aðstandanda
          </b-btn>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies

import { Validator } from 'vee-validate';
import { mapActions } from 'vuex';
import MaskedInput from 'vue-text-mask';
import { b64EncodeUnicode, serverValidationToForm } from '@/utils';

const DEFAULT_FORM = {
  ssn: '',
  name: '',
  email: '',
  tel: '',
  account: '',
  specialNeeds: '',
  contacts: [{ name: '', ssn: '', email: '', tel: '' }],
};

const CHECK_SSN_QUERY = gql`
  query CheckSsn($id: ID!) {
    checkSsn(id: $id) {
      ssn
      name
      familyNumber
      domicile
      postcode
      postcodeName
      birthYear {
        birthYear
        birthYearReal
        birthYearOverridden
      }
    }
  }
`;

const MUTATION = gql`
  mutation AddPersonToPlacementGroup($input: AddPersonToPlacementGroupInput!) {
    addPersonToPlacementGroup(input: $input) {
      placementGroupPerson {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export default {
  name: 'GroupPersonCreateModal',
  components: {
    MaskedInput,
  },
  data() {
    return {
      shownOnce: false,
      loading: false,
      submitted: false,
      errorList: [],
      groupId: null,
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
    };
  },
  computed: {},
  apollo: {
    placementGroup: {
      query: gql`
        query PlacementGroup($id: ID!) {
          placementGroup(id: $id) {
            id
            databaseId
            name
          }
        }
      `,
      variables() {
        return {
          id: b64EncodeUnicode(`PlacementGroupType:${this.groupId}`),
        };
      },
      skip() {
        return !this.shownOnce;
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  methods: {
    addContact() {
      this.form.contacts.push({ name: '', ssn: '', email: '', mobile: '' });
    },
    removeContact(index) {
      this.form.contacts.splice(index, 1);
    },
    async checkSSN(contactIndex) {
      if (contactIndex !== null) {
        const contact = this.form.contacts[contactIndex];

        if (!contact.ssn || contact.ssn.length !== 10) {
          this.$set(contact, 'name', '');
        }
      } else if (!this.form.ssn || this.form.ssn.length !== 10) {
        this.$set(this.form, 'name', '');
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        // Engar villur í formi.
        try {
          this.loading = true;

          const {
            data: {
              addPersonToPlacementGroup: { errors },
            },
          } = await this.$apollo.mutate({
            mutation: MUTATION,
            variables: {
              input: {
                placementGroup: b64EncodeUnicode(`PlacementGroupType:${this.groupId}`),
                ssn: this.form.ssn,
                email: this.form.email,
                tel: this.form.tel,
                account: this.form.account,
                contacts: this.form.contacts.map(c => ({
                  ssn: c.ssn,
                  name: c.name,
                  email: c.email,
                  tel: c.tel,
                })),
              },
            },
          });

          if (errors) {
            serverValidationToForm({
              errors,
              setFieldError: (field, error) => {
                this.errors.add({
                  field,
                  msg: error,
                  rule: 'server',
                });
              },
              errorFieldMap: { nonFieldErrors: 'ssn' },
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            this.displaySuccess(`Þátttakandanum hefur verið bætt í hópinn.`);
            this.$emit('successful', {});
            this.$refs.createModal.hide();
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
      }
    },
    show(groupId) {
      this.shownOnce = true;
      this.$set(this, 'form', JSON.parse(JSON.stringify(DEFAULT_FORM)));
      this.submitted = false;
      this.loading = false;
      this.groupId = null;
      this.groupId = groupId;
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  mounted() {
    const checkSsnF = (value, [otherValue]) =>
      new Promise(resolve => {
        if (otherValue === undefined) {
          this.$set(this.form, 'name', '');
        } else {
          this.$set(this.form.contacts[otherValue], 'name', '');
        }

        this.$apollo
          .query({
            query: CHECK_SSN_QUERY,
            variables: {
              id: b64EncodeUnicode(`CheckSsnType:${value}`),
            },
          })
          .then(({ data: { checkSsn } = {} }) => {
            if (checkSsn) {
              if (otherValue === undefined) {
                this.$set(this.form, 'name', checkSsn.name);
                this.$refs.emailField.$el.focus();
              } else {
                this.$set(this.form.contacts[otherValue], 'name', checkSsn.name);
                this.$refs.contactEmailField[otherValue].$el.focus();
              }
              return resolve({
                valid: true,
              });
            }
            return resolve({
              valid: false,
              data: {
                message: 'Kennitala fannst ekki.',
              },
            });
          })
          .catch(() => {
            this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
            return resolve({
              valid: false,
              data: {
                message: 'Óvænt villa kom upp. Vinsamlegast reyndu aftur.',
              },
            });
          });
      });

    Validator.extend('checkSsn', {
      validate: checkSsnF,
      getMessage: (field, params, data) => data.message,
    });
  },
};
</script>
