
import { Component, Vue } from 'vue-property-decorator';
import { Action, State, Getter } from 'vuex-class';
import { BModal } from 'bootstrap-vue';
import appl from '@/api/application';
import { ApplicationList, WorkplaceDropdown } from '@/api/openapi/api';
import InlineEdit from '@/components/InlineEdit.vue';

@Component({
  components: {
    InlineEdit,
  },
})
export default class ApplicationEditWorkplaceModal extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  @State loggedInUser: any;
  @Getter loggedInUserHasAnyPermission: any;

  item: ApplicationList | null = null;
  form: Array<string> = [];

  $refs!: {
    modal: BModal;
  };

  workplaceList: Array<
    WorkplaceDropdown & {
      selected: boolean;
      priorityIndex: number | null;
    }
  > = [];
  workplaceListLoading = false;
  workplaceListError = false;

  async loadWorkplace() {
    this.workplaceListLoading = true;
    this.workplaceList = [];
    this.workplaceListError = false;
    try {
      const response = await this.$coreApi.workplaceDropdownList({
        type: undefined,
      });

      this.workplaceList = response.data.map(w => {
        const priorityIndex = this.form.findIndex(x => x === w.id);

        return {
          ...w,
          selected: this.form.find(x => x === w.id) !== undefined,
          priorityIndex: priorityIndex === -1 ? null : priorityIndex,
        };
      });

      this.normalizeWorkplaceList();
    } catch (e) {
      // this.$log.error(e);
      this.workplaceListError = true;
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.workplaceListLoading = false;
    }
  }

  get workplaceListSelected() {
    return this.workplaceList.filter(w => w.selected);
  }

  show(item: ApplicationList) {
    this.item = item;
    this.form = item.workplaces.map(x => x.workplace.id);
    this.loadWorkplace();
    this.$refs.modal.show();
  }

  normalizeWorkplaceList(idChanged?: string) {
    if (idChanged) {
      this.workplaceList.forEach(w => {
        if (w.id === idChanged && w.priorityIndex === null) {
          this.$set(w, 'priorityIndex', this.workplaceListSelected.length);
        } else if (w.id === idChanged) {
          this.$set(w, 'priorityIndex', null);
        }
      });
    }

    this.workplaceList = this.workplaceList.sort((a, b) => {
      if (a.priorityIndex === b.priorityIndex) {
        return 0;
      }

      if (a.priorityIndex === null) {
        return 1;
      }
      if (b.priorityIndex === null) {
        return -1;
      }

      return a.priorityIndex < b.priorityIndex ? -1 : 1;
    });
  }

  swapWorkplace(idx1: number, idx2: number) {
    const list = [...this.workplaceList];
    const w = list[idx1];
    list[idx1] = {
      ...list[idx2],
      priorityIndex: idx1,
    };
    list[idx2] = {
      ...w,
      priorityIndex: idx2,
    };
    this.workplaceList = list;
  }

  moveWorkplaceUp(idx: number) {
    if (idx > 0) {
      this.swapWorkplace(idx, idx - 1);
    }
  }

  moveWorkplaceDown(idx: number) {
    if (idx < this.workplaceListSelected.length - 1) {
      this.swapWorkplace(idx, idx + 1);
    }
  }

  async submit() {
    if (this.item) {
      try {
        const data = {
          workplace: this.workplaceList.filter(x => x.selected).map(x => x.id),
        };

        await appl.applicationPatch(this.item.id, data);

        this.displaySuccess('"Vinnustaður" uppfærður!');
        this.$emit('successful', {});
        this.$refs.modal.hide();
        this.item = null;
        this.form = [];
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    }
  }
}
