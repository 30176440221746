import is from '@/locale/flatpickr/is';
/**
 * Vinna úr blaðsíðupplýsingum frá vefþjón.
 *
 * @param {objects} response Svar frá vefþjón.
 */
export const parsePagination = response => {
  // eslint-disable-line import/prefer-default-export
  const currentLimit = parseInt(response.headers['x-current-limit'], 10);
  const currentPage = parseInt(response.headers['x-current-page'], 10);
  const totalCount = parseInt(response.headers['x-total-count'], 10);
  const totalPages = parseInt(response.headers['x-total-pages'], 10);

  const showingFrom = totalCount > 0 ? currentLimit * (currentPage - 1) + 1 : 0;
  const showingTo = currentLimit * (currentPage - 1) + response.data.length;

  return {
    currentLimit,
    currentPage,
    totalCount,
    totalPages,
    showingFrom,
    showingTo,
  };
};

/*
 * Mixin fyrir blaðsíður og limit.
 */
export const paginationMixin = {
  data() {
    return {};
  },
  methods: {
    /*
     * Breyta um blaðsíðu.
     */
    changePage(page) {
      this.loadList(page, this.pagination.currentLimit);
    },
    /*
     * Breyta leitarniðurstöðum á blaðsíðu.
     */
    changeLimit(limit) {
      this.loadList(null, limit);
    },
  },
};

/*
 * Villuskilaboð frá Django yfir í VeeValidate.
 */
export const djangoErrorsToVee = (data, errorBag, errors) => {
  if (Array.isArray(data)) {
    data.forEach(e => errors.push(e));
  } else {
    const { non_field_errors: nonFieldErrors, ...other } = data;

    if (nonFieldErrors) {
      nonFieldErrors.forEach(err => {
        errors.push(err);
      });
    }

    Object.entries(other).forEach(([k, v]) => {
      v.forEach(e =>
        errorBag.add({
          field: k,
          msg: e,
          rule: 'server',
        }),
      );
    });
  }
};

export const serverValidationToForm = ({ errors, setFieldError, errorFieldMap }) => {
  if (setFieldError) {
    if (Array.isArray(errors)) {
      errors.forEach(({ field, messages }) => {
        if (errorFieldMap && field in errorFieldMap) {
          if (Array.isArray(messages)) {
            setFieldError(errorFieldMap[field], messages[0]);
          } else {
            setFieldError(errorFieldMap[field], messages);
          }
        } else if (Array.isArray(messages)) {
          setFieldError(field, messages[0]);
        } else {
          setFieldError(field, messages);
        }
      });
    } else {
      Object.entries(errors)
        .filter(([k]) => k !== 'response')
        .forEach(([k, v]) => {
          if (errorFieldMap && k in errorFieldMap) {
            if (Array.isArray(v)) {
              setFieldError(errorFieldMap[k], v[0]);
            } else {
              setFieldError(errorFieldMap[k], v);
            }
          } else if (Array.isArray(v)) {
            setFieldError(k, v[0]);
          } else {
            setFieldError(k, v);
          }
        });
    }
  }
};

export const handleGqlError = (error, setFieldError, field) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    error.graphQLErrors.map(({ message /* locations, path */ }) => setFieldError(field, message));
  } else {
    setFieldError(field, `Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
  }
};

export const flatpickrConfig = () => ({
  wrap: true,
  dateFormat: 'Y-m-d',
  locale: is,
  allowInput: false,
  altInput: true,
  altFormat: 'd.m.Y',
});

export const getInitials = string => {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const btoa2 = b => Buffer.from(b, 'binary').toString('base64');

export const b64EncodeUnicode = str => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa2(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode(`0x${p1}`);
    }),
  );
};

export const b64DecodeUnicode = str => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
};

export const parseAfter = (page, pageSize) => {
  return page > 1 ? b64EncodeUnicode(`arrayconnection:${(page - 1) * pageSize - 1}`) : null;
};
