import Vue from 'vue';

import config from '../config';

export default {
  /**
   * Vefþjónusta til þess að uppfæra starfstímabil á umsókn
   */
  applicationUpdatePeriodPart(id, data) {
    return Vue.http
      .put(config.APPLICATION_UPDATE_PERIOD_PART_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra tímabil á umsókn
   */
  applicationUpdatePeriod(id, data) {
    return Vue.http
      .put(config.APPLICATION_UPDATE_PERIOD_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra hlut í umsókn
   *
   * @param {string} id Einkenni umsóknar sem á að breyta.
   */
  applicationPatch(id, data) {
    return Vue.http
      .patch(config.APPLICATION_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá umsókn.
   *
   * @param {object} data Gögn um umsókn
   */
  applicationCreate(data) {
    return Vue.http
      .post(config.APPLICATION_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra umsókn.
   *
   * @param {number} id Einkenni umsóknar til að uppfæra.
   * @param {object} data Gögn um breytta umsókn
   */
  applicationUpdate(id, data) {
    return Vue.http
      .put(config.APPLICATION_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða umsókn.
   *
   * @param {number} id Einkenni umsókn til að eyða.
   */
  applicationDelete(id) {
    return Vue.http
      .delete(config.APPLICATION_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eina umsókn.
   *
   * @param {number} id Einkenni umsóknar til að sækja.
   */
  applicationAccept(id, data) {
    return Vue.http
      .post(config.APPLICATION_ACCEPT_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að hafna umsókn.
   *
   */
  applicationReject(id, data) {
    return Vue.http
      .post(config.APPLICATION_REJECT_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /*
   * Vefþjónusta til þess að klára umsókn
   */

  applicationFinish(id) {
    return Vue.http
      .post(config.APPLICATION_FINISH_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /*
   * Vefþjónusta til þess að breyta stöðu umsóknar
   */

  applicationChangeStatus(id) {
    return Vue.http
      .post(config.APPLICATION_CHANGE_STATUS_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /*
   * Vefþjónusta til þess að afskrá umsókn
   */

  applicationCancel(id, data) {
    return Vue.http
      .post(config.APPLICATION_CANCEL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tengiliði eftir umsókn.
   */
  applicationContactList(application) {
    return Vue.http
      .get(config.APPLICATION_CONTACT_LIST_URL, {
        params: {
          application,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn tengilið
   *
   * @param {number} id Einkenni tengiliðs til að sækja.
   */
  applicationContactDetail(id) {
    return Vue.http
      .get(config.APPLICATION_CONTACT_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að skrá tengilið.
   *
   * @param {object} data Gögn um tengilið
   */
  applicationContactCreate(data) {
    return Vue.http
      .post(config.APPLICATION_CONTACT_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra umsókn.
   *
   * @param {number} id Einkenni tengiliðs til að uppfæra.
   * @param {object} data Gögn um breytta umsókn
   */
  applicationContactUpdate(id, data) {
    return Vue.http
      .put(config.APPLICATION_CONTACT_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða tengilið.
   *
   * @param {number} id Einkenni tengiliðs til að eyða.
   */
  applicationContactDelete(id) {
    return Vue.http
      .delete(config.APPLICATION_CONTACT_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  workplaceTypeList() {
    return Vue.http
      .get(config.APPLICATION_WORKPLACE_TYPE_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  periodList() {
    return Vue.http
      .get(config.APPLICATION_PERIOD_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  workplaceList(type) {
    return Vue.http
      .get(config.APPLICATION_WORKPLACE_LIST_URL, {
        params: {
          type,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
