<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Launatímabil</div>
      </b-col>
      <b-col class="d-flex justify-content-end" v-if="loggedInUserHasAnyPermission('write_paymentperiod')">
        <b-btn variant="primary" @click.prevent="paymentPeriodCreateModal" class="pull-right">
          <i class="fa fa-fw fa-plus-circle"></i>
          Bæta við
        </b-btn>
      </b-col>
    </b-row>
    <b-card no-body border-variant="light" class="mb-2">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Heiti</th>
            <th>Dags. frá</th>
            <th>Dags. til</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <td colspan="100"><loading /></td>
        </tbody>
        <tbody v-if="paymentPeriodList.length == 0 && !loading">
          <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
        </tbody>
        <tbody v-if="!loading && paymentPeriodList.length > 0">
          <tr v-for="period in paymentPeriodList" :key="period.id">
            <td>{{ period.name }}</td>
            <td>{{ period.date_from | moment('DD.MM.YYYY') }}</td>
            <td>{{ period.date_to | moment('DD.MM.YYYY') }}</td>
            <td v-if="period.has_batch" class="text-right">
              <i
                class="fa fa-fw fa-lock"
                v-b-tooltip.hover
                title="Bunki hefur verið stofnaður á launatímabil og því ekki hægt að breyta því"
              ></i>
            </td>
            <td v-if="!period.has_batch" class="text-right">
              <b-btn
                :size="'sm'"
                variant="primary"
                @click.prevent="paymentPeriodEditModal(period.id)"
                v-if="loggedInUserHasAnyPermission('write_paymentperiod')"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <template slot="modal-footer">
      <payment-period-create-modal ref="paymentPeriodCreateModal" @successful="loadList"></payment-period-create-modal>
      <payment-period-create-modal ref="paymentPeriodEditModal" @successful="loadList"></payment-period-create-modal>
    </template>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import payment from '@/api/payment';
import PaymentPeriodCreateModal from '@/components/paymentperiod/PaymentPeriodCreateModal.vue';

export default {
  name: 'paymentperiod',
  components: {
    PaymentPeriodCreateModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission', 'loggedInUserOptions']),
  },
  data() {
    return {
      paymentPeriodList: [],
      loading: false,
      updateId: null,
    };
  },
  methods: {
    async loadList() {
      this.loading = true;
      this.paymentPeriodList = [];
      try {
        const response = await payment.paymentPeriodList();
        this.paymentPeriodList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Modal gluggar
     */
    paymentPeriodCreateModal() {
      this.$refs.paymentPeriodCreateModal.show();
    },
    paymentPeriodEditModal(id) {
      this.$refs.paymentPeriodEditModal.show(id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style></style>
