<template>
  <div id="app">
    <template v-if="!authLoading">
      <router-view></router-view>
    </template>
    <toast position="ne"></toast>
  </div>
</template>

<script>
import { Toast } from 'vuex-toast';
import { mapActions, mapState } from 'vuex';
import auth from '@/api/auth';

export default {
  name: 'app',
  components: {
    Toast,
  },
  data() {
    return {
      pingTimeout: null,
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
      authLoading: 'authLoading',
    }),
  },
  methods: {
    async ping() {
      try {
        if (this.loggedInUser) {
          await auth.ping();
        }
      } catch (e) {
        this.$log.debug(e);
        window.location.href = '/innskraning?timeout=true';
      } finally {
        this.pingTimeout = setTimeout(() => {
          this.ping();
        }, 120000);
      }
    },
    ...mapActions(['getLoggedInUser', 'getTownList']),
  },
  async created() {
    this.ping();
  },
  beforeDestroy() {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }
  },
};
</script>

<style lang="scss"></style>
