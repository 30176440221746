<template>
  <vinnuskoli-page>
    <div class="dashboard-date">
      <b-link @click.prevent="changeDate(-1)"><i class="fa fa-angle-left pr-2"></i></b-link>
      <span>{{ days[date.day()] }} {{ date | moment('DD.MM.YYYY') }}</span>
      <b-link @click.prevent="changeDate(1)"><i class="fa fa-angle-right pl-2"></i></b-link>
    </div>
    <b-card class="card-primary-header mt-5">
      <div slot="header">
        <h5 class="mb-0" style="font-weight: 100">Mínir hópar sem leiðbeinandi</h5>
      </div>
      <div v-if="groupList.length === 0 && !loading" class="mb-3">
        <div v-if="active"><i>Engir virkir hópar fundust</i></div>
        <div v-else><i>Engir hópar fundust</i></div>
      </div>
      <b-row v-if="!active && show_search" class="mb-3">
        <b-col>
          <strong>Leita eftir ártali</strong>
          <b-form-input
            id="year"
            name="year"
            type="text"
            v-model="year"
            maxlength="4"
            placeholder="Leita eftir ártali"
            @change="loadGroups()"
          >
          </b-form-input>
        </b-col>
      </b-row>
      <table class="table white-table" v-if="groupList.length > 0">
        <tr>
          <th>Tímabil</th>
          <th>Vinnustaður</th>
          <th>Hópur</th>
          <th>Dags.</th>
          <th class="text-center">Fj. þátttakenda</th>
          <th></th>
        </tr>
        <tr v-for="group in groupList" :key="group.id">
          <td>{{ group.period.name }}</td>
          <td>{{ group.workplace.name }}</td>
          <td>{{ group.name }}</td>
          <td>
            {{ group.date_from | moment('DD.MM.YYYY') }} -
            {{ group.date_to | moment('DD.MM.YYYY') }}
          </td>
          <td class="text-center">{{ group.student_count }}</td>
          <td>
            <b-link
              class="btn btn-primary pull-right"
              style="color: #fff"
              v-if="loggedInUserHasAnyPermission('read_placementgroup')"
              v-b-tooltip.hover
              title="Skoða hóp"
              :to="{
                name: 'Group',
                params: { id: group.id },
              }"
              target="_blank"
            >
              <i class="fa fa-fw fa-search"></i>
            </b-link>
            <b-link
              class="btn btn-primary pull-right mr-2"
              style="color: #fff"
              v-if="loggedInUserHasAnyPermission('write_placementgroup')"
              v-b-tooltip.hover
              title="Dagbók hóps"
              :to="{
                name: 'DiaryWithParams',
                params: { date: group.attendanceDate, group: group.id },
              }"
              target="_blank"
            >
              <i class="fa fa-fw fa-book"></i>
            </b-link>
            <b-link
              class="btn btn-primary pull-right mr-2"
              style="color: #fff"
              v-if="loggedInUserHasAnyPermission('read_attendance')"
              v-b-tooltip.hover
              title="Skrá viðveru"
              :to="{
                name: 'AttendanceWithParams',
                params: { date: group.attendanceDate, group: group.id },
              }"
              target="_blank"
            >
              <i class="fa fa-fw fa-calendar"></i>
            </b-link>
          </td>
        </tr>
      </table>

      <pagination
        v-if="pagination && pagination.totalPages > 1"
        :pagination-response="pagination"
        @change-limit="changeLimit"
        @change-page="changePage"
      >
      </pagination>
      <div class="pl-1">
        <b-link @click.prevent="changeActive">
          <i class="fa fa-fw fa-search"></i>
          <i v-if="active">Skoða alla hópa</i>
          <i v-else>Skoða virka hópa</i>
        </b-link>
      </div>
    </b-card>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import placement from '@/api/placement';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'dashboard',
  components: {
    Pagination,
  },
  mixins: [paginationMixin],
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      show_search: false,
      loading: false,
      page: null,
      pagination: null,
      groupList: [],
      date: moment(),
      year: null, // moment().year(),
      days: ['Sunnudagur', 'Mánudagur', 'Þriðjudagur', 'Miðvikudagur', 'Fimmtudagur', 'Föstudagur', 'Laugardagur'],
      active: true,
    };
  },
  methods: {
    async loadGroups(page = null, limit = null) {
      this.loading = true;
      this.groupList = [];
      try {
        const date = this.active ? this.date : null;
        const year = !this.active ? this.year : null;
        const response = await placement.instructorPlacementGroupList(page, limit, date, year);
        this.groupList = response.data;
        this.groupList.forEach(g => {
          if (this.active) {
            this.$set(g, 'attendanceDate', moment(this.date).format('YYYY-MM-DD'));
          } else {
            this.$set(g, 'attendanceDate', moment(g.date_from).format('YYYY-MM-DD'));
          }
        });
        if (this.groupList.length > 0) {
          this.show_search = true;
        }
        this.pagination = parsePagination(response);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    changeActive() {
      this.active = !this.active;
      this.loadGroups();
    },
    changeDate(change) {
      this.date = moment(this.date).add(change, 'days');
      this.active = true;
      this.loadGroups();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadGroups();
  },
};
</script>
<style lang="scss">
@import '../style/variables';
.dashboard-date {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 300;
  a {
    color: inherit;
  }
}

.card-primary-header {
  .card-header {
    background-color: $primary;
    color: #fff;
    font-size: 18px;
  }
}
</style>
