<template>
  <div>
    <h5 class="header">Umsækjandi</h5>
    <div class="alert alert-danger" v-if="errorList.length > 0">
      <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
    </div>
    <b-row>
      <b-col>
        <strong>Nafn</strong>
        <div>
          {{ item.name }}
          <b-link
            v-if="loggedInUserHasAnyPermission('read_person') && item.personDatabaseId"
            :to="{ name: 'Student', params: { id: item.personDatabaseId } }"
          >
            <i class="fa fa-fw fa-search"></i>
          </b-link>
        </div>
      </b-col>
      <b-col>
        <strong>Kennitala</strong>
        <div>{{ item.ssn }}</div>
      </b-col>
      <b-col>
        <strong>Fæðingarár</strong>
        <div>
          {{ item.birthYear.birthYearReal }}
          <strong v-if="item.birthYear.birthYearOverridden">
            ({{ item.birthYear.birthYear }})
            <i
              class="fa fa-fw fa-info-circle"
              v-b-tooltip.hover
              :title="`Þessi þátttakandi er með undanþágu og á að vera tekið sem ${item.birthYear.birthYearReal}`"
            ></i>
          </strong>
        </div>
      </b-col>
      <b-col v-if="item2">
        <strong>Lögheimili <small class="text-muted">(Í dag)</small></strong>
        <div>{{ item2.domicile }}, {{ item2.postcode }} {{ item2.postcodeName }}</div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <strong>Staða</strong>
        <div>
          {{ getStatusName(item.status) }}
          <span v-if="(item.status === 'IN_PROGRESS' || item.status === 'IN_PROGRESS_APPROVED') && item.workplaceInProgress">
            ({{ item.workplaceInProgress.name }})</span
          >
          <b-btn
            @click="changeStatusModal()"
            v-b-tooltip.hover
            title="Breyta stöðu umsóknar"
            v-if="
              item.status === 'REJECTED' ||
              item.status === 'WITHDRAWN' ||
              item.status === 'CANCELLED' ||
              (item.status === 'ACCEPTED' && item.allPeriodParts.length > 0)
            "
            variant="warning"
            size="sm"
            style="padding: 0px 4px"
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </b-btn>
          <b-btn
            @click="cancelApplicationModal()"
            v-b-tooltip.hover
            title="Afskrá umsókn"
            v-if="item.status === 'ACCEPTED'"
            variant="danger"
            size="sm"
            style="padding: 0px 4px"
          >
            <i class="fa fa-ban" aria-hidden="true"></i>
          </b-btn>
        </div>
      </b-col>
      <b-col>
        <strong>Netfang</strong>
        <div>
          <inline-edit
            class="mb-0"
            field="email"
            :doEdit="edit.email"
            @editField="editEmailField"
            v-if="canBeUpdated && loggedInUserHasAnyPermission('write_application')"
            @saveField="validateBeforeSubmit('email')"
          >
            <span slot="content"
              ><i>{{ item.email ? item.email : 'Ekki skráð' }}</i></span
            >
            <span slot="input">
              <b-form-textarea
                id="email"
                name="email"
                type="text"
                v-model="form.email"
                required
                placeholder="Sláðu inn netfang"
                :state="submitted && errors.has('email') ? false : ''"
                v-validate="'required'"
                data-vv-as="netfang"
                v-on:keyup.enter="validateBeforeSubmit('email')"
                ref="emailInput"
              ></b-form-textarea>
            </span>
          </inline-edit>
          <span v-else>
            {{ item.email }}
          </span>
        </div>
      </b-col>
      <b-col>
        <strong>Farsími</strong>
        <div>
          <inline-edit
            class="mb-0"
            field="tel"
            :doEdit="edit.tel"
            @editField="editTelField"
            v-if="canBeUpdated && loggedInUserHasAnyPermission('write_application')"
            @saveField="validateBeforeSubmit('tel')"
          >
            <span slot="content"
              ><i>{{ item.tel ? item.tel : 'Ekki skráð' }}</i></span
            >
            <span slot="input">
              <b-form-textarea
                id="tel"
                name="tel"
                type="text"
                v-model="form.tel"
                required
                placeholder="Sláðu inn farsíma"
                :state="submitted && errors.has('tel') ? false : ''"
                v-validate="'required'"
                data-vv-as="farsími"
                v-on:keyup.enter="validateBeforeSubmit('tel')"
                ref="telInput"
              ></b-form-textarea>
            </span>
          </inline-edit>
          <span v-else>
            {{ item.tel }}
          </span>
        </div>
      </b-col>
      <b-col>
        <strong>Bankareikningur</strong>
        <div>
          <inline-edit
            class="mb-0"
            field="account"
            :doEdit="edit.account"
            @editField="editAccountField"
            v-if="canBeUpdated && loggedInUserHasAnyPermission('write_application')"
            @saveField="validateBeforeSubmit('account')"
          >
            <span slot="content"
              ><i>{{ item.account ? item.account : 'Ekki skráð' }}</i></span
            >
            <span slot="input">
              <masked-input
                type="text"
                id="account"
                name="account"
                class="form-control"
                v-model="form.account"
                :mask="[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
                :guide="true"
                :showMask="false"
                :class="{ 'is-invalid': submitted && errors.has('account') }"
                v-validate="'required'"
                data-vv-as="bankareikning"
                v-on:keyup.enter="validateBeforeSubmit('account')"
                ref="accountInput"
              ></masked-input>
            </span>
          </inline-edit>
          <span v-else>
            {{ item.account }}
          </span>
        </div>
      </b-col> </b-row
    ><b-row class="mt-4">
      <b-col v-if="item.specialNeeds">
        <strong>Sérþarfir</strong>
        <div>
          <inline-edit
            class="mb-0"
            field="specialNeeds"
            :doEdit="edit.specialNeeds"
            @editField="editSpecialNeedsField"
            v-if="canBeUpdated && loggedInUserHasAnyPermission('write_application')"
            @saveField="validateBeforeSubmit('specialNeeds')"
          >
            <span slot="content"
              ><i>{{ item.specialNeeds ? item.specialNeeds : 'Ekki skráð' }}</i></span
            >
            <span slot="input">
              <b-form-textarea
                id="specialNeeds"
                name="specialNeeds"
                type="text"
                v-model="form.specialNeeds"
                placeholder="Sláðu inn sérþarfir"
                :state="submitted && errors.has('specialNeeds') ? false : ''"
                data-vv-as="sérþarfir"
                v-on:keyup.enter="validateBeforeSubmit('specialNeeds')"
                ref="specialNeedsInput"
              ></b-form-textarea>
            </span>
          </inline-edit>
          <span v-else>
            {{ item.specialNeeds }}
          </span>
        </div>
      </b-col>
      <b-col>
        <strong>Annað</strong>
        <div>
          <inline-edit
            class="mb-0"
            field="other"
            :doEdit="edit.other"
            @editField="editOtherField"
            v-if="canBeUpdated && loggedInUserHasAnyPermission('write_application')"
            @saveField="validateBeforeSubmit('other')"
          >
            <span slot="content"
              ><i>{{ item.other ? item.other : 'Ekki skráð' }}</i></span
            >
            <span slot="input">
              <b-form-textarea
                id="other"
                name="other"
                type="text"
                v-model="form.other"
                placeholder="Sláðu inn annað"
                :state="submitted && errors.has('other') ? false : ''"
                data-vv-as="sérþarfir"
                v-on:keyup.enter="validateBeforeSubmit('other')"
                ref="otherInput"
              ></b-form-textarea>
            </span>
          </inline-edit>
          <span v-else>
            {{ item.other }}
          </span>
        </div>
      </b-col>
      <b-col v-if="item.otherWorkplace">
        <strong>Annað um val á starfsstað</strong>
        <div>
          <inline-edit
            class="mb-0"
            field="otherWorkplace"
            :doEdit="edit.otherWorkplace"
            @editField="editOtherWorkplaceField"
            v-if="canBeUpdated && loggedInUserHasAnyPermission('write_application')"
            @saveField="validateBeforeSubmit('otherWorkplace')"
          >
            <span slot="content"
              ><i>{{ item.otherWorkplace ? item.otherWorkplace : 'Ekki skráð' }}</i></span
            >
            <span slot="input">
              <b-form-textarea
                id="otherWorkplace"
                name="otherWorkplace"
                type="text"
                v-model="form.otherWorkplace"
                placeholder="Sláðu inn annað"
                :state="submitted && errors.has('otherWorkplace') ? false : ''"
                data-vv-as="sérþarfir"
                v-on:keyup.enter="validateBeforeSubmit('otherWorkplace')"
                ref="otherWorkplaceInput"
              ></b-form-textarea>
            </span>
          </inline-edit>
          <span v-else>
            {{ item.otherWorkplace }}
          </span>
        </div>
      </b-col>
      <b-col></b-col>
    </b-row>
    <b-row class="mt-4" v-if="item.resolutionComment && item.resolutionComment.length > 0">
      <b-col>
        <strong>Ástæða afskráningar</strong>
        <div>{{ item.resolutionComment }}</div>
      </b-col>
    </b-row>
    <change-status-modal ref="changeStatusModal" @successful="reloadApplication"></change-status-modal>
    <cancel-application-modal ref="cancelApplicationModal" @successful="reloadApplication"></cancel-application-modal>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import { mapActions, mapGetters } from 'vuex';
import InlineEdit from '@/components/InlineEdit.vue';
import { djangoErrorsToVee } from '@/utils';
import appl from '@/api/application';
import ChangeStatusModal from '@/components/application/ChangeStatusModal.vue';
import CancelApplicationModal from '@/components/application/CancelApplicationModal.vue';

export default {
  name: 'application-applicant',
  props: ['item', 'item2', 'id'],
  components: {
    InlineEdit,
    MaskedInput,
    ChangeStatusModal,
    CancelApplicationModal,
  },
  computed: {
    canBeUpdated() {
      return this.item.status === 'NEW' || this.item.status === 'IN_PROGRESS' || this.item.status === 'IN_PROGRESS_APPROVED';
    },
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      form: {
        email: null,
        tel: null,
        specialNeeds: null,
        account: null,
        other: null,
        otherWorkplace: null,
      },
      edit: {
        email: false,
        tel: false,
        specialNeeds: false,
        account: false,
        other: false,
        otherWorkplace: false,
      },
    };
  },
  methods: {
    getStatusName(status) {
      switch (status) {
        case 'NEW':
          return 'Nýskráð';
        case 'SEMI_ACCEPTED':
          return 'Afgreidd að hluta';
        case 'ACCEPTED':
          return 'Afgreidd';
        case 'REJECTED':
          return 'Hafnað';
        case 'CANCELLED':
          return 'Afskráð';
        case 'IN_PROGRESS':
          return 'Í vinnslu';
        case 'IN_PROGRESS_APPROVED':
          return 'Í vinnslu samþykkt';
        case 'WITHDRAWN':
          return 'Dregin til baka';
        case 'COMPLETED':
          return 'Ráðning';
        default:
          return '';
      }
    },
    editField(field, edit) {
      if (edit) {
        this.edit[field] = true;
        this.form[field] = this.item[field];
        setTimeout(() => {
          this.$refs[`${field}Input`].focus();
        }, 0);
      } else {
        this.edit[field] = false;
        this.form[field] = null;
        this.errorList = [];
      }
    },
    editEmailField({ doEdit }) {
      this.editField('email', doEdit);
    },
    editTelField({ doEdit }) {
      this.editField('tel', doEdit);
    },
    editAccountField({ doEdit }) {
      this.editField('account', doEdit);
    },
    editSpecialNeedsField({ doEdit }) {
      this.editField('specialNeeds', doEdit);
    },
    editOtherField({ doEdit }) {
      this.editField('other', doEdit);
    },
    editOtherWorkplaceField({ doEdit }) {
      this.editField('otherWorkplace', doEdit);
    },
    reloadApplication() {
      this.$emit('reload', {});
    },
    async validateBeforeSubmit(type) {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        // Engar villur í formi.
        try {
          const data = {};
          let display = '';
          switch (type) {
            case 'email':
              data.email = this.form.email;
              display = '"Netfang" uppfært!';
              break;
            case 'tel':
              data.tel = this.form.tel;
              display = '"Farsími" uppfærður!';
              break;
            case 'account':
              data.account = this.form.account;
              display = '"Bankareikningur" uppfærður!';
              break;
            case 'specialNeeds':
              data.special_needs = this.form.specialNeeds;
              display = '"Sérþarfir" uppfærðar!';
              break;
            case 'other':
              data.other = this.form.other ?? '';
              display = '"Annað" uppfært!';
              break;
            case 'otherWorkplace':
              data.other_workplace = this.form.otherWorkplace ?? '';
              display = '"Annað um val á starfsstað" uppfært!';
              break;
            default:
              break;
          }
          await appl.applicationPatch(this.id, data);
          this.displaySuccess(display);
          this.editEmailField(false);
          this.editTelField(false);
          this.editAccountField(false);
          this.editSpecialNeedsField(false);
          this.editOtherField(false);
          this.editOtherWorkplaceField(false);
          this.$emit('reload', {});
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    changeStatusModal() {
      this.$refs.changeStatusModal.show(this.item.databaseId, this.item.status);
    },
    cancelApplicationModal() {
      this.$refs.cancelApplicationModal.show(this.item.databaseId);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
