<template>
  <div>
    <b-modal
      id="changeStatusModal"
      title="Breyta stöðu umsóknar"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Breyta stöðu"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <div>Ertu viss um að þú viljir breyta stöðu umsóknarinnar?</div>
        <div v-if="status === 'REJECTED'">Stöðu umsóknar verður breytt úr stöðunni <strong>Hafnað</strong> í <strong>Nýskráð</strong></div>
        <div v-if="status === 'CANCELLED'">
          Stöðu umsóknar verður breytt úr stöðunni <strong>Afskráð</strong> í <strong>Nýskráð</strong>
        </div>
        <div v-if="status === 'WITHDRAWN'">
          Stöðu umsóknar verður breytt úr stöðunni <strong>Dregin til baka</strong> í <strong>Nýskráð</strong>
        </div>
        <div v-if="status === 'ACCEPTED'">
          Stöðu umsóknar verður breytt úr stöðunni <strong>Afgreidd</strong> í <strong>Afgreidd að hluta</strong>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import appl from '@/api/application';

export default {
  name: 'change-status-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      failed: false,
      id: null,
      status: null,
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        try {
          await appl.applicationChangeStatus(this.id);
          this.displaySuccess('Stöðu umsóknar hefur verið breytt.');
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(id, status) {
      this.id = id;
      this.status = status;
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
