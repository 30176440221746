<template>
  <div>
    <b-modal
      id="registerAbsenceModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <h5 style="font-weight: 100" class="mb-3" v-if="this.student">
            <strong>{{ this.student.name }}</strong>
          </h5>
          <b-form-group
            label="Fjarvist:"
            label-for="absence"
            :state="submitted && errors.has('absence') ? false : ''"
            :invalid-feedback="errors.first('absence')"
            description="Fjarvistartegund"
          >
            <b-form-select id="absence" name="absence" v-model="form.absence">
              <option :value="null">-- Veldu fjarvistartegund --</option>
              <option v-for="absence in absenceList" :key="absence.id" :value="absence">
                {{ absence.name }}
              </option>
            </b-form-select>
          </b-form-group>
          <!--b-form-group label="Skrá fyrir tímabil:"
              v-if="form.absence.length > 0"
              label-for="period"
              description="Haka við ef fjarvist er fyrir tímabil en ekki dag">
              <b-form-checkbox id="period" name="period"
                            type="text" v-model="form.period"
              ></b-form-checkbox>
          </b-form-group-->
          <b-form-group
            v-if="form.period && form.absence.length > 0"
            :state="submitted && errors.has('date_from') ? false : ''"
            :invalid-feedback="errors.first('date_from')"
          >
            <label label-for="date_from"> Dags. frá </label>
            <datepicker
              id="date_from"
              name="date_from"
              placeholder="Veldu dags frá"
              :class="{ 'is-invalid': submitted && errors.has('date_from') }"
              v-model="form.dateFrom"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              data-vv-as="dags frá"
              ref="dateFromPickr"
            ></datepicker>
          </b-form-group>
          <b-form-group
            v-if="form.period && form.absence.length > 0"
            :state="submitted && errors.has('date_to') ? false : ''"
            :invalid-feedback="errors.first('date_to')"
          >
            <label label-for="date_to"> Dags. til </label>
            <datepicker
              id="date_to"
              name="date_to"
              placeholder="Veldu dags til"
              :class="{ 'is-invalid': submitted && errors.has('date_to') }"
              v-model="form.dateTo"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              ref="dateToPickr"
            ></datepicker>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
// import core from '@/api/core';
import { mapActions } from 'vuex';
// import { djangoErrorsToVee } from '@/utils';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import attendance from '@/api/attendance';

export default {
  name: 'register-absence-modal',
  components: {
    Datepicker,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      absenceList: [],
      student: null,
      title: 'Skrá fjarvist',
      lang: is,
      form: {
        absence: '',
        period: false,
        dateFrom: moment().toDate(),
        dateTo: moment().toDate(),
      },
    };
  },
  methods: {
    async loadAbsenceList() {
      this.loading = true;
      this.absenceList = [];
      try {
        const response = await attendance.attendanceOverviewTypeList();
        this.absenceList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      this.$emit('successful', {
        attendance_type: this.form.absence,
      });
      this.$refs.createModal.hide();
    },
    show(student) {
      this.form.period = false;
      this.form.dateFrom = moment().toDate();
      this.form.dateTo = moment().toDate();
      this.form.absence = student.absence ? student.absence : null;
      this.student = student;
      this.submitted = false;
      this.loading = false;
      this.loadAbsenceList();
      if (student.absence) {
        this.title = 'Breyta fjarvist';
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
