<template>
  <vinnuskoli-page>
    <b-row>
      <b-col>
        <h5 class="page-title">Senda skilaboð</h5>
      </b-col>
    </b-row>
    <b-row v-if="placementGroup">
      <b-col>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <table class="table white-table">
            <tr>
              <td width="15%"><strong>Tímabil</strong></td>
              <td>
                {{ placementGroup.period.name }}
                <span v-if="placementGroup.periodPart">
                  {{ placementGroup.periodPart.dateFrom | moment('DD.MM.YYYY') }} -
                  {{ placementGroup.periodPart.dateTo | moment('DD.MM.YYYY') }}
                </span>
                <span v-else
                  >{{ placementGroup.period.dateFrom | moment('DD.MM.YYYY') }} -
                  {{ placementGroup.period.dateTo | moment('DD.MM.YYYY') }}</span
                >
              </td>
            </tr>
            <tr>
              <td><strong>Vinnustaður</strong></td>
              <td>{{ placementGroup.workplace.name }}</td>
            </tr>
            <tr>
              <td><strong>Hópur</strong></td>
              <td>{{ placementGroup.name }}</td>
            </tr>
            <tr>
              <td><strong>Senda á</strong></td>
              <td>
                <div>
                  <div>Þátttakendur</div>
                  <b-form-checkbox id="sendStudents" name="sendStudents" type="text" v-model="form.sendStudents"></b-form-checkbox>
                </div>
                <div>
                  <div>Aðstandendur</div>
                  <b-form-checkbox id="sendContacts" name="sendContacts" type="text" v-model="form.sendContacts"></b-form-checkbox>
                </div>
              </td>
            </tr>
            <tr>
              <td><strong>Móttakendur</strong></td>
              <td>
                <div v-if="!showRecipients">
                  <span class="pr-2">Þátttakendur: {{ getRecipientLength }}</span>
                  <span class="pr-2">Aðstandendur: {{ getRecipientContactLength }}</span>
                  <b-btn variant="secondary" @click.prevent="toggleRecipients"> Skoða <i class="fa fa-fw fa-search"></i> </b-btn>
                </div>
                <div v-else>
                  <div class="pull-right">
                    <b-btn variant="secondary" @click.prevent="toggleRecipients" class="ml-4">
                      Fela <i class="fa fa-fw fa-remove" style="color: #fff"></i>
                    </b-btn>
                  </div>
                  <div>
                    <table class="table table-no-border table-4px-padding">
                      <tr>
                        <th></th>
                        <th>Þátttakandi</th>
                        <th style="padding-left: 20px">Aðstandendur</th>
                      </tr>
                      <tr v-for="person in placementGroup.allPersons" :key="person.id">
                        <td>
                          <b-form-checkbox
                            :id="`person${person.id}`"
                            :name="`person${person.id}`"
                            type="text"
                            v-model="person.selected"
                          ></b-form-checkbox>
                        </td>
                        <td>{{ person.person.name }} - {{ person.email }}</td>
                        <td style="padding-left: 20px">
                          <div v-for="contact in person.allContacts" :key="contact.id">{{ contact.name }} - {{ contact.email }}</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><strong>Titill</strong></td>
              <td>
                <b-form-input
                  id="subject"
                  name="subject"
                  type="text"
                  v-model="form.subject"
                  required
                  :state="submitted && errors.has('subject') ? false : ''"
                  v-validate="'required'"
                  data-vv-as="titill"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td><strong>Meginmál</strong></td>
              <td>
                <b-form-textarea
                  id="text"
                  name="text"
                  type="text"
                  v-model="form.text"
                  required
                  :state="submitted && errors.has('text') ? false : ''"
                  v-validate="'required'"
                  :rows="8"
                  data-vv-as="meginmál"
                ></b-form-textarea>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <b-btn variant="primary" @click.prevent="validateBeforeSubmit" :disabled="submitting">
                  <i class="fa fa-fw fa-envelope"></i>
                  Senda skilaboð
                </b-btn>
              </td>
            </tr>
          </table>
        </b-form>
      </b-col>
    </b-row>
  </vinnuskoli-page>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies

import { mapActions } from 'vuex';
import placement from '@/api/placement';
import { djangoErrorsToVee, b64EncodeUnicode } from '@/utils';

export default {
  name: 'send-message',
  data() {
    return {
      submitted: false,
      submitting: false,
      groupId: null,
      showRecipients: false,
      form: {
        subject: '',
        text: '',
        sendStudents: true,
        sendContacts: false,
      },
    };
  },
  computed: {
    getRecipientLength() {
      return this.placementGroup && this.form.sendStudents ? this.placementGroup.allPersons.filter(x => x.selected).length : 0;
    },
    getRecipientContactLength() {
      let count = 0;
      if (this.placementGroup && this.form.sendContacts) {
        this.placementGroup.allPersons
          .filter(x => x.selected)
          .forEach(x => {
            count += x.allContacts.length;
          });
      }
      return count;
    },
  },
  apollo: {
    placementGroup: {
      query: gql`
        query PlacementGroup($id: ID!) {
          placementGroup(id: $id) {
            id
            databaseId
            name
            workplace {
              id
              name
            }
            period {
              id
              name
              dateFrom
              dateTo
            }
            periodPart {
              id
              dateFrom
              dateTo
            }
            allPersons {
              id
              databaseId
              email
              person {
                id
                databaseId
                ssn
                name
              }
              allContacts {
                id
                databaseId
                ssn
                name
                email
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: b64EncodeUnicode(`PlacementGroupType:${this.groupId}`),
        };
      },
      result({ data: { placementGroup } }) {
        placementGroup.allPersons.forEach(s => this.$set(s, 'selected', true));
      },
      skip() {
        return !this.groupId;
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  methods: {
    toggleRecipients() {
      this.showRecipients = !this.showRecipients;
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.submitting = true;
          await placement.placementSendMail(this.groupId, {
            subject: this.form.subject,
            text: this.form.text,
            ids: this.placementGroup.allPersons.filter(x => x.selected).map(y => y.databaseId),
            students: this.form.sendStudents,
            contacts: this.form.sendContacts,
          });
          this.submitted = false;
          this.form.subject = '';
          this.form.text = '';
          this.form.sendStudents = true;
          this.form.sendContacts = false;
          this.displaySuccess(`Póstur hefur verið sendur á „${this.placementGroup.workplace.name} - ${this.placementGroup.name}“`);
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.submitting = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.$route.params.group) {
      this.groupId = this.$route.params.group;
    }
  },
};
</script>

<style lang="scss">
.table.table-no-border.table-4px-padding {
  tr {
    td,
    th {
      padding: 4px;
      border-color: transparent;
    }
  }
}
</style>
