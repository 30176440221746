<template>
  <div>
    <b-modal
      id="absenceCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Heiti:"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Heiti fjarvistartegundar"
          >
            <b-form-input
              id="name"
              name="name"
              autofocus
              type="text"
              v-model="form.name"
              required
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="nafn"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Sýnileg á umsóknarvef:"
            label-for="canApply"
            description="Hvort forráðarmenn megi skrá fjarvistartegund á umsóknarvef"
          >
            <input type="checkbox" id="isPublic" name="isPublic" v-model="form.isPublic" />
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import attendance from '@/api/attendance';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'absence-create-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      absenceId: '',
      title: 'Stofna fjarvistartegund',
      form: {
        name: '',
        isPublic: false,
      },
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const response = await attendance.attendanceTypeDetail(this.absenceId);
        this.form.name = response.data.name;
        this.form.isPublic = response.data.is_public;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          if (this.absenceId) {
            await attendance.attendanceTypeUpdate(this.absenceId, {
              name: this.form.name,
              is_public: this.form.isPublic,
            });
            this.displaySuccess(`Fjarvistartegundinni „${this.form.name}“ hefur verið breytt.`);
          } else {
            await attendance.attendanceTypeCreate({
              name: this.form.name,
              is_public: this.form.isPublic,
            });
            this.displaySuccess(`Fjarvistartegundin „${this.form.name}“ hefur verið stofnuð.`);
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(absenceId) {
      this.form.name = '';
      this.form.is_public = false;
      this.submitted = false;
      this.loading = false;
      if (absenceId) {
        this.title = 'Breyta fjarvistartegund';
        this.absenceId = absenceId;
        this.load();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
