<template>
  <ul class="navbar-nav bg-gradient-vala-gray sidebar sidebar-dark accordion" :class="{ toggled: sideNavToggled }" id="accordionSidebar">
    <b-link class="sidebar-brand d-flex align-items-center justify-content-center my-2" to="/">
      <img class="d-none sidebar-logo" :class="{ 'd-md-block': !sideNavToggled }" src="../assets/Logo-white-1.svg" style="width: 120px" />
      <img class="sidebar-icon" :class="{ 'd-md-none': !sideNavToggled }" src="../assets/Logo-plain.svg" />
    </b-link>

    <template v-if="loggedInUser.town">
      <div v-for="(navItem, key) in navItems" :key="key">
        <div
          class="sidebar-heading"
          :class="{ 'cursor-pointer': navItem.toggle }"
          @click.prevent="toggleNavbarOpened(navItem.toggle)"
          v-if="navItem.name && navItem.show()"
        >
          {{ navItem.name }}
          <template v-if="navItem.toggle">
            <font-awesome-icon
              class="d-none"
              :class="{ 'd-md-inline-block': !sideNavToggled }"
              icon="angle-right"
              fixed-width
              v-if="!navbar[navItem.toggle]"
            />
            <font-awesome-icon class="d-none" :class="{ 'd-md-inline-block': !sideNavToggled }" icon="angle-down" fixed-width v-else />
          </template>
        </div>

        <template v-if="!navItem.toggle || navbar[navItem.toggle]">
          <side-nav-link
            v-for="(item, idx) in navItem.items.filter(i => i.show())"
            :key="idx"
            :name="item.name"
            :to="item.to"
            :icon="item.icon"
            :active="item.active()"
          />
        </template>

        <hr class="sidebar-divider" />
      </div>
    </template>

    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0 sidebarToggle" @click="() => setSideNavToggled(!sideNavToggled)">
        <font-awesome-icon class="sidebarToggleIcon" :icon="sideNavToggled ? 'angle-right' : 'angle-left'" />
      </button>
    </div>
  </ul>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import SideNavLink from './SideNavLink.vue';

export default {
  name: 'side-nav',
  components: { SideNavLink },
  props: {
    sideNavToggled: Boolean,
    setSideNavToggled: Function,
  },
  data() {
    return {
      navItems: {
        root: {
          name: 'Vinnuskóli',
          toggle: false,
          show: () => true,
          items: [
            {
              name: 'Stjórnborð',
              to: '/',
              icon: 'tachometer-alt',
              show: () => true,
              active: () => this.$route.path === '/',
            },
            {
              name: 'Tölfræði',
              to: { name: 'Statistics' },
              icon: 'chart-bar',
              show: () => true,
              active: () => this.$route.name === 'Statistics',
            },
          ],
        },
        participants: {
          name: 'Þátttakendur',
          toggle: 'participantsOpened',
          show: () => this.showParticipants,
          items: [
            {
              name: 'Umsóknir',
              to: { name: 'ApplicationList' },
              icon: 'file-alt',
              show: () => this.loggedInUserHasAnyPermission('read_application'),
              active: () => this.$route.name === 'ApplicationList',
            },
            {
              name: 'Hópar',
              to: { name: 'Groups' },
              icon: 'users',
              show: () => this.loggedInUserHasAnyPermission('read_placementgroup'),
              active: () => this.$route.name === 'Groups',
            },
            {
              name: 'Viðvera',
              to: { name: 'Attendance' },
              icon: 'calendar-alt',
              show: () => this.loggedInUserHasAnyPermission('read_attendance'),
              active: () => this.$route.name === 'Attendance',
            },
            {
              name: 'Leit',
              to: { name: 'Search' },
              icon: 'search',
              show: () => this.loggedInUserHasAnyPermission('read_search'),
              active: () => this.$route.name === 'Search',
            },
            {
              name: 'Fjöldapóstur',
              to: { name: 'MassEmail' },
              icon: 'envelope',
              show: () => this.loggedInUserHasAnyPermission('write_emailmessage'),
              active: () => this.$route.name === 'MassEmail',
            },
            {
              name: 'Þátttakendur',
              to: { name: 'PlacementGroupPersons' },
              icon: 'users',
              show: () => this.loggedInUserHasAnyPermission('read_placementgroup'),
              active: () => this.$route.name === 'PlacementGroupPersons',
            },
            {
              name: 'Aðstandendur',
              to: { name: 'PlacementGroupPersonContacts' },
              icon: 'phone',
              show: () => this.loggedInUserHasAnyPermission('read_person'),
              active: () => this.$route.name === 'PlacementGroupPersonContacts',
            },
            // {
            //   name: '',
            //   to: { name: '' },
            //   icon: '',
            //   show: () => this.loggedInUserHasAnyPermission(''),
            //   active: () => this.$route.name === '',
            // },
          ],
        },
        supervision: {
          name: 'Umsjón',
          toggle: 'supervisionOpened',
          show: () => this.showSupervisors,
          items: [
            {
              name: 'Aðstoð',
              to: { name: 'Help' },
              icon: 'info-circle',
              show: () => true,
              active: () => this.$route.name === 'Help',
            },
            {
              name: 'Notendur',
              to: { name: 'Users' },
              icon: 'user-plus',
              show: () => this.loggedInUserHasAnyPermission('read_user'),
              active: () => this.$route.name === 'Users',
            },
            {
              name: 'Aðgangur',
              to: { name: 'Access' },
              icon: 'lock',
              show: () => this.loggedInUserHasAnyPermission('read_usergroup'),
              active: () => this.$route.name === 'Access',
            },
            {
              name: 'Hverfi',
              to: { name: 'District' },
              icon: 'home',
              show: () => this.loggedInUserHasAnyPermission('read_workplacedistrict'),
              active: () => this.$route.name === 'District',
            },
            {
              name: 'Flokkun starfsstaða',
              to: { name: 'WorkplaceType' },
              icon: 'layer-group',
              show: () => this.loggedInUserHasAnyPermission('read_workplacetype'),
              active: () => this.$route.name === 'WorkplaceType',
            },
            {
              name: 'Starfsstaðir',
              to: { name: 'Workplace' },
              icon: 'map-marker',
              show: () => this.loggedInUserHasAnyPermission('read_workplacetype'),
              active: () => this.$route.name === 'Workplace',
            },
            {
              name: 'Tímabil',
              to: { name: 'Period' },
              icon: 'calendar',
              show: () => this.loggedInUserHasAnyPermission('read_period'),
              active: () => this.$route.name === 'Period',
            },
            {
              name: 'Fjarvistartegundir',
              to: { name: 'Absence' },
              icon: 'calendar-minus',
              show: () => this.loggedInUserHasAnyPermission('read_attendancetype'),
              active: () => this.$route.name === 'Absence',
            },
            {
              name: 'Verkefnategundir',
              to: { name: 'AttendanceProject' },
              icon: 'clipboard',
              show: () => this.loggedInUserHasAnyPermission('read_attendanceproject'),
              active: () => this.$route.name === 'AttendanceProject',
            },
            {
              name: 'Undanþágulisti',
              to: { name: 'ExceptionList' },
              icon: 'list',
              show: () => this.loggedInUserHasAnyPermission('read_exemption'),
              active: () => this.$route.name === 'ExceptionList',
            },
            {
              name: 'Frammistöðumat',
              to: { name: 'ReviewQuestions' },
              icon: 'balance-scale',
              show: () => this.loggedInUserHasAnyPermission('read_reviewquestions'),
              active: () => this.$route.name === 'ReviewQuestions',
            },
            // {
            //   name: '',
            //   to: { name: '' },
            //   icon: '',
            //   show: () => this.loggedInUserHasAnyPermission(''),
            //   active: () => this.$route.name === '',
            // },
          ],
        },
        payment: {
          name: 'Uppgjör',
          toggle: 'paymentOpened',
          show: () => this.showPayment,
          items: [
            {
              name: 'Tilkynningar',
              to: { name: 'Templates' },
              icon: 'envelope-open',
              show: () => this.loggedInUserHasAnyPermission('read_template'),
              active: () => this.$route.name === 'Templates',
            },
            {
              name: 'Launatímabil',
              to: { name: 'PaymentPeriod' },
              icon: 'calendar-check',
              show: () => this.loggedInUserHasAnyPermission('read_paymentperiod'),
              active: () => this.$route.name === 'PaymentPeriod',
            },
            {
              name: 'Launataxtar',
              to: { name: 'WagePeriod' },
              icon: 'money-bill',
              show: () => this.loggedInUserHasAnyPermission('read_wageperiod'),
              active: () => this.$route.name === 'WagePeriod',
            },
            {
              name: 'Uppgjör',
              to: { name: 'Batch' },
              icon: 'calculator',
              show: () => this.loggedInUserHasAnyPermission('read_batch'),
              active: () => this.$route.name === 'Batch',
            },
            {
              name: 'Leiðréttingar',
              to: { name: 'BatchCorrection' },
              icon: 'wrench',
              show: () => this.loggedInUserHasAnyPermission('read_batch'),
              active: () => this.$route.name === 'BatchCorrection',
            },
            {
              name: 'Uppgjörsyfirlit',
              to: { name: 'BillingOverview' },
              icon: 'search',
              show: () => this.loggedInUserHasAnyPermission('read_batch'),
              active: () => this.$route.name === 'BillingOverview',
            },
            {
              name: 'Launakerfi',
              to: { name: 'Payroll' },
              icon: 'id-card',
              show: () => this.loggedInUserHasAnyPermission('read_batch'),
              active: () => this.$route.name === 'Payroll',
            },
            // {
            //   name: 'Útskrá',
            //   to: { name: 'Logout' },
            //   icon: 'power-off',
            //   show: () => this.loggedInUserHasAnyPermission(''),
            //   active: () => this.$route.name === 'Logout',
            // },
            // {
            //   name: '',
            //   to: { name: '' },
            //   icon: '',
            //   show: () => this.loggedInUserHasAnyPermission(''),
            //   active: () => this.$route.name === '',
            // },
          ],
        },
        system: {
          name: '',
          toggle: false,
          show: () => true,
          items: [
            {
              name: 'Útgáfusaga',
              to: '/utgafur',
              icon: 'code-branch',
              show: () => true,
              active: () => this.$route.path === '/utgafur',
            },
          ],
        },
      },
      navbar: {},
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
      townListLoading: 'townListLoading',
      townList: 'townList',
    }),
    ...mapGetters(['loggedInUserHasAnyPermission', 'loggedInUserOptions']),
    showParticipants() {
      return (
        this.loggedInUserHasAnyPermission('read_application') ||
        this.loggedInUserHasAnyPermission('read_placementgroup') ||
        this.loggedInUserHasAnyPermission('read_attendance') ||
        this.loggedInUserHasAnyPermission('read_search') ||
        this.loggedInUserHasAnyPermission('write_emailmessage')
      );
    },
    showSupervisors() {
      return (
        this.loggedInUserHasAnyPermission('read_user') ||
        this.loggedInUserHasAnyPermission('read_usergroup') ||
        this.loggedInUserHasAnyPermission('read_workplacedistrict') ||
        this.loggedInUserHasAnyPermission('read_workplacetype') ||
        this.loggedInUserHasAnyPermission('read_period') ||
        this.loggedInUserHasAnyPermission('read_attendancetype') ||
        this.loggedInUserHasAnyPermission('read_exemption') ||
        this.loggedInUserHasAnyPermission('read_attendanceproject')
      );
    },
    showPayment() {
      return this.loggedInUserHasAnyPermission('read_template');
    },
  },
  methods: {
    async toggleNavbarOpened(variable) {
      this.$set(this.navbar, variable, !this.navbar[variable]);
      await this.setLoggedInUserOption({ option: 'navbar', value: this.navbar });
      await this.saveLoggedInUserOptions();
    },

    ...mapActions(['saveLoggedInUserOptions', 'setLoggedInUserOption']),
  },
  created() {
    this.navbar = {};
    if (this.loggedInUser.town) {
      this.navbar = {
        participantsOpened: true,
        supervisionOpened: false,
        paymentOpened: false,
        ...this.loggedInUserOptions.navbar,
      };
    } else {
      this.navbar = {
        participantsOpened: true,
        supervisionOpened: false,
        paymentOpened: false,
      };
    }
  },
};
</script>
