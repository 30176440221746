<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Uppgjör</div>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-btn
          variant="primary"
          class="pull-right"
          v-if="options.batch.selectedBatch && options.batch.selectedBatch.status === 'NEW'"
          @click.prevent="populateBatchModal"
        >
          <i class="fa fa-fw fa-check-circle"></i>
          Staðfesta forskoðun
        </b-btn>
        <b-btn variant="secondary" class="pull-right mr-3" @click.prevent="previewBatchModal">
          <i class="fa fa-fw fa-plus-circle"></i>
          Stofna uppgjör
        </b-btn>
      </b-col>
    </b-row>
    <div v-if="!batchList || batchList.length === 0">
      <i>Ekkert uppgjör fannst. Vinsamlegast keyrið uppgjör hérna hægra megin.</i>
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-select id="batch" name="batch" v-model="options.batch.selectedBatch" @input="loadRecords">
            <option v-for="batch in batchList" :key="batch.id" :value="batch">
              {{ batch.payment_period.name }}
              ({{ batch.payment_period.date_from | moment('DD.MM.YYYY') }} - {{ batch.payment_period.date_to | moment('DD.MM.YYYY') }})
              <template v-if="batch.is_extra"> - Auka uppgjör {{ batch.extra_number }}</template>
            </option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <h5 class="page-title" style="font-size: 20px">
            <span v-if="options.batch.selectedBatch && options.batch.selectedBatch.status === 'NEW'">Forskoðun</span>
            <span v-if="options.batch.selectedBatch && options.batch.selectedBatch.status === 'POPULATED'">
              Staðfestar færslur
              <i class="fa fa-fw fa-file-excel-o" @click.prevent="getExcel" style="cursor: pointer; color: #008000"></i>
              <b-btn @click.prevent="getH3" class="pull-right btn-sm btn-dark" style="margin-left: 10px">
                <i class="fa fa-fw fa-file-o"></i>
                Sækja skrá fyrir launakerfi
              </b-btn>
              <b-btn
                @click.prevent="submitToKjarni"
                class="pull-right btn-sm btn-dark"
                style="margin-left: 10px"
                v-if="loggedInUser.town.hasKjarni"
                :disabled="submittingToKjarni || options.batch.selectedBatch.date_sent_to_kjarni || submittedToKjarni"
              >
                <i v-if="!submittingToKjarni" class="fa fa-fw fa-paper-plane mr-1"></i>
                <i v-if="submittingToKjarni" class="fa fa-fw fa-spinner fa-spin mr-1" />
                <template v-if="!submittedToKjarni && !options.batch.selectedBatch.date_sent_to_kjarni">Lesa inn í Kjarna</template>
                <template v-if="submittedToKjarni || options.batch.selectedBatch.date_sent_to_kjarni">Sent í Kjarna</template>
                <template v-if="!submittedToKjarni && options.batch.selectedBatch.date_sent_to_kjarni">
                  {{ options.batch.selectedBatch.date_sent_to_kjarni | moment('DD.MM.YYYY hh:mm:ss') }}</template
                >
              </b-btn>
            </span>
          </h5>
          <div class="alert alert-danger" v-if="errorList.length > 0">
            <div style="display: inline-block">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div>
            <div style="display: inline-block">
              <div v-for="(err, idx) in errorList" :key="idx">{{ err }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2" v-if="errorList.length === 0 && this.options.batch.selectedBatch">
        <b-col cols="3">
          <strong>Nafn</strong>
          <b-form-input id="name" name="name" type="text" v-model="filter.name" placeholder="Sía eftir nafni"></b-form-input>
        </b-col>
        <b-col cols="3">
          <strong>Kennitala</strong>
          <b-form-input id="ssn" name="name" type="text" v-model="filter.ssn" placeholder="Sía eftir kennitölu"></b-form-input>
        </b-col>
        <b-col cols="3" v-if="periodPartList.length > 0">
          <strong>Starfstímabil</strong>
          <b-form-select id="part" name="part" v-model="filter.part">
            <option :value="null">-- Sía eftir starfstímabili --</option>
            <option v-for="part in periodPartList" :key="part.id" :value="part.id">
              {{ part.date_from | moment('DD.MM.YYYY') }} - {{ part.date_to | moment('DD.MM.YYYY') }}
            </option>
          </b-form-select>
        </b-col>
        <b-col cols="3" v-if="workplaceList.length > 0">
          <strong>Starfsstaður</strong>
          <b-form-select id="workplace" name="workplace" v-model="filter.workplace">
            <option :value="null">-- Sía eftir starfsstað --</option>
            <option v-for="workplace in sortWorkplaceList" :key="workplace.id" :value="workplace.id">{{ workplace.name }}</option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-card no-body border-variant="light" class="mb-2">
        <div
          v-if="
            filteredRecordList.length === 0 &&
            errorList.length === 0 &&
            this.options.batch.selectedBatch &&
            !loading.batches &&
            !loading.records
          "
        >
          <i>Engar færslur fundust</i>
        </div>
        <div class="m-2" v-if="loading.batches || loading.records"><loading /></div>
        <table class="table mb-0" v-if="filteredRecordList.length > 0">
          <thead>
            <tr class="bg-gray-200 small">
              <th @click.prevent="changeSort('name')" style="cursor: pointer">Nafn</th>
              <th @click.prevent="changeSort('ssn')" style="cursor: pointer">Kennitala</th>
              <th @click.prevent="changeSort('placement_group.period.name')" style="cursor: pointer">Tímabil</th>
              <th @click.prevent="changeSort('placement_group.workplace.name')" style="cursor: pointer">Hópur</th>
              <th @click.prevent="changeSort('birth_year')" style="cursor: pointer">Fæðingarár</th>
              <th @click.prevent="changeSort('days')" style="cursor: pointer" class="text-center">
                Dagar/Tímabils
                <i
                  class="fa fa-fw fa-info-circle"
                  v-b-tooltip.hover
                  title="Fjöldi daga sem þátttakandi er að fá borgaða / Fjöldi daga innan tímabils miðað við skráningu hóps"
                ></i>
              </th>
              <th class="text-center" @click.prevent="changeSort('hours')" style="cursor: pointer">
                Klst/Hámark
                <i
                  class="fa fa-fw fa-info-circle"
                  v-b-tooltip.hover
                  title="Fjöldi klst sem þátttakandi er að fá borgaða / Hámarksfjöldi klukkutíma sem hægt er að skrá"
                ></i>
              </th>
              <th class="text-right" @click.prevent="changeSort('wages_corrections')" style="cursor: pointer">
                Leiðrétting
                <i
                  class="fa fa-fw fa-info-circle"
                  v-b-tooltip.hover
                  title="Fjöldi klst sem leiðrétting nær yfir / Upphæð sem leiðrétting nær yfir"
                ></i>
              </th>
              <th class="text-right" @click.prevent="changeSort('wage_age')" style="cursor: pointer">Tímakaup</th>
              <th class="text-right" @click.prevent="changeSort('wages')" style="cursor: pointer">Heildarlaun</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, index) in filteredRecordList" :key="index">
              <td>
                {{ record.name }}
                <span v-if="options.batch.selectedBatch && options.batch.selectedBatch.status === 'POPULATED'">
                  <b-link
                    :to="{
                      name: 'StudentWithGroup',
                      params: { id: record.person_id, group: record.placement_group_id },
                    }"
                    target="_blank"
                  >
                    <i class="fa fa-fw fa-search" v-b-tooltip.hover title="Skoða þátttakanda"></i>
                  </b-link>
                </span>
                <span v-else>
                  <b-link
                    :to="{
                      name: 'StudentWithGroup',
                      params: { id: record.id, group: record.placement_group.id },
                    }"
                    target="_blank"
                  >
                    <i class="fa fa-fw fa-search" v-b-tooltip.hover title="Skoða þátttakanda"></i>
                  </b-link>
                </span>
              </td>
              <td>{{ record.ssn }}</td>
              <td>
                <span v-if="options.batch.selectedBatch && options.batch.selectedBatch.status === 'POPULATED'">
                  {{ record.period_name }}
                  <div v-if="record.period_part_date_from" style="display: block; font-style: italic; font-size: 10px">
                    {{ record.period_part_date_from | moment('DD.MM.YYYY') }} - {{ record.period_part_date_to | moment('DD.MM.YYYY') }}
                  </div>
                </span>
                <span v-else>
                  {{ record.placement_group.period.name }}
                  <div v-if="record.placement_group.period_part" style="display: block; font-style: italic; font-size: 10px">
                    {{ record.placement_group.period_part.date_from | moment('DD.MM.YYYY') }}
                    - {{ record.placement_group.period_part.date_to | moment('DD.MM.YYYY') }}
                  </div>
                </span>
              </td>
              <td>
                <span v-if="options.batch.selectedBatch && options.batch.selectedBatch.status === 'POPULATED'">
                  {{ record.workplace_name }} - {{ record.placement_group_name }}
                  <b-link
                    :to="{
                      name: 'Group',
                      params: { id: record.placement_group_id },
                    }"
                    target="_blank"
                  >
                    <i class="fa fa-fw fa-search" v-b-tooltip.hover title="Skoða hóp"></i>
                  </b-link>
                  <br />
                  <small style="display: block; font-style: italic; font-size: 10px"
                    >{{ record.placement_group_date_from | moment('DD.MM.YYYY') }} -
                    {{ record.placement_group_date_to | moment('DD.MM.YYYY') }}</small
                  >
                </span>
                <span v-else>
                  {{ record.placement_group.workplace.name }} - {{ record.placement_group.name }}
                  <b-link
                    :to="{
                      name: 'Group',
                      params: { id: record.placement_group.id },
                    }"
                    target="_blank"
                  >
                    <i class="fa fa-fw fa-search" v-b-tooltip.hover title="Skoða hóp"></i>
                  </b-link>
                  <br />
                  <small style="display: block; font-style: italic; font-size: 10px"
                    >{{ record.placement_group.date_from | moment('DD.MM.YYYY') }} -
                    {{ record.placement_group.date_to | moment('DD.MM.YYYY') }}</small
                  >
                </span>
              </td>
              <td>
                {{ record.birth_year }}
                <strong v-if="record.birth_year_overridden">
                  ({{ record.birth_year_real }})
                  <i
                    class="fa fa-fw fa-info-circle"
                    v-b-tooltip.hover
                    :title="`Þessi þátttakandi er með undanþágu og á að vera tekið sem ${record.birth_year}`"
                  ></i>
                </strong>
              </td>
              <td class="text-center">{{ record.days }}/{{ record.days_in_period }}</td>
              <td class="text-center">
                {{ record.hours | formatMaxHours
                }}<span v-if="record.max_hours_in_period">/{{ record.max_hours_in_period | formatMaxHours }}</span>

                <b-link
                  :to="{
                    name: 'AttendanceWithParams',
                    params: {
                      date: record.placement_group ? record.placement_group.date_from : record.placement_group_date_from,
                      group: record.placement_group ? record.placement_group.id : record.placement_group_id,
                    },
                  }"
                  target="_blank"
                >
                  <i class="fa fa-fw fa-search" v-b-tooltip.hover title="Skoða viðveru"></i>
                </b-link>
                <br />
              </td>
              <td class="text-right">
                <span>{{ record.hours_corrections }} / {{ record.wages_corrections | currency }}</span>
              </td>
              <td class="text-right">{{ record.wage_age | currency }}</td>
              <td class="text-right">{{ record.wages | currency }}</td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </div>
    <preview-batch-modal ref="previewBatchModal" @successful="batchCreated"></preview-batch-modal>
    <populate-batch-modal ref="populateBatchModal" @successful="batchPopulated"></populate-batch-modal>
  </vinnuskoli-page>
</template>

<script>
// import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import payment from '@/api/payment';
import { djangoErrorsToVee } from '@/utils';
import PreviewBatchModal from '@/components/batch/previewBatchModal.vue';
import PopulateBatchModal from '@/components/batch/populateBatchModal.vue';

export default {
  name: 'batch',
  components: {
    PreviewBatchModal,
    PopulateBatchModal,
  },
  data() {
    return {
      loading: {
        batches: false,
        records: false,
      },
      sort: 'name',
      sortdesc: false,
      errorList: [],
      batchList: [],
      batch: null,
      recordList: [],
      options: {},
      filter: {
        part: null,
        workplace: null,
        ssn: '',
        name: '',
      },
      periodPartList: [],
      workplaceList: [],
      submittingToKjarni: false,
      submittedToKjarni: false,
    };
  },
  computed: {
    filteredRecordList() {
      if (this.options.batch.selectedBatch && this.options.batch.selectedBatch.status === 'NEW') {
        return this.recordList
          .filter(x => this.filter.workplace === null || x.placement_group.workplace.id === this.filter.workplace)
          .filter(y => this.filter.part === null || y.placement_group.period_part.id === this.filter.part)
          .filter(z => z.ssn.startsWith(this.filter.ssn))
          .filter(w => w.name.toUpperCase().startsWith(this.filter.name.toUpperCase()));
      }
      return this.recordList
        .filter(x => this.filter.workplace === null || x.workplace_id === this.filter.workplace)
        .filter(y => this.filter.part === null || y.period_part_id === this.filter.part)
        .filter(z => z.ssn.startsWith(this.filter.ssn))
        .filter(w => w.name.toUpperCase().startsWith(this.filter.name.toUpperCase()));
    },
    sortWorkplaceList() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.workplaceList.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    ...mapGetters(['loggedInUserOptions']),
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async load(id) {
      this.loading.batches = true;
      this.batchList = [];
      try {
        const response = await payment.paymentBatchList();
        this.batchList = response.data;
        if (id) {
          this.options.batch.selectedBatch = this.batchList.find(x => x.id === id);
          this.loadRecords();
        } else if (this.options.batch.selectedBatch) {
          this.loadRecords();
        }
      } catch (e) {
        this.$log.error(e);
        if (e.response && e.response.status === 400) {
          djangoErrorsToVee(e.response.data, this.errors, this.errorList);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        // this.loading.type = false;
        this.loading.batches = false;
      }
    },
    async loadRecords() {
      this.recordList = [];
      this.errorList = [];
      this.periodPartList = [];
      this.workplaceList = [];
      this.filter.part = null;
      this.filter.workplace = null;
      this.loading.records = true;
      try {
        let response = null;
        if (this.options.batch.selectedBatch && this.options.batch.selectedBatch.status === 'NEW') {
          response = await payment.paymentBatchPreview(this.options.batch.selectedBatch.id, this.sort, this.sortdesc);
          this.recordList = response.data;
          // Finna vinnustaði og tímabil til að sía
          this.recordList.forEach(r => {
            // Vinnustaður
            const tmpWorkplace = {
              id: r.placement_group.workplace.id,
              name: r.placement_group.workplace.name,
            };
            if (!this.workplaceList.find(x => x.id === r.placement_group.workplace.id)) this.workplaceList.push(tmpWorkplace);
            // Tímabil
            if (r.placement_group.period_part) {
              const tmpPart = {
                id: r.placement_group.period_part.id,
                date_from: r.placement_group.period_part.date_from,
                date_to: r.placement_group.period_part.date_to,
              };
              if (!this.periodPartList.find(x => x.id === r.placement_group.period_part.id)) this.periodPartList.push(tmpPart);
            }
          });
        } else {
          response = await payment.paymentBatchLineList(null, this.options.batch.selectedBatch.id, null, null, this.sort, this.sortdesc);
          this.recordList = response.data;
          // Finna vinnustaði og tímabil til að sía
          this.recordList.forEach(r => {
            // Vinnustaður
            const tmpWorkplace = {
              id: r.workplace_id,
              name: r.workplace_name,
            };
            if (!this.workplaceList.find(x => x.id === r.workplace_id)) this.workplaceList.push(tmpWorkplace);
            // Tímabil
            if (r.period_part_id) {
              const tmpPart = {
                id: r.period_part_id,
                date_from: r.period_part_date_from,
                date_to: r.period_part_date_to,
              };
              if (!this.periodPartList.find(x => x.id === r.period_part_id)) this.periodPartList.push(tmpPart);
            }
          });
        }
        await this.setLoggedInUserOption({ option: 'batch', value: this.options.batch });
        await this.saveLoggedInUserOptions();
      } catch (e) {
        this.$log.error(e);
        if (e.response && e.response.status === 400) {
          djangoErrorsToVee(e.response.data, this.errors, this.errorList);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.loading.records = false;
      }
    },
    batchCreated(data) {
      if (data.id) {
        this.load(data.id);
      }
    },
    batchPopulated() {
      this.load(this.options.batch.selectedBatch.id);
    },
    changeSort(column) {
      if (this.sort === column) {
        this.sortdesc = !this.sortdesc;
      } else {
        this.sort = column;
        this.sortdesc = false;
      }
      this.loadRecords();
    },
    async getExcel() {
      const w = window.open('', '_blank');

      const response = await payment.paymentBatchExcelToken(this.options.batch.selectedBatch.id);
      w.location.href = response.data.url;
    },
    async getH3() {
      const w = window.open('', '_blank');

      const response = await payment.paymentBatchH3Token(this.options.batch.selectedBatch.id);
      w.location.href = response.data.url;
    },
    async submitToKjarni() {
      this.submittingToKjarni = true;
      this.submittedToKjarni = false;

      try {
        await this.$paymentApi.submitToKjarni({ batch: this.options.batch.selectedBatch.id });

        this.displaySuccess(`Innlestri lokið.`);
        this.submittedToKjarni = true;
      } catch (e) {
        if ('response' in e) {
          this.displayError(JSON.parse(e.response.data));
        } else {
          this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.submittingToKjarni = false;
      }
    },
    /*
     * Modal gluggar
     */
    previewBatchModal() {
      this.$refs.previewBatchModal.show();
    },
    populateBatchModal() {
      this.$refs.populateBatchModal.show(this.options.batch.selectedBatch.id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
      saveLoggedInUserOptions: 'saveLoggedInUserOptions',
      setLoggedInUserOption: 'setLoggedInUserOption',
    }),
  },
  created() {
    this.load(this.$route.query.id);
    this.options = {};

    this.options.batch = {
      selectedBatch: null,
      ...this.loggedInUserOptions.batch,
    };
  },
};
</script>

<style></style>
