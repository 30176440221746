<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Skrá umsókn</div>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-link class="btn btn-secondary" :to="{ name: 'ApplicationList' }"> Hætta við </b-link>
      </b-col>
    </b-row>

    <b-card id="topOfForm">
      <div slot="header">
        <h4>Þátttakandi</h4>
      </div>
      <b-form @submit.prevent="validateBeforeSubmit" novalidate>
        <div class="alert alert-danger" v-if="errorList.length > 0">
          <div v-for="(err, idx) in filteredErrorList" :key="idx">{{ err }}</div>
        </div>
        <div class="mt-2 mb-2" v-if="existingApplication">
          <b-row>
            <b-col>
              <b-link :to="{ name: 'Application', params: { id: existingApplication } }" class="btn btn-primary" target="_blank">
                <i class="fa fa-search"></i>
                Smelltu hér til að skoða umsóknina
              </b-link>
            </b-col>
            <b-col>
              <b-btn variant="danger" class="pull-right" style="display: block" @click.prevent="forceSave">
                <i class="fa fa-exclamation-triangle"></i>
                Smelltu hér til að vista þrátt fyrir að til sé önnur umsókn
              </b-btn>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group
              label="Kennitala*:"
              label-for="ssn"
              :state="submitted && errors.has('ssn') ? false : ''"
              :invalid-feedback="errors.first('ssn')"
              description="Kennitala þátttakanda"
            >
              <b-form-input
                id="ssn"
                name="ssn"
                autofocus
                type="text"
                v-model="form.ssn"
                :state="submitted && errors.has('ssn') ? false : ''"
                v-validate="'required|length:10'"
                data-vv-as="kennitala"
                maxlength="10"
                @input="checkSSN()"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Nafn*:"
              label-for="name"
              :state="submitted && errors.has('name') ? false : ''"
              :invalid-feedback="errors.first('name')"
              description="Nafn þátttakanda"
            >
              <b-form-input
                id="name"
                name="name"
                autofocus
                type="text"
                v-model="form.name"
                :state="submitted && errors.has('name') ? false : ''"
                v-validate="'required'"
                disabled
                data-vv-as="nafn"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="ssnNotFound">
          <b-col>
            <strong class="text-danger"><i>Kennitala fannst ekki</i></strong>
          </b-col>
        </b-row>
        <div v-if="valid.ssn">
          <b-row>
            <b-col sm="12" md="4">
              <b-form-group
                label="Netfang*:"
                label-for="email"
                :state="submitted && errors.has('email') ? false : ''"
                :invalid-feedback="errors.first('email')"
                description="Netfang þátttakanda"
              >
                <b-form-input
                  id="email"
                  name="email"
                  type="email"
                  v-model="form.email"
                  :state="submitted && errors.has('email') ? false : ''"
                  v-validate="'required'"
                  data-vv-as="netfang"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="4">
              <b-form-group
                label="Farsími*:"
                label-for="mobile"
                :state="submitted && errors.has('mobile') ? false : ''"
                :invalid-feedback="errors.first('mobile')"
                description="Farsímanúmer þátttakanda"
              >
                <b-form-input
                  id="mobile"
                  name="mobile"
                  type="text"
                  v-model="form.mobile"
                  :state="submitted && errors.has('mobile') ? false : ''"
                  v-validate="'required'"
                  data-vv-as="farsími"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="4">
              <b-form-group
                label="Bankareikningur*:"
                label-for="account"
                :state="submitted && errors.has('account') ? false : ''"
                :invalid-feedback="errors.first('account')"
                description="Bankareikningur þátttakanda"
              >
                <masked-input
                  type="text"
                  id="account"
                  name="account"
                  class="form-control"
                  ref="bankFormInput"
                  :value="form.account"
                  @input="bankInput"
                  @blur="normalizeBank"
                  @paste.prevent="pasteBank"
                  :mask="bankMask"
                  :guide="false"
                  :showMask="true"
                  :class="{ 'is-invalid': submitted && errors.has('account') }"
                  v-validate="'required'"
                  data-vv-as="bankareikningur"
                ></masked-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col>
              <b-form-group
                label="Sérþarfir"
                label-for="specialNeeds"
                :state="submitted && errors.has('specialNeeds') ? false : ''"
                :invalid-feedback="errors.first('specialNeeds')"
                description="Sérþarfir þátttakanda ef á við"
              >
                <b-form-textarea id="specialNeeds" name="specialNeeds" type="text" v-model="form.specialNeeds"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col>
              <b-form-group
                label="Annað"
                label-for="other"
                :state="submitted && errors.has('other') ? false : ''"
                :invalid-feedback="errors.first('other')"
                description="Aðrar upplýsingar ef á við"
              >
                <b-form-textarea id="other" name="other" type="text" v-model="form.other"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <h4>Skráning</h4>
          <b-row>
            <b-col sm="12" md="6">
              <b-form-group
                label="Tímabil*:"
                label-for="period"
                :state="submitted && errors.has('period') ? false : ''"
                :invalid-feedback="errors.first('period')"
                description="Tímabil sem sótt er um"
              >
                <b-form-select
                  id="period"
                  name="period"
                  v-model="form.period"
                  :state="submitted && errors.has('period') ? false : ''"
                  data-vv-as="tímabil"
                  v-validate="'required'"
                >
                  <option :value="null" disabled>-- Veldu tímabil --</option>
                  <option v-for="period in periodList" :key="period.id" :value="period">{{ period.name }}</option>
                </b-form-select>
              </b-form-group>
              <div
                v-if="form.town && periodList.length === 0 && !loading.periodList"
                class="text-danger"
                style="margin-top: -1rem; margin-bottom: 0.5rem"
              >
                Engin tímabil opin fyrir umsóknir
              </div>
            </b-col>
            <b-col v-if="form.period && form.period.has_parts">
              <b-form-group
                label="Starfstímabil*:"
                label-for="parts"
                :state="submitted && errors.has('parts') ? false : ''"
                :invalid-feedback="errors.first('parts')"
                description="Starfstímabil sem sótt er um"
              >
                <div style="font-size: 90%">
                  <i>Vinsamlegast veljið að hámarki {{ form.period.num_parts_application }}</i>
                </div>
                <div v-for="(part, index) in getPeriodParts" :key="part.id">
                  <b-form-checkbox :id="`parts${index}`" :name="`parts${index}`" type="text" v-model="part.selected">
                    {{ part.date_from | moment('DD.MM.YYYY') }} - {{ part.date_to | moment('DD.MM.YYYY') }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
              <div class="text-danger" style="margin-top: -1rem; margin-bottom: 0.5rem">
                <div v-if="!valid.periodOne">Ath. velja verður amk 1</div>
                <div v-if="!valid.periodMany">Ath. velja má að hámarki {{ form.period.num_parts_application }}</div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col sm="12" md="12">
              <b-form-group
                label="Starfsstaður*:"
                label-for="workplace"
                :state="submitted && errors.has('workplace') ? false : ''"
                :invalid-feedback="errors.first('workplace')"
                description="Starfsstaður sem sótt er um"
              >
                <div v-for="(workplace, index) in workplaceList" :key="workplace.id">
                  <b-form-checkbox
                    :id="`workplace${index}`"
                    :name="`workplace${index}`"
                    type="text"
                    v-model="workplace.selected"
                    class="d-inline-block"
                    @change="() => normalizeWorkplaceList(workplace.id)"
                    >{{ workplace.name }}</b-form-checkbox
                  >
                  <template v-if="workplace.selected && loggedInUser.town.prioritizeWorkplacesApplication">
                    <font-awesome-icon
                      v-if="index < workplaceListSelected.length - 1"
                      class="text-muted cursor-pointer"
                      icon="caret-down"
                      fixed-width
                      @click="() => moveWorkplaceDown(index)"
                    />
                    <font-awesome-icon
                      v-if="index > 0"
                      class="text-muted cursor-pointer"
                      icon="caret-up"
                      fixed-width
                      @click="() => moveWorkplaceUp(index)"
                    />
                  </template>
                </div>
              </b-form-group>
              <div class="text-danger" style="margin-top: -1rem; margin-bottom: 0.5rem">
                <div v-if="!valid.workplaceOne">Ath. velja verður amk 1</div>
              </div>
              <div
                v-if="form.workplace && workplaceList.length === 0 && !loading.workplace"
                class="text-danger"
                style="margin-top: -1rem; margin-bottom: 0.5rem"
              >
                Engir starfsstaðir fundust
              </div>
            </b-col>
          </b-row>
          <h4>Aðstandendur</h4>
          <div v-for="(contact, index) in form.contacts" :key="index">
            <b-row>
              <b-col sm="12" md="6">
                <b-form-group>
                  <label
                    :label-for="`ssn${index}`"
                    :state="submitted && errors.has(`ssn${index}`) ? false : ''"
                    :invalid-feedback="errors.first(`ssn${index}`)"
                    description="Kennitala aðstandanda"
                  >
                    Kennitala*
                    <b-link @click.prevent="removeContact(index)" v-if="form.contacts.length > 1">
                      <i class="fa fa-trash"></i>
                    </b-link>
                  </label>
                  <b-form-input
                    :id="`ssn${index}`"
                    :name="`ssn${index}`"
                    type="text"
                    v-model="contact.ssn"
                    :state="submitted && errors.has(`ssn${index}`) ? false : ''"
                    v-validate="'required|length:10'"
                    maxlength="10"
                    data-vv-as="kennitala"
                    @input="checkSSN(contact)"
                  ></b-form-input>
                  <small class="form-text text-muted">Kennitala aðstandanda</small>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  label="Nafn*:"
                  :label-for="`name${index}`"
                  :state="submitted && errors.has(`name${index}`) ? false : ''"
                  :invalid-feedback="errors.first(`name${index}`)"
                  description="Nafn aðstandanda"
                >
                  <b-form-input
                    :id="`name${index}`"
                    :name="`name${index}`"
                    type="text"
                    v-model="contact.name"
                    data-vv-as="nafn"
                    :state="submitted && errors.has(`name${index}`) ? false : ''"
                    v-validate="'required'"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="6">
                <b-form-group
                  label="Netfang*:"
                  :label-for="`email${index}`"
                  :state="submitted && errors.has(`email${index}`) ? false : ''"
                  :invalid-feedback="errors.first(`email${index}`)"
                  description="Netfang aðstandanda"
                >
                  <b-form-input
                    :id="`email${index}`"
                    :name="`email${index}`"
                    type="email"
                    v-model="contact.email"
                    :state="submitted && errors.has(`email${index}`) ? false : ''"
                    v-validate="'required'"
                    data-vv-as="netfang"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  label="Farsími*:"
                  :label-for="`mobile${index}`"
                  :state="submitted && errors.has(`mobile${index}`) ? false : ''"
                  :invalid-feedback="errors.first(`mobile${index}`)"
                  description="Farsímanúmer aðstandanda"
                >
                  <b-form-input
                    :id="`mobile${index}`"
                    :name="`mobile${index}`"
                    type="text"
                    v-model="contact.tel"
                    :state="submitted && errors.has(`mobile${index}`) ? false : ''"
                    v-validate="'required'"
                    data-vv-as="farsími"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col>
              <b-btn type="button" class="float-right" @click.prevent="addContact" v-if="form.contacts.length <= 3">
                <i class="fa fa-plus-circle"></i>
                Bæta við aðstandanda
              </b-btn>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col>
              <b-button type="submit" variant="primary">
                <i class="fa fa-save"></i>
                Senda inn umsókn
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </b-card>
  </vinnuskoli-page>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import { mapState, mapActions } from 'vuex';
import auth from '@/api/auth';
import appl from '@/api/application';
import placement from '@/api/placement';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'CreateApplication',
  components: {
    MaskedInput,
  },
  computed: {
    getPeriodParts() {
      if (this.form.period && this.form.period.parts) {
        if (this.form.period.age_exclusion_parts) {
          return this.form.period.parts.filter(x => x.age_exclusion.indexOf(this.form.birthyear) > -1);
        }
        return this.form.period.parts;
      }
      return '';
    },
    filteredErrorList() {
      return this.errorList.map(x => (this.existingApplication ? x.replace(`#${this.existingApplication}#`, '') : x));
    },
    workplaceListSelected() {
      return this.workplaceList.filter(w => w.selected);
    },
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      existingApplication: null,
      townList: [],
      periodList: [],
      workplaceList: [],
      submitted: false,
      errorList: [],
      loading: {
        info: false,
        workplace: false,
      },
      valid: {
        ssn: false,
        periodOne: true,
        periodMany: true,
        workplaceOne: true,
        workplaceMany: true,
      },
      ssnNotFound: false,
      form: {
        ssn: '',
        birthyear: '',
        age: '',
        name: '',
        tmpPeriod: null,
        tmpPeriodParts: null,
        workplace: null,
        tmpWorkplaces: null,
        period: null,
        mobile: '',
        email: '',
        account: '',
        // specialNeeds: '',
        other: '',
        force: false,
        contacts: [{ name: '', ssn: '', email: '', tel: '' }],
      },
    };
  },
  methods: {
    async loadAge() {
      if (this.form.ssn) {
        try {
          this.loading.year = true;
          const response = await placement.placementExemptionCheck(this.form.ssn);
          this.$set(this.form, 'age', response.data.age_year);
          this.$set(this.form, 'birthyear', response.data.year);
        } catch (e) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.year = false;
        }
      }
    },
    async loadPeriod() {
      this.periodList = [];
      try {
        this.loading.periodList = true;
        const response = await appl.periodList(true);
        this.periodList = response.data;
        if (this.form.tmpPeriod) {
          this.form.period = this.periodList.find(p => p.id === this.form.tmpPeriod);
          if (this.form.tmpPeriodParts) {
            this.form.period.parts.forEach(p => {
              this.$set(p, 'selected', this.form.tmpPeriodParts.find(x => x.period_part.id === p.id) !== undefined);
            });
          }
        } else if (this.periodList.length === 1) {
          [this.form.period] = this.periodList;
        }
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.periodList = false;
      }
    },
    async loadWorkplaces() {
      this.workplaceList = [];
      try {
        this.loading.workplace = true;
        const response = await appl.workplaceList();
        this.workplaceList = response.data;
        if (this.form.tmpWorkplaces) {
          this.workplaceList.forEach(p => {
            const priorityIndex = this.form.tmpWorkplaces.findIndex(x => x === p.id);
            this.$set(p, 'selected', this.form.tmpWorkplaces.find(x => x.id === p.id) !== undefined);
            this.$set(p, 'priorityIndex', priorityIndex === -1 ? null : priorityIndex);
          });
          this.normalizeWorkplaceList();
        }
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.workplace = false;
      }
    },
    loadTownData() {
      this.loadAge();
      this.loadWorkplaces();
      this.loadPeriod();
    },
    async checkSSN(contact) {
      if (contact) {
        this.$set(contact, 'name', '');
        this.errorList = [];
        if (contact.ssn && contact.ssn.length === 10) {
          try {
            const response = await auth.nameCheck(contact.ssn);
            this.$set(contact, 'name', response.data.name);
            this.$set(contact, 'ssnNotFound', false);
          } catch (e) {
            if (e.response && e.response.status === 400) {
              this.$set(contact, 'ssnNotFound', true);
              djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            } else {
              this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
          }
        }
      } else {
        this.form.name = '';
        this.errorList = [];
        this.valid.ssn = false;
        this.ssnNotFound = false;
        if (this.form.ssn && this.form.ssn.length === 10) {
          try {
            const response = await auth.nameCheck(this.form.ssn);
            this.form.name = response.data.name;
            this.form.age = response.data.age_year;
            this.form.birthyear = response.data.birth_year;
            this.valid.ssn = true;
            this.loadTownData();
          } catch (e) {
            if (e.response && e.response.status === 400) {
              this.ssnNotFound = true;
              djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            } else {
              this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
          }
        }
      }
    },
    addContact() {
      this.form.contacts.push({ name: '', ssn: '', email: '', mobile: '' });
    },
    removeContact(index) {
      this.form.contacts.splice(index, 1);
    },
    validatePeriods() {
      let invalid = false;
      this.$set(this.valid, 'periodOne', true);
      this.$set(this.valid, 'periodMany', true);
      if (this.form.period.has_parts) {
        const count = this.form.period.parts.filter(p => p.selected).length;
        if (count === 0) {
          invalid = true;
          this.$set(this.valid, 'periodOne', false);
        }
        if (!this.form.period.age_exclusion_parts && count > this.form.period.num_parts_application) {
          invalid = true;
          this.$set(this.valid, 'periodMany', false);
        }
      }
      return invalid;
    },
    validateWorkplaces() {
      let invalid = false;
      this.$set(this.valid, 'workplaceOne', true);
      this.$set(this.valid, 'workplaceMany', true);
      const count = this.workplaceList.filter(p => p.selected).length;
      if (count === 0) {
        invalid = true;
        this.$set(this.valid, 'workplaceOne', false);
      }
      return invalid;
    },
    bankInput(input) {
      let parsedInput = input;
      if (parsedInput) {
        const split = input.split('-');

        if (split.length === 2 && !split[1]) {
          const book = split[0];
          parsedInput = `${book.padStart(4, '0')}-`;
        }
      }

      this.$set(this.form, 'account', parsedInput);
    },
    normalizeBank() {
      if (this.form.account) {
        const split = this.form.account.split('-');

        if (split.length === 3) {
          const [book, bank, account] = split;

          this.$set(this.form, 'account', `${book.padStart(4, '0')}-${bank.padStart(2, '0')}-${account.padStart(6, '0')}`);
        }
      }
    },
    pasteBank(evt) {
      this.$set(this.form, 'account', evt.clipboardData.getData('text'));
      this.normalizeBank();
    },
    bankMask(input) {
      if (input) {
        const split = input.split('-');

        if ((split.length === 1 && split[0].length < 4) || (split.length === 2 && !split[1])) {
          return [/\d/, /[0-9\-]/, /[0-9\-]/, /[0-9\-]/];
        }
      }

      return [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response || this.validatePeriods() || this.validateWorkplaces()) {
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });

        // Engar villur í formi.
        try {
          // this.loading = true;
          const data = {
            force: this.form.force,
            ssn: this.form.ssn,
            email: this.form.email,
            tel: this.form.mobile,
            account: this.form.account,
            // special_needs: this.form.specialNeeds,
            other: this.form.other,
            period: this.form.period.id,
            period_parts: this.form.period.has_parts ? this.form.period.parts.filter(x => x.selected).map(x => x.id) : [],
            workplace: this.workplaceList.filter(x => x.selected).map(x => x.id),
            contacts: this.form.contacts,
          };
          const response = await appl.applicationCreate(data);
          this.displaySuccess(`Umsókn fyrir „${this.form.name}“ hefur verið stofnuð.`);
          this.$router.push({ name: 'Application', params: { id: response.data.id } });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            this.$scrollTo('#topOfForm');
            this.displayError('Ath! Villa er í forminu. Vinsamlegast lesið það yfir og lagfærið.');
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            if (this.errorList) {
              this.errorList.forEach(el => {
                if (el.replace(/[^#]/g, '').length === 2) {
                  this.existingApplication = el.substring(el.indexOf('#') + 1, el.lastIndexOf('#'));
                }
              });
            }
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        // this.loading = false;
      }
    },
    forceSave() {
      const conf = confirm('Ertu alveg viss um að þú viljir stofna aðra umsókn fyrir sama þátttakanda fyrir sama tímabil?'); //eslint-disable-line
      if (conf) {
        this.form.force = true;
        this.validateBeforeSubmit();
      }
    },
    normalizeWorkplaceList(idChanged) {
      if (idChanged) {
        this.workplaceList.forEach(w => {
          if (w.id === idChanged && w.priorityIndex == null) {
            this.$set(w, 'priorityIndex', this.workplaceListSelected.length);
          } else if (w.id === idChanged) {
            this.$set(w, 'priorityIndex', null);
          }
        });
      }

      // Stack overflow sagði að það væri hægt að raða í Javascript svona þannig að
      // "nulls" kæmu "last" og það virkaði.
      this.workplaceList = this.workplaceList.sort(
        (a, b) =>
          (a.priorityIndex == null) - (b.priorityIndex == null) ||
          +(a.priorityIndex > b.priorityIndex) ||
          -(a.priorityIndex < b.priorityIndex),
      );
    },
    swapWorkplace(idx1, idx2) {
      const list = [...this.workplaceList];
      const w = list[idx1];
      list[idx1] = {
        ...list[idx2],
        priorityIndex: idx1,
      };
      list[idx2] = {
        ...w,
        priorityIndex: idx2,
      };
      this.workplaceList = list;
    },
    moveWorkplaceUp(idx) {
      if (idx > 0) {
        this.swapWorkplace(idx, idx - 1);
      }
    },
    moveWorkplaceDown(idx) {
      if (idx < this.workplaceListSelected.length - 1) {
        this.swapWorkplace(idx, idx + 1);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTownData();
  },
};
</script>

<style lang="scss">
.card-header {
  background-color: #fff;
  border-color: #ddd;
  h4 {
    margin-bottom: 0px;
  }
}
h4 {
  font-weight: 100;
  margin-bottom: 1rem;
}
</style>
