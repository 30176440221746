import Vue from 'vue';

import config from '../config';

export default {
  /**
   * Vefþjónusta fyrir villuskilaboð óháð gagnagrunni.
   *
   */
  status() {
    return Vue.http
      .get(config.STATUS_URL, { skipAuthorization: true })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að athuga með innskráningu.
   */
  ping() {
    return Vue.http
      .get(config.PING_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að velja sveitarfélag á innskráðan notanda.
   */
  selectTown(town) {
    return Vue.http
      .post(config.SELECT_TOWN_URL, {
        town,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja aðgangshópa
   */
  authGroupList() {
    return Vue.http
      .get(config.USER_GROUP_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn aðgangshóp
   *
   * @param {number} id Einkenni aðgangshóps til að sækja.
   */
  authGroupDetail(id) {
    return Vue.http
      .get(config.USER_GROUP_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá aðgangshóp.
   *
   * @param {object} data Gögn um nýjan aðgangshóp.
   */
  authGroupCreate(data) {
    return Vue.http
      .post(config.USER_GROUP_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra aðgangshóp.
   *
   * @param {number} id Einkenni aðgangshóps til að uppfæra.
   * @param {object} data Gögn um breyttan aðgangshóp.
   */
  authGroupUpdate(id, data) {
    return Vue.http
      .put(config.USER_GROUP_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn notanda
   *
   * @param {number} id Einkenni notanda til að sækja.
   */
  userDetail(id) {
    return Vue.http
      .get(config.USER_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá notanda.
   *
   * @param {object} data Gögn um nýjan notanda.
   */
  userCreate(data) {
    return Vue.http
      .post(config.USER_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra notanda.
   *
   * @param {number} id Einkenni notanda til að uppfæra.
   * @param {object} data Gögn um breyttan notanda.
   */
  userUpdate(id, data) {
    return Vue.http
      .put(config.USER_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja notendalista
   */
  userCheck(username) {
    return Vue.http
      .get(config.USER_CHECK_URL, {
        params: {
          username,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja notendalista
   */
  nameCheck(ssn) {
    return Vue.http
      .get(config.NAME_CHECK_URL, {
        params: {
          ssn,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að vista notendastillingar
   */
  saveUserOptions(data) {
    return Vue.http
      .post(config.USER_OPTIONS_URL, {
        options: data ? JSON.stringify(data) : null,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
