<template>
  <div>
    <h5 class="header mt-4">Skráning</h5>
    <b-row>
      <b-col>
        <strong>
          Tímabil
          <b-link @click.prevent="toggle('period')" v-if="!edit.period">
            <i class="fa fa-pencil" v-if="canBeUpdatedAsNew"> </i>
          </b-link>
        </strong>
        <div>
          <div v-if="!edit.period">
            {{ item.period.name }}
          </div>
          <div v-else>
            <div v-for="(period, index) in periodList" :key="period.id" class="p-1">
              <div style="display: inline-block">
                <b-form-checkbox
                  :id="`periods${index}`"
                  :name="`periods${index}`"
                  :value="period.id"
                  type="text"
                  v-model="form.period"
                ></b-form-checkbox>
              </div>
              <div style="display: inline-block; vertical-align: top">
                {{ period.name }} ({{ period.date_from | moment('DD.MM.YYYY') }} - {{ period.date_to | moment('DD.MM.YYYY') }})
              </div>
            </div>
            <button type="button" class="btn btn-sm btn-success" @click.prevent="savePeriod()" :disabled="!this.form.period">
              <i class="fa fa-check"></i>
            </button>
            <button type="button" class="btn btn-sm btn-default" @click.prevent="toggle('period')">
              <i class="fa fa-remove"></i>
            </button>
          </div>
        </div>
      </b-col>
      <b-col>
        <strong>
          Starfstímabil
          <b-link @click.prevent="toggle('periodPart')" v-if="!edit.periodPart">
            <i class="fa fa-pencil" v-if="canBeUpdated"> </i>
          </b-link>
        </strong>
        <div>
          <div v-if="!edit.periodPart">
            <div v-for="part in item.allPeriodParts" :key="part.id" class="d-block">
              {{ part.periodPart.dateFrom | moment('DD.MM.YYYY') }} - {{ part.periodPart.dateTo | moment('DD.MM.YYYY') }}
              <i class="fa fa-check text-success" style="" v-if="part.status == 'ACCEPTED'" />
              <i style="display: block; font-size: 13px">
                {{ getPlacementGroupInfo(part.periodPart.databaseId) }}
              </i>
            </div>
          </div>
          <div v-else>
            <div v-for="(part, index) in periodPartList" :key="part.id">
              <div style="display: inline-block">
                <b-form-checkbox
                  :id="`parts${index}`"
                  :name="`parts${index}`"
                  type="text"
                  v-model="part.selected"
                  :disabled="part.status !== 'NEW'"
                ></b-form-checkbox>
              </div>
              <div style="display: inline-block; vertical-align: top">
                {{ part.dateFrom | moment('DD.MM.YYYY') }} - {{ part.dateTo | moment('DD.MM.YYYY') }}
              </div>
            </div>
            <button type="button" class="btn btn-sm btn-success" @click.prevent="savePeriodPart()"><i class="fa fa-check"></i></button>
            <button type="button" class="btn btn-sm btn-default" @click.prevent="toggle('periodPart')">
              <i class="fa fa-remove"></i>
            </button>
          </div>
        </div>
      </b-col>
      <b-col>
        <strong>Vinnustaður</strong>
        <div>
          <inline-edit
            class="mb-0"
            field="workplace"
            :doEdit="edit.workplace"
            @editField="editWorkplaceField"
            v-if="canBeUpdated && loggedInUserHasAnyPermission('write_application')"
            @saveField="validateBeforeSubmit('workplace')"
          >
            <span slot="content">
              <i>
                <div v-for="(workplace, index) in item2.workplaces" :key="workplace.id">
                  <span v-if="loggedInUser.town.prioritizeWorkplacesApplication">{{ index + 1 }}.</span>
                  {{ workplace.workplace.name }}
                </div>
              </i>
            </span>
            <span slot="input">
              <div v-for="(workplace, index) in workplaceList" :key="workplace.id">
                <b-form-checkbox
                  :id="`workplace${index}`"
                  :name="`workplace${index}`"
                  type="text"
                  v-model="workplace.selected"
                  @change="() => normalizeWorkplaceList(workplace.id)"
                  class="d-inline-block"
                >
                  {{ workplace.name }}
                </b-form-checkbox>
                <template v-if="workplace.selected && loggedInUser.town.prioritizeWorkplacesApplication">
                  <font-awesome-icon
                    v-if="index < workplaceListSelected.length - 1"
                    class="text-muted cursor-pointer"
                    icon="caret-down"
                    fixed-width
                    @click="() => moveWorkplaceDown(index)"
                  />
                  <font-awesome-icon
                    v-if="index > 0"
                    class="text-muted cursor-pointer"
                    icon="caret-up"
                    fixed-width
                    @click="() => moveWorkplaceUp(index)"
                  />
                </template>
              </div>
            </span>
          </inline-edit>
          <span v-else>
            <div v-for="workplace in item2.workplaces" :key="workplace.id">
              {{ workplace.workplace.name }}
            </div>
          </span>
          <span
            v-if="item2.workplacesCount !== item2.workplaces.length"
            class="text-muted small"
            v-b-tooltip.hover
            title="Umsækjandi sótti um fleiri starfsstaði sem innskráður notandi hefur ekki aðgang að."
            >(+{{ item2.workplacesCount - item2.workplaces.length }})</span
          >
        </div>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import core from '@/api/core';
import appl from '@/api/application';
import InlineEdit from '@/components/InlineEdit.vue';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'application-registration',
  props: ['item', 'item2', 'id'],
  components: {
    InlineEdit,
  },
  computed: {
    workplaceListSelected() {
      return this.workplaceList.filter(w => w.selected);
    },
    canBeUpdatedAsNew() {
      return this.item.status === 'NEW' || this.item.status === 'IN_PROGRESS' || this.item.status === 'IN_PROGRESS_APPROVED';
    },
    canBeUpdated() {
      return (
        this.item.status === 'NEW' ||
        this.item.status === 'IN_PROGRESS' ||
        this.item.status === 'IN_PROGRESS_APPROVED' ||
        this.item.status === 'SEMI_ACCEPTED'
      );
    },
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      loading: { workplace: false, periods: false },
      submitted: false,
      periodList: [],
      periodPartList: [],
      workplaceList: [],
      workplaceTypeList: [],
      form: {
        period: null,
        periodPart: null,
        workplace: null,
      },
      edit: {
        period: false,
        periodPart: false,
        workplace: false,
      },
    };
  },
  methods: {
    async loadPeriods() {
      this.loading.periods = true;
      this.periodList = [];
      try {
        const response = await core.dropdownPeriodList();
        this.periodList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.periods = false;
      }
    },
    async loadPeriodParts() {
      this.periodPartList = [];
      const response = await this.$coreApi.periodRetrieve({
        uuid: this.item.period.databaseId,
      });
      this.periodPartList = response.data.parts;
      this.periodPartList.forEach(p => {
        const partSelected = this.item.allPeriodParts.find(x => x.periodPart.databaseId === p.id);
        this.$set(p, 'selected', partSelected !== undefined);
        this.$set(p, 'status', partSelected !== undefined ? partSelected.status : 'NEW');
      });
    },
    async savePeriod() {
      try {
        const data = {
          period: this.form.period,
        };
        this.edit.period = false;
        await appl.applicationUpdatePeriod(this.id, data);
        this.form.period = null;
        this.$emit('reload', {});
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response && e.response.status === 400) {
          djangoErrorsToVee(e.response.data, this.errors, this.errorList);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async savePeriodPart() {
      try {
        const data = {
          period_parts: this.periodPartList.filter(x => x.selected).map(x => x.id),
        };
        this.edit.periodPart = false;
        await appl.applicationUpdatePeriodPart(this.id, data);
        this.edit.periodPart = false;
        this.$emit('reload', {});
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response && e.response.status === 400) {
          djangoErrorsToVee(e.response.data, this.errors, this.errorList);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadWorkplace() {
      this.loading.workplace = true;
      this.workplaceList = [];
      try {
        const response = await core.dropdownWorkplaceList();
        this.workplaceList = response.data;
        if (this.form.workplace) {
          this.workplaceList.forEach(w => {
            const priorityIndex = this.form.workplace.findIndex(x => x === w.id);
            this.$set(w, 'selected', this.form.workplace.find(x => x === w.id) !== undefined);
            this.$set(w, 'priorityIndex', priorityIndex === -1 ? null : priorityIndex);
          });
          this.normalizeWorkplaceList();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.workplace = false;
      }
    },
    async editWorkplaceField({ doEdit }) {
      if (doEdit) {
        this.edit.workplace = true;
        this.form.workplace = this.item2.workplaces.map(x => x.workplace.id);
        this.loadWorkplace();
        /* setTimeout(() => {
          this.$refs.workplaceInput.focus();
        }, 0); */
      } else {
        this.edit.workplace = false;
        this.form.workplace = null;
        this.errorList = [];
      }
    },
    async editWorkplaceTypeField({ doEdit }) {
      if (doEdit) {
        this.form.workplace = this.item2.workplaces.map(x => x.workplace.id);
        this.loadWorkplaceType();
        this.editWorkplaceField({ doEdit: true });
        /* setTimeout(() => {
          this.$refs.workplaceInput.focus();
        }, 0); */
      } else {
        this.editWorkplaceField({ doEdit: false });
        this.errorList = [];
      }
    },
    getPlacementGroupInfo(periodPart) {
      let name = '';
      if (periodPart && this.item.allPlacementGroupPersons.length > 0) {
        const group = this.item.allPlacementGroupPersons.filter(x => x.placementGroup.periodPart.databaseId === periodPart);
        if (group.length === 1) {
          name = `${group[0].placementGroup.workplace.name} ${group[0].placementGroup.name}`;
        }
      }
      return name;
    },
    toggle(type) {
      this.edit[type] = !this.edit[type];
      if (type === 'period' && this.edit.period) {
        this.form.period = this.item2.period.id;
        this.loadPeriods();
      }
      if (type === 'periodPart' && this.edit.periodPart) {
        this.loadPeriodParts();
      }
    },
    async validateBeforeSubmit(type) {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        // Engar villur í formi.
        try {
          const data = {};
          let display = '';
          switch (type) {
            case 'workplace':
              data.workplace = this.workplaceList.filter(x => x.selected).map(x => x.id);
              display = '"Vinnustaður" uppfærður!';
              break;
            default:
              break;
          }
          await appl.applicationPatch(this.id, data);
          this.displaySuccess(display);
          // this.$set(this.item, type, this.form[type]);
          this.$emit('reload', {});
          this.editWorkplaceField({ doEdit: false });
          this.editWorkplaceTypeField({ doEdit: false });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    normalizeWorkplaceList(idChanged) {
      if (idChanged) {
        this.workplaceList.forEach(w => {
          if (w.id === idChanged && w.priorityIndex === null) {
            this.$set(w, 'priorityIndex', this.workplaceListSelected.length);
          } else if (w.id === idChanged) {
            this.$set(w, 'priorityIndex', null);
          }
        });
      }

      // Stack overflow sagði að það væri hægt að raða í Javascript svona þannig að
      // "nulls" kæmu "last" og það virkaði.
      this.workplaceList = this.workplaceList.sort(
        (a, b) =>
          (a.priorityIndex === null) - (b.priorityIndex === null) ||
          +(a.priorityIndex > b.priorityIndex) ||
          -(a.priorityIndex < b.priorityIndex),
      );
    },
    swapWorkplace(idx1, idx2) {
      const list = [...this.workplaceList];
      const w = list[idx1];
      list[idx1] = {
        ...list[idx2],
        priorityIndex: idx1,
      };
      list[idx2] = {
        ...w,
        priorityIndex: idx2,
      };
      this.workplaceList = list;
    },
    moveWorkplaceUp(idx) {
      if (idx > 0) {
        this.swapWorkplace(idx, idx - 1);
      }
    },
    moveWorkplaceDown(idx) {
      if (idx < this.workplaceListSelected.length - 1) {
        this.swapWorkplace(idx, idx + 1);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
