
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import placement from '@/api/placement';
import core from '@/api/core';

@Component({
  components: {
    Datepicker,
  },
})
export default class Diary extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  @Getter loggedInUserHasAnyPermission: any;

  date: Date = moment().toDate();
  group: string | null = null;
  lang = is;
  text: string = '';
  saving = false;

  groups: {
    loading: boolean;
    data: Array<any> | null;
  } = {
    loading: true,
    data: null,
  };

  validate: {
    loading: boolean;
    data: any | null;
  } = {
    loading: true,
    data: null,
  };

  diary: {
    loading: boolean;
    data: any | null;
  } = {
    loading: true,
    data: null,
  };

  changeDate(change: any) {
    this.date = moment(this.date).add(change, 'days').toDate();
    this.loadGroups();
  }

  async loadGroups() {
    this.groups.loading = true;
    this.groups.data = [];
    try {
      const response = await core.dropdownPlacementgroupList([], this.date);

      this.groups.data = response.data;
      if (this.group && this.groups.data && !this.groups.data.find(g => g.id === this.group)) {
        this.group = null;
      } else if (this.group) {
        this.loadDate();
      }
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.groups.loading = false;
    }
  }

  async loadDate() {
    if (this.group) {
      this.validate.loading = true;
      this.validate.data = null;

      try {
        const response = await placement.placementGroupDateValid(this.group, this.date);

        this.validate.data = response.data;
        if (this.validate.data.weekday_valid && !this.validate.data.vacation_day) {
          this.loadData();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.validate.loading = false;
      }
    } else {
      this.diary.data = null;
      this.text = '';
    }
  }

  async loadData() {
    if (this.date && this.group) {
      this.diary.loading = true;
      this.diary.data = null;
      this.text = '';

      try {
        const response = await this.$placementApi.placementGroupDiaryRetrieve({
          date: moment(this.date).format('YYYY-MM-DD'),
          placementGroup: this.group,
        });

        this.diary.data = response.data;
        this.text = response.data.text || '';
      } catch (e) {
        this.diary.data = null;
        this.text = '';
      } finally {
        this.diary.loading = false;
      }
    }
  }

  async saveData() {
    if (this.date && this.group) {
      this.saving = true;

      try {
        await this.$placementApi.placementGroupDiaryCreate({
          placementGroup: this.group,
          placementGroupDiaryDetailRequest: {
            date: moment(this.date).format('YYYY-MM-DD'),
            text: this.text,
          },
        });

        this.loadData();

        this.displaySuccess(`Dagbókarfærsla skráð.`);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    }
  }

  created() {
    if (this.$route.params.date) {
      this.date = moment(this.$route.params.date).toDate();
    }

    if (this.$route.params.group) {
      this.group = this.$route.params.group;
    }

    this.loadGroups();
  }
}
