<template>
  <div>
    <b-modal
      id="exemptionCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Kennitala:"
            label-for="ssn"
            :state="submitted && errors.has('ssn') ? false : ''"
            :invalid-feedback="errors.first('ssn')"
            description="Kennitala einstaklings"
          >
            <b-form-input
              id="ssn"
              name="ssn"
              autofocus
              type="text"
              v-model="form.ssn"
              required
              :state="submitted && errors.has('ssn') ? false : ''"
              v-validate="'required|length:10'"
              maxlength="10"
              :disabled="exemptionId != null"
              data-vv-as="kennitala"
              @input="checkSSN"
            >
              ></b-form-input
            >
          </b-form-group>
          <div v-if="ssnNotFound" class="text-danger" style="margin-top: -1rem; margin-bottom: 0.5rem">Kennitala fannst ekki</div>
          <b-form-group
            label="Nafn:"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Nafn einstaklings"
            v-if="valid.ssn"
          >
            <b-form-input
              id="name"
              name="name"
              type="text"
              v-model="form.name"
              required
              disabled
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="nafn"
            >
              ></b-form-input
            >
          </b-form-group>
          <b-form-group
            label="Fæðingarár:"
            label-for="birth_year"
            :state="submitted && errors.has('birth_year') ? false : ''"
            :invalid-feedback="errors.first('birth_year')"
            description="Raunverulegt fæðingarár einstaklings"
            v-if="valid.ssn"
          >
            <b-form-input
              id="birth_year"
              name="birth_year"
              type="text"
              v-model="form.birth_year"
              required
              disabled
              :state="submitted && errors.has('birth_year') ? false : ''"
              v-validate="'required'"
              data-vv-as="fæðingarár"
            >
              ></b-form-input
            >
          </b-form-group>
          <b-form-group
            label="Fæðingarár í kerfi:"
            label-for="birth_year_override"
            :state="submitted && errors.has('birth_year_override') ? false : ''"
            :invalid-feedback="errors.first('birth_year_override')"
            description="Fæðingarár sem einstaklingur á að teljast sem í kerfinu.
                Hann mun geta sótt um starfsstaði sem þessi aldur og fær borgað miðað við hann."
            v-if="valid.ssn"
          >
            <b-form-input
              id="birth_year_override"
              name="birth_year_override"
              type="text"
              v-model="form.birth_year_override"
              required
              :state="submitted && errors.has('birth_year_override') ? false : ''"
              v-validate="'required'"
              data-vv-as="fæðingarár"
            >
              ></b-form-input
            >
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import placement from '@/api/placement';
import auth from '@/api/auth';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'exemption-create-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      exemptionId: null,
      ssnNotFound: false,
      title: 'Bæta við á undanþágulista',
      valid: {
        ssn: false,
      },
      form: {
        ssn: '',
        name: '',
        birth_year: '',
        birth_year_override: '',
      },
    };
  },
  methods: {
    getBirthYear(ssn) {
      let birthyear = '';
      switch (ssn.substr(9, 1)) {
        case '8':
          birthyear = '18';
          break;
        case '9':
          birthyear = '19';
          break;
        case '0':
          birthyear = '20';
          break;
        case '1':
          birthyear = '21';
          break;
        default:
          birthyear = '20';
      }
      birthyear = `${birthyear}${ssn.substr(4, 2)}`;
      return birthyear;
    },
    async load() {
      try {
        this.loading = true;
        const response = await placement.placementExemptionDetail(this.exemptionId);
        this.valid.ssn = true;
        this.form.ssn = response.data.ssn;
        this.form.name = response.data.name;
        this.form.birth_year = this.getBirthYear(response.data.ssn);
        this.form.birth_year_override = response.data.birth_year_override;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async checkSSN() {
      if (!this.exemptionId) {
        this.form.name = '';
        this.form.birth_year = '';
        this.errorList = [];
        this.valid.ssn = false;
        this.ssnNotFound = false;
        if (this.form.ssn && this.form.ssn.length === 10) {
          try {
            const response = await auth.nameCheck(this.form.ssn);
            this.form.name = response.data.name;
            this.form.birth_year = this.getBirthYear(this.form.ssn);
            this.valid.ssn = true;
          } catch (e) {
            if (e.response && e.response.status === 400) {
              this.ssnNotFound = true;
              djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            } else {
              this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
          }
        }
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          if (this.exemptionId) {
            await placement.placementExemptionUpdate(this.exemptionId, {
              birth_year_override: this.form.birth_year_override,
            });
            this.displaySuccess(`Undanþága fyrir „${this.form.name}“ hefur verið breytt.`);
          } else {
            await placement.placementExemptionCreate({
              ssn: this.form.ssn,
              birth_year_override: this.form.birth_year_override,
            });
            this.displaySuccess(`Undanþága fyrir „${this.form.name}“ hefur verið stofnað.`);
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.loading = false;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(exemptionId) {
      this.form.name = '';
      this.form.ssn = '';
      this.form.birth_year = '';
      this.form.birth_year_override = '';
      this.submitted = false;
      this.loading = false;
      if (exemptionId) {
        this.title = 'Breyta undanþágu';
        this.exemptionId = exemptionId;
        this.load();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
