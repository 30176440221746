<template>
  <vinnuskoli-page>
    <b-row class="mb-3">
      <b-col>
        <div class="page-title mb-0">Tilkynningar</div>
      </b-col>
    </b-row>
    <b-card no-body border-variant="light" class="mb-2">
      <table class="table mb-0">
        <thead>
          <tr class="bg-gray-200 small">
            <th>Tegund</th>
            <th>Texti</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <td colspan="100"><loading /></td>
        </tbody>
        <tbody v-if="templateList.length == 0 && !loading">
          <td colspan="100"><i>Engar leitarniðurstöður fundust.</i></td>
        </tbody>
        <tbody v-if="!loading && templateList.length > 0"></tbody>
        <tbody v-for="template in templateList" :key="template.type">
          <tr>
            <td>{{ template.name }}</td>
            <td style="white-space: pre-wrap">{{ template.text }}</td>
            <td class="text-right">
              <b-btn
                :size="'sm'"
                variant="primary"
                @click.prevent="templateEditModal(template)"
                v-if="loggedInUserHasAnyPermission('write_template')"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <template-edit-modal ref="templateEditModal" @successful="loadList"></template-edit-modal>
  </vinnuskoli-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import core from '@/api/core';
import TemplateEditModal from '@/components/template/TemplateEditModal.vue';

export default {
  name: 'notification',
  components: {
    TemplateEditModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission', 'loggedInUserOptions']),
  },
  data() {
    return {
      templateList: [
        {
          type: 'APPLICATION_RECEIVED',
          name: 'Umsókn móttekin',
          id: undefined,
          text: '',
        },
        {
          type: 'APPLICATION_ACCEPTED',
          name: 'Umsókn samþykkt',
          id: undefined,
          text: '',
        },
        {
          type: 'APPLICATION_REJECTED',
          name: 'Umsókn hafnað',
          id: undefined,
          text: '',
        },
      ],
      loading: false,
    };
  },
  methods: {
    async loadList() {
      this.loading = true;
      try {
        const response = await core.templateList();
        this.templateList.forEach(n => {
          const template = response.data.find(x => x.type === n.type);
          if (template) {
            this.$set(n, 'id', template.id);
            this.$set(n, 'text', template.text);
          }
        });
        // this.notificationList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Modal gluggar
     */
    templateEditModal(item) {
      this.$refs.templateEditModal.show(item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style></style>
