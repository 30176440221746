<template>
  <div>
    <b-modal
      id="districtCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Kennitala:"
            label-for="username"
            :state="(submitted || usernameCheckSuccess !== null) && errors.has('username') ? false : ''"
            :invalid-feedback="errors.first('username')"
            description="Kennitala notanda (án bandstriks)"
          >
            <b-input-group>
              <b-form-input
                id="username"
                name="username"
                type="text"
                v-model="form.username"
                required
                placeholder="Sláðu inn kennitölu"
                :state="(submitted || usernameCheckSuccess !== null) && errors.has('username') ? false : ''"
                v-validate="'required|length:10'"
                data-vv-as="kennitala"
                @input="checkUsername"
                :disabled="userId != undefined"
              ></b-form-input>
              <b-input-group-addon v-if="usernameCheckSuccess !== null">
                <strong class="text-danger" v-if="usernameCheckSuccess === false"><i class="fa fa-exclamation"></i></strong>
                <strong class="text-success" v-if="usernameCheckSuccess === true"><i class="fa fa-check"></i></strong>
              </b-input-group-addon>
            </b-input-group>
          </b-form-group>

          <b-form-group
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Fullt nafn notanda"
            ><slot name="label"
              >Nafn:<i
                class="fa fa-fw fa-info-circle"
                v-b-tooltip.hover
                title="Þegar búið er að fletta upp kennitölu notanda í þjóðskrá er
              hægt að yfirskrifa nafnið með því að skrá birtinganafn í reitinn.
              Athugið að þjóðskrárnafn notanda mun hvergi birtast í kerfinu eftir breytingu"
              ></i
            ></slot>
            <b-form-input
              id="name"
              name="name"
              type="text"
              v-model="form.name"
              required
              placeholder="Sláðu inn kennitölu"
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="nafn"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Farsími:"
            label-for="tel"
            :state="submitted && errors.has('tel') ? false : ''"
            :invalid-feedback="errors.first('tel')"
            description="Farsími notanda"
          >
            <b-form-input
              id="tel"
              name="tel"
              type="text"
              v-model="form.tel"
              required
              placeholder="Sláðu inn farsíma"
              :state="submitted && errors.has('tel') ? false : ''"
              data-vv-as="farsími"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Netfang:"
            label-for="email"
            :state="submitted && errors.has('email') ? false : ''"
            :invalid-feedback="errors.first('email')"
            description="Netfang notanda"
          >
            <b-form-input
              id="email"
              name="email"
              type="text"
              v-model="form.email"
              required
              placeholder="Sláðu inn netfang"
              :state="submitted && errors.has('email') ? false : ''"
              data-vv-as="netfang"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Virkur:"
            label-for="is_active"
            :state="submitted && errors.has('is_active') ? false : ''"
            :invalid-feedback="errors.first('is_active')"
            description="Hvort notandi sé virkur eða ekki"
            v-if="userId"
          >
            <b-form-checkbox id="is_active" name="is_active" type="text" v-model="form.is_active"></b-form-checkbox>
          </b-form-group>
          <b-form-group
            label="Aðgangur:"
            label-for="access"
            :state="submitted && errors.has('access') ? false : ''"
            :invalid-feedback="errors.first('access')"
            description="Aðgangur notanda"
          >
            <b-form-select
              id="access"
              name="access"
              v-model="form.access"
              :state="submitted && errors.has('access') ? false : ''"
              data-vv-as="aðgangur"
              v-validate="'required'"
            >
              <option :value="null">-- Veldu aðgang --</option>
              <option v-for="access in accessList" :key="access.id" :value="access.id">{{ access.name }}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Starfsstaðir:" description="Starfsstaðir sem notandi hefur aðgang að.">
            <shuttle v-model="this.form.workplaceList" :list="workplaceList"></shuttle>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import auth from '@/api/auth';
import { djangoErrorsToVee } from '@/utils';
import Shuttle from '@/components/Shuttle.vue';

export default {
  name: 'user-create-modal',
  components: {
    Shuttle,
  },
  data() {
    return {
      loading: {
        access: false,
        info: false,
        workplaces: false,
        username: false,
      },
      submitted: false,
      failed: false,
      created: false,
      usernameCheckSuccess: null,
      errorList: [],
      workplaceList: [],
      accessList: [],
      userId: undefined,
      title: 'Stofna notanda',
      form: {
        name: '',
        username: '',
        tel: '',
        email: '',
        access: null,
        is_active: true,
        workplaceList: [],
      },
    };
  },
  methods: {
    async load() {
      this.loading.info = true;
      try {
        const response = await auth.userDetail(this.userId);
        this.form.name = response.data.name;
        this.form.username = response.data.username;
        this.form.tel = response.data.tel;
        this.form.email = response.data.email;
        this.form.is_active = response.data.is_active;
        this.form.access = response.data.groups.length > 0 ? response.data.groups[0].id : null;
        this.form.workplaceList = response.data.workplaces.map(x => x.id);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
    async loadWorkplace() {
      this.loading.workplaces = true;
      this.workplaceList = [];
      try {
        const response = await core.dropdownWorkplaceEditUserList();
        this.workplaceList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.workplaces = false;
      }
    },
    async loadAccess() {
      this.loading.access = true;
      this.accessList = [];
      try {
        const response = await core.dropdownAccessList();
        this.accessList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.access = false;
      }
    },
    async checkUsername(val) {
      this.form.name = '';
      if (val && val.length === 10) {
        try {
          this.loading.username = true;
          const response = await auth.userCheck({ username: val });
          this.form.name = response.data.name;
          this.usernameCheckSuccess = !!response.data.name && !response.data.user;

          if (!response.data.name) {
            this.errors.add('username', 'Þessi kennitala finnst ekki í þjóðskrá.', 'server');
          }

          if (response.data.user) {
            this.errors.add('username', 'Þessi kennitala er þegar skráð á notanda.', 'server');
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.username = false;
        }
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          if (this.userId) {
            await auth.userUpdate(this.userId, {
              username: this.form.username,
              name: this.form.name,
              email: this.form.email != null ? this.form.email : '',
              tel: this.form.tel != null ? this.form.tel : '',
              is_active: this.form.is_active,
              workplaces: this.form.workplaceList,
              groups: [this.form.access],
            });
            this.displaySuccess(`Notandanum „${this.form.name}“ hefur verið breytt.`);
          } else {
            await auth.userCreate({
              username: this.form.username,
              name: this.form.name,
              email: this.form.email != null ? this.form.email : '',
              tel: this.form.tel != null ? this.form.tel : '',
              workplaces: this.form.workplaceList,
              groups: [this.form.access],
            });
            this.displaySuccess(`Notandinn „${this.form.name}“ hefur verið stofnaður.`);
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(userId) {
      this.form.name = '';
      this.form.username = '';
      this.form.tel = '';
      this.form.email = '';
      this.form.access = null;
      this.form.is_active = true;
      this.form.workplaceList = [];
      this.submitted = false;
      if (userId) {
        this.title = 'Breyta notanda';
        this.userId = userId;
        this.load();
      }
      this.loadWorkplace();
      this.loadAccess();
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
