var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('td',[(_vm.selected !== null && _vm.loggedInUserHasAnyPermission('write_application'))?_c('b-checkbox',{model:{value:(_vm.syncedSelected),callback:function ($$v) {_vm.syncedSelected=$$v},expression:"syncedSelected"}}):_vm._e()],1),_c('td',[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{ name: 'Application', params: { id: _vm.item.id } },"title":"Opna umsókn"}},[_vm._v(" "+_vm._s(_vm.item.name)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm.item.ssn))]),_c('td',[_vm._v(" "+_vm._s(_vm.item.birthYear.birthYear)+" "),(_vm.item.birthYear.birthYearOverridden)?_c('span',{staticClass:"small text-muted"},[_vm._v("("+_vm._s(_vm.item.birthYear.birthYearReal)+")")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.item.email))]),_c('td',[_vm._v(_vm._s(_vm.item.postalCode))]),_c('td',[_vm._v(" "+_vm._s(_vm.statusName)+" "),((_vm.item.status === 'IN_PROGRESS' || _vm.item.status === 'IN_PROGRESS_APPROVED') && _vm.item.workplaceInProgress)?_c('span',[_vm._v("("+_vm._s(_vm.item.workplaceInProgress.name)+")")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.item.period.name))]),_c('td',_vm._l((_vm.item.periodParts),function(part){return _c('div',{key:part.id,staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("moment")(part.periodPart.dateFrom,'DD.MM.YYYY'))+" - "+_vm._s(_vm._f("moment")(part.periodPart.dateTo,'DD.MM.YYYY'))+" ")])}),0),_c('td',[(
        (_vm.item.status === 'NEW' ||
          _vm.item.status === 'IN_PROGRESS' ||
          _vm.item.status === 'IN_PROGRESS_APPROVED' ||
          _vm.item.status === 'SEMI_ACCEPTED') &&
        _vm.loggedInUserHasAnyPermission('write_application')
      )?_c('div',[_vm._l((_vm.item.workplaces),function(workplace,idx){return _c('div',{key:workplace.id,staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(workplace.workplace.name)+" "),(idx === _vm.item.workplaces.length - 1)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa fa-fw fa-pencil text-muted",staticStyle:{"cursor":"pointer"},attrs:{"title":"Smelltu til að breyta starfsstað"},on:{"click":function($event){$event.preventDefault();return _vm.applicationEditWorkplaceModal(_vm.item)}}}):_vm._e()])}),(_vm.item.workplaces.length === 0)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa fa-fw fa-pencil text-muted",staticStyle:{"cursor":"pointer"},attrs:{"title":"Smelltu til að breyta starfsstað"},on:{"click":function($event){$event.preventDefault();return _vm.applicationEditWorkplaceModal(_vm.item)}}}):_vm._e()],2):_c('div',_vm._l((_vm.item.workplaces),function(workplace){return _c('div',{key:workplace.id,staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(workplace.workplace.name)+" ")])}),0),(_vm.item.workplacesCount !== _vm.item.workplaces.length)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-muted small",attrs:{"title":"Umsækjandi sótti um fleiri starfsstaði sem innskráður notandi hefur ekki aðgang að."}},[_vm._v("(+"+_vm._s(_vm.item.workplacesCount - _vm.item.workplaces.length)+")")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.item.dateCreated,'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-primary",staticStyle:{"padding":"0px 2px"},attrs:{"title":"Skoða","to":{ name: 'Application', params: { id: _vm.item.id } }}},[_c('i',{staticClass:"fa fa-fw fa-search",staticStyle:{"color":"#fff"}})]),(
        (_vm.item.status === 'NEW' ||
          _vm.item.status === 'IN_PROGRESS' ||
          _vm.item.status === 'IN_PROGRESS_APPROVED' ||
          _vm.item.status === 'SEMI_ACCEPTED') &&
        _vm.loggedInUserHasAnyPermission('write_application') &&
        _vm.loggedInUserHasAnyPermission('write_approve_application')
      )?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"padding":"0px 2px"},attrs:{"variant":"success","title":"Samþykkja"},on:{"click":function($event){$event.preventDefault();return _vm.applicationApproveOneModal(_vm.item.id)}}},[_c('i',{staticClass:"fa fa-fw fa-check",staticStyle:{"color":"#fff"}})]):_vm._e(),(
        (_vm.item.status === 'NEW' || _vm.item.status === 'IN_PROGRESS' || _vm.item.status === 'IN_PROGRESS_APPROVED') &&
        _vm.loggedInUserHasAnyPermission('write_application') &&
        _vm.loggedInUserHasAnyPermission('write_approve_application')
      )?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"padding":"0px 2px"},attrs:{"variant":"danger","title":"Hafna"},on:{"click":function($event){$event.preventDefault();return _vm.applicationRejectOneModal(_vm.item)}}},[_c('i',{staticClass:"fa fa-fw fa-remove",staticStyle:{"color":"#fff"}})]):_vm._e(),(
        _vm.item.status === 'SEMI_ACCEPTED' &&
        _vm.loggedInUserHasAnyPermission('write_application') &&
        _vm.loggedInUserHasAnyPermission('write_approve_application')
      )?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"padding":"0px 2px"},attrs:{"variant":"dark","title":"Klára umsókn"},on:{"click":function($event){$event.preventDefault();return _vm.applicationFinishOneModal(_vm.item)}}},[_c('i',{staticClass:"fa fa-fw fa-envelope",staticStyle:{"color":"#fff"}})]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }