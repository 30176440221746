<template>
  <div>
    <b-modal
      id="workplaceTypeCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="submitting || $apollo.queries.workplaceType.loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Heiti"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Heiti flokkunar starfsstaða"
          >
            <b-form-input
              id="name"
              name="name"
              autofocus
              type="text"
              v-model="form.name"
              required
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="nafn"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-for="enabled" description="Er flokkur starfsstaða virkur?">
            <b-form-checkbox id="enabled" name="enabled" autofocus v-model="form.enabled" data-vv-as="virkur"> Virkur </b-form-checkbox>
          </b-form-group>

          <template v-if="loggedInUser.town.filterWorkplacesApplication">
            <b-form-group label-for="ageExclusion" description="Hvort eigi að takmarka flokk við aldur">
              <b-form-checkbox id="ageExclusion" name="ageExclusion" v-model="form.ageExclusion" @change="resetAge($event)">
                Aldurstakmörkun
              </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="form.ageExclusion" label="Aldurstakmörkun" label-for="ageExclusions" description="Sláðu inn aldur">
              <div v-for="(age, index) in form.ageExclusions" :key="index" class="mb-2">
                <div class="d-inline-block">
                  <b-form-input
                    :id="`age${index}`"
                    :name="`age${index}`"
                    ref="ageFormInput"
                    type="number"
                    v-model="age.value"
                    min="1"
                    style="width: 100px"
                    v-on:keydown.enter="addAge()"
                    v-on:keydown.tab="addAge()"
                  ></b-form-input>
                </div>
                <div class="d-inline-block">
                  <i class="fa fa-fw fa-trash" style="cursor: pointer" @click.prevent="removeAge(index)"></i>
                </div>
              </div>
              <b-button size="sm" @click="addAge()" variant="default">
                <i class="fa fa-fw fa-plus-circle"></i>
                Bæta við ári
              </b-button>
            </b-form-group>
          </template>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies
import { mapActions, mapState } from 'vuex';
import { serverValidationToForm } from '@/utils';

export default {
  name: 'workplace-type-create-modal',
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  apollo: {
    workplaceType: {
      query: gql`
        query WorkplaceType($id: ID!) {
          workplaceType(id: $id) {
            id
            databaseId
            name
            ageExclusions
            enabled
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
      result({ data: { workplaceType } }) {
        this.form.name = workplaceType.name;
        this.form.enabled = workplaceType.enabled;
        this.form.ageExclusions = workplaceType.ageExclusions ? workplaceType.ageExclusions.split(',').map(a => ({ value: a })) : [];
        this.form.ageExclusion = this.form.ageExclusions.length > 0;
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  data() {
    return {
      submitting: false,
      submitted: false,
      errorList: [],
      id: '',
      title: 'Skrá flokk starfsstöðva',
      form: {
        name: '',
        enabled: true,
        ageExclusion: false,
        ageExclusions: [],
      },
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        const mutation = this.id
          ? gql`
              mutation ($input: UpdateWorkplaceTypeInput!) {
                updateWorkplaceType(input: $input) {
                  errors {
                    field
                    messages
                  }
                }
              }
            `
          : gql`
              mutation ($input: CreateWorkplaceTypeInput!) {
                createWorkplaceType(input: $input) {
                  workplaceType {
                    id
                  }
                  errors {
                    field
                    messages
                  }
                }
              }
            `;

        const mutationName = this.id ? 'updateWorkplaceType' : 'createWorkplaceType';

        const variables = {
          input: {
            name: this.form.name,
            enabled: this.form.enabled,
            ageExclusions: this.form.ageExclusion
              ? this.form.ageExclusions
                  .map(a => a.value)
                  .filter(a => !!a)
                  .sort()
                  .join(',')
              : '',
          },
        };

        if (this.id) {
          variables.input.id = this.id;
        }

        // Engar villur í formi.
        try {
          this.submitting = true;

          const {
            data: {
              [mutationName]: { errors },
            },
          } = await this.$apollo.mutate({
            mutation,
            variables,
          });

          if (errors) {
            serverValidationToForm({
              errors,
              setFieldError: (field, error) => {
                this.errors.add({
                  field,
                  msg: error,
                  rule: 'server',
                });
              },
              errorFieldMap: { details: 'name' },
            });
          } else {
            if (this.id) {
              this.displaySuccess(`Flokknum „${this.form.name}“ hefur verið breytt.`);
            } else {
              this.displaySuccess(`Flokkurinn „${this.form.name}“ hefur verið stofnaður.`);
            }
            this.form.name = '';
            this.submitted = false;
            this.$emit('successful', { id: this.id });
            this.$refs.createModal.hide();
            this.id = null;
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.submitting = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(id) {
      if (id) {
        this.title = 'Breyta flokk starfsstöðva';
        this.id = id;
      }
      this.$refs.createModal.show();
    },
    resetAge(e) {
      if (e) {
        this.addAge();
      } else {
        this.form.ageExclusions = [];
      }
    },
    addAge() {
      this.form.ageExclusions.push({
        value: '',
      });
      setTimeout(() => {
        this.$refs.ageFormInput[this.$refs.ageFormInput.length - 1].focus();
      });
    },
    removeAge(index) {
      this.form.ageExclusions.splice(index, 1);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
