<template>
  <div>
    <h5 class="header mt-4">Aðstandendur</h5>
    <b-row v-for="contact in item.contacts" :key="contact.id">
      <b-col>
        <strong>Nafn</strong>
        <div>
          {{ contact.name }}
          <b-link v-if="canBeUpdated && loggedInUserHasAnyPermission('write_application')" @click.prevent="updateContactModal(contact.id)">
            <i class="fa fa-fw fa-pencil"></i>
          </b-link>
        </div>
      </b-col>
      <b-col>
        <strong>Kennitala</strong>
        <div>{{ contact.ssn }}</div>
      </b-col>
      <b-col>
        <strong>Netfang</strong>
        <div>{{ contact.email }}</div>
      </b-col>
      <b-col>
        <strong>
          Farsími
          <b-link
            v-if="canBeUpdated && item.contacts.length > 1 && loggedInUserHasAnyPermission('write_application')"
            class="pull-right"
            @click.prevent="deleteContact(contact)"
          >
            <i class="fa fa-fw fa-trash"></i>
          </b-link>
        </strong>
        <div>
          {{ contact.tel }}
        </div>
      </b-col>
    </b-row>
    <b-btn
      variant="primary"
      size="sm"
      class="pull-right"
      v-if="canBeUpdated && loggedInUserHasAnyPermission('write_application')"
      @click.prevent="createContact()"
    >
      <i class="fa fa-fw fa-plus-circle" style="color: #fff"></i>
      Bæta við aðstandanda
    </b-btn>
    <contact-modal ref="contactModal" @successful="reloadContacts"></contact-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import appl from '@/api/application';
import ContactModal from '@/components/contact/ContactModal.vue';

export default {
  name: 'application-contacts',
  props: ['item'],
  components: {
    ContactModal,
  },
  computed: {
    canBeUpdated() {
      return (
        this.item.status === 'NEW' ||
        this.item.status === 'IN_PROGRESS' ||
        this.item.status === 'IN_PROGRESS_APPROVED' ||
        this.item.status === 'SEMI_ACCEPTED'
      );
    },
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    reloadContacts() {
      this.$emit('reload', {});
    },
    async deleteContact(contact) {
      const conf = confirm(`Ertu viss um að þú viljir eyða eftirfarandi tengilið - ${contact.name} kt.${contact.ssn}`); //eslint-disable-line
      if (conf) {
        try {
          await appl.applicationContactDelete(contact.id);
          this.reloadContacts();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    createContact() {
      this.$refs.contactModal.show('application', this.item.id);
    },
    updateContactModal(id) {
      this.$refs.contactModal.show('application', this.item.id, id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
