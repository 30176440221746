import Vue from 'vue';
import moment from 'moment';

import config from '../config';

export default {
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tegundir fjarvista.
   */
  attendanceTypeList() {
    return Vue.http
      .get(config.ATTENDANCE_TYPE_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eina tegund fjravista.
   *
   * @param {number} id Einkenni fjarvistartegundar til að sækja.
   */
  attendanceTypeDetail(id) {
    return Vue.http
      .get(config.ATTENDANCE_TYPE_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá fjarvistartegund.
   *
   * @param {object} data Gögn um fjarvistartegund
   */
  attendanceTypeCreate(data) {
    return Vue.http
      .post(config.ATTENDANCE_TYPE_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra fjarvistartegund.
   *
   * @param {number} id Einkenni fjarvistartegundar til að uppfæra.
   * @param {object} data Gögn um breytt fjarvistartegund
   */
  attendanceTypeUpdate(id, data) {
    return Vue.http
      .put(config.ATTENDANCE_TYPE_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða fjarvistartegund.
   *
   * @param {number} id Einkenni fjarvistartegundar til að eyða.
   */
  attendanceTypeDelete(id) {
    return Vue.http
      .delete(config.ATTENDANCE_TYPE_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja fjarvistaryfirlit
   */
  attendanceOverviewList(placementGroup, date) {
    return Vue.http
      .get(config.ATTENDANCE_OVERVIEW_URL, {
        params: {
          placement_group: placementGroup,
          date: date ? moment(date).format('YYYY-MM-DD') : null,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit viðveruskráningu nemanda
   */
  attendanceList(page, limit, persongroup) {
    return Vue.http
      .get(config.ATTENDANCE_LIST_URL, {
        params: {
          page,
          limit,
          placement_group_person: persongroup,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja viðveruskráningu
   */
  attendanceDetail(id) {
    return Vue.http
      .get(config.ATTENDANCE_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá viðveru.
   */
  attendanceCreate(data) {
    return Vue.http
      .post(config.ATTENDANCE_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að breyta viðveru.
   */
  attendanceUpdate(id, data) {
    return Vue.http
      .put(config.ATTENDANCE_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  attendanceOverviewTypeList() {
    return Vue.http
      .get(config.ATTENDANCE_OVERVIEW_TYPE_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  attendanceOverviewProjectList() {
    return Vue.http
      .get(config.ATTENDANCE_OVERVIEW_PROJECT_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tegundir verkefna.
   */
  attendanceProjectList() {
    return Vue.http
      .get(config.ATTENDANCE_PROJECT_LIST_URL)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eina tegund verkefna.
   *
   * @param {number} id Einkenni verkefnategundar til að sækja.
   */
  attendanceProjectDetail(id) {
    return Vue.http
      .get(config.ATTENDANCE_PROJECT_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá verkefnategund.
   *
   * @param {object} data Gögn um verkefnategund
   */
  attendanceProjectCreate(data) {
    return Vue.http
      .post(config.ATTENDANCE_PROJECT_LIST_URL, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra verkefnategund.
   *
   * @param {number} id Einkenni verkefnategundar til að uppfæra.
   * @param {object} data Gögn um breytt verkefnategund
   */
  attendanceProjectUpdate(id, data) {
    return Vue.http
      .put(config.ATTENDANCE_PROJECT_DETAIL_URL(id), data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða verkefnategund.
   *
   * @param {number} id Einkenni verkefnategundar til að eyða.
   */
  attendanceProjectDelete(id) {
    return Vue.http
      .delete(config.ATTENDANCE_PROJECT_DETAIL_URL(id))
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
