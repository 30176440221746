<template>
  <div>
    <b-modal
      id="groupChangeModal"
      :title="title"
      size="lg"
      :no-close-on-backdrop="true"
      ok-title="Vista"
      :ok-disabled="attendanceSummary.loading"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      ref="changeGroupModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <h5>Núverandi skráning</h5>
          <table class="table" v-if="groupPerson && groupPerson.placement_group">
            <tr>
              <td>Tímabil</td>
              <td>{{ groupPerson.placement_group.period.name }}</td>
            </tr>
            <tr v-if="groupPerson.placement_group.period_part">
              <td>Starfstímabil</td>
              <td>
                {{ groupPerson.placement_group.period_part.date_from | moment('DD.MM.YYYY') }} -
                {{ groupPerson.placement_group.period_part.date_to | moment('DD.MM.YYYY') }}
              </td>
            </tr>
            <tr>
              <td>Vinnustaður</td>
              <td>{{ groupPerson.placement_group.workplace.name }}</td>
            </tr>
            <tr>
              <td>Hópur</td>
              <td>{{ groupPerson.placement_group.name }}</td>
            </tr>
            <tr>
              <td>Samtals viðvera</td>
              <td v-if="attendanceSummary.loading || !attendanceSummary.data"><loading /></td>
              <td v-else>{{ attendanceSummary.data.hours | formatMaxHours }}</td>
            </tr>
          </table>

          <br />
          <h5>Breytt skráning</h5>
          <b-form-group label="Flokkun vinnustaðar" label-for="type">
            <b-form-select id="type" name="type" v-model="form.type" @input="onWorkplaceTypeChange">
              <option :value="null">-- Veldu flokk --</option>
              <template v-if="!workplaceTypes.loading && workplaceTypes.data">
                <option v-for="type in workplaceTypes.data" :key="type.id" :value="type.id">
                  {{ type.name }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Starfsstaður" label-for="workplace">
            <b-form-select id="workplace" name="workplace" v-model="form.workplace" @input="loadGroups">
              <option :value="null">-- Veldu starfsstað --</option>
              <template v-if="!workplaces.loading && workplaces.data">
                <option v-for="workplace in workplaces.data" :key="workplace.id" :value="workplace.id">
                  {{ workplace.name }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Hópur*"
            label-for="group"
            :state="submitted && errors.has('group') ? false : ''"
            :invalid-feedback="errors.first('group')"
          >
            <b-form-select id="group" name="group" v-model="form.group" v-validate="'required'" data-vv-as="hópur">
              <option :value="null">-- Veldu hóp --</option>
              <option v-for="group in groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Breyting tekur gildi frá og með*"
            label-for="date"
            :state="submitted && errors.has('date') ? false : ''"
            :invalid-feedback="errors.first('date')"
          >
            <datepicker
              id="date"
              name="date"
              placeholder="Veldu dagsetningu"
              :class="{ 'is-invalid': submitted && errors.has('date') }"
              v-model="form.date"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              data-vv-as="dags"
              v-validate="'required'"
              ref="dateFromPickr"
              :disabled-dates="disabledDates"
            />
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { is } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import core from '@/api/core';
import placement from '@/api/placement';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'change-group-modal',
  components: {
    Datepicker,
  },
  data() {
    return {
      loading: {
        groups: false,
      },
      lang: is,
      submitted: false,
      errorList: [],
      groupPerson: {},
      title: 'Færa þátttakanda á milli hópa',
      groupList: [],
      form: {
        type: null,
        workplace: null,
        group: null,
        date: null,
      },
      workplaceTypes: {
        data: [],
        loading: false,
        error: false,
      },
      workplaces: {
        data: [],
        loading: false,
        error: false,
      },
      attendanceSummary: {
        data: null,
        loading: false,
        error: false,
      },
    };
  },
  computed: {
    groups() {
      return this.groupList.filter(p => p.id !== this.groupPerson.placement_group.id);
    },
    disabledDates() {
      if (this.attendanceSummary.data?.dateMax) {
        return {
          to: moment(this.attendanceSummary.data.dateMax).add(1, 'days').toDate(),
        };
      }
      return {};
    },
  },
  methods: {
    onWorkplaceTypeChange() {
      this.loadWorkplaces();
      this.loadGroups();
    },
    async loadGroups() {
      this.loading.groups = true;
      this.groupList = [];
      this.form.group = null;
      try {
        const response = await core.dropdownApplicationPlacementgroupList({
          period: this.groupPerson.placement_group.period ? this.groupPerson.placement_group.period.id : null,
          period_part: this.groupPerson.placement_group.period_part ? this.groupPerson.placement_group.period_part.id : null,
          workplace: this.form.workplace,
          type: this.form.type,
          birth_year: this.birthYear,
        });
        this.groupList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.groups = false;
      }
    },
    async loadWorkplaceTypes() {
      this.workplaceTypes.loading = true;
      this.workplaceTypes.data = [];
      this.workplaceTypes.error = false;

      try {
        const response = await this.$coreApi.workplaceTypeDropdownList();

        this.workplaceTypes.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.workplaceTypes.error = true;
      } finally {
        this.workplaceTypes.loading = false;
      }
    },
    async loadWorkplaces() {
      this.workplaces.loading = true;
      this.workplaces.data = [];
      this.workplaces.error = false;

      try {
        const response = await this.$coreApi.workplaceDropdownList({
          type: this.form.type || undefined,
        });

        this.workplaces.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.workplaces.error = true;
      } finally {
        this.workplaces.loading = false;
      }
    },
    async loadAttendanceSummary() {
      this.attendanceSummary.loading = true;
      this.attendanceSummary.data = null;
      this.attendanceSummary.error = false;

      try {
        const response = await this.$placementApi.placementGroupPersonAttendanceSummary({
          uuid: this.groupPerson.id,
        });

        this.attendanceSummary.data = response.data;
      } catch (e) {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
        this.attendanceSummary.error = true;
      } finally {
        this.attendanceSummary.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) {
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        // Engar villur í formi.
        try {
          // this.loading = true;
          await placement.placementGroupPersonChangeGroup(this.groupPerson.id, {
            placement_group: this.form.group,
            date: moment(this.form.date).format('YYYY-MM-DD'),
          });
          this.displaySuccess('Þátttakandi hefur verið færður um hóp');
          this.$emit('successful', {
            id: this.form.group,
          });
          this.$refs.changeGroupModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.loading = false;
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
        this.loading = false;
      }
    },
    show(groupPerson, birthYear) {
      this.groupPerson = groupPerson;
      this.form.type = null;
      this.form.workplace = null;
      this.form.group = null;
      this.form.date = null;
      this.submitted = false;
      this.birthYear = birthYear;
      this.$refs.changeGroupModal.show();
      this.loadGroups();
      this.loadWorkplaceTypes();
      this.loadWorkplaces();
      this.loadAttendanceSummary();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
