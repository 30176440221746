<template>
  <div>
    <b-card class="card-primary-header mt-3">
      <div slot="header">
        <div>Tölvupóstar</div>
      </div>
      <div v-if="!loggedInUserHasAnyPermission('read_emailmessage')">
        <i>Þú hefur ekki aðgang að því að skoða viðveru</i>
      </div>
      <div v-else>
        <div v-if="emailList.length === 0 && !loading">
          <i>Engar færslur fundust</i>
        </div>
        <table class="table white-table" v-else>
          <tr>
            <th>Titill</th>
            <th>Sent á</th>
            <th>Tegund</th>
            <th>Staða</th>
            <th>Dags</th>
            <th></th>
          </tr>
          <tr v-for="email in emailList" :key="email.id">
            <td>{{ email.subject }}</td>
            <td>{{ email.email_to }}</td>
            <td><span v-if="email.is_contact">Aðstandandi</span><span v-else>Þátttakandi</span></td>
            <td>{{ getEmailStatus(email.status) }}</td>
            <td>{{ email.date_created | moment('DD.MM.YYYY HH:mm:ss') }}</td>
            <td>
              <b-btn
                variant="primary"
                class="pull-right"
                v-if="loggedInUserHasAnyPermission('read_emailmessage')"
                @click.prevent="viewEmailModal(email.id)"
                style="padding: 0px 2px"
              >
                <i class="fa fa-fw fa-search" style="color: #fff"></i>
              </b-btn>
            </td>
          </tr>
        </table>
      </div>
    </b-card>
    <view-email-modal ref="viewEmailModal"></view-email-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import core from '@/api/core';
import ViewEmailModal from '@/components/email/ViewEmailModal.vue';

export default {
  name: 'student-email',
  props: ['reload', 'item'],
  components: {
    ViewEmailModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasAnyPermission']),
  },
  data() {
    return {
      loading: false,
      emailList: [],
    };
  },
  methods: {
    getEmailStatus(status) {
      switch (status) {
        case 'NEW':
          return 'Nýskráð';
        case 'DELIVERED':
          return 'Sent';
        case 'NOT_DELIVERED':
          return 'Ekki sent';
        default:
          return '';
      }
    },
    async loadList() {
      this.emailList = [];
      if (this.loggedInUserHasAnyPermission('read_emailmessage') && this.item) {
        this.loading = true;
        try {
          const response = await core.emailMessageList(null, null, this.item.id, null);
          this.emailList = response.data;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    /*
     * Modal gluggar
     */
    viewEmailModal(id) {
      this.$refs.viewEmailModal.show(id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    reload(val) {
      if (val) {
        this.loadList();
      }
    },
  },
  created() {
    this.loadList();
  },
};
</script>
