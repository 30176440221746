<!-- Launakerfi - Launamenn -->
<template>
  <div>
    <payroll-employees-h3-filters v-model="search" />
    <div class="clearfix my-2">
      <b-btn @click.prevent="getExcel" class="pull-right btn-sm btn-dark">
        <i class="fa fa-fw fa-file-excel-o"></i>
        Sækja skrá fyrir launakerfi
      </b-btn>
    </div>
    <div class="m-2" v-if="!$apollo.queries.payrollPaymentEmployeesH3.loading && payrollPaymentEmployeesH3">
      <i v-if="payrollPaymentEmployeesH3.length === 1">Fannst {{ payrollPaymentEmployeesH3.length }} færsla.</i>
      <i v-if="payrollPaymentEmployeesH3.length > 1">Fundust {{ payrollPaymentEmployeesH3.length }} færslur.</i>
    </div>
    <b-card no-body border-variant="light" class="mb-2">
      <div class="m-2" v-if="$apollo.queries.payrollPaymentEmployeesH3.loading"><loading /></div>
      <div
        class="m-2"
        v-if="!$apollo.queries.payrollPaymentEmployeesH3.loading && payrollPaymentEmployeesH3 && payrollPaymentEmployeesH3.length === 0"
      >
        <p>Engin gögn fundust.</p>
      </div>
      <div
        class="overflow-auto"
        v-if="!$apollo.queries.payrollPaymentEmployeesH3.loading && payrollPaymentEmployeesH3 && payrollPaymentEmployeesH3.length > 0"
      >
        <table class="table mb-0">
          <thead>
            <tr class="bg-gray-200" style="font-size: 7px">
              <th title="Nr. (index)"></th>
              <th title="Kennitala (code)">Kennitala</th>
              <th title="Nafn (name)">Nafn</th>
              <th title="Greiðslutíðni (paymentfrequency)">Greiðslu- tíðni</th>
              <th title="Útborgað (paymentmethod)">Út- borgað</th>
              <th title="Kyn (gender)">Kyn</th>
              <th title="Þjóðerni (nationalitycode)">Þjóð- erni</th>
              <th title="Lögheimili (addr1street)">Lögheimili</th>
              <th title="Póstnúmer (addr1postofficecode)">Póst- númer</th>
              <th title="Land (addr1countrycode)">Land</th>
              <th title="Aðsetur (addr2street)">Að- setur</th>
              <th title="Póstnúmer (addr2postofficecode)">Póst- númer</th>
              <th title="Land (addr2countrycode)">Land</th>
              <th title="Vinnusími (phonebusiness)">Vinnu- sími</th>
              <th title="Farsími (mobile)">Far- sími</th>
              <th title="Heimasími (phonehome)">Heima- sími</th>
              <th title="Einka farsími (mobilehome)">Einka farsími</th>
              <th title="Vinnunetfang (emailbusiness)">Vinnu- netfang</th>
              <th title="Einkanetfang (emailpersonal)">Einka- netfang</th>
              <th title="Banki laun (payaccountbankcode)">Banki laun</th>
              <th title="Höfuðbók (payaccounttypecode)">Höfuð- bók</th>
              <th title="Reikningsnúmer (payaccountnumber)">Reiknings- númer</th>
              <th title="Stýrikerfisauðkenni (adidentity)">Stýrikerfis- auðkenni</th>
              <th title="Banki orlof (holiaccountbankcode)">Banki orlof</th>
              <th title="Höfuðbók (holiaccounttypecode)">Höfuð- bók</th>
              <th title="Reikningsnúmer (holiaccountnumber)">Reiknings- númer</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(emp, idx) in payrollPaymentEmployeesH3" :key="emp.person.ssn" style="font-size: 11px">
              <td>{{ idx + 1 }}</td>
              <td>{{ emp.person.ssn }}</td>
              <td>{{ emp.person.name }}</td>
              <td>4</td>
              <td>3</td>
              <template v-if="emp.nationalRegistry">
                <td>{{ emp.nationalRegistry.gender }}</td>
                <td>{{ emp.nationalRegistry.nationality }}</td>
                <td style="max-width: 50px">{{ emp.nationalRegistry.domicile }}</td>
                <td>{{ emp.nationalRegistry.postcode }}</td>
                <td>{{ emp.nationalRegistry.postcode ? 'IS' : '' }}</td>
              </template>
              <template v-else>
                <td colspan="5"></td>
              </template>
              <td><!--Aðsetur (addr2street)--></td>
              <td><!--Póstnúmer (addr2postofficecode)--></td>
              <td><!--Land (addr2countrycode)--></td>
              <td><!--Vinnusími (phonebusiness)--></td>
              <td><!--Farsími (mobile)--></td>
              <td><!--Heimasími (phonehome)--></td>
              <td>{{ emp.tel }}</td>
              <td><!--Vinnunetfang (emailbusiness)--></td>
              <td style="max-width: 50px">{{ emp.email }}</td>
              <td>{{ emp.account && emp.account.split('-').length > 0 ? emp.account.split('-')[0] : '' }}</td>
              <td>{{ emp.account && emp.account.split('-').length > 1 ? emp.account.split('-')[1] : '' }}</td>
              <td>{{ emp.account && emp.account.split('-').length > 2 ? emp.account.split('-')[2] : '' }}</td>
              <td><!--Stýrikerfisauðkenni (adidentity)--></td>
              <td><!--Banki orlof (holiaccountbankcode)--></td>
              <td><!--Höfuðbók (holiaccounttypecode)--></td>
              <td><!--Reikningsnúmer (holiaccountnumber)--></td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import gql from 'graphql-tag'; // eslint-disable-line import/no-extraneous-dependencies
import { mapActions } from 'vuex';
import payment from '@/api/payment';
import { b64DecodeUnicode } from '@/utils';
import PayrollEmployeesH3Filters from './PayrollEmployeesH3Filters.vue';

export default {
  name: 'payroll-payment-employees-h3',
  components: {
    PayrollEmployeesH3Filters,
  },
  data() {
    return {
      search: {
        placementGroup: null,
        period: null,
        periodPart: null,
        workplace: null,
        workplaceType: null,
      },
    };
  },
  apollo: {
    payrollPaymentEmployeesH3: {
      query: gql`
        query PayrollPaymentEmployeesH3(
          $period: ID
          $periodPart: ID
          $placementGroup: ID
          $workplace: ID
          $workplaceType: ID
        ) {
          payrollPaymentEmployeesH3(
            period: $period
            periodPart: $periodPart
            placementGroup: $placementGroup
            workplace: $workplace
            workplaceType: $workplaceType
          ) {
            person {
              id
              ssn
              name
            }
            nationalRegistry {
              domicile
              postcode
              postcodeName
              gender
              nationality
            }
            email
            tel
            account
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      variables() {
        const variables = {};

        if (this.search.placementGroup) variables.placementGroup = this.search.placementGroup;
        if (this.search.period) variables.period = this.search.period;
        if (this.search.periodPart) variables.periodPart = this.search.periodPart;
        if (this.search.workplace) variables.workplace = this.search.workplace;
        if (this.search.workplaceType) variables.workplaceType = this.search.workplaceType;

        return variables;
      },
      error(e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      },
    },
  },
  methods: {
    async getExcel() {
      try {
        const variables = {};

        if (this.search.placementGroup) [, variables.placementGroup] = b64DecodeUnicode(this.search.placementGroup).split(':');
        if (this.search.period) [, variables.period] = b64DecodeUnicode(this.search.period).split(':');
        if (this.search.periodPart) [, variables.periodPart] = b64DecodeUnicode(this.search.periodPart).split(':');
        if (this.search.workplace) [, variables.workplace] = b64DecodeUnicode(this.search.workplace).split(':');
        if (this.search.workplaceType) [, variables.workplaceType] = b64DecodeUnicode(this.search.workplaceType).split(':');

        const response = await payment.payrollPaymentEmployeeH3Excel(variables);
        // const w = window.open('', '_blank');
        window.location.href = response.data.url;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
