<template>
  <div>
    <b-modal
      id="jobCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Starfstegund:"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
            description="Heiti starfstegundar"
          >
            <b-form-input
              id="name"
              name="name"
              autofocus
              type="text"
              v-model="form.name"
              required
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="nafn"
              :disabled="jobId > 0"
            ></b-form-input>
          </b-form-group>
          <h5>Aðgangur</h5>
          <div v-for="access in form.accessList" :key="access.name">
            <b-form-group :label="`${access.title}:`" :label-for="`access${access.name}`">
              <b-form-select :id="`access${access.name}`" :name="`access${access.name}`" v-model="access.permission">
                <option value="none">Enginn</option>
                <option value="read">Les</option>
                <option value="write">Les+Skrif</option>
              </b-form-select>
            </b-form-group>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import auth from '@/api/auth';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'group-create-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      errorList: [],
      jobId: '',
      title: 'Stofna starfstegund',
      form: {
        name: '',
        accessList: [
          { name: 'user', title: 'Notendur', permission: 'none' },
          { name: 'usergroup', title: 'Aðgangur', permission: 'none' },
          { name: 'template', title: 'Tilkynningar', permission: 'none' },
          { name: 'period', title: 'Tímabil', permission: 'none' },
          { name: 'workplacedistrict', title: 'Hverfi', permission: 'none' },
          { name: 'workplacetype', title: 'Flokkun starfsstaða', permission: 'none' },
          { name: 'workplace', title: 'Starfsstaður', permission: 'none' },
          { name: 'application', title: 'Umsóknir', permission: 'none' },
          { name: 'approve_application', title: 'Umsóknarsamþykktir', permission: 'none' },
          { name: 'placementgroup', title: 'Hópar', permission: 'none' },
          { name: 'person', title: 'Þátttakendur', permission: 'none' },
          { name: 'exemption', title: 'Undanþága', permission: 'none' },
          { name: 'attendance', title: 'Viðvera', permission: 'none' },
          { name: 'attendancetype', title: 'Fjarvistartegundir', permission: 'none' },
          { name: 'paymentperiod', title: 'Launatímabil', permission: 'none' },
          { name: 'wageperiod', title: 'Launtaxtar', permission: 'none' },
          { name: 'batch', title: 'Uppgjör', permission: 'none' },
          { name: 'history', title: 'Breytingasaga', permission: 'none' },
          { name: 'emailmessage', title: 'Tölvupóstar', permission: 'none' },
          { name: 'search', title: 'Leit', permission: 'none' },
          { name: 'attendanceproject', title: 'Verkefnategundir', permission: 'none' },
          { name: 'reviewquestion', title: 'Frammistöðumat', permission: 'none' },
          { name: 'helpdocument', title: 'Aðstoð', permission: 'none' },
        ],
      },
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const response = await auth.authGroupDetail(this.jobId);
        this.form.name = response.data.name;
        this.form.accessList.forEach(a => {
          const permission =
            response.data.permissions.find(p => p.name === a.name) !== undefined
              ? response.data.permissions.find(p => p.name === a.name).permission
              : 'none';
          this.$set(a, 'permission', permission);
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          if (this.jobId) {
            await auth.authGroupUpdate(this.jobId, {
              name: this.form.name,
              permissions: this.form.accessList,
            });
            this.displaySuccess(`Starfstegundinni „${this.form.name}“ hefur verið breytt.`);
          } else {
            await auth.authGroupCreate({
              name: this.form.name,
              permissions: this.form.accessList,
            });
            this.displaySuccess(`Starfstegundin „${this.form.name}“ hefur verið stofnað.`);
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(jobId) {
      this.form.name = '';
      this.form.accessList = [
        { name: 'user', title: 'Notendur', permission: 'none' },
        { name: 'usergroup', title: 'Aðgangur', permission: 'none' },
        { name: 'template', title: 'Tilkynningar', permission: 'none' },
        { name: 'period', title: 'Tímabil', permission: 'none' },
        { name: 'workplacedistrict', title: 'Hverfi', permission: 'none' },
        { name: 'workplacetype', title: 'Flokkun starfsstaða', permission: 'none' },
        { name: 'workplace', title: 'Starfsstaður', permission: 'none' },
        { name: 'application', title: 'Umsóknir', permission: 'none' },
        { name: 'approve_application', title: 'Umsóknarsamþykktir', permission: 'none' },
        { name: 'placementgroup', title: 'Hópar', permission: 'none' },
        { name: 'person', title: 'Þátttakendur', permission: 'none' },
        { name: 'exemption', title: 'Undanþága', permission: 'none' },
        { name: 'attendance', title: 'Viðvera', permission: 'none' },
        { name: 'attendancetype', title: 'Fjarvistartegundir', permission: 'none' },
        { name: 'paymentperiod', title: 'Launatímabil', permission: 'none' },
        { name: 'wageperiod', title: 'Launtaxtar', permission: 'none' },
        { name: 'batch', title: 'Uppgjör', permission: 'none' },
        { name: 'history', title: 'Breytingasaga', permission: 'none' },
        { name: 'emailmessage', title: 'Tölvupóstar', permission: 'none' },
        { name: 'search', title: 'Leit', permission: 'none' },
        { name: 'attendanceproject', title: 'Verkefnategundir', permission: 'none' },
        { name: 'reviewquestion', title: 'Frammistöðumat', permission: 'none' },
          { name: 'helpdocument', title: 'Aðstoð', permission: 'none' },
      ];
      this.submitted = false;
      this.loading = false;
      this.jobId = null;
      if (jobId) {
        this.title = 'Breyta starfstegund';
        this.jobId = jobId;
        this.load();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
