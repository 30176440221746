
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import { parsePagination } from '@/utils';
import Pagination from '@/components/Pagination.vue';
import {
  PlacementApiAxiosParamCreator,
  PlacementApiPlacementGroupPersonListRequest,
  PlacementGroupPersonList,
  WorkplaceDropdown,
} from '@/api/openapi';

type OrderBy =
  | 'personSsn'
  | '-personSsn'
  | 'personName'
  | '-personName'
  | 'workplaceName'
  | '-workplaceName'
  | 'placementGroupName'
  | '-placementGroupName'
  | 'periodName'
  | '-periodName';

@Component({
  components: { Pagination, Datepicker },
})
export default class PlacementGroupPersons extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;

  data: Array<PlacementGroupPersonList> | null = null;
  loading: boolean = true;
  pagination: any | null = null;
  lang = is;

  orderBy = 'personName';

  form: {
    date: Date | undefined;
    workplace: string | undefined;
    ssn: string | undefined;
    name: string | undefined;
    birthYear:
      | Array<{
          id: string;
          name: string;
        }>
      | undefined;
  } = {
    date: new Date(),
    workplace: undefined,
    ssn: undefined,
    name: undefined,
    birthYear: undefined,
  };

  workplaces: {
    loading: boolean;
    error: boolean;
    data: Array<WorkplaceDropdown> | null;
  } = {
    loading: true,
    error: false,
    data: null,
  };

  birthYearList: {
    loading: boolean;
    error: boolean;
    data: Array<number> | null;
  } = {
    loading: true,
    error: false,
    data: null,
  };

  get birthYears() {
    return this.birthYearList.data?.map(birthYear => ({
      id: birthYear,
      name: birthYear,
    }));
  }
  clearFilters() {
    this.$set(this, 'form', {
      date: new Date(),
      workplace: undefined,
      ssn: undefined,
      name: undefined,
      birthYear: undefined,
    });
    this.loadList();
  }

  async loadList(page?: number, limit?: number) {
    const variables = {
      page: page || undefined,
      limit: limit || undefined,
      ssn: this.form.ssn,
      name: this.form.name,
      date: this.form.date ? moment(this.form.date).format('YYYY-MM-DD') : undefined,
      workplace: this.form.workplace,
      birthYear: this.form.birthYear && this.form.birthYear.length > 0 ? this.form.birthYear.map(y => y.id) : undefined,
      orderBy: this.orderBy as OrderBy,
    };

    this.loading = true;
    this.data = [];

    try {
      const response = await this.$placementApi.placementGroupPersonList(variables);

      this.data = response.data;

      this.pagination = parsePagination(response);
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp við að sækja lista. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading = false;
    }
  }

  async loadWorkplaces() {
    this.workplaces.loading = true;
    this.workplaces.data = [];
    this.workplaces.error = false;

    try {
      const response = await this.$coreApi.workplaceDropdownList();

      this.workplaces.data = response.data;
    } catch (e) {
      this.displayError(`Óvænt villa kom upp við að sækja vinnustaði. Vinsamlegast reyndu aftur.`);
      this.workplaces.error = true;
    } finally {
      this.workplaces.loading = false;
    }
  }

  async loadBirthYears() {
    this.birthYearList.loading = true;
    this.birthYearList.data = [];
    this.birthYearList.error = false;

    try {
      const response = await this.$placementApi.placementGroupPersonBirthYearLookups();

      this.birthYearList.data = response.data;
    } catch (e) {
      this.displayError(`Óvænt villa kom uppvið að sækja fæðingarár. Vinsamlegast reyndu aftur.`);
      this.birthYearList.error = true;
    } finally {
      this.birthYearList.loading = false;
    }
  }

  changeLimit(limit: number) {
    this.loadList(undefined, limit);
  }

  changeSort(clickedSortValue: OrderBy) {
    if (this.orderBy === clickedSortValue) {
      this.orderBy = `-${clickedSortValue}`;
    } else {
      this.orderBy = `${clickedSortValue}`;
    }

    this.loadList();
  }

  changePage(page: number) {
    this.loadList(page, this.pagination.currentLimit);
  }

  async created() {
    this.loadWorkplaces();
    this.loadBirthYears();
    this.loadList();
  }

  async excel() {
    const requestParameters: PlacementApiPlacementGroupPersonListRequest = {
      date: this.form.date ? moment(this.form.date).format('YYYY-MM-DD') : undefined,
      workplace: this.form.workplace,
    };

    const excelData = await PlacementApiAxiosParamCreator().placementGroupPersonExcel(
      requestParameters.birthYear,
      requestParameters.date,
      requestParameters.name,
      requestParameters.orderBy,
      requestParameters.period,
      requestParameters.periodPart,
      requestParameters.placementGroup,
      requestParameters.ssn,
      requestParameters.workplace,
      requestParameters.workplaceDistrict,
      requestParameters.workplaceType,
    );

    window.location.href = excelData.url;
  }
}
